import { router } from "@/lib/router"
import { ToolbarButton } from "@/lib/components/toolbar/toolbar-button"

export class ToolbarBackButton extends ToolbarButton
   constructor: (options, callback) ->
      assertArgs(arguments, optional(Object), optional(Function))
      options = options or {}
      options.icon = options.icon or ToolbarButton.ICONS.BACK
      options.title = options.title or "Back"
      options.isVisible = options.isVisible or router.hasHistory()
      order = options.order or 1
      callback = callback or () -> router.back() if router.hasHistory()
      super(options, order, callback)

