import "./grid.styl"
import gridFn from "./grid.pug"
import { ScrollbarMediator } from "@/lib/mediators/scrollbar-mediator"
import { Guid as GUID } from "@/lib/utils/guid"
import { ColumnGroup } from "@/models/column-group"
import ko from "knockout"

Sort = {
   ASCENDING: 'ascending',
   DESCENDING: 'descending'
}

PADDING_BEFORE_COLUMNS = 15;
PADDING_BETWEEN_COLUMNS = 5;

export class Grid
   constructor: (params) ->
      assertArgs(arguments, Object)
      guid = GUID()
      @containerClass = "grid-#{guid}"
      @contentClass = "grid-content-#{guid}"
      @gridRowsContentClass = 'grid__rows-container'
      @gridRowsClass = 'grid__rows'
      @horizontalScrollbarClass = 'grid'
      @horizontalScrollbarContentClass = 'grid__content'
      @props = params
      @isLoading = params.isLoading
      @isCheckable = params.isCheckable
      @noDataMessage = params.noDataMessage
      @columnGroups = params.columnGroups
      @rows = params.rows 
      @context = params.context
      @isChecked = params.isChecked
      @progressMediator = params.progressMediator
      @topProgressMediator = params.topProgressMediator             
      @verticalScrollbarMediator = new ScrollbarMediator()
      @rowsCheckContentSizeSubscription = @subscribeAndCheckContentSize_(@rows, @verticalScrollbarMediator)
      
      @rowsCheckFillHeightSubscription = @rows.subscribe () => 
         parent = document.getElementsByClassName(@gridRowsContentClass)[0]
         child = document.getElementsByClassName(@gridRowsClass)[0]
         requestAnimationFrame () =>
            if parent? and child?
               parentHeight = parent.offsetHeight
               childHeight = child.offsetHeight
               if childHeight < parentHeight
                  @onReachedBottom() 
                  @onReachedTop()


      @horizontalScrollbarMediator = new ScrollbarMediator()
      @columnGroupsSubscription = @subscribeAndCheckContentSize_(@columnGroups, @horizontalScrollbarMediator); 

      @computedColumnGroups = ko.pureComputed () => 
         isCheckable = ko.unwrap(params.isCheckable)
         columnGroups = @columnGroups().concat()
         if isCheckable && columnGroups.length > 0
            columnGroups.unshift(new ColumnGroup({
                     disableDrag: true
                     columns: [{
                        key: 'grid-checkable'
                        headerTemplate: 'grid-header-checkbox'
                        cellTemplate: 'grid-cell-checkbox'
                        isSortable: false
                        width: 40
                     }]
                  }))
         columnGroups.forEach (c, index) => c.sequence(index)          
         return columnGroups

      @combinedColumnWidth = ko.pureComputed =>
         totalWidth = PADDING_BEFORE_COLUMNS
         for columnGroup in @computedColumnGroups()
            for column in columnGroup.columns()
               totalWidth += column.width() + PADDING_BETWEEN_COLUMNS
         return totalWidth
   
      @numberOfResizableColumns = ko.pureComputed =>
         totalResizableColumns = 0; 

         for group in @computedColumnGroups() 
            for column in group.columns()
               if column.resize()
                  totalResizableColumns += 1
            
         return totalResizableColumns;

   subscribeAndCheckContentSize_: (observable, mediator) =>
      return observable.subscribe () =>
         if mediator.initialized
            mediator.checkContentSize()
      
   createRenderContext: (column, row) => 
      return {
         vm: @context
         grid: @
         column: column
         row: row
      }

   onRowClick: (row, index) =>
      @props.onRowClick(row, index()) if @props.onRowClick?

   trackTopElement: (index, row) =>
      @props.trackTopElement(index, row) if @props.trackTopElement?

   onSort: (column) =>
      @props.onSort(column) if @props.onSort?

   getSortArrowClass: (column) =>
      if column.sortActive()
         return 'icon-arrow-up--asc' if column.sortOrder() == Sort.ASCENDING
         return 'icon-arrow-up--desc' if column.sortOrder() == Sort.DESCENDING
      return 'icon-arrow-up--default'
      
   onAllRowsToggle: (isChecked) =>
      @props.onToggleAllRows(isChecked) if @props.onToggleAllRows?
   
   onRowToggle: (_, row) =>
      @props.onRowToggle(row) if @props.onRowToggle?
   
   onReachedBottom: () =>
      @props.onReachedBottom() if @props.onReachedBottom?
      
   onReachedTop: () =>
      @props.onReachedTop() if @props.onReachedTop?
      
   onColumnHeaderDrag: (element) =>
      loopingEl = element
      newColumnSequence = 0

      while (loopingEl = loopingEl.previousSibling) != null
         # Filter out virtual elements
         continue unless loopingEl.innerHTML?
         newColumnSequence++
      updatedColumnHeader = ko.dataFor(element)
      @onColumnDragUpdate updatedColumnHeader, newColumnSequence
      
   onColumnDragUpdate: (columnGroupToUpdate, newColumnSequence) ->
      return if newColumnSequence == columnGroupToUpdate.sequence()
      @props.onDragUpdate(columnGroupToUpdate, newColumnSequence) if @props.onDragUpdate?
      
   dispose: () =>
      @rowsCheckContentSizeSubscription.dispose()
      @columnGroupsSubscription.dispose()
      @rowsCheckFillHeightSubscription.dispose()

grid = gridFn()

ko.components.register("grid",
   viewModel: Grid,
   template: grid
)
