import "./photo-uploader.styl"
import photoUploaderTemplateFn from "./photo-uploader.pug"
import { cloudinaryStore } from "@/stores/cloudinary-store"
import ko from "knockout"

export class PhotoUploader
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.photo, Function)
      assertOfType(params.callback, optional(Function))
      assertOfType(params.disableEdit, optional([Boolean, Function]))

      @logoUrl = "#{window.config.LC_DOMAIN}/images/lc-logo-gray.png"
      @photo = params.photo
      @callback = if params.callback? then params.callback else () ->
      @showLoading = ko.observable(false)
      if params.disableEdit?
         if params.disableEdit instanceof Function
            @disableEdit = params.disableEdit
         else
            @disableEdit = ko.observable(params.disableEdit)
      else
         @disableEdit = ko.observable(false)

      # Control properties.
      @showFormatAlert = ko.observable(false)

      @disposablePhotoBlock = false

      @newFile = ko.observable()
      @newFile.subscribe (newVal) =>
         return unless newVal?
         @showLoading(true)
         @showFormatAlert(false)
         @disposablePhotoBlock = true
         cloudinaryStore.uploadPhoto newVal, (err, data) =>
            return console.log "err: ", err if err
            if ['png', 'jpg', 'jpeg', 'gif'].indexOf(data.format) == -1
               @showFormatAlert(true)
               @showLoading(false)
               return @photo(null)
            urlChunks = data.secure_url.split("upload/")
            cleanedUrl = "#{urlChunks[0]}upload/fl_ignore_aspect_ratio/#{urlChunks[1]}"
            @photo(cleanedUrl)
            @showLoading(false)
            @callback(data.secure_url)

   clearPhoto: ->
      @photo(null)
      @callback(null)


photoUploaderTemplate = photoUploaderTemplateFn()

ko.components.register("photo-uploader",
   viewModel: PhotoUploader,
   template: photoUploaderTemplate,
)
