import "./color-selector.styl"
import colorSelectorTemplateFn from "./color-selector.pug"
import { Popup } from "@/lib/components/popup/popup"
import ko from "knockout"

### Popups ###
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"

export class ColorSelector
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.value, Function)

      @selectedColor = params.value
      @colorStrings = defaultStore.getResourceColorStrings (err, colors) => return colors

      @colorSelectorPopupBuilder = =>
         return new Popup("Select Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@selectedColor)],
            ['color-selector__color-btn', 'color-selector__color-btn__fill'], ['popup--color-selector', 'popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['color-selector__color-btn__fill']}
      }

   clearSelection: =>
      return unless @clearable()
      @selectedDate(null)

colorSelectorTemplate = colorSelectorTemplateFn()

ko.components.register("color-selector",
   viewModel: ColorSelector,
   template: colorSelectorTemplate,
)
