import "./workday-calendar-pane.styl"
import template from "./workday-calendar-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { DateUtils } from "@/lib/utils/date"

export class WorkdayCalendarPane extends PopupPane
   constructor: (startDate, endDate, defaultIndicies, activeAnomalys, inactiveAnomalys) ->
      assertArgs(arguments, Date, Date, arrayOf(Number), Function, Function)
      assertOfType(activeAnomalys(), arrayOf(Number))
      assertOfType(inactiveAnomalys(), arrayOf(Number))
      super(template())
      # Params
      @startDate = ko.observable(startDate)
      @endDate = ko.observable(endDate)
      @defaultActiveDayIndicies = ko.observableArray(defaultIndicies)
      @activeAnomalyDates = activeAnomalys
      @inactiveAnomalyDates = inactiveAnomalys

      # Popup Properties
      @title = ko.observable()
      @leftActionBtn = ko.observable({
         iconClass: "icon-left-arrow"
         callback: @backBtnClick
      })
      @rightActionBtn = ko.observable({
         iconClass: "icon-righ-arrow"
         callback: @forwardBtnClick
      })

      # Date Properties
      @curMonthIndex = ko.observable()
      @curYear = ko.observable()
      @currentTitleString = ko.pureComputed =>
         return "#{DateUtils.getMonth(@curMonthIndex())} #{@curYear()}"

      @startingDayOfWeek = ko.computed =>
         year = ko.unwrap(@curYear)
         monthIndex = ko.unwrap(@curMonthIndex)
         return null unless year? and monthIndex?
         date = new Date(year, monthIndex)
         return date.getDay()

      @hasSixthRow = ko.observable(false)
      @daysData = ko.observableArray()
      @hasDays = ko.pureComputed =>
         return if @daysData().length then true else false

      @startingDayOfWeek.subscribe (newVal) =>
         return unless newVal?
         daysData = []
         # Leading days for previous month
         unless newVal == 0
            prevMonthLastDay = new Date(@curYear(), @curMonthIndex(), 0).getDate()
            prevMonthDayToShow = prevMonthLastDay - (newVal - 1)
            i = 0
            while i < newVal
               if @curMonthIndex() == 0
                  month = 11
                  year = @curYear() - 1
               else
                  month = @curMonthIndex() - 1
                  year = @curYear()
               active = @getActiveState(new Date(year, month, prevMonthDayToShow))

               daysData.push({name: prevMonthDayToShow, value: false, monthIndex: month, year: year, active: ko.observable(active), activeMonth: false})
               prevMonthDayToShow++
               i++
         # Days for current month
         curMonthLastDate = new Date(@curYear(), (@curMonthIndex() + 1), 0).getDate()
         for day in [1..curMonthLastDate]
            active = @getActiveState(new Date(@curYear(), @curMonthIndex(), day))
            daysData.push({name: day, value: day, monthIndex: @curMonthIndex(), year: @curYear(), active: ko.observable(active), activeMonth: true})
         # Trailing days of next month
         curMonthLastDay = new Date(@curYear(), (@curMonthIndex() + 1), 0).getDay()
         unless curMonthLastDay == 6
            i = 1
            while curMonthLastDay < 6
               if @curMonthIndex() == 11
                  month = 0
                  year = @curYear() + 1
               else
                  month = @curMonthIndex() + 1
                  year = @curYear()
               active = @getActiveState(new Date(year, month, i))
               daysData.push({name: i, value: false, monthIndex: month, year: year, active: ko.observable(active), activeMonth: false})
               curMonthLastDay++
               i++
         @hasSixthRow(false) unless curMonthLastDate == 31 and newVal > 4
         @daysData(daysData)
         @hasSixthRow(true) if curMonthLastDate == 31 and newVal > 4

      # Trigger inital load
      @curMonthIndex(@startDate().getMonth())
      @curYear(@startDate().getFullYear())

   getActiveState: (date) ->
      assertOfType(date, Date)
      epochDay = date.setHours(0,0,0,0) / 1000
      if @defaultActiveDayIndicies().indexOf(date.getDay()) != -1
         return true unless @inactiveAnomalyDates().indexOf(epochDay) != -1
         return false
      else
         return false unless @activeAnomalyDates().indexOf(epochDay) != -1
         return true

   dateClicked: (data) =>
      return unless data.value
      date = new Date(data.year, data.monthIndex, data.value)
      epochDay = date.setHours(0,0,0,0) / 1000
      if @defaultActiveDayIndicies().indexOf(date.getDay()) != -1
         if @inactiveAnomalyDates().indexOf(epochDay) != -1
            @inactiveAnomalyDates.remove(epochDay)
            data.active(true)
         else
            @inactiveAnomalyDates.push(epochDay)
            data.active(false)
      else
         if @activeAnomalyDates().indexOf(epochDay) != -1
            @activeAnomalyDates.remove(epochDay)
            data.active(false)
         else
            @activeAnomalyDates.push(epochDay)
            data.active(true)

   pageBack: =>
      unless @curMonthIndex() == 0
         return @curMonthIndex(@curMonthIndex() - 1)
      @curYear(@curYear() - 1)
      return @curMonthIndex(11)

   pageForward: =>
      unless @curMonthIndex() == 11
         return @curMonthIndex(@curMonthIndex() + 1)
      @curYear(@curYear() + 1)
      return @curMonthIndex(0)
