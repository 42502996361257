import "./element-anchored-popup.styl";
import template from "./element-anchored-popup.pug";
import type { components, MaybeObservable, Observable } from "knockout";
import { isObservable } from "knockout";
import ko, { observable, unwrap } from "knockout";
import type { AnchoredPopupParams } from "@/lib/components/anchord-popup/anchored-popup";
import type { Bounds } from "@/lib/utils/geometry";

export interface ElementAnchoredPopupParams
   extends Omit<
      AnchoredPopupParams,
      "isVisible" | "viewportBounds" | "anchorBounds" | "data" | "contentNodes"
   > {
   isPopupVisible: Observable<boolean>;

   anchorData?: MaybeObservable<unknown> | null;
   anchorNodes?: MaybeObservable<HTMLElement[]> | null;
   elementData?: MaybeObservable<unknown> | null;
   elementNodes?: MaybeObservable<HTMLElement[]> | null;

   anchorBounds?: MaybeObservable<Bounds | null>;
   viewportBounds?: MaybeObservable<Bounds>;
}

/**
 * Convenience component for anchoring a popup to an element.
 * Usage:
 * - The first element contained within the element-anchored-popup component
 *   will be used as the anchored element.
 * - All subsequent elements will be used as the content of the popup.
 *
 * @example
 * <element-anchored-popup params="isPopupVisible: isPopupVisible">
 *    <button>Anchored button</button>
 *    <div class="popup-content">
 *       <span>Content of popup</span>
 *    </div>
 * </element-anchored-popup>
 */
export class ElementAnchoredPopup {
   readonly viewportBounds: Exclude<ElementAnchoredPopupParams["viewportBounds"], undefined>;
   readonly anchorBounds: Exclude<ElementAnchoredPopupParams["anchorBounds"], undefined>;

   readonly elementNodes: HTMLElement[];
   readonly anchorNodes: HTMLElement[];

   constructor(
      public readonly params: ElementAnchoredPopupParams,
      public readonly contentNodes: HTMLElement[],
   ) {
      this.viewportBounds =
         params.viewportBounds ?? observable<Bounds>({ top: 0, left: 0, width: 0, height: 0 });

      this.anchorBounds = params.anchorBounds
         ? isObservable(params.anchorBounds)
            ? params.anchorBounds
            : (observable<Bounds>(params.anchorBounds as any) as any)
         : observable<Bounds>({ top: 0, left: 0, width: 0, height: 0 });

      if (params.elementNodes) {
         this.elementNodes = unwrap(params.elementNodes);
         this.anchorNodes = params.anchorNodes ? unwrap(params.anchorNodes) : contentNodes;
      } else {
         this.elementNodes = contentNodes.slice(0, 1);
         this.anchorNodes = params.anchorNodes ? unwrap(params.anchorNodes) : contentNodes.slice(1);
      }
   }
}

ko.components.register("element-anchored-popup", {
   viewModel: {
      createViewModel: (params: components.ViewModelParams, componentInfo) => {
         return new ElementAnchoredPopup(
            params as ElementAnchoredPopupParams,
            componentInfo.templateNodes as HTMLElement[],
         );
      },
   } as components.ViewModelFactory,
   template: template(),
   synchronous: true,
});
