import "./time-input.styl"
import timeInputTemplateFn from "./time-input.pug"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import ko from "knockout"

export class TimeInput
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.selectedTime, Function)
      assertOfType(params.selectedTime(), nullable(Number))
      @selectedTime = params.selectedTime
      @meridianOptions = ko.observableArray([
         new SegmentedControllerItem("AM", "am")
         new SegmentedControllerItem("PM", "pm")
         ])
      @selectedMeridian = ko.observable(new SegmentedControllerItem("AM", "am"))

      @hourValue = ko.observable(12)
      @minuteValue = ko.observable('00')

      if @selectedTime()?
         hourValue = Math.floor(@selectedTime())
         minuteValue = Math.round(60 * (@selectedTime() - hourValue))
         minuteValue = "0#{minuteValue}" if String(minuteValue).length == 1
         if hourValue > 12
            @hourValue(hourValue - 12)
            @selectedMeridian(new SegmentedControllerItem("PM", "pm"))
         else
            @hourValue(hourValue)
         @minuteValue(minuteValue)

      @selectedMeridian.subscribe =>
         @updateValue()

      @hourValue.subscribe (newVal) =>
         return @hourValue(12) if isNaN(newVal) or Number(newVal) > 12 or !newVal?
         @updateValue()

      @minuteValue.subscribe (newVal) =>
         return @minuteValue('00') if isNaN(newVal) or Number(newVal) > 60 or !newVal?
         @updateValue()

   updateValue: =>
      hour = Number(@hourValue())
      minute = Number((Number(@minuteValue())/60).toPrecision(3))
      time = hour + minute
      time = time + 12 if @selectedMeridian().value() == "pm"
      @selectedTime(time)


timeInputTemplate = timeInputTemplateFn()

ko.components.register("time-input",
   viewModel: TimeInput,
   template: timeInputTemplate,
)