import "./button-icon.styl";
import template from "./button-icon.pug";
import type { components, MaybeObservable, PureComputed } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";

export interface ButtonIconParams {
   /** Required: Called but the button is clicked. */
   onClick: () => void;

   /** Whether the button is disabled. */
   isDisabled?: MaybeObservable<boolean> | PureComputed<boolean>;

   /** Tooltip shown when the button is hovered. */
   tooltip?: MaybeObservable<string | null>;

   /** Context to use when binding the content nodes (i.e. the icon). */
   data?: MaybeObservable<unknown> | null;
}

/**
 * Button containing an icon with a hovering tooltip.
 *
 * @example
 * include "../../icons/icon-message"
 *
 * <button-icon params="...">
 *    +icon-message
 * </button-icon>
 */
export class ButtonIcon {
   readonly onClick: () => void;
   readonly isDisabled: MaybeObservable<boolean> | PureComputed<boolean>;
   readonly tooltip: MaybeObservable<string | null>;
   readonly data: MaybeObservable<unknown> | null;

   readonly isHovered = observable(false);
   readonly isPopupVisible = pureComputed(() => {
      return this.isHovered() && unwrap(this.tooltip);
   });

   constructor(
      { onClick, isDisabled = false, tooltip = null, data = null }: ButtonIconParams,
      public readonly iconContentNodes: Node[],
   ) {
      this.onClick = onClick;
      this.isDisabled = isDisabled;
      this.tooltip = tooltip;
      this.data = data;
   }
}

ko.components.register("button-icon", {
   viewModel: {
      createViewModel: (params: components.ViewModelParams, componentInfo) => {
         return new ButtonIcon(params as ButtonIconParams, componentInfo.templateNodes);
      },
   } as components.ViewModelFactory,
   template: template(),
   synchronous: true,
});
