var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-lock"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-lock\" width=\"12px\" height=\"16px\" viewbox=\"0 0 12 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-lock\"\u003E\u003Cpath id=\"icon-lock__shape\" d=\"M10.5,5.33333333 L9.75,5.33333333 L9.75,3.80952381 C9.75,1.70666667 8.07,0 6,0 C3.93,0 2.25,1.70666667 2.25,3.80952381 L2.25,5.33333333 L1.5,5.33333333 C0.675,5.33333333 0,6.01904762 0,6.85714286 L0,14.4761905 C0,15.3142857 0.675,16 1.5,16 L10.5,16 C11.325,16 12,15.3142857 12,14.4761905 L12,6.85714286 C12,6.01904762 11.325,5.33333333 10.5,5.33333333 Z M6,12.1904762 C5.175,12.1904762 4.5,11.5047619 4.5,10.6666667 C4.5,9.82857143 5.175,9.14285714 6,9.14285714 C6.825,9.14285714 7.5,9.82857143 7.5,10.6666667 C7.5,11.5047619 6.825,12.1904762 6,12.1904762 Z M8.325,5.33333333 L3.675,5.33333333 L3.675,3.80952381 C3.675,2.50666667 4.7175,1.44761905 6,1.44761905 C7.2825,1.44761905 8.325,2.50666667 8.325,3.80952381 L8.325,5.33333333 Z\" fill=\"#7C8084\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003Crect id=\"icon-lock__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"12\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-integration-arrows"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-integrations-arrows\" width=\"16px\" height=\"16px\" viewBox=\"0 0 16 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"icon-integration-arrows\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-integration-arrows__shape\" transform=\"translate(0.000000, 0.500000)\" stroke=\"#7C8084\" stroke-width=\"1.5\"\u003E\u003Cg id=\"Group-2\"\u003E\u003Cline id=\"Path\" x1=\"4.42284758e-13\" y1=\"3.680032\" x2=\"14.3115115\" y2=\"3.680032\"\u003E\u003C\u002Fline\u003E\u003Cpolyline id=\"Path\" stroke-linejoin=\"round\" points=\"11.4035115 -1.13686838e-14 15.1875115 3.784 11.4035115 7.568128\"\u003E\u003C\u002Fpolyline\u003E\u003C\u002Fg\u003E\u003Cg id=\"Group-2\" transform=\"translate(8.000000, 10.880000) rotate(180.000000) translate(-8.000000, -10.880000) translate(-0.000000, 6.880000)\"\u003E\u003Cline id=\"Path\" x1=\"4.42284758e-13\" y1=\"3.680032\" x2=\"14.3115115\" y2=\"3.680032\"\u003E\u003C\u002Fline\u003E\u003Cpolyline id=\"Path\" stroke-linejoin=\"round\" points=\"11.4035115 -1.13686838e-14 15.1875115 3.784 11.4035115 7.568128\"\u003E\u003C\u002Fpolyline\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003Crect id=\"icon-integration-arrows__bg\" fill-opacity=\"0\" fill=\"#D8D8D8\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"integrated-field-indicator\"\u003E\u003C!-- ko if: showIntegrated --\u003E";
pug_mixins["icon-integration-arrows"]();
pug_html = pug_html + "\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: showLocked --\u003E";
pug_mixins["icon-lock"]();
pug_html = pug_html + "\u003C!-- \u002Fko --\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;