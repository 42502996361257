import "./placeholder-card.styl";
import template from "./placeholder-card.pug";
import { Color as ColorUtil } from "@/lib/utils/color";
import type { Observable, PureComputed } from "knockout";
import ko from "knockout";
import {
   formatTimeValue,
   formatDetachedDay,
} from "@laborchart-modules/common/dist/datetime/format";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { defaultStore } from "@/stores/default-store";

/* Models */
import { PermissionLevel } from "@/models/permission-level";
import type { ValueSet } from "@/models/value-set";
import type { Position } from "@/models/position";
import type { Placeholder } from "@/models/placeholder";

/* Modals */
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { FyiInfoPaneViewModel } from "@/lib/components/modals/fyi-info-pane";

/* Popups */
import { Popup } from "@/lib/components/popup/popup";
import { PlaceholderCardInfoPane } from "@/lib/components/popup/placeholder-card-info-pane/placeholder-card-info-pane";

export type PlaceholderCardParams = {
   data: {
      creatorId: Observable<string | null>;
      endDay: Observable<number>;
      endTime: Observable<number | null>;
      percentAllocated: Observable<number | null>;
      position: Observable<Position | null>;
      resourceSubtitle: Observable<string>;
      resourceTitle: Observable<string>;
      status: Observable<ValueSet | null>;
      startDay: Observable<number>;
      startTime: Observable<number | null>;
      tbdEndDate: Observable<boolean>;
   };
   resourceOptions: PureComputed<ValueSet[]>;
   editCallback: (...params: unknown[]) => void;
   resourceSelectedCallback: (...params: unknown[]) => void;
   invalidFillCallback: (...params: unknown[]) => void;
};

export class PlaceholderCard {
   private readonly data: PlaceholderCardParams["data"];
   private readonly editCallback: PlaceholderCardParams["editCallback"];
   private readonly resourceSelectedCallback: PlaceholderCardParams["resourceSelectedCallback"];
   private readonly invalidFillCallback: PlaceholderCardParams["invalidFillCallback"];
   private readonly resourceOptions: PlaceholderCardParams["resourceOptions"];

   private readonly allowOverflow: Observable<boolean>;
   private readonly btnsVisible: Observable<boolean>;
   private readonly placeholderInfoPopupWrapper: Observable<unknown>;

   private readonly canManageRequests = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_REQUESTS,
   );
   private readonly canManageOthersRequests = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_OTHERS_REQUESTS,
   );
   private readonly canManageAssignments = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_ASSIGNMENTS,
   );
   private readonly canEditRequest = ko.pureComputed(() => {
      if (!this.canManageRequests) {
         return false;
      }
      if (this.data.creatorId() !== authManager.authedUserId()) {
         if (!this.canManageOthersRequests) {
            return false;
         }
      }
      return true;
   });

   private readonly subtitle = ko.pureComputed(() => {
      const startDayText = formatDetachedDay(this.data.startDay(), defaultStore.getDateFormat());
      const endDayText =
         this.data.tbdEndDate() === true
            ? "TBD"
            : formatDetachedDay(this.data.endDay(), defaultStore.getDateFormat());
      return `${startDayText} - ${endDayText}`;
   });

   private readonly startTime = ko.pureComputed(() => {
      const startTime = this.data.startTime();
      if (startTime == null) {
         return "";
      }
      return formatTimeValue(startTime);
   });

   private readonly endTime = ko.pureComputed(() => {
      const endTime = this.data.endTime();
      if (endTime == null) {
         return "";
      }
      return formatTimeValue(endTime);
   });

   private readonly positionColorDark = ko.pureComputed(() => {
      return ColorUtil.colorIsDark(this.data.position()?.color());
   });

   private readonly truncateName = ko.pureComputed(() => this.data.resourceTitle().length >= 16);

   private readonly truncateSubtitle = ko.pureComputed(
      () => this.data.resourceSubtitle() != null && this.data.resourceSubtitle().length >= 30,
   );

   private readonly title = ko.pureComputed(() => {
      const position = this.data.position();
      if (position != null) {
         return position.name();
      } else {
         return "Request";
      }
   });

   constructor(params: PlaceholderCardParams) {
      this.allowOverflow = ko.observable(false);
      this.data = params.data;
      this.resourceOptions = params.resourceOptions;
      this.resourceSelectedCallback = params.resourceSelectedCallback;
      this.invalidFillCallback = params.invalidFillCallback;
      this.editCallback = params.editCallback;
      this.btnsVisible = ko.observable(false);

      const placeholderInfoPopupBuilder = (data: { data: Placeholder }) => {
         if (!this.canEditRequest) {
            return;
         }
         this.allowOverflow(true);
         return new Popup(
            "Request Info",
            Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_RIGHT,
            [
               new PlaceholderCardInfoPane(
                  data.data,
                  this.resourceOptions,
                  this.fillingResourceSelected,
               ),
            ],
            [
               "placeholder-card__color-bar",
               "placeholder-card__fill-space",
               "icon-fill-placeholder-white",
               "icon-fill-placeholder",
            ],
            ["placeholder-card__info-popup", "dragula-no-drag-descendents"],
            this.infoPopupDismissed,
         );
      };
      this.placeholderInfoPopupWrapper = ko.observable<any>({
         popupBuilder: placeholderInfoPopupBuilder,
         options: {
            triggerClasses: ["icon-fill-placeholder-white", "icon-fill-placeholder"],
         },
         dynamicPositioning: true,
      });
   }

   private readonly infoPopupDismissed = (): void => {
      return this.allowOverflow(false);
   };

   private readonly handleDragFill = (
      element: Element,
      endContainer: Element,
      endContainerData: unknown,
      sourceContainer: Element,
      sourceData: unknown,
   ): void => {
      if (this.canEditRequest() !== true) {
         return;
      }
      const assignmentCard = ko.dataFor(element);
      element.parentNode!.removeChild(element);
      if (assignmentCard.id != null) {
         return this.invalidFillCallback(assignmentCard, sourceData);
      }
      return this.resourceSelectedCallback(this.data, ko.unwrap(assignmentCard.resourceId));
   };

   cannotEditWarningClicked(): void {
      const message = "You do not have permission to edit this request.";
      const pane1 = new FyiInfoPaneViewModel("Access Blocked", message);
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-action-modal",
         },
         function () {},
      );
   }

   fillingResourceSelected = (resourceValueSet: ValueSet): void => {
      return this.resourceSelectedCallback(this.data, resourceValueSet.value());
   };

   handleEditPlaceholder(): void {
      return this.editCallback(this.data);
   }

   showBtns(): void {
      if (!this.canEditRequest) {
         return;
      }
      return this.btnsVisible(true);
   }

   hideBtns(): void {
      if (!this.canEditRequest) {
         return;
      }
      return this.btnsVisible(false);
   }
}

ko.components.register("placeholder-card", {
   viewModel: PlaceholderCard,
   template: template(),
});
