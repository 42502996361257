import type { MaybeObservable } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import template from "./validation-text.pug";
import "./validation-text.styl";

export interface ValidationTextParams {
   text: MaybeObservable<string>;
   paddingTop?: MaybeObservable<number>;
   paddingBottom?: MaybeObservable<number>;
}

export class ValidationText {
   readonly text: MaybeObservable<string>;
   readonly activeIndex = pureComputed(() => {
      return unwrap(this.text) ? 0 : -1;
   });
   readonly style = pureComputed(() => {
      const paddingTop = unwrap<number | null>(this.params.paddingTop || null);
      const paddingBottom = unwrap<number | null>(this.params.paddingBottom || null);
      return {
         ...(paddingTop != null ? { paddingTop: `${paddingTop}px` } : {}),
         ...(paddingBottom != null ? { paddingBottom: `${paddingBottom}px` } : {}),
      };
   });

   constructor(readonly params: ValidationTextParams) {
      this.text = params.text;
   }
}

ko.components.register("validation-text", {
   viewModel: ValidationText,
   template: template(),
   synchronous: true,
});
