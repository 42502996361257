import "./placeholder-card-info-pane.styl";
import template from "./placeholder-card-info-pane.pug";
import { DateUtils } from "@/lib/utils/date";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { defaultStore } from "@/stores/default-store";

/* Popups */
import { PopupPane } from "@/lib/components/popup/popup-pane";

/* Models */
import { PermissionLevel } from "@/models/permission-level";
import type { Observable } from "knockout";
import { observable, pureComputed } from "knockout";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import type { Placeholder } from "@/models/placeholder";
import type { ValueSet } from "@/models/value-set";

export type PlaceholderCardInfoPaneFillHandler = (
   resource: AssignmentSupportData["resourceOptions"][0],
) => void;

export class PlaceholderCardInfoPane extends PopupPane {
   readonly canManageAssignments = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_ASSIGNMENTS,
   );
   readonly canManageOthersRequests = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_OTHERS_REQUESTS,
   );
   readonly canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS);

   readonly batchFormattedEnd: string | null;
   readonly batchFormattedStart: string | null;
   readonly data: Placeholder;
   readonly endTime: string | null;
   readonly fillHandler: PlaceholderCardInfoPaneFillHandler;
   readonly formattedCreatedAt: string;
   readonly friday: boolean;
   readonly header: string;
   readonly monday: boolean;
   readonly percentAllocated: string | null;
   readonly resourceOptions: NestedComputedAssignmentSupportData["resourceOptions"];
   readonly saturday: boolean;
   readonly selectedResource: Observable<ValueSet | null>;
   readonly startTime: string | null;
   readonly sunday: boolean;
   readonly thursday: boolean;
   readonly tuesday: boolean;
   readonly wednesday: boolean;

   readonly enableOverflow = pureComputed(() => true);

   readonly canFillRequest = pureComputed(() => {
      if (!this.canManageAssignments) {
         return false;
      }
      if (!this.canManageRequests) {
         return false;
      }
      if (this.data.creatorId() !== authManager.authedUserId()) {
         if (!this.canManageOthersRequests) {
            return false;
         }
      }
      return true;
   });

   constructor(
      data: Placeholder,
      resourceOptions: NestedComputedAssignmentSupportData["resourceOptions"],
      fillHandler: PlaceholderCardInfoPaneFillHandler,
   ) {
      super(template());

      this.header = data.position()?.name() ?? "Request";
      this.data = data;
      this.resourceOptions = resourceOptions;
      this.fillHandler = fillHandler;
      this.selectedResource = observable(null);

      this.sunday = data.workDays()[0];
      this.monday = data.workDays()[1];
      this.tuesday = data.workDays()[2];
      this.wednesday = data.workDays()[3];
      this.thursday = data.workDays()[4];
      this.friday = data.workDays()[5];
      this.saturday = data.workDays()[6];

      const dateFormatOptions = {
         yearFormat: DateUtils.YearFormat.SHORT,
         monthFormat: DateUtils.MonthFormat.ONE_DIGIT,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
      };
      const startDay = data.startDay();
      const endDay = data.endDay();
      this.batchFormattedStart =
         startDay != null
            ? DateUtils.formatDetachedDay(startDay, defaultStore.getDateFormat(), dateFormatOptions)
            : null;
      this.batchFormattedEnd =
         endDay != null
            ? DateUtils.formatDetachedDay(endDay, defaultStore.getDateFormat(), dateFormatOptions)
            : null;

      const startTime = data.startTime();
      const endTime = data.endTime();
      this.startTime =
         startTime != null ? (this.startTime = defaultStore.formatTime(startTime)) : null;
      this.endTime = endTime != null ? (this.endTime = defaultStore.formatTime(endTime)) : null;
      this.percentAllocated = data.percentAllocated()?.toString() ?? null;

      const createdAt = new Date(data.createdAt());
      const formattedDate = DateUtils.formatDate(createdAt, defaultStore.getDateFormat(), {
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
      });
      this.formattedCreatedAt = `${formattedDate} at ${DateUtils.getTime(createdAt)}`;
   }
}
