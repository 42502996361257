import "./files-uploader.styl"
import filesUploaderTemplateFn from "./files-uploader.pug"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { attachmentStore } from "@/stores/attachment-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { FileDownloadPaneViewModel } from "@/lib/components/modals/file-download-pane"
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane"

### Models ###
import { Attachment } from "@/models/attachment"

export class FilesUploader
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.files, [arrayOf(Attachment), Function])
      assertOfType(params.uploadCallback, optional(Function))
      assertOfType(params.deleteCallback, optional(Function))
      assertOfType(params.allowUploadPerm, optional(Boolean))
      assertOfType(params.requireTwoStep, optional(Boolean))
      assertOfType(params.isUploading, optional(Function))
      @allowUploadPerm = if params.allowUploadPerm? then params.allowUploadPerm else true
      if params.files instanceof Function
         assertOfType(params.files(), arrayOf(Attachment))
         @files = params.files
      else
         @files = ko.observableArray(params.files)

      @uploadCallback = params.uploadCallback
      @deleteCallback = params.deleteCallback
      @requireTwoStep = params.requireTwoStep or false
      @isUploading = if params.isUploading? then params.isUploading else ko.observable(false)
      @isUploading(false)

   handleNewFile: (err, file) =>
      return unless @allowUploadPerm
      filetype = if file.type.length != 0 then file.type else null
      tmpAttachment = new Attachment({
         name: file.name,
         mimetype: filetype
         }, true)
      @isUploading(true)
      data = {name: file.name, mimetype: filetype, bytes: file.size}
      attachmentStore.createAttachment data, (err, attachment) =>
         if err
            @isUploading(false)
            return console.log "error: ", err
         tmpAttachment.id = attachment.id
         attachmentStore.uploadFile tmpAttachment.id, file, (err, success) =>
            if err
               @isUploading(false)
               return console.log "err: ", err
            return @isUploading(false) unless success
            tmpAttachment.createdAt(attachment.createdAt())
            @files.push(tmpAttachment)
            @isUploading(false)
            return unless @uploadCallback?
            return @uploadCallback(err) if err
            return @uploadCallback(null, attachment.id)

   deleteFile: (data) =>
      execute = =>
         attachmentStore.deleteAttachment data.id, (err, success) =>
            if err
               return @deleteCallback(err) if @deleteCallback?
               return console.log "error: ", err
            return unless success
            @files.remove(data)
            @deleteCallback(null, data.id) if @deleteCallback?
      if @requireTwoStep
         pane1 = new ConfirmActionPaneViewModel("Remove File", null, "Confirm Deletion")
         modal = new Modal()
         modal.setPanes([pane1])
         modalManager.maybeCancelModal ->
            modalManager.showModal modal, null, {class: 'confirm-action-modal'}, (modal, exitStatus) =>
               return unless exitStatus == "finished"
               execute()
      else
         execute()

   getDashoffset: (percent, radius) ->
      assertArgs(arguments, Number, Number)
      cir = Math.PI * (radius * 2)
      percent = percent / 100
      offset = cir - (cir * percent)
      return offset

   showDowloadModal: (attachment) =>
      return unless attachment.createdAt()?
      pane1 = new FileDownloadPaneViewModel(attachment)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.maybeCancelModal ->
         modalManager.showModal modal, null, {class:"file-download-pane"}

filesUploaderTemplate = filesUploaderTemplateFn()

ko.components.register("files-uploader",
   viewModel: FilesUploader,
   template: filesUploaderTemplate,
)
