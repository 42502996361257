import "./paging-control.styl"
import PagingControlTemplateFn from "./paging-control.pug"
import ko from "knockout"

export class PagingControl
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.pageIndex, Function)
      assertOfType(params.totalCount, Function)
      assertOfType(params.increment, Number)
      @pageIndex = params.pageIndex
      @totalCount = params.totalCount
      @increment = params.increment
      @maxIndex = ko.pureComputed =>
         return 0 unless @totalCount()? and @totalCount() > 0
         return Math.ceil(@totalCount() / @increment) - 1

      @incrementDisabled = ko.pureComputed =>
         return @pageIndex() >= @maxIndex()

      @rangeMin = ko.pureComputed =>
         return '' unless @totalCount()? and @totalCount() > 0
         return (@pageIndex() * @increment) + 1

      @rangeMax = ko.pureComputed =>
         return '' unless @totalCount()? and @totalCount() > 0
         return @totalCount() if @incrementDisabled()
         return (@pageIndex() * @increment) + @increment

   incrementPage: ->
      @pageIndex(@pageIndex() + 1) unless @incrementDisabled()

   decrementPage: ->
      @pageIndex(@pageIndex() - 1) unless @pageIndex() <= 0


PagingControlTemplate = PagingControlTemplateFn()

ko.components.register("paging-control",
   viewModel: PagingControl,
   template: PagingControlTemplate,
)
