import { binarySearch, groupAdjacent } from "@/lib/utils/arrays";
import type { Bounds, Size } from "@/lib/utils/geometry";
import { contains, findContainmentDelta, intersects } from "@/lib/utils/geometry";
import { subscribeWithPreviousValue } from "@/lib/utils/knockout";
import type {
   Observable,
   ObservableArray,
   PureComputed,
   Subscribable,
   Subscription,
} from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import type { GridCell } from "./grid-cell";
import type { GridColumn } from "./grid-column";
import type { GridColumnGroup } from "./grid-column-group";
import type { RowBase } from "./grid-store";

const ROWS_TO_RENDER_PER_FRAME = 2;

export type RenderedColumnGroupHeader<TRow extends RowBase> = {
   columnGroup: GridColumnGroup<TRow>;
   columns: Array<RenderedColumnHeader<TRow>>;
   /** Width of the combined columns. Includes all padding. */
   width: number;
   hasAutoResized: boolean;
};

export type RenderedColumnHeader<TRow extends RowBase> = {
   columnGroup: GridColumnGroup<TRow>;
   column: GridColumn<TRow, unknown>;
   /** Width is exclusive of padding. */
   width: number;
   /** Column padding is both sides combined. */
   columnPadding: number;
};

export type RenderedRow<TRow extends RowBase> = {
   cells: ObservableArray<RenderedCell<TRow>>;
   /** Height is exclusive of rowPadding. */
   height: Observable<number>;
   /** Width is exclusive of columnsPadding */
   width: Observable<number>;
   rowPadding: number;
   columnsPadding: number;
   hasBorder: boolean;
   top: Observable<number>;
   index: number;
   value: TRow;
   hasFocus: Observable<boolean>;
   hasFocusSubscription: Subscription;
   isVisible: Observable<boolean>;
};

export type RenderedCell<TRow extends RowBase> = GridCell<unknown> & {
   column: RenderedColumnHeader<TRow>;
   row: RenderedRow<TRow>;
   index: Observable<number>;
   hasFocus: Observable<boolean>;
   hasFocusSubscription: Subscription;
   left: Observable<number>;
   width: number;
};

export type GridLayoutParams<TRow extends RowBase> = {
   viewport: Subscribable<Size>;
   scrollTop: Observable<number>;
   scrollLeft: Observable<number>;
   paddingContentTop: PureComputed<number>;
   paddingContentBottom: PureComputed<number>;
   headerHeight: number;
   scrollbarSize: number;
   paddingBetweenRows: number;
   paddingBetweenColumns: number;
   paddingAroundColumns: number;
   defaultRowHeight: number;
   hasRowBorders: boolean;
   onRowFocusChanged: (row: RenderedRow<TRow>, hasFocus: boolean) => void;
   onCellFocusChanged: (cell: RenderedCell<TRow>, hasFocus: boolean) => void;
};

/**
 * Class responsible for maintaining the layout of the grid as columns and rows are added/removed
 * and the scroll positions change.
 */
export class GridLayout<TRow extends RowBase> {
   /** All rendered column group headers. Do NOT update directly. */
   readonly renderedColumnGroups = observableArray<RenderedColumnGroupHeader<TRow>>();

   /** Array of the rendered rows. Do NOT update directly. */
   readonly renderedRows = observableArray<RenderedRow<TRow>>();

   /**
    * Subset of the rendered rows that have been rendered to the DOM.
    * Do NOT update directly.
    */
   readonly rowsInDom = observableArray<RenderedRow<TRow>>();

   /** Width of the rendered content including all padding. */
   readonly outerContentWidth = pureComputed(() => {
      return this.renderedColumnGroups().reduce((acc, columnGroup) => {
         return (
            acc +
            columnGroup.columns.reduce((acc, columnHeader) => {
               return acc + columnHeader.width + columnHeader.columnPadding;
            }, 0)
         );
      }, this.paddingAroundColumns * 2);
   });

   /** Height of the rendered content excluding top and bottom content padding. */
   readonly innerContentHeight = pureComputed(() => {
      const rows = this.renderedRows();
      const lastRow = rows.length ? rows[rows.length - 1] : null;
      return lastRow ? lastRow.top() + this.getOuterRowHeight(lastRow) : 0;
   });

   /** Whether the content is larger horizontally than the viewport. */
   readonly hasHorizontalScroll = pureComputed(() => {
      return this.outerContentWidth() > this.viewport().width;
   });

   /** Whether the content is larger vertically than the viewport. */
   readonly hasVerticalScroll = pureComputed(() => {
      const contentHeight =
         this.paddingContentTop() + this.innerContentHeight() + this.paddingContentBottom();
      return contentHeight > this.viewport().height - this.headerHeight;
   });

   /** Bounds of the viewport from the perspective of the rows. */
   readonly rowsViewportBounds = pureComputed(() => {
      const contentViewport = this.contentViewport();
      return {
         top: this.scrollTop() - this.paddingContentTop(),
         left: this.scrollLeft(),
         height: contentViewport.height,
         width: contentViewport.width,
      };
   });

   /**
    * The first (top-most) visible row and last (bottom-most) visible rows or null if no rows
    * are visible.
    */
   readonly visibleRange = pureComputed<[RenderedRow<TRow>, RenderedRow<TRow>] | null>(() => {
      return this.findVisibleRange();
   });

   /** The first (top-most) visible element or null if no rows are rendered. */
   readonly firstVisibleRow = pureComputed<RenderedRow<TRow> | null>(() => {
      const range = this.visibleRange();
      return range ? range[0] : null;
   });

   private readonly viewport: Subscribable<Size>;
   private readonly scrollTop: Observable<number>;
   private readonly scrollLeft: Observable<number>;
   private readonly headerHeight: number;
   private readonly scrollbarSize: number;
   private readonly paddingContentTop: PureComputed<number>;
   private readonly paddingContentBottom: PureComputed<number>;
   private readonly paddingBetweenRows: number;
   private readonly paddingAroundColumns: number;
   private readonly paddingBetweenColumns: number;
   private readonly defaultRowHeight: number;
   private readonly hasRowBorders: boolean;

   private readonly contentViewport = pureComputed<Size>(() => {
      const viewport = this.viewport();
      const horizontalScrollbar = this.hasHorizontalScroll() ? this.scrollbarSize : 0;
      const verticalScollbar = this.hasVerticalScroll() ? this.scrollbarSize : 0;
      return {
         height: viewport.height - this.headerHeight - horizontalScrollbar,
         width: viewport.width - verticalScollbar,
      };
   });
   private readonly onRowFocusChanged: (row: RenderedRow<TRow>, hasFocus: boolean) => void;
   private readonly onCellFocusChanged: (cell: RenderedCell<TRow>, hasFocus: boolean) => void;
   private readonly subscriptions: Subscription[] = [];

   /** Rendered rows that have been queued to be rendered into the DOM via adding to rowsInDom. */
   private queuedRows: Array<RenderedRow<TRow>> = [];
   private hasScheduledDequeueRows = false;

   /**
    * Current column groups. May differ from the renderedColumnGroups if the
    * viewport still has a width of 0.
    */
   private columnGroups: Array<GridColumnGroup<TRow>> = [];

   constructor({
      viewport,
      scrollTop,
      scrollLeft,
      paddingContentTop,
      paddingContentBottom,
      headerHeight,
      scrollbarSize,
      paddingBetweenRows,
      paddingAroundColumns,
      paddingBetweenColumns,
      defaultRowHeight,
      hasRowBorders,
      onRowFocusChanged,
      onCellFocusChanged,
   }: GridLayoutParams<TRow>) {
      this.viewport = viewport;
      this.scrollTop = scrollTop;
      this.scrollLeft = scrollLeft;
      this.paddingContentTop = paddingContentTop;
      this.paddingContentBottom = paddingContentBottom;
      this.headerHeight = headerHeight;
      this.scrollbarSize = scrollbarSize;
      this.paddingBetweenRows = paddingBetweenRows;
      this.paddingAroundColumns = paddingAroundColumns;
      this.paddingBetweenColumns = paddingBetweenColumns;
      this.defaultRowHeight = defaultRowHeight;
      this.hasRowBorders = hasRowBorders;
      this.onRowFocusChanged = onRowFocusChanged;
      this.onCellFocusChanged = onCellFocusChanged;
      this.subscriptions.push(
         subscribeWithPreviousValue(this.contentViewport, this.onContentViewportChanged),
         this.visibleRange.subscribe(() => this.updateVisibleRows()),
      );
   }

   /** Sets the column groups. Overwrites the current column groups if any exist. */
   setColumnGroups(columnGroups: Array<GridColumnGroup<TRow>>): void {
      this.columnGroups = columnGroups;

      // Render the column groups on if the viewport has width. Otherwise, the column
      // groups will appear to shift as the width changes.
      if (this.contentViewport().width > 0) {
         this.renderColumnGroups(this.columnGroups);
      }
   }

   /**
    * Inserts rows at the given index. The scroll position will be adjusted to
    * maintain the first visible row.
    */
   insertRows(index: number, rows: TRow[]): void {
      const renderedRows = this.renderedRows();

      // Append when the index is past the rendered rows.
      if (index >= renderedRows.length) {
         return this.appendRows(rows);
      }

      const currentRow = renderedRows[index];
      const newRows = this.createRenderedRows(rows, index, currentRow.top());
      const newRowsHeight = newRows.reduce((acc, row) => {
         return acc + this.getOuterRowHeight(row);
      }, 0);
      renderedRows.slice(index).forEach((row) => {
         row.index += newRows.length;
         row.top(row.top() + newRowsHeight);
      });

      this.renderedRows.splice(index, 0, ...newRows);

      this.queueRowsToUpdate(newRows);

      // Adjust the scroll position to keep the viewport in the same position.
      const firstVisibleRow = this.firstVisibleRow();
      if (firstVisibleRow && firstVisibleRow.index <= index) {
         this.scrollTop(this.scrollTop() + newRowsHeight);
         this.updateVisibleRows();
      }
   }

   /** Appends new rows to grid. */
   appendRows(rows: RowBase[]): void {
      const lastRow = this.renderedRows()[this.renderedRows().length - 1] || null;
      const newRows = this.createRenderedRows(
         rows,
         this.renderedRows().length,
         lastRow ? lastRow.top() + this.getOuterRowHeight(lastRow) : 0,
      );
      this.renderedRows.push(...newRows);
      this.queueRowsToUpdate(newRows);
   }

   /**
    * Deletes rows at from the given index. The scroll position will be adjusted to maintain the
    * first visible row.
    */
   deleteRows(index: number, count: number): void {
      const renderedRows = this.renderedRows();
      if (index + count > renderedRows.length) {
         return console.warn(
            `Attempted to delete non-existent row(s). Index=${index}, Count=${count}`,
         );
      }

      const rowsToDelete = renderedRows.slice(index, index + count);
      const rowsHeight = rowsToDelete.reduce((acc, row) => {
         return acc + this.getOuterRowHeight(row);
      }, 0);

      // Determine the new scroll position after deleting the rows.
      const firstVisibleRow = this.firstVisibleRow();
      const newScrollTop =
         firstVisibleRow && firstVisibleRow.index > index
            ? this.scrollTop() - rowsHeight
            : this.scrollTop();

      this.rowsInDom.removeAll(rowsToDelete);
      this.renderedRows.splice(index, count);

      // Adjust the top of each of the remaining rows.
      renderedRows.slice(index).forEach((row) => {
         row.top(row.top() - rowsHeight);
         row.index = row.index - count;
      });

      this.scrollTop(Math.max(0, newScrollTop));
      this.updateVisibleRows();

      // Dispose the deleted rows.
      rowsToDelete.forEach(this.disposeRow, this);
   }

   updateRows(index: number, rows: RowBase[]): void {
      const renderedRows = this.renderedRows();
      if (index == -1 || index >= renderedRows.length) {
         return this.appendRows(rows);
      }

      // Create the new rows.
      const newRows = this.createRenderedRows(rows, index, renderedRows[index].top());
      const firstVisibleRow = this.firstVisibleRow();
      const topOfFirstVisibleBeforeChange = firstVisibleRow?.top() ?? 0;

      // Calculate the height difference to adjust after rendering the new rows.
      const newHeight = newRows.reduce((acc, row) => acc + this.getOuterRowHeight(row), 0);
      const previousHeight = renderedRows
         .slice(index, index + rows.length)
         .reduce((acc, row) => acc + this.getOuterRowHeight(row), 0);

      // Update the rendered rows.
      this.renderedRows.splice(index, newRows.length, ...newRows).forEach(this.disposeRow, this);

      // Shift all the rows up or down based on the delta.
      if (newHeight != previousHeight) {
         // Shift all the following rows by the delta.
         const heightDelta = newHeight - previousHeight;
         renderedRows.slice(index + rows.length).forEach((row) => {
            row.top(row.top() + heightDelta);
         });

         // Adjust the scroll position to remain in the same place after the update.
         if (firstVisibleRow && topOfFirstVisibleBeforeChange > 0) {
            this.scrollTop(
               this.scrollTop() +
                  (renderedRows[firstVisibleRow.index].top() - topOfFirstVisibleBeforeChange),
            );
         }
      }
      this.queueRowsToUpdate(newRows);
   }

   /** Clear all rows from the layout. */
   clearRows(): void {
      this.renderedRows().forEach(this.disposeRow, this);
      this.renderedRows([]);
      this.rowsInDom([]);
   }

   /** Re-orders a column and updates without recreating the cells. */
   reorderColumn({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void {
      if (oldIndex == newIndex) return;

      const reorder = <T>(array: T[], oldIndex: number, newIndex: number) => {
         const [item] = array.splice(oldIndex, 1);
         array.splice(newIndex, 0, item);
         return array;
      };

      // Update the column groups to reflect the change.
      reorder(this.columnGroups, oldIndex, newIndex);
      this.renderedColumnGroups(reorder(this.renderedColumnGroups().concat(), oldIndex, newIndex));

      // Find the new column left positions.
      const columns = this.flattenColumnGroups(this.renderedColumnGroups());
      let left = this.paddingAroundColumns;
      const leftPositions = columns.map((column) => {
         const columnLeft = left;
         // Setup the left position for the next cell.
         left += column.width + column.columnPadding;
         return columnLeft;
      });

      // Reorder each of the cells.
      this.renderedRows().forEach((row) => {
         // Set the cells instead of operating directing on the observable
         // to allow Knockout to pick up the change as a move.
         const cells = row
            .cells()
            .concat()
            .sort((a, b) => {
               if (a.column.columnGroup == b.column.columnGroup) return a.index() - b.index();
               const aIndex = this.columnGroups.indexOf(a.column.columnGroup);
               const bIndex = this.columnGroups.indexOf(b.column.columnGroup);
               return aIndex - bIndex;
            });
         cells.forEach((cell, index) => {
            cell.index(index);
            cell.left(leftPositions[index]);
         });
         row.cells(cells);
      });
   }

   /** Resizes a column and updates the rows without recreating cells unnecessarily. */
   resizeColumn({ key, width }: { key: string; width: number }): void {
      // Find the affected column.
      const renderedColumns = this.flattenColumnGroups(this.renderedColumnGroups());
      const renderedColumn = renderedColumns.find((rendered) => rendered.column.key == key);

      if (!renderedColumn) {
         console.warn(`Attempting to resize a column that doesn't exist: ${key}`);
         return;
      }

      // Change the width of the column directly then recreate all of the rendered column groups
      // to pick up the change.
      renderedColumn.column.width = width;
      const updatedRenderedColumnGroups = this.createRenderedColumnGroupHeaders(this.columnGroups);
      this.renderedColumnGroups(updatedRenderedColumnGroups);

      const updatedColumns = this.flattenColumnGroups(updatedRenderedColumnGroups);

      let relayoutAfterIndex = -1;
      const renderedRows = this.renderedRows();
      renderedRows.forEach((row, index) => {
         const cells = row.cells();
         row.cells(
            cells.map((cell, index) => {
               // Re-associated the cells with the rendered column.
               cell.column = updatedColumns[cell.index()];
               if (cell.width == cell.column.width) {
                  return cell;
               }

               // Recreate the cell since the width has changed which may have caused the height to change.
               const newCell: RenderedCell<TRow> = {
                  ...cell.column.column.cellFactory(row.value, { width: cell.column.width }),
                  column: cell.column,
                  row,
                  index: observable(index),
                  left: observable(cell.left()),
                  width: cell.column.width,
                  hasFocus: cell.hasFocus,
                  hasFocusSubscription: cell.hasFocusSubscription,
               };

               // Reposition all the following cells.
               const widthDelta = newCell.width - cell.width;
               cells.slice(index + 1).forEach((cell) => {
                  cell.left(cell.left() + widthDelta);
               });

               return newCell;
            }),
         );

         // Check whether the position of all rows beneath this row need to be repositioned.
         const newHeight = this.findRowHeight(row.cells());
         if (newHeight != row.height() && relayoutAfterIndex == -1) {
            relayoutAfterIndex = index;
         }
         row.height(newHeight);
         row.width(this.findRowWidth(row.cells()));
      });

      // Update the position of all rows after the first row that changed height.
      if (relayoutAfterIndex != -1) {
         const first = renderedRows[relayoutAfterIndex];
         let top = first.top() + this.getOuterRowHeight(first);
         for (let i = relayoutAfterIndex + 1; i < renderedRows.length; i++) {
            renderedRows[i].top(top);
            top += this.getOuterRowHeight(renderedRows[i]);
         }
      }
   }

   /** Returns a cell based on the row and column or null if the cell doesn't exist. */
   getCell(row: number, column: number): RenderedCell<TRow> | null {
      const columnCount = this.renderedColumnGroups().reduce((acc, c) => acc + c.columns.length, 0);
      return row >= 0 && row < this.renderedRows().length && column >= 0 && column < columnCount
         ? this.renderedRows()[row].cells()[column]
         : null;
   }

   /** Scrolls the given row to be the first visible row. */
   scrollToRow(row: RenderedRow<TRow>): void {
      const visibleRange = this.visibleRange();
      if (!visibleRange) return;
      const totalHeight =
         this.paddingContentTop() + this.innerContentHeight() + this.paddingContentBottom();
      this.scrollTop(Math.min(row.top(), totalHeight - this.contentViewport().height));
   }

   /** Scrolls the given cell into view. Accounts for the present of scrollbars. */
   scrollCellIntoView(cell: RenderedCell<TRow>): void {
      // Get the cell's bounds and update to account for the top padding.
      const bounds = this.getOuterCellBounds(cell);
      const viewportBounds = this.rowsViewportBounds();
      if (contains(viewportBounds, bounds)) {
         // Do nothing if the viewport already contains the bounds.
         return;
      }
      const delta = findContainmentDelta(viewportBounds, bounds);
      if (delta.top != 0) {
         this.scrollTop(this.scrollTop() + delta.top);
      }
      if (delta.left != 0) {
         this.scrollLeft(this.scrollLeft() + delta.left);
      }
   }

   /** Scrolls down to the bottom of the viewport. */
   scrollToEdge(edge: "top" | "bottom" | "left" | "right"): void {
      if (edge == "top") {
         this.scrollTop(0);
      } else if (edge == "bottom") {
         const totalHeight =
            this.paddingContentTop() + this.innerContentHeight() + this.paddingContentBottom();
         this.scrollTop(Math.max(0, totalHeight - this.contentViewport().height));
      } else if (edge == "left") {
         this.scrollLeft(0);
      } else if (edge == "right") {
         this.scrollLeft(Math.max(0, this.outerContentWidth() - this.contentViewport().width));
      }
   }

   /** Returns whether the given row is visible. */
   isRowVisible(row: RenderedRow<TRow>): boolean {
      return intersects(this.rowsViewportBounds(), this.getOuterRowBounds(row));
   }

   /** Returns the outer bounds of the rendered column group header or null. */
   getGroupHeaderOuterBounds(headerGroup: RenderedColumnGroupHeader<TRow>): Bounds | null {
      const firstHeaderOuterBounds = this.getHeaderOuterBounds(headerGroup.columns[0]);
      if (!firstHeaderOuterBounds) return null;

      const width = headerGroup.columns.reduce((acc, column) => {
         return acc + column.width + column.columnPadding;
      }, 0);

      return {
         ...firstHeaderOuterBounds,
         width,
      };
   }

   /** Returns the outer bounds of the header or null. */
   getHeaderOuterBounds(header: RenderedColumnHeader<TRow>): Bounds | null {
      const headers = this.flattenColumnGroups(this.renderedColumnGroups());
      const index = headers.indexOf(header);
      if (index == -1) return null;

      const left = headers.slice(0, index).reduce((acc, header) => {
         return acc + header.width + header.columnPadding;
      }, this.paddingAroundColumns);

      return {
         top: 0,
         left,
         width: header.width,
         height: this.headerHeight,
      };
   }

   /** Returns the outer row bounds (includes all padding). */
   getOuterRowBounds(row: RenderedRow<TRow>): Bounds {
      return {
         top: row.top(),
         left: 0,
         width: row.width(),
         height: this.getOuterRowHeight(row),
      };
   }

   /** Returns the outer cell bounds (includes all padding). */
   getOuterCellBounds(cell: RenderedCell<TRow>): Bounds {
      return {
         top: cell.row.top(),
         left: cell.left(),
         width: cell.width + cell.column.columnPadding,
         height: cell.row.height() + cell.row.rowPadding,
      };
   }

   /** Returns the outer row bounds (excludes all padding). */
   getInnerCellBounds(cell: RenderedCell<TRow>): Bounds {
      return {
         top: cell.row.top() + cell.row.rowPadding / 2,
         left: cell.left() + cell.column.columnPadding / 2,
         width: cell.width,
         height: cell.row.height(),
      };
   }

   /** Disposes of the layout. */
   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
      this.renderedRows().forEach(this.disposeRow, this);
   }

   private onContentViewportChanged = (viewport: Size, previousViewport: Size) => {
      // Ignore if the viewport has no width or the width has not changed.
      if (viewport.width <= 0 || viewport.width == previousViewport.width) {
         return;
      }

      // Reset the scroll positions in case they're out of sync with the DOM element.
      this.scrollTop(this.scrollTop());
      this.scrollLeft(this.scrollLeft());

      // Create the column groups if they don't exist.
      if (this.renderedColumnGroups().length != this.columnGroups.length) {
         this.renderColumnGroups(this.columnGroups);
         return;
      }

      const renderedColumnGroups = this.renderedColumnGroups();
      const hasAutoResized = renderedColumnGroups.some((group) => group.hasAutoResized);
      const isAutoResizable = renderedColumnGroups.some((group) =>
         group.columns.some((c) => c.column.autoResizable),
      );
      const isViewportLargerThanGrid =
         this.contentViewport().width > this.getGridWidth(renderedColumnGroups);

      // Force the entire grid to be recalculated if the column groups are auto-resized already
      // or the viewport is greater than the width of the grid and resizing is enabled by at
      // least one column.
      if (hasAutoResized || (isAutoResizable && isViewportLargerThanGrid)) {
         this.recreateRenderedColumnGroupHeaders();
         this.recreateRenderedRows();
      } else {
         this.updateVisibleRows();
      }
   };

   private renderColumnGroups(columnGroups: Array<GridColumnGroup<TRow>>) {
      this.renderedColumnGroups(this.createRenderedColumnGroupHeaders(columnGroups));
      if (this.renderedRows().length) {
         this.recreateRenderedRows();
      }
   }

   private recreateRenderedColumnGroupHeaders() {
      this.renderedColumnGroups(
         this.createRenderedColumnGroupHeaders(
            this.renderedColumnGroups().map((c) => c.columnGroup),
         ),
      );
   }

   private createRenderedColumnGroupHeaders(columnGroups: Array<GridColumnGroup<TRow>>) {
      let renderedColumnGroups = columnGroups.map(
         (columnGroup): RenderedColumnGroupHeader<TRow> => {
            const columns = columnGroup.columns.map(
               (column): RenderedColumnHeader<TRow> => ({
                  columnGroup,
                  column: column,
                  width: column.width,
                  columnPadding: this.paddingBetweenColumns,
               }),
            );
            return {
               columnGroup,
               columns,
               width: columns.reduce((acc, column) => {
                  return acc + column.width + column.columnPadding;
               }, 0),
               hasAutoResized: false,
            };
         },
      );

      // Calculate the auto-resized width of each column.
      const gridWidth = this.viewport().width;
      const combinedWidth = this.getGridWidth(renderedColumnGroups);

      // Determine the additional width to allocate to each resizable column.
      const numberOfResizableColumns = columnGroups.reduce((acc, columnGroups) => {
         return acc + columnGroups.columns.filter((column) => column.autoResizable).length;
      }, 0);
      const additionalWidth = Math.max(0, (gridWidth - combinedWidth) / numberOfResizableColumns);

      // Apply the additional width to each of the auto-resizable elements when grid viewport
      // is wider than the columns combined.
      if (additionalWidth > 0) {
         renderedColumnGroups = renderedColumnGroups.map((columnGroup) => {
            const columns = columnGroup.columns.map((column) => {
               return column.column.autoResizable
                  ? { ...column, width: column.width + additionalWidth }
                  : column;
            });
            return {
               ...columnGroup,
               columns,
               width: columns.reduce((acc, column) => {
                  return acc + column.width + column.columnPadding;
               }, 0),
               hasAutoResized: columnGroup.columns.some((c) => c.column.autoResizable),
            };
         });
      }
      return renderedColumnGroups;
   }

   private recreateRenderedRows() {
      const renderedRows = this.renderedRows();
      const focused = this.getFocusedRowOrCell(renderedRows);
      const rows = renderedRows.map((r) => r.value);
      const scrollTop = this.scrollTop();
      const scrollLeft = this.scrollLeft();

      // Set the re-created rendered rows and dispose of the old rows.
      this.rowsInDom([]);
      this.renderedRows(this.createRenderedRows(rows, 0, 0));
      renderedRows.forEach(this.disposeRow, this);

      // Reset the visible rows to use the new rendered rows.
      this.queuedRows = [];
      this.queueRowsToUpdate(this.renderedRows());

      // Reset the scroll positions.
      this.scrollTop(scrollTop);
      this.scrollLeft(scrollLeft);

      // Refocus the row or cell that was focused before the update, if one existed.
      if (focused) {
         if (focused.isRow) {
            this.renderedRows()[focused.row.index].hasFocus(true);
         } else {
            this.getCell(focused.cell.row.index, focused.cell.index())!.hasFocus(true);
         }
      }
   }

   private createRenderedRows(rows: unknown[], nextIndex: number, nextTop: number) {
      const columns = this.flattenColumnGroups(this.renderedColumnGroups());
      const renderedRows = rows.map((row: unknown, index: number) => {
         const renderedRow = this.createRenderedRow(columns, row, nextIndex + index, nextTop);
         nextTop += this.getOuterRowHeight(renderedRow);
         return renderedRow;
      });
      return renderedRows;
   }

   private createRenderedRow(
      columns: Array<RenderedColumnHeader<TRow>>,
      row: any,
      index: number,
      position: number,
   ) {
      // Create the rendered row object so it can be referenced by each cell.
      const renderedRow = {
         index,
         value: row,
         top: observable(position),
         rowPadding: this.paddingBetweenRows,
         hasBorder: this.hasRowBorders,
         columnsPadding: this.paddingAroundColumns,
         isVisible: observable(false),
      } as RenderedRow<TRow>;
      const [hasFocus, subscription] = this.createRowHasFocusObservable(renderedRow);
      renderedRow.hasFocus = hasFocus;
      renderedRow.hasFocusSubscription = subscription;

      let left = this.paddingAroundColumns;
      renderedRow.cells = observableArray(
         columns.map((column, index) => {
            const cell: RenderedCell<TRow> = {
               ...column.column.cellFactory(row, { width: column.width }),
               column,
               row: renderedRow,
               index: observable(index),
               left: observable(left),
               width: column.width,
            } as RenderedCell<TRow>;
            const [hasFocus, subscription] = this.createCellHasFocusObservable(cell);
            cell.hasFocus = hasFocus;
            cell.hasFocusSubscription = subscription;

            // Setup the left position for the next cell.
            left += column.width + column.columnPadding;

            return cell;
         }),
      );

      renderedRow.height = observable(
         renderedRow.cells().length > 0
            ? this.findRowHeight(renderedRow.cells())
            : this.defaultRowHeight,
      );
      renderedRow.width = observable(this.findRowWidth(renderedRow.cells()));

      return renderedRow;
   }

   private findRowHeight(cells: Array<RenderedCell<TRow>>) {
      return cells.reduce((acc, cell) => {
         const cellHeight = cell.height == null ? this.defaultRowHeight : cell.height;
         return Math.max(acc, cellHeight);
      }, 0);
   }

   private findRowWidth(cells: Array<RenderedCell<TRow>>) {
      return cells.reduce((acc, cell) => {
         return acc + cell.width + cell.column.columnPadding;
      }, 0);
   }

   private createRowHasFocusObservable(
      row: RenderedRow<TRow>,
   ): [Observable<boolean>, Subscription] {
      const hasFocus = observable(false);
      const subscription = hasFocus.subscribe((hasFocus) => {
         this.onRowFocusChanged(row, hasFocus);
      });
      return [hasFocus, subscription];
   }

   private createCellHasFocusObservable(
      cell: RenderedCell<TRow>,
   ): [Observable<boolean>, Subscription] {
      const hasFocus = observable(false);
      const subscription = hasFocus.subscribe((hasFocus) => {
         this.onCellFocusChanged(cell, hasFocus);
      });
      return [hasFocus, subscription];
   }

   private queueRowsToUpdate(rows: Array<RenderedRow<TRow>>) {
      // If there are no columns, skip queuing rows since they will need to be
      // recreated when the columns are actually set anyways.
      if (!this.renderedColumnGroups().length) return;
      this.queuedRows.push(...rows);
      this.queuedRows.sort((a, b) => a.index - b.index);
      this.scheduleDequeueRowChanges();
      this.updateVisibleRows();
   }

   private updateVisibleRows() {
      const visibleRange = this.visibleRange();
      if (!visibleRange) return;

      const rowsInDom = this.rowsInDom();
      const renderedRows = this.renderedRows();
      const firstVisibleIndex = Math.max(visibleRange[0].index - 1, 0);
      const lastVisibleIndex = Math.min(visibleRange[1].index + 1, renderedRows.length);

      // Check if there are any rows that are within the viewport but not yet in the DOM.
      if (this.queuedRows.length) {
         const rowsToAdd: Array<RenderedRow<TRow>> = [];
         for (const row of this.queuedRows) {
            if (row.index >= firstVisibleIndex && row.index <= lastVisibleIndex) {
               row.isVisible(true);
               rowsToAdd.push(row);
            }
         }
         this.renderRowsToDom(rowsToAdd);
      }

      for (const row of rowsInDom) {
         // Allow one additional row before and after the technically visible rows to remain visible
         // so it can receive focus immediately when navigating via the keyboard.
         row.isVisible(row.index >= firstVisibleIndex && row.index <= lastVisibleIndex);
      }
   }

   private renderRowsToDom(rows: Array<RenderedRow<TRow>>) {
      const rowsInDom = this.rowsInDom();

      // Group continuous rows together to minimize the number of array updates.
      const groupedRows = groupAdjacent(rows, (current, next) => current.index + 1 == next.index);

      // Add each of the rows to the DOM.
      groupedRows.reverse().forEach((rows) => {
         const index = binarySearch(rowsInDom, rows[0], (a, b) => a.index - b.index);
         if (index < 0) {
            this.rowsInDom.splice(-index - 1, 0, ...rows);
         } else {
            this.rowsInDom.splice(index, rows.length, ...rows);
         }
      });

      // Removes rows for queue.
      this.queuedRows = this.queuedRows.filter((r) => !rows.includes(r));
   }

   private flattenColumnGroups(columnGroups: Array<RenderedColumnGroupHeader<TRow>>) {
      return columnGroups
         .map((columnGroup) => columnGroup.columns)
         .reduce((acc, columns) => acc.concat(columns), []);
   }

   private getGridWidth(columnGroupHeaders: Array<RenderedColumnGroupHeader<TRow>>) {
      return columnGroupHeaders.reduce((acc, columnGroup) => {
         return acc + columnGroup.width;
      }, this.paddingAroundColumns * 2);
   }

   private findVisibleRange(): [RenderedRow<TRow>, RenderedRow<TRow>] | null {
      const renderedRows = this.renderedRows();
      if (!renderedRows.length) return null;

      const viewport = this.rowsViewportBounds();
      let visibleRowIndex = binarySearch(renderedRows, null, (row) => {
         const bounds = this.getOuterRowBounds(row);
         return intersects(bounds, viewport) ? 0 : bounds.top < viewport.top ? -1 : 1;
      });

      if (visibleRowIndex == -renderedRows.length - 1) {
         // Use the last row index when the viewport is scrolled past the last row.
         visibleRowIndex = renderedRows.length - 1;
      } else if (visibleRowIndex < 0) {
         // Return null if no rows are visible.
         return null;
      }

      const findAdjacentVisibleRows = (start: number, increment: number) => {
         let current = start;
         while (current >= 0 && current < renderedRows.length) {
            const bounds = this.getOuterRowBounds(renderedRows[current]);
            if (!intersects(bounds, viewport)) {
               return current - increment;
            }
            current += increment;
         }
         return current - increment;
      };

      return [
         renderedRows[findAdjacentVisibleRows(visibleRowIndex - 1, -1)],
         renderedRows[findAdjacentVisibleRows(visibleRowIndex + 1, 1)],
      ];
   }

   private scheduleDequeueRowChanges() {
      if (this.hasScheduledDequeueRows) return;
      this.hasScheduledDequeueRows = true;

      const dequeueRows = () => {
         setTimeout(() => {
            if (!this.hasScheduledDequeueRows || !this.queuedRows.length) {
               this.hasScheduledDequeueRows = false;
               return;
            }

            // Determine if we should pull from the front of the queue or the back based
            // on which is closer to the visible index.
            const firstVisibleIndex = this.firstVisibleRow()?.index || 0;
            const first = this.queuedRows[0].index;
            const last = this.queuedRows[this.queuedRows.length - 1].index;
            const rows =
               Math.abs(firstVisibleIndex - first) < Math.abs(firstVisibleIndex - last)
                  ? this.queuedRows.slice(0, ROWS_TO_RENDER_PER_FRAME)
                  : this.queuedRows.slice(-ROWS_TO_RENDER_PER_FRAME);

            this.renderRowsToDom(rows);

            // Request another frame.
            dequeueRows();
         }, 0);
      };

      // Start the process of dequeuing rows.
      dequeueRows();
   }

   private getOuterRowHeight(row: RenderedRow<TRow>) {
      return row.height() + row.rowPadding + (row.hasBorder ? 1 : 0);
   }

   private getFocusedRowOrCell(rows: Array<RenderedRow<TRow>>):
      | {
           isRow: true;
           row: RenderedRow<TRow>;
        }
      | {
           isRow: false;
           cell: RenderedCell<TRow>;
        }
      | null {
      for (let i = 0; i < rows.length; i++) {
         if (rows[i].hasFocus()) {
            return { isRow: true, row: rows[i] };
         }
         const focusedCell = rows[i].cells().find((c) => c.hasFocus());
         if (focusedCell) {
            return { isRow: false, cell: focusedCell };
         }
      }
      return null;
   }

   private disposeRow(row: RenderedRow<TRow>) {
      row.cells().forEach(this.disposeCell, this);
      row.hasFocusSubscription.dispose();
   }

   private disposeCell(cell: RenderedCell<TRow>) {
      cell.hasFocusSubscription.dispose();
   }
}
