import "./rich-list-item.styl";
import * as ko from "knockout";
import template from "./rich-list-item.pug";

const LEFT_COMPONENT_NAME = "RICH-LIST-ITEM-LEFT";
const RIGHT_COMPONENT_NAME = "RICH-LIST-ITEM-RIGHT";

export type RichListItemParams = {
   title: string;
   subTitle?: string;
   onClick?: () => void;

   data?: unknown;
};

/**
 * RichListItems are used in the common use-case of a
 * clickable list item that needs to support:
 * 1) A title.
 * 2) A subtitle.
 * 3) A sub-component on the left side within an item.
 * 4) A sub-component on the right side within an item.
 *
 * @example
 * rich-list-item(params=`
 *    title: myTitle,
 *    subTitle: mySubTitle,
 *    onClick: () => $parent.onClick($data),
 *    data: {
 *       item: myItem,
 *       doSomething: () => $parent.doSomething($data)
 *    }
 * `)
 *    rich-list-item-left
 *       some-other-component(params=`
 *          usefulInfo: $data.item.usefulInfo,
 *          doSomething: $data.doSomething`)
 *    rich-list-item-right
 *       +icon-arrow-right
 */
export class RichListItem {
   readonly title: string;
   readonly subTitle?: string;
   readonly onClick?: () => void;

   readonly leftComponent: Node[];
   readonly rightComponent: Node[];

   constructor(readonly params: RichListItemParams, nodes: Node[]) {
      (this.title = params.title),
         (this.subTitle = params.subTitle),
         (this.onClick = params.onClick),
         (this.leftComponent = nodes.filter(
            (node) => node.nodeName.toUpperCase() === LEFT_COMPONENT_NAME,
         ));
      this.rightComponent = nodes.filter(
         (node) => node.nodeName.toUpperCase() === RIGHT_COMPONENT_NAME,
      );
   }

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      if (this.onClick && (event.key == "Enter" || event.key == " ")) {
         this.onClick();
         return false;
      }
      return true;
   };
}

ko.components.register("rich-list-item", {
   viewModel: {
      createViewModel: (params: ko.components.ViewModelParams, componentInfo) => {
         return new RichListItem(params as RichListItemParams, componentInfo.templateNodes);
      },
   } as ko.components.ViewModelFactory,
   template: template(),
   synchronous: true,
});
