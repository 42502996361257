import "./project-manager-accordion.styl"
import projectManagerTemplateFn from "./project-manager-accordion.pug"
import { Dom } from "@/lib/utils/dom"

export class ProjectManager
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.groupedOptions, Function)
      assertOfType(params.groupedOptions(), Object)
      assertOfType(params.selectedValue, optional(Object))

      if params.selectedValue?
         @creatingNewPM = ko.observable(false)
      else
         @creatingNewPM = ko.observable(true)
      @groupedOptions = params.groupedOptions
      @selectedValue = ko.observable(params.selectedValue)
      
      @saveCallback = if params.saveCallback? then params.saveCallback else false
      @cancelCallback = if params.cancelCallback? then params.cancelCallback else false
      @deleteCallback = if params.deleteCallback? then params.deleteCallback else false
      
      @isExpanded = ko.observable(false)
      if params.openOnLoad == true then @isExpanded(true)

      # Alerts
      @showPMAlert = ko.observable(false)

   toggleExpansion: ->
      @isExpanded(!@isExpanded())

   fireSave: =>
      @showPMAlert(false)
      return @showPMAlert(true) unless @selectedValue().name()?
      @saveCallback(@selectedValue()) if @saveCallback

   fireCancel: ->
      @isExpanded(false)
      @cancelCallback() if @cancelCallback

   fireDelete: ->
      @deleteCallback(@selectedValue()) if @deleteCallback

      
projectManagerTemplate = projectManagerTemplateFn()

ko.components.register("project-manager-accordion",
   viewModel: ProjectManager,
   template: projectManagerTemplate,
)