import "./select-assignment-range-pane.styl"
import template from "./select-assignment-range-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput
import { DateUtils } from "@/lib/utils/date"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class SelectAssignmentRangePane extends ModalPane
   constructor: (assignment) ->
      super("Select Editing Range", "Save", template())
      @overflowable = ko.observable(true)
      @actionText(null)

      @projectName = assignment.projectName
      @projectId = assignment.projectId

      @formattedBatchStart = ko.observable(null)
      options = {
         yearFormat: DateUtils.YearFormat.SHORT
         monthFormat: DateUtils.MonthFormat.ONE_DIGIT
         dayFormat: DateUtils.DayFormat.ONE_DIGIT
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV
      }
      @formattedBatchStart(DateUtils.formatDetachedDay(assignment.batchStartDay, defaultStore.getDateFormat()), options) if assignment.batchStartDay?

      @formattedBatchEnd = ko.observable(null)
      @formattedBatchEnd(DateUtils.formatDetachedDay(assignment.batchEndDay, defaultStore.getDateFormat()), options) if assignment.batchEndDay?

      @formattedStart = ko.observable(DateUtils.formatDetachedDay(assignment.startDay, defaultStore.getDateFormat(), options))
      @formattedEnd = ko.observable(DateUtils.formatDetachedDay(assignment.endDay, defaultStore.getDateFormat(), options))

      @detachedToday = DateUtils.getDetachedDay(new Date())

      @batchAlreadyStarted = ko.pureComputed =>
         return if assignment.batchStartDay? then assignment.batchStartDay < @detachedToday else false

      @instanceAlreadyStarted = ko.pureComputed =>
         return assignment.startDay < @detachedToday

      @batchAlreadyOver = ko.pureComputed =>
         return assignment.batchEndDay < @detachedToday

      @occurenceAlreadyOver = ko.pureComputed =>
         return assignment.endDay < @detachedToday

   getBatchString: ->
      return "#{@formattedBatchStart()} - #{@formattedBatchEnd()}"

   getOccurenceString: ->
      return "#{@formattedStart()} - #{@formattedEnd()}"

   selectEditingRange: (editAssignmentBatch) ->
      @data.set("editAssignmentBatch", editAssignmentBatch)
      modalManager.modalFinished()

   navigateToProject: ->
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{@projectId}")
      modalManager.modalCancelled()
