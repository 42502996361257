import "./batch-actions.styl";
import template from "./batch-actions.pug";
import type { MaybeObservable, Observable, PureComputed, Subscribable } from "knockout";
import { unwrap } from "knockout";
import { isSubscribable } from "knockout";
import { pureComputed } from "knockout";
import { observable, components } from "knockout";
import type { RowBase } from "../grid/grid-store";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import type { GridColumnGroup } from "../grid/grid-column-group";
import type { EditActionParams } from "./batch-edit/batch-edit";
import { authManager } from "@/lib/managers/auth-manager";
import { PermissionLevel } from "@/models/permission-level";
import type { DeleteActionParams } from "./batch-delete/batch-delete";

export const enum BatchActionState {
   LOADING_SELECTION,
   TOO_MANY_RECORDS,
   VIEW_ALL,
   EDIT,
   SEND_ALERTS,
   DELETE,
}

/**
 * Time for transitions between batch-actions.
 */
export const TRANSITION_TIMING = 300;

/** The maximum number of records that can be handled at once via any batch action. */
export const BATCH_ACTION_MAX_RECORD_COUNT = 1000;

//#region Types
export type Conflict<T> = {
   record: T;
   reason: string;
};

export type ConflictRow<T> = Conflict<T> & { id: string };

export type BatchActionsTransition = {
   state: Observable<BatchActionState>;
   direction: Observable<Transition>;
   backToActionList: () => void;
   closePopup: () => void;
   disableUntil: (readyToOpen: Promise<void>) => Promise<void>;
   onClickOff: (childCallbacks?: () => void) => void;
};

export type BaseActionParams<T extends RowBase> = {
   /**
    * Observable for when the batch delete should be disabled while records are being
    * loaded.
    */
   isWaitingForRecords?: Subscribable<boolean>;

   /**
    * Keeps track of active Actions, transition direction, and provides method to
    * return to options list.
    */
   transitionController: BatchActionsTransition;

   /**
    * The column groups used in the conflict/confirmation modal to identify the records whose
    * updates are invalid.
    */
   conflictModalColumns:
      | MaybeObservable<Array<GridColumnGroup<T>>>
      | PureComputed<Array<GridColumnGroup<T>>>;

   /**
    * The records to be batch deleted.
    */
   records: MaybeObservable<T[]> | PureComputed<T[]>;
};

type HasPermission = { permission: boolean };

// This is only place that BaseActionParams is used without the transitionController param.
// Not needed here because it is created by this component.
export type BatchActionsComponentParams<T extends RowBase> = Omit<
   BaseActionParams<T>,
   "transitionController"
> & {
   enableBatchSendAlerts: boolean;
   edit: EditActionParams<T> & HasPermission;
   delete: DeleteActionParams<T> & HasPermission;
};
//#endregion

export class BatchActions<T extends RowBase> {
   //#region Permissions
   readonly canManageAlerts =
      this.batch.enableBatchSendAlerts &&
      authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS);
   readonly hasBatchPermissions =
      this.batch.edit.permission || this.canManageAlerts || this.batch.delete.permission;
   //#endregion

   //#region State
   readonly isPopupVisible = observable(false);
   readonly isWaitingForRecords: Subscribable<boolean>;

   readonly exceedsMaxBatchLimit = pureComputed(() => {
      return unwrap(this.batch.records).length > BATCH_ACTION_MAX_RECORD_COUNT;
   });
   readonly disabled: Observable<boolean> = observable(false);

   // This will propogate down through all of the batch action components
   readonly transitionController: BatchActionsTransition = {
      state: observable<BatchActionState>(BatchActionState.VIEW_ALL),
      direction: observable<Transition>(Transition.FADE),
      backToActionList: () => {
         this.transitionController.direction(Transition.BACKWARD);
         this.transitionController.state(BatchActionState.VIEW_ALL);
      },
      closePopup: () => {
         this.isPopupVisible(false);
         this.patientReset();
      },
      disableUntil: async (readyToOpen) => {
         this.disabled(true);
         await readyToOpen;
         this.disabled(false);
      },
      onClickOff: (childCallbacks) => {
         if (childCallbacks) childCallbacks();
         this.onClickOff();
      },
   };
   //#endregion

   readonly maxLimitExceededMessage = `Unable to take batch-actions on more than ${BATCH_ACTION_MAX_RECORD_COUNT} records at a time.`;

   constructor(private readonly batch: BatchActionsComponentParams<T>) {
      this.isWaitingForRecords = batch.isWaitingForRecords ?? observable(false);

      this.isWaitingForRecords.subscribe(() => this.updateState(), this);
      if (isSubscribable(batch.records)) {
         batch.records.subscribe(() => this.updateState({ preserveView: true }), this);
      }
   }

   //#region Event Callbacks
   onButtonClick = (): void => {
      this.isPopupVisible(!this.isPopupVisible());
      if (this.isPopupVisible() === false) this.patientReset();
   };

   onClickOff = (): void => {
      if (this.isPopupVisible() === false) return;
      this.isPopupVisible(false);
      this.patientReset();
   };

   onEscape = (): void => {
      if (
         this.isPopupVisible() === true &&
         (this.transitionController.state() === BatchActionState.VIEW_ALL ||
            this.transitionController.state() === BatchActionState.TOO_MANY_RECORDS)
      )
         this.isPopupVisible(false);
   };
   //#endregion

   //#region Helpers
   private viewBatchEdit() {
      this.transitionController.direction(Transition.FORWARD);
      this.transitionController.state(BatchActionState.EDIT);
   }

   private viewSendAlerts() {
      this.transitionController.direction(Transition.FORWARD);
      this.transitionController.state(BatchActionState.SEND_ALERTS);
   }

   private viewBatchDelete() {
      this.transitionController.direction(Transition.FORWARD);
      this.transitionController.state(BatchActionState.DELETE);
   }

   private updateState = (options?: { preserveView: boolean }) => {
      const preserveView =
         options?.preserveView &&
         this.transitionController.state() !== BatchActionState.TOO_MANY_RECORDS;

      if (this.isWaitingForRecords()) {
         this.setState(Transition.FADE, BatchActionState.LOADING_SELECTION);
      } else if (this.exceedsMaxBatchLimit()) {
         this.setState(Transition.FADE, BatchActionState.TOO_MANY_RECORDS);
      } else if (preserveView !== true) {
         this.setState(Transition.FORWARD, BatchActionState.VIEW_ALL);
      }
   };

   private setState(requestedDirection: Transition, requestedState: BatchActionState) {
      if (this.transitionController.state() !== requestedState) {
         this.transitionController.state(requestedState);
      }
      if (this.transitionController.direction() !== requestedDirection) {
         this.transitionController.direction(requestedDirection);
      }
   }

   /**
    * This timeout ensures that the onTransitionEnd callbacks from the nested transitioning-content
    * components have time to finish executing. Without this, the other action views won't have
    * time to close properly and they'll cause overlay issues the next time you open batch-actions.
    */
   patientReset = (): void => {
      setTimeout(this.updateState, TRANSITION_TIMING);
   };
   //#endregion
}

components.register("batch-actions", {
   viewModel: BatchActions,
   template: template(),
});
