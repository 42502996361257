import template from "./focus-lock.pug";
import type { components, MaybeObservable, Subscription } from "knockout";
import ko, { observableArray, unwrap } from "knockout";
import { findFocusableElements } from "@/lib/utils/elements";

/**
 * Stack of `FocusLock` instances. The right most focus lock will be the
 * only focus lock with the 'ALWAYS' behavior active.
 */
const activeFocusLockStack = observableArray<FocusLock>();

export enum FocusLockType {
   /**
    * Only lock the focus within this component once foucs has been captured.
    * This is the default behavior.
    */
   AFTER_CAPTURE = "after_capture",

   /**
    * Force focus to remain within this component always.
    * NOTE: Only a single focus lock with `ALWAYS` can be rendered in the DOM at a time.
    */
   ALWAYS = "always",
}

export interface FocusLockParams {
   /** Type of the Whether the element will always be focused  */
   lock?: FocusLockType;

   /** Content nodes to use instead of the child nodes of the component. */
   contentNodes?: MaybeObservable<HTMLElement[]> | null;

   /** Context to use when binding the content nodes. */
   data?: MaybeObservable<unknown> | null;
}

export class FocusLock {
   private readonly subscriptions: Subscription[] = [];

   constructor(readonly params: FocusLockParams, private readonly element: Element) {
      if (unwrap(params.lock) == FocusLockType.ALWAYS) {
         activeFocusLockStack.push(this);
         window.addEventListener("focusin", this.onFocusInOut);
         window.addEventListener("focusout", this.onFocusInOut);
         this.subscriptions.push(activeFocusLockStack.subscribe(this.onActiveFocusLockStack, this));

         // Give the component a chance to render its DOM then focus the first element.
         requestAnimationFrame(() => {
            if (this.isActive()) {
               this.focusFirstElement();
            }
         });
      }
   }

   dispose(): void {
      if (activeFocusLockStack.remove(this)) {
         window.removeEventListener("focusin", this.onFocusInOut);
         window.removeEventListener("focusout", this.onFocusInOut);
      }
   }

   private onActiveFocusLockStack() {
      if (this.isActive() && !this.element.contains(document.activeElement)) {
         this.focusFirstElement();
      }
   }

   private onFocusInOut = (event: FocusEvent): void => {
      if (this.isActive() && event.target instanceof Node && !this.element.contains(event.target)) {
         this.focusFirstElement();
      }
   };

   private isActive() {
      const stack = activeFocusLockStack();
      return stack[stack.length - 1] == this;
   }

   private focusFirstElement() {
      const focusable = findFocusableElements(this.element);
      if (focusable.length) {
         focusable[0].focus();
      } else if (this.element instanceof HTMLElement && this.element.tabIndex >= 0) {
         this.element.focus();
      } else {
         console.warn("Unable to find focusable target for element", this.element);
      }
   }
}

ko.components.register("focus-lock", {
   viewModel: {
      createViewModel: (params: FocusLockParams, componentInfo) => {
         return new FocusLock(params, componentInfo.element as Element);
      },
   } as components.ViewModelFactory,
   template: template(),
   synchronous: true,
});
