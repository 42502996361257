import "./toolbar-drop-down.styl"
import template from "./toolbar-drop-down.pug"
import { ControlViewModel } from "@/lib/vm/control-viewmodel"

export class ToolbarDropDown extends ControlViewModel
   constructor: (options, order) ->
      assertArgs(arguments, Object, Number)
      super(template(), order)
      assertOfType(options.defaultTitle, [String, Function])
      assertOfType(options.selectedValue, Function)
      assertOfType(options.values, [Array, Function])
      assertOfType(options.disabled, optional(Function))

      @defaultTitle = options.defaultTitle
      @selectedValue = options.selectedValue
      @values = options.values
      @selectCallback = options.selectCallback or null
      @disabled = options.disabled or false
