import "./integrated-field-indicator.styl"
import integratedFieldIndicatorTemplateFn from "./integrated-field-indicator.pug"
import ko from "knockout"

export class IntegratedFieldIndicator
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.fields, optional(Function))
      # Allows combined properties in UI
      assertOfType(params.property, [String, arrayOf(String)])

      @showIntegrated = ko.observable(false)
      @showLocked = ko.observable(false)

      for field in params.fields()
         passed = false
         if Array.isArray(params.property)
            passed = params.property.indexOf(field.property) != -1
         else
            passed = field.property == params.property

         if passed
            if field.locked
               @showLocked(true)
               @showIntegrated(false)
            else
               @showLocked(false)
               @showIntegrated(true)
            break

integratedFieldIndicatorTemplate = integratedFieldIndicatorTemplateFn()

ko.components.register("integrated-field-indicator",
   viewModel: IntegratedFieldIndicator,
   template: integratedFieldIndicatorTemplate,
)