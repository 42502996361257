import type { Bounds, Point } from "./geometry";
import { findIntersection } from "./geometry";

/** Calculate the bounds of the element based on the element's viewport. */
export function findBoundsWithinViewport(element: HTMLElement): {
   /**
    * The bounds of the viewport.
    * Should the following statement be true?: [The viewport will always be positioned at (0, 0).] */
   viewportBounds: Bounds;

   /**
    * Bounds of the element within the viewport.
    * Use this with `findAnchoredPosition` when attempting to position an element within
    * a scrollable content area.
    */
   visibleContentBounds: Bounds;

   /**
    * Bounds of the element positioned within the scrollable content area.
    * These values represent the coordinate of the element in absolute units.
    */
   contentBounds: Bounds;

   /**
    * Element responsible for scrolling vertically. May be equivalent to the `leftViewportElement`
    * when an element can scroll in both directions.
    */
   topViewportElement: HTMLElement;

   /**
    * Element responsible for scrolling horizontally. May be equivalent to the `topViewportElement`
    * when an element can scroll in both directions.
    */
   leftViewportElement: HTMLElement;
} {
   const topViewportElement = findViewportElement(element, "top");
   const leftViewportElement = findViewportElement(element, "left");
   const [parentElement, childElement] = orderByAncestry(
      findCommonOffsetAncestor({ element, ancestor: topViewportElement }),
      findCommonOffsetAncestor({ element, ancestor: leftViewportElement }),
   );
   const offsetAncestor = findCommonOffsetAncestor({
      element: childElement,
      ancestor: parentElement,
   });

   const topViewportOffset = findOffsetToOffsetAncestor(topViewportElement, offsetAncestor);
   const topBoundsOffset = findOffsetToOffsetAncestor(element, offsetAncestor);
   const topViewport = {
      ...topViewportOffset,
      // Exclude bottom padding for the height to account for scrollbars.
      // NOTE: This behavior may need to be configurable in the future.
      height: topViewportElement.clientHeight - getPadding(topViewportElement).bottom,
      width: topViewportElement.clientWidth,
   };

   const leftViewportOffset = findOffsetToOffsetAncestor(leftViewportElement, offsetAncestor);
   const leftBoundsOffset = findOffsetToOffsetAncestor(element, offsetAncestor);
   const leftViewport = {
      ...leftViewportOffset,
      height: leftViewportElement.clientHeight,
      // Exclude bottom padding for the height to account for scrollbars.
      // NOTE: This behavior may need to be configurable in the future.
      width: leftViewportElement.clientWidth - getPadding(leftViewportElement).right,
   };

   // Find the bounds of the element within the inner scroll area.
   // This should be exactly equal to where the element would be if absolutely
   // positioned with these parameters.
   const contentBounds = {
      top: topBoundsOffset.top - topViewportOffset.top,
      left: leftBoundsOffset.left - leftViewportOffset.left,
      height: element.clientHeight,
      width: element.clientWidth,
   };

   return {
      contentBounds,
      visibleContentBounds: {
         ...contentBounds,
         top: contentBounds.top - topViewportElement.scrollTop,
         left: contentBounds.left - leftViewportElement.scrollLeft,
      },
      viewportBounds: {
         ...(findIntersection(topViewport, leftViewport) || {
            height: 0,
            width: 0,
            top: 0,
            left: 0,
         }),
      },
      topViewportElement,
      leftViewportElement,
   };
}

/** Finds the viewport element for a given element and dimension. */
export function findViewportElement(element: HTMLElement, dimension: "top" | "left"): HTMLElement {
   const property: keyof CSSStyleDeclaration = dimension == "top" ? "overflowY" : "overflowX";
   let current = element;
   while (current != document.body && current.parentElement != null) {
      const overflow = getComputedStyle(current)[property];
      const canScroll = overflow == "auto" || overflow == "scroll";
      if (
         canScroll &&
         ((dimension == "top" && current.scrollHeight != current.clientHeight) ||
            (dimension == "left" && current.scrollWidth != current.clientWidth))
      ) {
         return current;
      }
      current = current.parentElement;
   }
   return current;
}

/** Find the common offset ancestor between an element and an ancestor element. */
export function findCommonOffsetAncestor({
   element,
   ancestor,
}: {
   element: HTMLElement;
   ancestor: HTMLElement;
}): HTMLElement {
   let current: HTMLElement = element;
   const ancestorOffset = ancestor.offsetParent as HTMLElement;
   while (current.offsetParent) {
      if (current.offsetParent == ancestor) {
         return ancestor;
      }
      if (current.offsetParent == ancestorOffset) {
         return ancestorOffset;
      }
      current = current.offsetParent as HTMLElement;
   }
   return document.body;
}

/**
 * Calculates the offset from the given element to an offset ancestor.
 * NOTE: The offsetAncestor must be and offsetParent to either the element or an ancestor
 * of the element.
 */
export function findOffsetToOffsetAncestor(
   element: HTMLElement | SVGElement,
   offsetAncestor: HTMLElement,
): Point {
   let top = 0;
   let left = 0;
   let current: HTMLElement | SVGElement | null = element;
   while (current && current != offsetAncestor) {
      if (current instanceof SVGElement) {
         // SVG elements do not affect the offset. Continue up the tree until
         // the current target is found.
         current = current.parentElement!;
      } else {
         top += current.offsetTop;
         left += current.offsetLeft;
         current = current.offsetParent as HTMLElement;
      }
   }
   return { top, left };
}

/**
 * Orders the elements by ancestory. Only supports when one element is a direct ancestory
 * of another.
 * @return [parent, child]
 */
export function orderByAncestry(a: HTMLElement, b: HTMLElement): [HTMLElement, HTMLElement] {
   let current = a.parentElement;
   while (current) {
      if (current == b) {
         return [b, a];
      }
      current = current.parentElement;
   }
   return [a, b];
}

/** Returns a NodeList of all the focusable child elements. */
export function findFocusableElements(element: Element): NodeListOf<HTMLElement> {
   return element.querySelectorAll(
      "button:not([disabled]), [href], input:not([disabled]), select, textarea, [tabindex]:not([tabindex='-1']):not([data-focus-lock])",
   );
}

/** Returns an array of focusable elements filtered down to those which are visible. */
export function findVisibleFocusableElements(element: Element): HTMLElement[] {
   return Array.from(findFocusableElements(element)).filter((element) => !isElementHidden(element));
}

/** Returns the first focusable element that's not hidden. */
export function findFirstFocusableElement(element: Element): HTMLElement | null {
   const elements = findFocusableElements(element);
   return Array.from(elements).find((element) => !isElementHidden(element)) || null;
}

/** Returns whether the child is contained within the parent. */
export function isContainedElement({
   parent,
   child,
}: {
   parent: HTMLElement;
   child: HTMLElement;
}): boolean {
   let current: HTMLElement | null = child;
   while (current) {
      if (current == parent) {
         return true;
      }
      current = current.parentElement;
   }
   return false;
}

/** Checks whether the elment is hidden via its own styles or by the styles of ancestors. */
export function isElementHidden(element: Element): boolean {
   let current: Element | null = element;
   while (current) {
      if (current == document.body) {
         return false;
      }
      if (hasHiddenStyles(current)) {
         return true;
      }
      current = current.parentElement;
   }
   // The element is detached from the document.
   return true;
}

/** Checks whether the element has hidden styles such that it is not visible. */
export function hasHiddenStyles(element: Element): boolean {
   const styles = getComputedStyle(element);
   return styles.visibility == "hidden" || styles.display == "none";
}

/** Whether the element is contained within the specified element type. */
export function isContainedWithinType({
   element,
   type,
}: {
   element: Element;
   type: string;
}): boolean {
   const uppercasedType = type.toUpperCase();
   let current: Element | null = element;
   while (current) {
      if (current.tagName == uppercasedType) {
         return true;
      }
      if (current == document.body) {
         return false;
      }
      current = current.parentElement;
   }
   return false;
}

/** Retrieves the padding for an element. */
export function getPadding(element: Element): {
   top: number;
   right: number;
   bottom: number;
   left: number;
} {
   const styles = getComputedStyle(element);
   const parse = (value: string) => {
      const num = Number(value.replace("px", ""));
      return isNaN(num) ? 0 : num;
   };
   return {
      left: parse(styles.paddingLeft),
      right: parse(styles.paddingRight),
      top: parse(styles.paddingTop),
      bottom: parse(styles.paddingBottom),
   };
}
