import "./batch-edit-conflict-modal-pane.styl";
import template from "./batch-edit-conflict-modal-pane.pug";
import { ModalPane } from "../../modals/modal-pane";
import type { RowBase } from "../../grid/grid-store";
import type { GridColumnGroup } from "../../grid/grid-column-group";
import { transformColumnGroups } from "../../grid/grid-column-group";
import { ArrayGridStore } from "../../grid/array-grid-store";
import type { Observable } from "knockout";
import { observable, pureComputed } from "knockout";
import { ParagraphCell } from "../../grid/cells/paragraph-cell";
import { Modal } from "../../modals/modal";
import type { ModalExitStatus } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager";
import type { Conflict, ConflictRow } from "../batch-actions";
import type {
   SegmentedController2Option,
   SegmentedController2Params,
} from "../../segmented-controller-2/segmented-controller-2";

enum BatchEditModalTab {
   CONFLICTS = "conflicts",
   VALID = "valid",
}

export type BatchEditConflictModalPaneParams<TRow extends RowBase> = {
   records: TRow[];
   conflicts: Array<Conflict<TRow>>;
   columnGroups: Array<GridColumnGroup<TRow>>;
   saveProvider: () => Promise<void>;
};

export class BatchEditConflictModalPane<TRow extends RowBase> extends ModalPane {
   readonly selectedSegment: Observable<BatchEditModalTab>;
   readonly segmentedControllerParams: SegmentedController2Params<BatchEditModalTab>;
   readonly conflictsColumnGroups: Array<GridColumnGroup<ConflictRow<TRow>>>;
   readonly conflictsGridStore: ArrayGridStore<ConflictRow<TRow>>;
   readonly conflictCount: number;
   readonly validCount: number;

   readonly validColumnGroups: Array<GridColumnGroup<TRow>>;
   readonly validGridStore: ArrayGridStore<TRow>;
   private readonly saveProvider: () => Promise<void>;

   constructor({
      records,
      conflicts,
      columnGroups,
      saveProvider,
   }: BatchEditConflictModalPaneParams<TRow>) {
      super("Conflicts", null, template());
      this.saveProvider = saveProvider;
      this.validColumnGroups = columnGroups;
      this.validGridStore = new ArrayGridStore(
         records.filter((record) => {
            return !conflicts.find((conflict) => conflict.record.id == record.id);
         }),
      );
      this.conflictsColumnGroups = this.transformColumnGroups(columnGroups);
      this.conflictsGridStore = new ArrayGridStore(
         conflicts.map((conflict) => ({
            ...conflict,
            id: conflict.record.id,
         })),
      );

      this.conflictCount = this.conflictsGridStore.rows().length;
      this.validCount = this.validGridStore.rows().length;

      const conflictSegment: SegmentedController2Option<BatchEditModalTab> = {
         name: `Conflicts (${this.conflictCount})`,
         value: BatchEditModalTab.CONFLICTS,
         isSelected: pureComputed(() => this.selectedSegment() === BatchEditModalTab.CONFLICTS),
      };
      const validSegment: SegmentedController2Option<BatchEditModalTab> = {
         name: `Valid (${this.validCount})`,
         value: BatchEditModalTab.VALID,
         isSelected: pureComputed(() => this.selectedSegment() === BatchEditModalTab.VALID),
      };

      this.segmentedControllerParams = {
         onChange: (segment) => this.selectedSegment(segment),
         options: [conflictSegment, validSegment],
         requestSize: () => {},
      };

      this.selectedSegment = observable(conflictSegment.value);
      if (this.validCount > 0) {
         this.setReadyToSave();
      }
   }

   async actionBtnIntercept(done: () => void): Promise<void> {
      this.saveProvider();
      done();
   }

   private transformColumnGroups(columnGroups: Array<GridColumnGroup<TRow>>) {
      return transformColumnGroups({
         columnGroups,
         transform: (row: ConflictRow<TRow>) => row.record,
      }).concat([
         {
            columns: [
               {
                  key: "reason",
                  header: "Conflict",
                  width: 100,
                  cellFactory: ParagraphCell.factory((row) => ({
                     text: row.reason,
                     className: "batch-edit-conflict-modal__conflict-cell",
                  })),
                  autoResizable: true,
               },
            ],
         },
      ]);
   }

   private setReadyToSave() {
      this.setActionText("Save");
      this.isActionEnabled(true);
   }

   static show<TRow extends RowBase>(
      params: BatchEditConflictModalPaneParams<TRow>,
   ): Promise<ModalExitStatus> {
      return new Promise((resolve) => {
         const pane = new BatchEditConflictModalPane<TRow>(params);
         const modal = new Modal();
         modal.setPanes([pane]);
         modalManager.showModal(
            modal,
            null,
            { class: "batch-edit-conflict-modal-pane" },
            (modal, status) => {
               resolve(status);
            },
         );
      });
   }
}
