import "./calendar-selector.styl";
import template from "./calendar-selector.pug";
import type { Observable, PureComputed } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import { ViewModel } from "@/lib/vm/viewmodel";

export type CalendarSelectorParams = {
   date: PureComputed<Date | null>;
   onDateChange: (date: Date) => void;
   title?: string;
};

type DayData = {
   date: Date;
   isOutsideMonth: boolean;
   isSelected: boolean;
   isToday: boolean;
};

class CalendarSelectorState {
   private readonly interalState: {
      currentMonthView: Observable<number>;
      currentYearView: Observable<number>;
   };

   readonly currentMonthView = pureComputed(() => unwrap(this.interalState.currentMonthView));
   readonly currentYearView = pureComputed(() => unwrap(this.interalState.currentYearView));

   constructor(date: Date | null) {
      const safeDate = date ?? new Date();
      this.interalState = {
         currentMonthView: observable(safeDate.getMonth()),
         currentYearView: observable(safeDate.getFullYear()),
      };
   }

   increaseMonthView = (amount: number = 1): void => {
      const currentMonth = unwrap(this.currentMonthView);
      const yearIncreaseAmount = Math.floor((currentMonth + amount) / 12);
      if (yearIncreaseAmount > 0) {
         this.interalState.currentMonthView((currentMonth + amount) % 12);
         this.increaseYearView(yearIncreaseAmount);
      } else {
         this.interalState.currentMonthView(currentMonth + amount);
      }
   };
   increaseYearView = (amount: number = 1): void =>
      this.interalState.currentYearView(unwrap(this.currentYearView) + amount);

   decreaseMonthView = (amount: number = 1): void => {
      const currentMonth = unwrap(this.currentMonthView);
      const yearDecreaseAmount = Math.floor((currentMonth - amount) / 12);
      if (yearDecreaseAmount > 0) {
         this.interalState.currentMonthView((currentMonth - amount) % 12);
         this.decreaseYearView(yearDecreaseAmount);
      } else {
         this.interalState.currentMonthView(currentMonth - amount);
      }
   };
   decreaseYearView = (amount: number = 1): void =>
      this.interalState.currentYearView(unwrap(this.currentYearView) - amount);

   moveViewToDate = (date: Date): void => {
      this.interalState.currentMonthView(date.getMonth());
      this.interalState.currentYearView(date.getFullYear());
   };
}

export class CalendarSelector extends ViewModel {
   private readonly state: CalendarSelectorState;

   readonly date: CalendarSelectorParams["date"];
   readonly onDateChange: CalendarSelectorParams["onDateChange"];
   readonly title: CalendarSelectorParams["title"];

   readonly year = pureComputed(() => unwrap(this.date)?.getFullYear());
   readonly month = pureComputed(() => unwrap(this.date)?.getMonth());
   readonly day = pureComputed(() => unwrap(this.date)?.getDate());

   readonly viewingYear = pureComputed(() => unwrap(this.state.currentYearView));
   readonly viewingMonth = pureComputed(() => unwrap(this.state.currentMonthView));
   readonly viewingMonthName = pureComputed(() => {
      const viewingDate = new Date(unwrap(this.viewingYear), unwrap(this.viewingMonth), 1);
      return viewingDate.toLocaleString("default", { month: "long" });
   });

   readonly increaseYearView = (amount: number = 1): void => this.state.increaseYearView(amount);
   readonly decreaseYearView = (amount: number = 1): void => this.state.decreaseYearView(amount);
   readonly increaseMonthView = (amount: number = 1): void => this.state.increaseMonthView(amount);
   readonly decreaseMonthView = (amount: number = 1): void => this.state.decreaseMonthView(amount);

   readonly selectTodaysDate = (): void => {
      this.selectDate(this.today);
   };

   readonly selectDate = (date: Date): void => {
      this.onDateChange(date);
   };

   readonly weekdayHeaders = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];
   readonly today = new Date();

   readonly weeksInView = pureComputed(() => {
      const year = unwrap(this.state.currentYearView);
      const month = unwrap(this.state.currentMonthView);
      const weeks: DayData[][] = [[]];

      const firstOfTheMonth = new Date(year, month, 1);
      const firstIndexPaddedToFullWeek = 0 - firstOfTheMonth.getDay();
      const lastOfTheMonth = new Date(year, month + 1, 0);
      const lastIndexPaddedToFullWeek = lastOfTheMonth.getDate() + (6 - lastOfTheMonth.getDay());

      for (let i = firstIndexPaddedToFullWeek; i < lastIndexPaddedToFullWeek; i++) {
         if (weeks[weeks.length - 1].length == 7) weeks.push([]);
         const date = new Date(year, month, i + 1);
         weeks[weeks.length - 1].push({
            date,
            isOutsideMonth: i < 0 || i >= lastOfTheMonth.getDate(),
            isSelected: date.toDateString() === (unwrap(this.date)?.toDateString() ?? null),
            isToday: date.toDateString() === this.today.toDateString(),
         });
      }
      return weeks;
   });

   constructor({ date, onDateChange, title = "Select Date" }: CalendarSelectorParams) {
      super(template());

      this.state = new CalendarSelectorState(date());

      this.date = date;
      date.subscribe((date: Date | null) => {
         if (date) this.state.moveViewToDate(date);
      });
      this.onDateChange = onDateChange;
      this.title = title;
   }
}

ko.components.register("calendar-selector", {
   viewModel: CalendarSelector,
   template: template(),
   synchronous: true,
});
