import type { LoadingState, RowBase } from "./grid-store";
import type { PaginatedGridStoreParams } from "./paginated-grid-store";
import { LoadType, PaginatedGridStore } from "./paginated-grid-store";
import { Action, notificationManagerInstance } from "@/lib/managers/notification-manager";
import { ProgressNotification } from "@/notifications/progress-notification";
import { CancelledError } from "@/lib/async/cancelled-error";
import type { Subscribable } from "knockout";

const DEFAULT_LIMIT = 40;
const LOADING_ALL_LIMIT = 100;

export interface SkipQueryParams {
   skip?: number;
   limit?: number;
}

export interface SkipResponse<TRow extends RowBase> {
   data: TRow[];
   total: number;
}

export type ErrorCallback = (error: Error, loadingState: LoadingState) => void;

/** Abstract store for working with skip-based endpoints. */
export abstract class SkipGridStore<
   TRow extends RowBase,
   TQueryParams extends SkipQueryParams,
> extends PaginatedGridStore<TRow, TQueryParams, number> {
   private startingSkip = 0;
   private totalRows = 0;

   constructor(params: PaginatedGridStoreParams<TQueryParams, number>) {
      // Default the limit if it has not been
      super({
         ...params,
         queryParams: {
            ...params.queryParams,
            limit: params.queryParams.limit || DEFAULT_LIMIT,
         },
      });
      this.startingSkip = this.getCursor() || 0;
      this.hasPreviousRows(this.startingSkip > 0);
      this.hasNextRows(true);
   }

   /** Subclasses implement this method to load data. */
   protected abstract loadRows(
      queryParams: TQueryParams,
   ): SkipResponse<TRow> | Promise<SkipResponse<TRow>>;

   protected createCursor(rowIndex: number): number {
      return this.startingSkip + rowIndex;
   }

   protected loadRowsForType(
      loadType: LoadType,
      queryParams: TQueryParams,
   ): TRow[] | Promise<TRow[]> {
      const limit = this.isLoadingAll() ? LOADING_ALL_LIMIT : this.getQueryParams().limit!;
      if (loadType == LoadType.PREVIOUS) {
         const skip = Math.max(0, this.startingSkip - limit);
         const response = this.loadRows({
            ...queryParams,
            limit: Math.min(this.startingSkip, limit),
            skip,
         });
         const onSuccess = (response: SkipResponse<TRow>) => {
            this.startingSkip = skip;
            this.totalRows = response.total;
            this.hasPreviousRows(skip > 0);
            return response.data;
         };
         return response instanceof Promise ? response.then(onSuccess) : onSuccess(response);
      }

      const skip = this.startingSkip + this.rows().length;
      const response = this.loadRows({ ...queryParams, limit, skip });
      const onSuccess = (response: SkipResponse<TRow>) => {
         this.totalRows = response.total;
         this.hasNextRows(response.data.length >= limit);
         return response.data;
      };
      return response instanceof Promise ? response.then(onSuccess) : onSuccess(response);
   }

   async loadAll(): Promise<void> {
      if (this.isLoadingAll()) return;
      if (!this.hasNextRows() && !this.hasPreviousRows()) return;
      this.isLoadingAll(true);

      const notification = new ProgressNotification({
         message: "Loading all records...",
         actions: [
            new Action({
               text: "Cancel",
               type: Action.Type.RED,
               onClick: () => this.cancelLoadAll(),
            }),
         ],
      });
      const subscriptions = [
         this.rows.subscribe(() => {
            if (this.isLoadingAll()) {
               notification.update({ percent: this.rows().length / this.totalRows });
            }
         }),
         this.isLoadingAll.subscribe(() => {
            notification.failed({
               message: "Loading all records was cancelled.",
               actions: [],
            });
         }),
      ];

      notificationManagerInstance.show(notification);

      try {
         await this.loadAllForType({ loadType: LoadType.PREVIOUS, hasMore: this.hasPreviousRows });
         await this.loadAllForType({ loadType: LoadType.NEXT, hasMore: this.hasNextRows });
         notification.success({ message: "All records have been loaded.", actions: [] });
      } catch (error) {
         if (!(error instanceof CancelledError)) {
            notification.failed({
               message: "An unexpected error occurred while loading.",
               actions: [],
            });
         }
      } finally {
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
         subscriptions.forEach((s) => s.dispose());
         this.isLoadingAll(false);
      }
   }

   cancelLoadAll(): void {
      this.isLoadingAll(false);
   }

   private async loadAllForType({
      loadType,
      hasMore,
   }: {
      loadType: LoadType;
      hasMore: Subscribable<boolean>;
   }) {
      while (hasMore()) {
         if (!this.isLoadingAll()) throw new CancelledError();
         const rows = await this.loadRowsForType(loadType, this.getQueryParams());
         if (loadType == LoadType.PREVIOUS) {
            this.rows.unshift(...rows);
         } else {
            this.rows.push(...rows);
         }
      }
   }
}
