export function requestAnimationFrames(count: number, callback: () => void): void {
   if (count < 1) return callback();
   let frames = 0;
   const requestFrame = () => {
      requestAnimationFrame(() => {
         frames += 1;
         if (frames >= count) {
            callback();
         } else {
            requestFrame();
         }
      });
   };
   requestFrame();
}
