var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-context-menu-copy"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg width=\"16\" height=\"14\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cdefs\u003E\u003Cfilter x=\"-29.2%\" y=\"-20.8%\" width=\"158.3%\" height=\"158.3%\" filterUnits=\"objectBoundingBox\" id=\"icon-context-menu-copy-a\"\u003E\u003CfeOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"\u002F\u003E\u003CfeGaussianBlur stdDeviation=\"1\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"\u002F\u003E\u003CfeColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161795236 0\" in=\"shadowBlurOuter1\"\u002F\u003E\u003C\u002Ffilter\u003E\u003Cpath d=\"M8.842 0H1.263C.568 0 0 .49 0 1.09v7.637h1.263V1.091h7.58V0zm1.895 2.182H3.789c-.694 0-1.263.49-1.263 1.09v7.637c0 .6.569 1.091 1.263 1.091h6.948c.695 0 1.263-.49 1.263-1.09V3.272c0-.6-.568-1.091-1.263-1.091zm0 8.727H3.789V3.273h6.948v7.636z\" id=\"icon-context-menu-copy-b\"\u002F\u003E\u003C\u002Fdefs\u003E\u003Cg transform=\"translate(2 1)\" fill-rule=\"nonzero\" fill=\"none\"\u003E\u003Cuse fill=\"#000\" filter=\"url(#icon-context-menu-copy-a)\" xlink:href=\"#icon-context-menu-copy-b\"\u002F\u003E\u003Cuse fill=\"#D9D9D9\" xlink:href=\"#icon-context-menu-copy-b\"\u002F\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-context-menu-edit"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg width=\"16\" height=\"14\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E \u003Cdefs\u003E  \u003Cfilter x=\"-29.2%\" y=\"-20.8%\" width=\"158.3%\" height=\"158.3%\" filterUnits=\"objectBoundingBox\" id=\"icon-context-menu-edit-a\"\u003E   \u003CfeOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"\u002F\u003E   \u003CfeGaussianBlur stdDeviation=\"1\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"\u002F\u003E   \u003CfeColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161795236 0\" in=\"shadowBlurOuter1\"\u002F\u003E  \u003C\u002Ffilter\u003E  \u003Cpath d=\"M0 9.5V12h2.5l7.373-7.373-2.5-2.5L0 9.5zm11.807-6.807c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z\" id=\"icon-context-menu-edit-b\"\u002F\u003E \u003C\u002Fdefs\u003E \u003Cg transform=\"translate(2 1)\" fill-rule=\"nonzero\" fill=\"none\"\u003E  \u003Cuse fill=\"#000\" filter=\"url(#icon-context-menu-edit-a)\" xlink:href=\"#icon-context-menu-edit-b\"\u002F\u003E  \u003Cuse fill=\"#D9D9D9\" xlink:href=\"#icon-context-menu-edit-b\"\u002F\u003E \u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-context-menu-open"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg width=\"16\" height=\"14\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cdefs\u003E\u003Cfilter x=\"-29.2%\" y=\"-20.8%\" width=\"158.3%\" height=\"158.3%\" filterUnits=\"objectBoundingBox\" id=\"icon-context-menu-open-a\"\u003E\u003CfeOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"\u002F\u003E\u003CfeGaussianBlur stdDeviation=\"1\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"\u002F\u003E\u003CfeColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161795236 0\" in=\"shadowBlurOuter1\"\u002F\u003E\u003C\u002Ffilter\u003E\u003Cpath d=\"M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z\" id=\"icon-context-menu-open-b\"\u002F\u003E\u003C\u002Fdefs\u003E\u003Cg transform=\"translate(2 1)\" fill-rule=\"nonzero\" fill=\"none\"\u003E\u003Cuse fill=\"#000\" filter=\"url(#icon-context-menu-open-a)\" xlink:href=\"#icon-context-menu-open-b\"\u002F\u003E\u003Cuse fill=\"#D9D9D9\" xlink:href=\"#icon-context-menu-open-b\"\u002F\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003C!-- ko if: actions().includes(\"edit\") --\u003E\u003Cbutton class=\"grid-context-menu__action\" data-bind=\"click: () =&gt; onAction('edit')\"\u003E";
pug_mixins["icon-context-menu-edit"]();
pug_html = pug_html + "\u003Cspan\u003EEdit\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: actions().includes(\"open_in_new_tab\") --\u003E\u003Cbutton class=\"grid-context-menu__action\" data-bind=\"click: () =&gt; onAction('open_in_new_tab')\"\u003E";
pug_mixins["icon-context-menu-open"]();
pug_html = pug_html + "\u003Cspan\u003EOpen in New Tab\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: actions().includes(\"copy\") --\u003E\u003Cbutton class=\"grid-context-menu__action\" data-bind=\"click: () =&gt; onAction('copy')\"\u003E";
pug_mixins["icon-context-menu-copy"]();
pug_html = pug_html + "\u003Cspan\u003ECopy\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C!-- \u002Fko --\u003E";;return pug_html;};
module.exports = template;