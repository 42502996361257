import "./assignment-card-info-pane.styl"
import template from "./assignment-card-info-pane.pug"
import { DateUtils } from "@/lib/utils/date"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"

### Popups ###
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

export class AssignmentCardInfoPane extends PopupPane
   constructor: (data, exprClassCheck, outsideGroupAccess) ->
      assertArgs(arguments, Object, Function, Boolean)
      super(template(), "Assignment Info")
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewPeopleSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE)
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canViewPeopleFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS)

      @data = data
      city = data.baggage()?.resource_city or ""
      state = data.baggage()?.resource_state or ""
      zip = data.baggage()?.resource_zipcode or ""
      if !city && !state && !zip
         @cityStateZip = "-"
      else
         @cityStateZip = "#{city}, #{state} #{zip}"

      @outsideGroupAccess = outsideGroupAccess

      if data.resourcePhotoUrl()?
         fragments = data.resourcePhotoUrl().split("upload/")
         url = "#{fragments[0]}upload/g_face,c_thumb,w_80,h_80/#{fragments[1]}"
         @resourcePhotoUrl = url
      else  
         @resourcePhotoUrl =  null

      @hasWorkDays = data.workDays()?
      if @hasWorkDays
         @sunday = data.workDays()[0]
         @monday = data.workDays()[1]
         @tuesday = data.workDays()[2]
         @wednesday = data.workDays()[3]
         @thursday = data.workDays()[4]
         @friday = data.workDays()[5]
         @saturday = data.workDays()[6]

      options = {
         yearFormat: DateUtils.YearFormat.SHORT
         monthFormat: DateUtils.MonthFormat.ONE_DIGIT
         dayFormat: DateUtils.DayFormat.ONE_DIGIT
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV
      }
      @formattedStart = null
      @formattedStart = DateUtils.formatDetachedDay(data.startDay(), defaultStore.getDateFormat(), options) if data.startDay()?

      @formattedEnd = null
      @formattedEnd = DateUtils.formatDetachedDay(data.endDay(), defaultStore.getDateFormat(), options) if data.startDay()?

      @startTime = null
      @startTime = defaultStore.formatTime(data.startTime()) if data.startTime()?
      @endTime = null
      @endTime = defaultStore.formatTime(data.endTime()) if data.endTime()?
      
      @percentAllocated = data.percentAllocated()

      @resourcesGroupNames = null
      if data.groupIds()? and data.groupIds().length > 0
         @resourcesGroupNames = ""
         for id in data.groupIds()
            groupName = authManager.companyGroupNames()[id]
            continue unless groupName?
            @resourcesGroupNames += "#{groupName}, "
         @resourcesGroupNames = @resourcesGroupNames.slice(0,-2)

      @exprClassCheck = exprClassCheck

   navigateToPerson: =>
      return unless @canViewPeople
      return if @outsideGroupAccess
      @dismissHandler()
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{@data.resourceId()}")
