import "./virtual-grid.styl";
import template from "./virtual-grid.pug";
import type {
   Observable,
   ObservableArray,
   utils,
   Subscription,
   MaybeObservable,
   MaybeObservableArray,
   Subscribable,
   PureComputed,
} from "knockout";
import { isComputed } from "knockout";
import ko, { isObservable, observable, pureComputed, unwrap } from "knockout";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import type { GridColumn } from "@/lib/components/grid/grid-column";
import { GridActionResult, GridActionSource } from "@/lib/components/grid/grid-column";
import type {
   RenderedCell,
   RenderedColumnGroupHeader,
   RenderedColumnHeader,
   RenderedRow,
} from "@/lib/components/grid/grid-layout";
import { GridLayout } from "@/lib/components/grid/grid-layout";
import type { GridStore, RowBase } from "@/lib/components/grid/grid-store";
import { LoadingState } from "@/lib/components/grid/grid-store";
import type { Point, Size } from "@/lib/utils/geometry";
import { compactArrayChanges } from "@/lib/utils/knockout";
import type { CellSelection } from "@/lib/components/grid/grid-editor";
import { GridEditor, SelectionState } from "@/lib/components/grid/grid-editor";
import { GridContextMenuAction } from "@/lib/components/grid/grid-context-menu/grid-context-menu";
import {
   notificationManagerInstance,
   Notification,
   Icons,
} from "@/lib/managers/notification-manager";
import type { DragUpdate } from "@/lib/utils/drag-handler";
import { createDragHandler } from "@/lib/utils/drag-handler";
import { findOffsetToOffsetAncestor, isContainedWithinType } from "@/lib/utils/elements";
import type { ComponentArgs } from "@/lib/components/common";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

const DEFAULT_HEADER_HEIGHT = 40;
const DEFAULT_MINIMUM_ROW_HEIGHT = 36;
const DEFAULT_PADDING_BETWEEN_ROWS = 4;
const DEFAULT_PADDING_BETWEEN_COLUMNS = 8;
const DEFAULT_PADDING_AROUND_COLUMNS = 16;

const SCROLLBAR_SIZE = 20;
const TOP_LOADING_PLACEHOLDER_HEIGHT = 10;

/**
 * Threshold used to determine if a double click should trigger the action for a cell
 * when initially focused.
 */
const DOUBLE_CLICK_TIMEOUT = 500;

/** Distance from the edge of the screen that triggers scrolling the viewport. */
const SCROLL_BUFFER = 70;

/**
 * Maximum distance to scrolled at each increment when scrolling by dragging near the
 * edge of the viewport. A percentage of this value is used based on how close the cursor
 * is to the edge of the screen.
 */
const MAX_SCROLL_AMOUNT = 10;

export type GridSortOrder = {
   columnKey: string;
   direction: Order;
};

export const enum FocusMode {
   NONE = "none",
   CELL = "cell",
}

/** Represents the current focus of the grid. */
export type GridCellFocus = {
   rowIndex: number;
   columnIndex: number;
};

// TODO: Add more actions for hooks as needed.
export const enum GridActionType {
   CELL_CLICK = "cell_click",
   CELL_ENTER = "cell_enter",
}

export type GridAction<TRow extends RowBase> = {
   type: GridActionType.CELL_CLICK | GridActionType.CELL_ENTER;
   cell: RenderedCell<TRow>;
};

export type VirtualGridParams<TRow extends RowBase> = {
   /** Column groups to display. */
   columnGroups: MaybeObservableArray<GridColumnGroup<TRow>>;

   /** Store providing row data. */
   store: Observable<GridStore<TRow>> | GridStore<TRow>;

   /** Optional: Observable of the current sort order. */
   sortOrder?: Observable<GridSortOrder | null>;

   /**
    * Optional: Observable of the current selected row IDs. IDs will be added
    * and removed as the user interacts. See `selection_mode`.
    */
   selectedIds?: ObservableArray<string> | null;

   /** Optional: Focus mode. Defaults to NONE. */
   focusMode?: MaybeObservable<FocusMode>;

   /** Optional: Callback invoked when the user interacts with the grid. */
   onGridAction?: (action: GridAction<TRow>) => void;

   /** Optional: Observable of the currently focused cell. */
   cellFocus?: Observable<GridCellFocus | null>;

   /** Optional: Observable of the first currently visible item.  */
   firstVisibleRowId?: Observable<string | null>;

   /**
    * Optional: Whether the cell selection cursor is visible.
    * Defaults to `true`.
    */
   hasCellSelectionCursor?: MaybeObservable<boolean>;

   /** Optional: Message shown when the grid is empty */
   emptyMessage?: MaybeObservable<string> | PureComputed<string>;

   /** Optional: Height of the header. */
   headerHeight?: MaybeObservable<number>;

   /** Optional: Minimum row height. Each row will be at least this tall. */
   defaultRowHeight?: MaybeObservable<number>;

   /**
    * Optional: Maximum height of the grid. Useful when the grid's height
    * should be determined by the amount of content such as for drop downs.
    */
   maxHeight?: MaybeObservable<number | null>;

   /**
    * Optional: Padding between each of the rows. Measured from the bottom of one cell to the top
    * of the following cell. Implemented as vertical padding between cells.
    */
   paddingBetweenRows?: MaybeObservable<number>;

   /**
    * Optional: Padding between columns. Measured from the right of one cell to the left of the
    * next.
    */
   paddingBetweenColumns?: MaybeObservable<number>;

   /** Optional: Padding around all of the columns. Insets the content on each side this amount. */
   paddingAroundColumns?: MaybeObservable<number> | PureComputed<number>;

   /**
    * Optional: Additional vertical padding around the progress bars on top ofthe minimum
    * row height.
    */
   paddingAroundProgressBars?: MaybeObservable<number>;

   /** Optional: Additional vertical padding around the initial progress bar. */
   paddingAroundInitialProgressBar?: MaybeObservable<number>;

   /** Optional: Whether the grid has borders in between rows. */
   hasRowBorders?: MaybeObservable<boolean>;

   /** Optional: Whether the scroll bars are visible. */
   hasScrollBars?: MaybeObservable<boolean>;

   /** Optional: Class added to the empty message element. */
   emptyMessageClass?: MaybeObservable<string | null>;

   /** Optional: Class added to the error message element. */
   errorMessageClass?: MaybeObservable<string | null>;

   /** Optional: Class to add to each row element. */
   rowClass?: MaybeObservable<string | null>;

   rowFocusedClass?: MaybeObservable<string | null>;

   /** Optional: Class to add to each row element when selected. */
   rowSelectedClass?: MaybeObservable<string | null>;

   /**
    * Optional: Subscribable to trigger a size recalculation which changed.
    * NOTE: The grid's content will be immediately resized. Consider restricting the
    * notification rate of observables if it causes the size to change in a tight
    * loop.
    */
   resizeTrigger?: Subscribable<unknown>;

   /** Optional: Callback invoked before the content will change. */
   onBeforeContentChange?: () => void;

   /** Optional: Callback invoked when the content has changed. */
   onContentChanged?: () => void;

   loadReady?: Observable<boolean>;
};

type ResizingState<TRow extends RowBase> = {
   header: RenderedColumnHeader<TRow>;
   groupHeader: RenderedColumnGroupHeader<TRow>;
   delta: number;
   offset: number;
};

type ReorderingState<TRow extends RowBase> = {
   groupHeader: RenderedColumnGroupHeader<TRow>;
   offsets: number[];
};

export class VirtualGrid<TRow extends RowBase> {
   readonly sortOrder: Observable<GridSortOrder | null>;
   readonly emptyMessage: Observable<string> | PureComputed<string>;
   readonly layout: GridLayout<TRow>;
   readonly editor: GridEditor<TRow> | null = null;
   readonly headerHeight: number;
   readonly hasRowBorders: boolean;
   readonly hasScrollBars: boolean;
   readonly paddingAroundColumns: number;
   readonly paddingBetweenRows: number;
   readonly paddingAroundInitialProgressBar: number;
   readonly defaultRowHeight: number;
   readonly maxHeight: Observable<number | null>;
   readonly focusMode: FocusMode;

   readonly isEmpty = observable(false);
   readonly contentSize = observable<Size>({ height: 0, width: 0 });
   readonly size = observable<Size>({ height: 0, width: 0 });
   readonly resizeTrigger = observable(0);
   readonly scrollTop = observable(0).extend({ notify: "always" });
   readonly scrollLeft = observable(0).extend({ notify: "always" });
   readonly isContextMenuFocused = observable(false);
   readonly isEditorFocused = observable(false);
   readonly hasCellSelectionCursor: Observable<boolean>;
   readonly resizingState = observable<ResizingState<TRow> | null>(null);
   readonly hasTouch = navigator.maxTouchPoints > 0;
   readonly reorderingState = observable<ReorderingState<TRow> | null>(null);

   readonly maxSize = pureComputed<Size>(() => {
      const size = this.size();
      const maxHeight = this.maxHeight();
      if (maxHeight == null) return size;
      return {
         height: Math.max(size.height, maxHeight),
         width: size.width,
      };
   });
   readonly gridStyle = pureComputed<Partial<CSSStyleDeclaration>>(() => {
      if (this.maxHeight() == null) return {};
      return { maxHeight: `${this.maxHeight()}px` };
   });
   readonly paddingContentTop = pureComputed(() => {
      if (this.visibleLoadingState() == LoadingState.PREVIOUS) {
         return this.progressBarHeight();
      }
      if (this.store().hasPreviousRows()) {
         return TOP_LOADING_PLACEHOLDER_HEIGHT;
      }
      return 0;
   });
   readonly paddingContentBottom = pureComputed(() => {
      return this.store().loadingState() == LoadingState.NEXT ? this.progressBarHeight() : 0;
   });
   readonly error = pureComputed(() => {
      return unwrap(this.store().error || null);
   });

   readonly isNearTop = pureComputed(() => {
      return this.scrollTop() < this.paddingContentTop();
   }).extend({ rateLimit: 50 });
   readonly isNearBottom = pureComputed(() => {
      const bottomOfViewport = this.scrollTop() + this.size().height;
      return bottomOfViewport >= this.layout.innerContentHeight() - this.defaultRowHeight;
   }).extend({ rateLimit: 50 });

   readonly progressBarHeight = pureComputed(() => {
      return (
         this.defaultRowHeight +
         this.paddingBetweenRows +
         unwrap(this.params.paddingAroundProgressBars || 0)
      );
   });
   readonly progressBarHeightInitial = pureComputed(() => {
      const padding =
         unwrap(this.params.paddingAroundInitialProgressBar || 0) ||
         unwrap(this.params.paddingAroundProgressBars || 0);
      return this.defaultRowHeight + this.paddingBetweenRows + padding;
   });
   readonly centeredContentStyle = pureComputed(() => {
      // Use the content viewport if possible to account for vertical scrollbars.
      const contentViewport = this.layout.rowsViewportBounds();
      const width = contentViewport.width > 0 ? contentViewport.width : this.size().width;
      return {
         marginLeft: `${width * 0.2}px`,
         marginRight: `${width * 0.2}px`,
         width: `${width * 0.6}px`,
         left: `${this.scrollLeft()}px`,
      };
   });
   readonly contentWidth = pureComputed(() => {
      const additionalWidth =
         this.hasScrollBars && this.layout.hasVerticalScroll() ? SCROLLBAR_SIZE : 0;
      return this.layout.outerContentWidth() + additionalWidth;
   });

   private readonly columnGroups: ObservableArray<GridColumnGroup<TRow>>;
   private readonly store: Observable<GridStore<TRow>>;
   private readonly selectedIds: ObservableArray<string>;
   private readonly cellFocus: Observable<GridCellFocus | null>;
   private readonly firstVisibleRowId: Observable<string | null> | null;
   private readonly onGridAction: (action: GridAction<TRow>) => void;
   private readonly visibleLoadingState = observable(LoadingState.NONE);

   /** Cached rows that will be displayed as soon as the loading indicator is finished animating. */
   private queuedChanges: utils.ArrayChanges<TRow> = [];
   private subscriptions: Subscription[] = [];
   private storeSubscriptions: Subscription[] = [];
   /** Tracks the number of clicks that have occurred since the cell was focused. */
   private waitingForDoubleClickSelection: CellSelection | null = null;
   private isIgnoringColumnGroupChanges = false;

   constructor(public readonly params: VirtualGridParams<TRow>) {
      this.columnGroups = isObservable(params.columnGroups)
         ? params.columnGroups.extend({ trackArrayChanges: true })
         : ko.observableArray(params.columnGroups);
      this.store = isObservable(params.store)
         ? params.store
         : (observable(params.store) as Observable<GridStore<TRow>>);
      this.sortOrder = params.sortOrder || ko.observable<GridSortOrder | null>(null);
      this.selectedIds = params.selectedIds || ko.observableArray();
      this.emptyMessage =
         isObservable(params.emptyMessage) || isComputed<string>(params.emptyMessage)
            ? params.emptyMessage
            : (ko.observable(params.emptyMessage || "Empty") as Observable<string>);
      this.focusMode = unwrap<FocusMode | undefined>(params.focusMode) || FocusMode.NONE;
      this.cellFocus = isObservable(params.cellFocus) ? params.cellFocus : observable(null);
      this.hasCellSelectionCursor = isObservable(params.hasCellSelectionCursor)
         ? params.hasCellSelectionCursor
         : (observable(params.hasCellSelectionCursor ?? true) as Observable<boolean>);
      this.onGridAction = params.onGridAction ?? (() => {});

      this.headerHeight =
         params.headerHeight != null ? unwrap(params.headerHeight) : DEFAULT_HEADER_HEIGHT;
      this.hasRowBorders = unwrap(params.hasRowBorders || false);
      this.hasScrollBars = unwrap(params.hasScrollBars || false);
      this.paddingAroundColumns =
         params.paddingAroundColumns != null
            ? unwrap(params.paddingAroundColumns)
            : DEFAULT_PADDING_AROUND_COLUMNS;
      this.paddingBetweenRows =
         params.paddingBetweenRows != null
            ? unwrap(params.paddingBetweenRows)
            : DEFAULT_PADDING_BETWEEN_ROWS;
      this.paddingAroundInitialProgressBar = unwrap(params.paddingAroundInitialProgressBar || 0);
      this.defaultRowHeight =
         params.defaultRowHeight != null
            ? unwrap(params.defaultRowHeight)
            : DEFAULT_MINIMUM_ROW_HEIGHT;
      this.maxHeight = isObservable(params.maxHeight)
         ? params.maxHeight
         : (observable(params.maxHeight ?? null) as Observable<number | null>);
      this.firstVisibleRowId = params.firstVisibleRowId || null;
      this.layout = new GridLayout({
         viewport: this.maxSize,
         paddingContentTop: this.paddingContentTop,
         paddingContentBottom: this.paddingContentBottom,
         scrollTop: this.scrollTop,
         scrollLeft: this.scrollLeft,
         headerHeight: this.headerHeight,
         scrollbarSize: this.hasScrollBars ? SCROLLBAR_SIZE : 0,
         defaultRowHeight: this.defaultRowHeight,
         paddingBetweenRows: this.paddingBetweenRows,
         paddingBetweenColumns:
            params.paddingBetweenColumns != null
               ? unwrap(params.paddingBetweenColumns)
               : DEFAULT_PADDING_BETWEEN_COLUMNS,
         paddingAroundColumns: this.paddingAroundColumns,
         hasRowBorders: this.hasRowBorders,
         onRowFocusChanged: this.onLayoutRowFocusChanged,
         onCellFocusChanged: this.onLayoutCellFocusChanged,
      });
      if (params.resizeTrigger) {
         this.subscriptions.push(
            params.resizeTrigger.subscribe(() => this.resizeTrigger(Date.now())),
         );
      }
      if (params.onBeforeContentChange) {
         this.subscriptions.push(
            this.layout.rowsInDom.subscribe(params.onBeforeContentChange, null, "beforeChange"),
            this.layout.innerContentHeight.subscribe(
               params.onBeforeContentChange,
               null,
               "beforeChange",
            ),
         );
      }
      if (params.onContentChanged) {
         this.subscriptions.push(
            this.layout.rowsInDom.subscribe(params.onContentChanged),
            this.layout.innerContentHeight.subscribe(params.onContentChanged, null, "beforeChange"),
         );
      }

      if (this.firstVisibleRowId) {
         this.subscriptions.push(
            this.firstVisibleRowId.subscribe(this.onFirstVisibleRowIdChanged, this),
         );
      }

      if (this.focusMode != FocusMode.NONE) {
         this.editor = new GridEditor({
            layout: this.layout,
            focusMode: this.focusMode,
            onGridAction: this.onGridAction,
         });
      }

      // Subscribe to changes.
      this.subscriptions.push(
         this.columnGroups.subscribe(this.onColumnGroupsChanged, this),
         this.store.subscribe(this.onStoreChanged, this),
         this.layout.firstVisibleRow.subscribe(this.onFirstVisibleRowChanged, this),
         this.layout.rowsInDom.subscribe(() => this.resizeTrigger(Date.now())),
         this.isNearBottom.subscribe(this.onIsNearBottomChanged, this),
         this.isNearTop.subscribe(this.onIsNearTopChanged, this),
         this.maxHeight.subscribe(() => this.resizeTrigger(Date.now())),
      );
      this.storeSubscriptions = [
         this.store().rows.subscribe(this.onRowsChanged, this, "arrayChange"),
         this.store().loadingState.subscribe(this.onLoadingStateChanged, this),
      ];
      if (this.editor) {
         this.subscriptions.push(
            this.cellFocus.subscribe(this.onCellFocusChanged, this),
            this.editor.cellSelection.subscribe(this.onCellSelectionChanged, this),
         );
      }

      const columnGroups = this.columnGroups();
      if (columnGroups.length) {
         this.layout.setColumnGroups(this.columnGroups());
      }

      if (params.loadReady == null || params.loadReady() == true) {
         this.loadInitialRows();
      } else {
         this.subscriptions.push(
            params.loadReady.subscribe((ready) => {
               if (ready) {
                  this.loadInitialRows();
                  // params.loadReady?.dispose();
               }
            }),
         );
      }
   }

   onProgressFinished = (): void => {
      // If no changes were queued and there are currently no rows in the grid then
      // show the empty message.
      if (!this.queuedChanges.length && !this.store().rows().length) {
         this.isEmpty(true);
         if (this.params.onContentChanged) this.params.onContentChanged();
         return;
      }

      this.applyRowChanges(this.queuedChanges);
      this.queuedChanges = [];

      const previousLoadingState = this.visibleLoadingState();
      this.visibleLoadingState(LoadingState.NONE);

      if (previousLoadingState == LoadingState.INITIAL && this.store().hasPreviousRows()) {
         // Offset the scroll position by the loading placeholder height to visually indicate
         // to the user that scrolling up is possible.
         this.scrollTop(TOP_LOADING_PLACEHOLDER_HEIGHT);
      } else if (previousLoadingState == LoadingState.PREVIOUS) {
         // Offset the scroll position by the height of the loading indicator to show the
         // user the freshly loaded rows.
         this.scrollTop(this.scrollTop() - this.progressBarHeight());
      }
   };

   onSort = (column: RenderedColumnHeader<TRow>): void => {
      const sortOrder = unwrap(this.sortOrder);
      const direction =
         !sortOrder || sortOrder.columnKey != column.column.key
            ? Order.ASCENDING
            : sortOrder.direction == Order.ASCENDING
            ? Order.DESCENDING
            : Order.ASCENDING;
      this.sortOrder({ columnKey: column.column.key, direction });
   };

   onColumnGroupDragStart = (
      groupHeader: RenderedColumnGroupHeader<TRow>,
      event: JQuery.Event,
   ): boolean => {
      if (!groupHeader.columnGroup.isDraggable) return true;

      const originalEvent = (event as any).originalEvent as MouseEvent | TouchEvent;

      // Skip handling touch events that start on a button to allow the sort buttons to operate.
      if (
         originalEvent.type == "touchstart" &&
         originalEvent.target instanceof Element &&
         isContainedWithinType({ element: originalEvent.target, type: "BUTTON" })
      ) {
         return true;
      }

      const oldIndex = this.layout.renderedColumnGroups.indexOf(groupHeader);
      const bounds = this.layout
         .renderedColumnGroups()
         .map((group) => this.layout.getGroupHeaderOuterBounds(group)!);
      const groupBounds = bounds[oldIndex];
      const firstDraggableIndex = this.columnGroups().findIndex((group) => group.isDraggable) ?? 0;

      const findNewIndex = (update: DragUpdate) => {
         const centerLine = groupBounds.left + update.delta.left + groupBounds.width / 2;
         for (let i = firstDraggableIndex; i < bounds.length; i++) {
            const current = bounds[i];
            if (centerLine < current.left + current.width) {
               return i;
            }
         }
         return bounds.length;
      };

      const findReorderState = (update: DragUpdate): ReorderingState<TRow> => {
         const newIndex = findNewIndex(update);
         return {
            groupHeader,
            offsets: this.layout.renderedColumnGroups().map((group, index) => {
               if (group == groupHeader) return update.delta.left;
               if (newIndex == oldIndex || !group.columnGroup.isDraggable) return 0;
               return index < Math.min(oldIndex, newIndex) || index > Math.max(oldIndex, newIndex)
                  ? 0
                  : newIndex > oldIndex
                  ? -groupBounds.width
                  : groupBounds.width;
            }),
         };
      };

      createDragHandler({
         event: originalEvent,
         onChange: (update) => {
            this.reorderingState(findReorderState(update));
            this.scrollHorizontalIfNearEdge(update.position.left - this.scrollLeft());
         },
         onDone: (update) => {
            const newIndex = findNewIndex(update);
            if (newIndex != oldIndex) {
               const columnGroups = this.columnGroups().concat();
               const columnGroup = columnGroups[oldIndex];
               columnGroups.splice(oldIndex, 1);
               columnGroups.splice(Math.max(firstDraggableIndex, newIndex), 0, columnGroup);

               // Update the column groups and apply the change to the layout.
               this.isIgnoringColumnGroupChanges = true;
               this.columnGroups(columnGroups);
               this.isIgnoringColumnGroupChanges = false;
               this.layout.reorderColumn({ oldIndex, newIndex });
            }
            this.reorderingState(null);
         },
         onCancel: () => {
            this.resizingState(null);
         },
      });

      return false;
   };

   onColumnResizeStart = (header: RenderedColumnHeader<TRow>, event: JQuery.Event): void => {
      const headerBounds = this.layout.getHeaderOuterBounds(header);
      const headerGroup = this.layout
         .renderedColumnGroups()
         .find((g) => g.columns.includes(header));
      if (!headerBounds || !headerGroup) return;

      const calculateWidth = (update: DragUpdate) => {
         return Math.max(header.column.minWidth || 0, header.column.width + update.delta.left);
      };

      createDragHandler({
         event: (event as any).originalEvent as MouseEvent | TouchEvent,
         onChange: (update) => {
            const newWidth = calculateWidth(update);
            this.resizingState({
               header,
               groupHeader: headerGroup,
               delta: newWidth - header.column.width,
               offset: headerBounds.left + newWidth + header.columnPadding / 2 - 1,
            });
            this.scrollHorizontalIfNearEdge(update.position.left - this.scrollLeft());
         },
         onDone: (update) => {
            this.resizingState(null);

            // Skip updating if the width remained the same.
            const newWidth = calculateWidth(update);
            if (header.column.width == newWidth) return;

            // Set the column width and reset the array. Create the new columns for the updated column group.
            header.column.width = newWidth;

            // Update the column groups and apply the change to the layout.
            this.isIgnoringColumnGroupChanges = true;
            this.columnGroups(this.columnGroups().concat());
            this.isIgnoringColumnGroupChanges = false;
            this.layout.resizeColumn({ key: header.column.key, width: newWidth });
         },
         onCancel: () => {
            this.resizingState(null);
         },
      });

      // Setup the initial state.
      this.resizingState({
         header,
         groupHeader: headerGroup,
         delta: 0,
         offset: headerBounds.left + header.width + header.columnPadding / 2 - 1,
      });
      event.stopPropagation();
   };

   onCellClick = (cell: RenderedCell<TRow>, event: MouseEvent): void => {
      if (!this.editor) return;
      const cellSelection = this.editor.cellSelection();

      // When a user first clicks on a cell, the 'focus' event is triggered approximately 75 ms before
      // the click event. We have to track the timestamp of when the cell selection changed to determine
      // whether to treat the click event as a separate action from the 'focus' event.
      if (
         cellSelection.state == SelectionState.FOCUSED &&
         cell.index() == cellSelection.cellIndex &&
         cell.row.index == cellSelection.rowIndex
      ) {
         const actionResult = this.editor.triggerAction({
            rowIndex: cell.row.index,
            cellIndex: cell.index(),
            clickLocation: this.findClickLocation(cell, event),
            source: GridActionSource.UNFOCUSED_CLICK,
         });

         // If the action result is not 'REMAIN_FOCUSED' then the cell has accepted a input & performed an action.
         if (actionResult != GridActionResult.REMAIN_FOCUSED) return;

         if (Date.now() - this.editor.lastCellSelectionChange() >= DOUBLE_CLICK_TIMEOUT) {
            this.editor.triggerAction({
               rowIndex: cell.row.index,
               cellIndex: cell.index(),
               clickLocation: this.findClickLocation(cell, event),
               source: GridActionSource.FOCUSED_CLICK,
            });
         } else if (this.waitingForDoubleClickSelection != cellSelection) {
            this.waitingForDoubleClickSelection = cellSelection;
            setTimeout(() => {
               // Clear if this is still the active selection.
               if (this.waitingForDoubleClickSelection == cellSelection) {
                  this.waitingForDoubleClickSelection = null;
               }
            }, DOUBLE_CLICK_TIMEOUT);
         } else {
            // A second click has occurred within the timeout window.
            this.editor.triggerAction({
               rowIndex: cell.row.index,
               cellIndex: cell.index(),
               clickLocation: this.findClickLocation(cell, event),
               source: GridActionSource.DOUBLE_CLICK,
            });
            this.waitingForDoubleClickSelection = null;
         }
      }

      this.onGridAction({ type: GridActionType.CELL_CLICK, cell });
   };

   onCellContextMenuEvent = (cell: RenderedCell<TRow>, event: MouseEvent): void => {
      if (!this.editor) return;
      this.editor.showContextMenu({
         rowIndex: cell.row.index,
         cellIndex: cell.index(),
         clickLocation: this.findClickLocation(cell, event),
      });
   };

   onCellFocusIn = (cell: RenderedCell<TRow>, event: FocusEvent): void => {
      // Capture focus for elements within the cell and move it to the top-level.
      if (this.editor && event.target != event.currentTarget) {
         this.editor.focusCell({ rowIndex: cell.row.index, cellIndex: cell.index() });
      }
   };

   onContextMenuAction = async (action: GridContextMenuAction): Promise<void> => {
      if (!this.editor) return;

      const selection = this.editor.cellSelection();
      if (selection.state != SelectionState.CONTEXT_MENU) return;

      const cell = this.layout.getCell(selection.rowIndex, selection.cellIndex);
      if (!cell) return;

      switch (action) {
         case GridContextMenuAction.COPY:
            try {
               await navigator.clipboard.writeText(
                  cell.column.column.copyProvider!(cell.row.value),
               );
               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.INFO,
                     text: "Copied to clipboard.",
                     duration: 5,
                  }),
               );
               this.editor.focusCell(selection);
            } catch (error) {
               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "Failed to copy to clipboard.",
                     duration: 5,
                  }),
               );
            }
            break;
         case GridContextMenuAction.OPEN_IN_NEW_TAB:
            window.open(cell.column.column.urlProvider!(cell.row.value) ?? undefined, "_blank");
            this.editor.focusCell(selection);
            break;
         case GridContextMenuAction.EDIT:
            this.editor.showEditor(selection);
            break;
      }
   };

   onContentClickOff = (): void => {
      if (this.editor) {
         this.editor.clearSelection();
      }
   };

   onContextMenuTransitionEnd = (): void => {
      if (!this.editor) return;
      if (this.editor.cellSelection().state == SelectionState.CONTEXT_MENU) {
         this.isContextMenuFocused(true);
      }
   };

   onEditorTransitionEnd = (): void => {
      if (!this.editor) return;
      if (this.editor.cellSelection().state == SelectionState.EDITOR) {
         this.isEditorFocused(true);
      }
   };

   getSortArrowClass = (column: GridColumn<RowBase, unknown, unknown>): string => {
      const sortOrder = unwrap(this.sortOrder);
      if (!sortOrder || sortOrder.columnKey != column.key) {
         return "";
      }
      return sortOrder.direction == Order.ASCENDING
         ? "virtual-grid__header__sort--asc"
         : "virtual-grid__header__sort--desc";
   };

   getRowClasses = (renderedRow: RenderedRow<TRow>): string => {
      const classes = [unwrap(this.params.rowClass || null)];
      const isSelected = this.selectedIds.indexOf(renderedRow.value.id) != -1;

      // Add the selected classes.
      if (isSelected) {
         classes.push("virtual-grid__row--selected", unwrap(this.params.rowSelectedClass || null));
      }

      // Add the focused class (if defined).
      if (
         unwrap(this.params.rowFocusedClass || null) &&
         renderedRow.cells().some((cell) => cell.hasFocus())
      ) {
         classes.push(unwrap(this.params.rowFocusedClass!));
      }

      if (this.hasRowBorders) {
         classes.push("virtual-grid__row--has-border");

         // Remove the border of the last row when the content is scrollable
         // and the next set of rows is not being loaded.
         const rowsInDom = this.layout.rowsInDom();
         if (
            this.hasScrollBars &&
            this.layout.hasVerticalScroll() &&
            renderedRow.index == rowsInDom[rowsInDom.length - 1].index &&
            this.visibleLoadingState() != LoadingState.NEXT
         ) {
            classes.push("virtual-grid__row--has-border-transparent");
         }

         // Add a border above the row when...
         // 1. ...the row is selected.
         // 2. ...the row is the first and there more rows to load above
         const hasPreviousRowsToLoad =
            this.visibleLoadingState() == LoadingState.PREVIOUS ||
            (this.visibleLoadingState() == LoadingState.NONE && this.store().hasPreviousRows());
         if (this.selectedIds().includes(renderedRow.value.id)) {
            classes.push("virtual-grid__row--has-selected-top-border");
         } else if (renderedRow.index == 0 && hasPreviousRowsToLoad) {
            classes.push("virtual-grid__row--has-top-border");
         }
      }

      return classes.filter((c) => c != null).join(" ");
   };

   getResizeHandleOffset = (columnHeader: RenderedColumnHeader<TRow>): number => {
      const padding = columnHeader.columnPadding / 2;
      const resizingState = this.resizingState();
      return resizingState && resizingState.header == columnHeader
         ? resizingState.delta - padding
         : -padding;
   };

   getColumnGroupOffset = (columnGroup: RenderedColumnGroupHeader<TRow>): number => {
      const reorderingState = this.reorderingState();
      if (!reorderingState) return 0;
      const index = this.layout.renderedColumnGroups.indexOf(columnGroup);
      return index != -1 ? reorderingState.offsets[index] : 0;
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
      this.layout.dispose();
      this.editor?.dispose();
   };

   private onColumnGroupsChanged() {
      if (this.isIgnoringColumnGroupChanges) return;
      this.layout.setColumnGroups(this.columnGroups());
   }

   private onStoreChanged() {
      this.isEmpty(false);
      if (this.editor) this.editor.clearSelection();
      this.layout.clearRows();
      this.selectedIds([]);
      this.queuedChanges = [];
      this.storeSubscriptions.forEach((s) => s.dispose());
      this.storeSubscriptions = [
         this.store().rows.subscribe(this.onRowsChanged, this, "arrayChange"),
         this.store().loadingState.subscribe(this.onLoadingStateChanged, this),
      ];
      this.loadInitialRows();
   }

   private onFirstVisibleRowChanged(row: RenderedRow<TRow> | null) {
      if (row) {
         this.store().setFirstVisibleRow(row.value);
      }
      if (this.firstVisibleRowId && this.firstVisibleRowId() != row?.value.id) {
         this.firstVisibleRowId(row?.value.id ?? null);
      }
   }

   private onIsNearTopChanged(isNearTop: boolean) {
      if (
         isNearTop &&
         this.store().hasPreviousRows() &&
         this.visibleLoadingState() == LoadingState.NONE
      ) {
         this.store().loadPreviousRows();
      }
   }

   private onIsNearBottomChanged(isNearBottom: boolean) {
      if (
         isNearBottom &&
         this.store().hasNextRows() &&
         this.visibleLoadingState() == LoadingState.NONE
      ) {
         this.store().loadNextRows();
      }
   }

   private onLoadingStateChanged(loadingState: LoadingState) {
      // Capture the loading state when it transitions to a non-NONE state but ignore
      // when transitioning to NONE to give the progress bar time to finish.
      if (loadingState != LoadingState.NONE) {
         this.visibleLoadingState(loadingState);
         if (this.params.onContentChanged) this.params.onContentChanged();
      }
   }

   private onRowsChanged = (changes: utils.ArrayChanges<TRow>): void => {
      if (this.visibleLoadingState() != LoadingState.NONE) {
         // Cache the array changes until the loading indicator is hidden.
         this.queuedChanges.push(...changes);
      } else {
         this.applyRowChanges(changes);
      }
   };

   private onLayoutRowFocusChanged = (row: RenderedRow<TRow>, hasFocus: boolean): void => {
      this.onLayoutFocusChanged({
         rowIndex: row.index,
         cellIndex: 0,
         hasFocus,
      });
   };

   private onLayoutCellFocusChanged = (cell: RenderedCell<TRow>, hasFocus: boolean): void => {
      this.onLayoutFocusChanged({
         rowIndex: cell.row.index,
         cellIndex: cell.index(),
         hasFocus,
      });
   };

   private onLayoutFocusChanged({
      rowIndex,
      cellIndex,
      hasFocus,
   }: {
      rowIndex: number;
      cellIndex: number;
      hasFocus: boolean;
   }) {
      if (!this.editor) return;
      const selection = this.editor.cellSelection();
      const matchesSelection =
         selection.state != SelectionState.NONE &&
         selection.rowIndex == rowIndex &&
         selection.cellIndex == cellIndex;
      if (hasFocus && (!matchesSelection || selection.state != SelectionState.FOCUSED)) {
         this.editor.focusCell({ rowIndex: rowIndex, cellIndex: cellIndex });
      } else if (!hasFocus && matchesSelection && selection.state == SelectionState.FOCUSED) {
         this.editor.clearSelection();
      }
   }

   private onCellSelectionChanged(selection: CellSelection) {
      this.cellFocus(
         selection.state == SelectionState.NONE
            ? null
            : {
                 rowIndex: selection.rowIndex,
                 columnIndex: selection.cellIndex,
              },
      );
   }

   private onCellFocusChanged(cellFocus: GridCellFocus | null) {
      if (!this.editor) return;
      const selection = this.editor.cellSelection();
      if (!cellFocus) {
         // Clear focus if necessary.
         if (selection.state != SelectionState.NONE) {
            this.editor.clearSelection();
         }
      } else {
         // Update focus if necessary.
         if (
            selection.state == SelectionState.NONE ||
            selection.rowIndex != cellFocus.rowIndex ||
            selection.cellIndex != cellFocus.columnIndex
         ) {
            this.editor.focusCell({
               rowIndex: cellFocus.rowIndex,
               cellIndex: cellFocus.columnIndex,
            });
         }
      }
   }

   private onFirstVisibleRowIdChanged(id: string | null) {
      const row = this.layout.renderedRows().find((row) => row.value.id == id);
      const visibleRange = this.layout.visibleRange();

      if (id == null || this.layout.firstVisibleRow()?.value.id == id) {
         return;
      }

      // Clear the observable if the row isn't rendered.
      if (!row || !visibleRange) {
         this.firstVisibleRowId!(null);
         return;
      }

      this.layout.scrollToRow(row);
   }

   private applyRowChanges(changes: utils.ArrayChanges<TRow>) {
      // Capture the currently focused row ID to move the selection after the updates.
      const cellSelection = this.editor?.cellSelection() || null;
      const focusedIndex =
         cellSelection && cellSelection.state != SelectionState.NONE
            ? cellSelection.rowIndex
            : null;
      const selectedId =
         focusedIndex != null ? this.layout.renderedRows()?.[focusedIndex]?.value.id ?? null : null;

      // Apply each of the changes in as few operations as possible.
      compactArrayChanges(changes).forEach((change) => {
         if (change.status == "added") {
            this.layout.insertRows(change.index, change.values);
         } else if (change.status == "deleted") {
            this.layout.deleteRows(change.index, change.values.length);
         } else if (change.status == "updated") {
            this.layout.updateRows(change.index, change.values);
         }
      });
      this.isEmpty(this.layout.renderedRows().length == 0);

      // Update the selection based on where the row is now.
      if (selectedId) {
         const row = this.layout.renderedRows().find((row) => row.value.id == selectedId);
         if (row && row.index != focusedIndex) {
            this.editor!.moveCellSelection({ rowIndex: row.index });
         }
      }

      // Check if we need to load additional rows to fill the screen.
      if (!this.layout.hasVerticalScroll() && this.store().hasPreviousRows()) {
         this.store().loadPreviousRows();
      }
   }

   private scrollHorizontalIfNearEdge(viewportPositionLeft: number) {
      if (!this.layout.hasHorizontalScroll()) return;

      const findScrollAmount = (distanceFromEdge: number) => {
         const percentageWithinBuffer = 1 - distanceFromEdge / SCROLL_BUFFER;
         if (percentageWithinBuffer <= 0.35) return MAX_SCROLL_AMOUNT * 0.25;
         if (percentageWithinBuffer <= 0.5) return MAX_SCROLL_AMOUNT * 0.5;
         return MAX_SCROLL_AMOUNT;
      };

      if (viewportPositionLeft < SCROLL_BUFFER) {
         const scrollAmount = findScrollAmount(viewportPositionLeft);
         this.scrollLeft(Math.max(this.scrollLeft() - scrollAmount, 0));
      } else if (this.size().width - viewportPositionLeft < SCROLL_BUFFER) {
         const scrollAmount = findScrollAmount(this.size().width - viewportPositionLeft);
         this.scrollLeft(
            Math.min(this.scrollLeft() + scrollAmount, this.contentWidth() - this.size().width),
         );
      }
   }

   private findClickLocation(cell: RenderedCell<TRow>, event: MouseEvent): Point {
      const bounds = this.layout.getOuterCellBounds(cell);

      // Account for an element within the cell being the actual event target.
      const offset = findOffsetToOffsetAncestor(
         event.target as HTMLElement | SVGElement,
         event.currentTarget as HTMLElement,
      );
      return {
         top: bounds.top + event.offsetY + offset.top,
         left: bounds.left + event.offsetX + offset.left,
      };
   }

   private loadInitialRows() {
      const store = this.store();

      // Gracefully transition to the new grid store if the visible loading state
      // was INITIAL but now we have content in the grid store.
      if (this.visibleLoadingState() == LoadingState.INITIAL && store.rows().length) {
         // Cache the array changes until the loading indicator is hidden.
         this.queuedChanges.push(
            ...store.rows().map<utils.ArrayChange>((row, index) => ({
               status: "added",
               value: row,
               index,
            })),
         );
         return;
      }
      this.visibleLoadingState(store.loadingState());
      if (store.rows().length) {
         this.layout.insertRows(0, store.rows());
      } else {
         store.loadInitialRows();
         // Check if the store has already loaded. If the store's loading state did not change
         // and the store still does not have rows, then the store must be empty.
         if (
            store.loadingState() == LoadingState.NONE &&
            store.rows().length == 0 &&
            !store.hasNextRows() &&
            !store.hasPreviousRows()
         ) {
            this.isEmpty(true);
         }
      }
   }

   static factory<TRow extends RowBase>(
      params: VirtualGridParams<TRow>,
   ): ComponentArgs<VirtualGridParams<TRow>> {
      return {
         name: "virtual-grid",
         params,
      };
   }
}

ko.components.register("virtual-grid", {
   viewModel: VirtualGrid,
   template: template(),
   synchronous: true,
});
