import "./grouped-drop-down.styl"
import groupedDropDownTemplateFn from "./grouped-drop-down.pug"
import { regexSanitizedString } from "@/lib/utils/regex"
import { ValidationUtils } from "@/lib/utils/validation"

import ko from "knockout";

export class GroupedDropDown
   constructor: (params) ->
      assertArgs(arguments, Object)

      @isMulti = if params.multi? then params.multi else true

      if params.actionTitle instanceof Function
         @actionTitle = params.actionTitle
      else if typeof params.actionTitle == "string"
         @actionTitle = ko.observable(params.actionTitle)
      else
         @actionTitle = null

      if params.defaultTitle instanceof Function
         @defaultTitle = params.defaultTitle
      else if typeof params.defaultTitle == "string"
         @defaultTitle = ko.observable(params.defaultTitle)
      else
         @defaultTitle = ko.observable("Select an Option")

      if params.selectedValues instanceof Function
         @selectedValues = params.selectedValues
      else
         @selectedValues = ko.observableArray([])

      # When not multi
      if params.selectedValue instanceof Function
         @selectedValue = params.selectedValue
      else
         @selectedValue = ko.observable()

      if params.groupedValues instanceof Function
         @groupedValues = params.groupedValues
      else
         @groupedValues = ko.observable(params.groupedValues)

      @groupNames = ko.pureComputed =>
         return [] unless @groupedValues()?
         names = []
         for key from Object.keys(@groupedValues())
            names.push(key)
         names = names.sort()
         if ValidationUtils.validateInput(@searchQuery())
            return names.filter (i) => return i.toLowerCase().match(regexSanitizedString(@searchQuery().toLowerCase()))?
         return names

      @selectedGroup = ko.observable(null)

      @valueOptions = ko.pureComputed =>
         return [] unless @selectedGroup()?
         values = @groupedValues()[@selectedGroup()]
         return [] unless values?
         if ValidationUtils.validateInput(@searchQuery())
            return values.filter (i) => return i.name().toLowerCase().match(regexSanitizedString(@searchQuery().toLowerCase()))?
         return values

      if params.selectCallback
         assertOfType(params.selectCallback, Function)
         @selectCallback = params.selectCallback

      @searchQuery = ko.observable('')
      @searchPlaceholder = ko.observable(params.searchPlaceholder or 'Type search here')

      if params.searchable
         if params.searchable instanceof Function
            assertOfType(params.searchable(), Boolean)
            @searchable = params.searchable
         else
            @searchable = ko.observable(params.searchable)
      else
         @searchable = false

      if params.clearable
         if params.clearable instanceof Function
            assertOfType(params.clearable(), Boolean)
            @clearable = params.clearable
         else
            @clearable = ko.observable(params.clearable)
      else
         @clearable = null

      @isExpanded = ko.observable(false)
      @currentTitle = ko.pureComputed =>
         selectedCount = @selectedValues().length
         unless @isExpanded()
            return "#{selectedCount} Selected" unless selectedCount == 0
         return @defaultTitle()

      @selectAllEnabled = ko.observable(params.selectAllEnabled or false)
      @selectAllSelected = ko.observable(false)

      @searchQuery.subscribe =>
         @toggleSelectAll() if @selectAllSelected()

   toggleDropDown: =>
      @isExpanded(!@isExpanded())

   selectGroup: (group) =>
      @searchQuery(null)
      @selectedGroup(group)

   getGroupSelectedCount: (group) ->
      return '' unless @selectedValues().length > 0
      count = 0
      for value in @selectedValues()
         count++ if value.baggage().group == group
      return if count > 0 then "- (#{count})" else ''

   toggleSelectAll: ->
      if @selectAllSelected()
         for option in @selectedValues()
            option.selected(false)
            @selectCallback(option) if @selectCallback
         @selectAllSelected(false)
         @selectedValues([])
      else
         for option in @selectedValues()
            option.selected(false)
            @selectCallback(option) if @selectCallback
         @selectedValues([])
         for option in @valueOptions()
            option.selected(true)
            @selectCallback(option) if @selectCallback
         # Needs to be this way so you can unselect a item after select all without it
         # removing that item form a shared array between selectedValues and values.
         @selectedValues @valueOptions().map (item) =>
            if item.baggage()?
               item.baggage()['group'] = @selectedGroup()
            else
               item.baggage({group: @selectedGroup()})
            return item
         @selectAllSelected(true)

   # This is required as opposed to just passing the 'toggleDropDown' to the dropdownDismisser
   # custom binding because the 'if isExpanded' check needs to be preformed and we can't do
   # that inside a annoymous function in the event listener that will call this becuase it
   # prevents the removal of the event listener if the callback is anonymous.
   maybeDismissDropDown: =>
      if @isExpanded()
         @isExpanded(false)
         @selectedGroup(null)
         @searchQuery(null)

   valueSelected: (data) =>
      if @isMulti
         data.selected(!data.selected())
         if data.baggage()?
            data.baggage()['group'] = @selectedGroup()
         else
            data.baggage({group: @selectedGroup()})
         existingItem = null
         for option in @selectedValues()
            if option.value() == data.value()
               existingItem = option
               break
         if existingItem?
            @selectedValues.remove(existingItem)
         else
            @selectedValues.push(data)
      else
         @selectedValue(data)
         @isExpanded(false)

      @selectCallback(data) if @selectCallback

   clearSelection: =>
      return unless @clearable()
      for option in @selectedValues()
         option.selected(false)
      @selectAllSelected(false)
      @selectedValues([])

   backToGroupPane: =>
      @searchQuery(null)
      @selectedGroup(null)

groupedDropDownTemplate = groupedDropDownTemplateFn()

ko.components.register("grouped-drop-down",
   viewModel: GroupedDropDown,
   template: groupedDropDownTemplate
)
