import "./navigate-pane.styl"
import template from "./navigate-pane.pug"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Popups ###
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

export class NavigatePane extends PopupPane
   constructor: () ->
      super(template(), "")
      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)
      @canViewRequests = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
      @canViewAssignments = authManager.checkAuthAction(PermissionLevel.Action.VIEW_ASSIGNMENTS)
      @allowDataExporting = authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA)
      @canViewPeopeTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canAccessMapPage = authManager.checkAuthAction(PermissionLevel.Action.ACCESS_MAP_PAGE)
      @canAccessGanttPage = authManager.checkAuthAction(PermissionLevel.Action.ACCESS_GANTT_PAGE)
      @canAccessTotalsPage = authManager.checkAuthAction(PermissionLevel.Action.ACCESS_TOTALS_PAGE)
      @canViewAlerts = authManager.checkAuthAction(PermissionLevel.Action.VIEW_ALERTS)
      @canViewPeopleTimeoff = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF)
      @canViewPeopleActivity = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_ACTIVITY)
      @canViewProjectActivity = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ACTIVITY)

   navigate: (page) =>
      pageName = @getPageName(page)
      if (pageName == "mainRoute-messages" or pageName == "mainRoute-assignmentAlerts" or
      pageName == "mainRoute-settings")
         router.navigate(pageName, "/company/#{authManager.companyId()}/#{page}")
      else if (pageName == "mainRoute-home")
         router.navigate(pageName, "/#{page}")
      else
         router.navigate(pageName, "/groups/#{authManager.selectedGroupId()}/#{page}")
      @dismissHandler()

   getPageName: (name) ->
      # Requiring App creates circular dependency so I am hardcoding the route name
      switch name
         when "assignments" then "mainRoute-assignmentsPage"
         when "assignments-list" then "mainRoute-assignmentsList"
         when "projects" then "mainRoute-projectList"
         when "people" then "mainRoute-peopleList"
         when "requests" then "mainRoute-requestsList"
         when "time-off" then "mainRoute-timeOffList"
         when "map" then "mainRoute-map"
         when "messages" then "mainRoute-messages"
         when "reports" then "mainRoute-reports"
         when "assignment-alerts" then "mainRoute-assignmentAlerts"
         when "gantt" then "mainRoute-gantt"
         when "activity" then "mainRoute-activity"
         when "totals" then "mainRoute-totals"
         when "settings" then "mainRoute-settings"
         when "home" then "mainRoute-home"
