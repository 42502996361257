var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-sync"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"\u003E\u003Cpath d=\"M12 18a6 6 0 01-6-6c0-1 .25-1.97.7-2.8L5.24 7.74A7.93 7.93 0 004 12a8 8 0 008 8v3l4-4-4-4m0-11V1L8 5l4 4V6a6 6 0 016 6c0 1-.25 1.97-.7 2.8l1.46 1.46A7.93 7.93 0 0020 12a8 8 0 00-8-8z\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-warning-tri"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-warning-tri\" width=\"18px\" height=\"16px\" viewBox=\"0 0 18 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-warning-tri\"\u003E\u003Cpath id=\"icon-warning-tri__shape\" d=\"M8.10691329,0.513781465 C8.49734631,-0.171261157 9.50265278,-0.171260154 9.89307557,0.513782468 L17.8685369,14.5075429 C18.2496293,15.1761292 17.7566134,16 16.9753995,16 L1.02454846,16 C0.243344796,16 -0.249619175,15.1761292 0.131467315,14.5075429 L8.10691329,0.513781465 Z M7.97693263,3.95007325 L10.023046,3.95007325 L10.023046,10.9500733 L7.97693263,10.9500733 L7.97693263,3.95007325 Z M10.023046,11.9500733 L7.97691217,11.9500733 L7.97691217,13.9500733 L10.023046,13.9500733 L10.023046,11.9500733 Z\" fill=\"#7C8084\"\u003E\u003C\u002Fpath\u003E\u003Crect id=\"icon-warning-tri__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"18\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};








pug_mixins["icon-carrot"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-carrot\" width=\"14px\" height=\"10px\" viewbox=\"0 0 14 10\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-carrot\"\u003E\u003Cpolygon id=\"icon-carrot__shape\" fill=\"#7C8084\" transform=\"translate(7.000000, 5.000000) scale(1, -1) translate(-7.000000, -5.000000) \" points=\"7 0 14 10 0 10\"\u003E\u003C\u002Fpolygon\u003E\u003Crect id=\"icon-carrot__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"14\" height=\"10\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Celement-anchored-popup class=\"transition-opacity\" params=\"\n         isPopupVisible: isPopupVisible,\n         preferences: ['below-right']\" data-bind=\"\n         clickOffElement: onClickOff,\n         css: { hidden: ko.unwrap(batch.records).length === 0 }\"\u003E\u003Cbutton class=\"batch-actions__button\" data-bind=\"click: onButtonClick, disable: disabled\"\u003EBatch Actions\u003C\u002Fbutton\u003E\u003C!-- ko if: hasBatchPermissions --\u003E\u003Ctransitioning-content params=\"\n            activeIndex: transitionController.state,\n            transition: transitionController.direction\" data-bind=\"onEscape: onEscape\"\u003E\u003Cdiv class=\"batch-actions__message\" tabindex=\"0\"\u003E\u003Cdiv class=\"batch-actions__message__icon batch-actions__message__icon--waiting\"\u003E";
pug_mixins["icon-sync"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cspan\u003EWaiting for all records to be loaded...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"batch-actions__message\" tabindex=\"0\"\u003E\u003Cdiv class=\"batch-actions__message__icon batch-actions__message__icon--error\"\u003E";
pug_mixins["icon-warning-tri"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cspan data-bind=\"text: maxLimitExceededMessage\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"batch-actions__options\"\u003E\u003C!-- ko if: batch.edit.permission --\u003E\u003Cli data-bind=\"click: viewBatchEdit\"\u003E\u003Cspan\u003EBatch Edit\u003C\u002Fspan\u003E";
pug_mixins["icon-carrot"]();
pug_html = pug_html + "\u003C\u002Fli\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: canManageAlerts --\u003E\u003Cli data-bind=\"click: viewSendAlerts\"\u003E\u003Cspan\u003ESend Alerts\u003C\u002Fspan\u003E";
pug_mixins["icon-carrot"]();
pug_html = pug_html + "\u003C\u002Fli\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: batch.delete.permission --\u003E\u003Cli data-bind=\"click: viewBatchDelete\"\u003E\u003Cspan\u003EBatch Delete\u003C\u002Fspan\u003E";
pug_mixins["icon-carrot"]();
pug_html = pug_html + "\u003C\u002Fli\u003E\u003C!-- \u002Fko --\u003E\u003C\u002Ful\u003E\u003C!-- ko if: batch.edit.permission --\u003E\u003Caction-batch-edit params=\"\n         transitionController: transitionController,\n         isWaitingForRecords: batch.isWaitingForRecords,\n         conflictModalColumns: batch.conflictModalColumns,\n         records: batch.records,\n         editors: batch.edit.editors\"\u003E\u003C\u002Faction-batch-edit\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: canManageAlerts --\u003E\u003Caction-send-alert params=\"transitionController: transitionController, assignments: batch.records\"\u003E\u003C\u002Faction-send-alert\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: batch.delete.permission --\u003E\u003Caction-batch-delete params=\"\n         transitionController: transitionController,\n         isWaitingForRecords: batch.isWaitingForRecords,\n         conflictModalColumns: batch.conflictModalColumns,\n         records: batch.records,\n         modalNote: batch.delete.modalNote,\n         validator: batch.delete.validator,\n         action: batch.delete.action\"\u003E\u003C\u002Faction-batch-delete\u003E\u003C!-- \u002Fko --\u003E\u003C\u002Ftransitioning-content\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko ifnot: hasBatchPermissions --\u003E\u003Cp class=\"notice\"\u003EYou do not have the required permissions to take any batch actions.\u003C\u002Fp\u003E\u003C!-- \u002Fko --\u003E\u003C\u002Felement-anchored-popup\u003E";;return pug_html;};
module.exports = template;