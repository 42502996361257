import "./grid-context-menu.styl";
import template from "./grid-context-menu.pug";
import type { ObservableArray } from "knockout";
import ko from "knockout";

export enum GridContextMenuAction {
   COPY = "copy",
   OPEN_IN_NEW_TAB = "open_in_new_tab",
   EDIT = "edit",
}

export interface GridContextMenuParams {
   actions: ObservableArray<GridContextMenuAction>;
   onAction: (action: GridContextMenuAction) => void;
}

export class GridContextMenu {
   readonly actions: ObservableArray<GridContextMenuAction>;
   readonly onAction: (action: GridContextMenuAction) => void;

   constructor({ actions, onAction }: GridContextMenuParams) {
      this.actions = actions;
      this.onAction = onAction;
   }
}

ko.components.register("grid-context-menu", {
   viewModel: GridContextMenu,
   template: template(),
   synchronous: true,
});
