import "./anchored-popup.styl";
import template from "./anchored-popup.pug";
import type { MaybeObservable, Observable, Subscription, PureComputed } from "knockout";
import ko, { observable, pureComputed, unwrap, isSubscribable } from "knockout";
import type { Bounds, Point } from "@/lib/utils/geometry";
import { AnchoredPosition, findAnchoredPosition } from "@/lib/utils/geometry";
import { createValidValueComputed } from "@/lib/utils/knockout";
import { requestAnimationFrames } from "@/lib/utils/animation";

/** Amount of spacing between the anchor position and the content. */
const ANCHOR_SPACING = 10;

const EMPTY_POSITIONING = { top: "0", left: "0", width: "0", height: "0" };

export enum AnchoredPopupColor {
   WHITE = "white",
   LIGHT_GRAY = "light-gray",
   DARK_GRAY = "dark-gray",
}

export enum AnchoredPopupShadow {
   LIGHT = "light",
   DARK = "dark",
}

export type AnchoredPopupParams = {
   isVisible: Observable<boolean>;

   /** Viewport the popup is rendered within. Used to determine the orientation of the popup. */
   viewportBounds: MaybeObservable<Bounds>;

   /**
    * Bounds of the anchor within the viewport. Used to determine the orientation of the popup
    * and position the popup. Offset can be overriden using the `fixedOffset` parameter.
    */
   anchorBounds: MaybeObservable<Bounds | null>;

   /**
    * Offset to use when positioning the popup. Overrides the positioning of the bounds.
    * Useful when the popup is contained within another element.
    */
   fixedOffset: MaybeObservable<Point | null>;

   /**
    * Anchored position preferences. The first preference where the popup content is entirely
    * within the viewport will be used.
    */
   preferences?: MaybeObservable<AnchoredPosition[]>;

   /** Color to use for the popup. */
   color?: MaybeObservable<AnchoredPopupColor>;

   /** Shadow to use for the popup. */
   shadow?: MaybeObservable<AnchoredPopupShadow>;

   /** Callback invoked when the transition starts. */
   onTransitionStart?: () => void;

   /** Callback invoked when the transition ends. */
   onTransitionEnd?: () => void;

   /** Content nodes to use instead of the child nodes of the component. */
   contentNodes?: MaybeObservable<HTMLElement[]> | null;

   /** Context to use when binding the content nodes. */
   data?: MaybeObservable<unknown> | null;
};

export class AnchoredPopup {
   readonly onTransitionEnd: () => void;
   readonly contentNodes: MaybeObservable<HTMLElement[]> | null;
   readonly data: MaybeObservable<unknown> | null;

   readonly isActuallyVisible = observable(false);
   readonly positioning = observable(EMPTY_POSITIONING);
   readonly cssClasses = pureComputed(() => {
      const classes = [`anchored-popup__bounds--${this.position()}`];
      if (unwrap(this.color)) {
         classes.push(`anchored-popup__bounds--color-${unwrap(this.color)}`);
      }
      if (unwrap(this.shadow)) {
         classes.push(`anchored-popup__bounds--shadow-${unwrap(this.shadow)}`);
      }
      return classes.join(" ");
   });
   readonly size = observable({ height: 0, width: 0 });
   readonly updateSize = observable(0);

   private readonly subscriptions: Subscription[] = [];
   private readonly viewportBounds: MaybeObservable<Bounds>;
   private readonly isVisible: Observable<boolean>;
   private readonly anchorBounds: MaybeObservable<Bounds | null>;
   private readonly lastValidBounds: PureComputed<Bounds | null>;
   private readonly fixedOffset: MaybeObservable<Point | null>;
   private readonly preferences: MaybeObservable<AnchoredPosition[]>;
   private readonly color: MaybeObservable<AnchoredPopupColor>;
   private readonly shadow: MaybeObservable<AnchoredPopupShadow>;
   private readonly onTransitionStart: () => void;
   private readonly position = observable(AnchoredPosition.BELOW_CENTER);

   /** Whether the anchor is waiting for the size to be adjusted before repositioning. */
   private hasScheduledUpdate = false;

   constructor({
      isVisible,
      viewportBounds,
      anchorBounds,
      fixedOffset = null,
      preferences = [],
      color = AnchoredPopupColor.WHITE,
      shadow = AnchoredPopupShadow.DARK,
      onTransitionStart = () => {},
      onTransitionEnd = () => {},
      contentNodes = null,
      data = null,
   }: AnchoredPopupParams) {
      this.isVisible = isVisible;
      this.viewportBounds = viewportBounds;
      this.anchorBounds = anchorBounds;
      this.fixedOffset = fixedOffset;
      this.preferences = preferences;
      this.color = color;
      this.shadow = shadow;
      this.onTransitionStart = onTransitionStart;
      this.onTransitionEnd = onTransitionEnd;
      this.contentNodes = contentNodes;
      this.data = data;
      this.lastValidBounds = createValidValueComputed({ value: this.anchorBounds });
      this.subscriptions.push(this.isVisible.subscribe(this.scheduleUpdate, this));
      if (isSubscribable(this.anchorBounds)) {
         this.subscriptions.push(this.anchorBounds.subscribe(this.scheduleUpdate, this));
      }
      if (isSubscribable(this.viewportBounds)) {
         this.subscriptions.push(this.viewportBounds.subscribe(this.scheduleUpdate, this));
      }
   }

   onTransitionStartInternal = (isVisible: boolean): void => {
      if (isVisible) {
         this.updateSize(Date.now());
         this.updatePositioning();
      }
      this.onTransitionStart();
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
   };

   private scheduleUpdate() {
      if (this.hasScheduledUpdate) return;
      this.hasScheduledUpdate = true;
      requestAnimationFrames(2, () => {
         this.hasScheduledUpdate = false;
         this.updateSize(Date.now());
         this.updatePositioning();
         this.isActuallyVisible(Boolean(this.isVisible() && unwrap(this.anchorBounds)));
      });
   }

   private updatePositioning(): void {
      const anchorBounds = this.lastValidBounds();
      if (anchorBounds == null) {
         this.positioning(EMPTY_POSITIONING);
         return;
      }

      const result = findAnchoredPosition({
         viewport: unwrap(this.viewportBounds),
         anchor: anchorBounds,
         size: this.size(),
         paddingBetween: ANCHOR_SPACING,
         preferences: unwrap(this.preferences),
      });

      const fixedOffset = unwrap(this.fixedOffset);
      const top = fixedOffset != null ? fixedOffset?.top : anchorBounds.top;
      const left = fixedOffset != null ? fixedOffset?.left : anchorBounds.left;

      this.position(result.position);
      switch (result.position) {
         case AnchoredPosition.ABOVE_LEFT:
         case AnchoredPosition.ABOVE_CENTER:
         case AnchoredPosition.ABOVE_RIGHT:
            this.positioning({
               top: `${top}px`,
               left: `${left}px`,
               height: "0",
               width: `${anchorBounds.width}px`,
            });
            break;
         case AnchoredPosition.BELOW_LEFT:
         case AnchoredPosition.BELOW_CENTER:
         case AnchoredPosition.BELOW_RIGHT:
            this.positioning({
               top: `${top + anchorBounds.height}px`,
               left: `${left}px`,
               height: "0",
               width: `${anchorBounds.width}px`,
            });
            break;
         case AnchoredPosition.LEFT_TOP:
         case AnchoredPosition.LEFT_CENTER:
         case AnchoredPosition.LEFT_BOTTOM:
            this.positioning({
               top: `${top}px`,
               left: `${left}px`,
               height: `${anchorBounds.height}px`,
               width: "0",
            });
            break;
         case AnchoredPosition.RIGHT_TOP:
         case AnchoredPosition.RIGHT_CENTER:
         case AnchoredPosition.RIGHT_BOTTOM:
            this.positioning({
               top: `${top}px`,
               left: `${left + anchorBounds.width}px`,
               height: `${anchorBounds.height}px`,
               width: "0",
            });
            break;
      }
   }
}

ko.components.register("anchored-popup", {
   viewModel: AnchoredPopup,
   template: template(),
   synchronous: true,
});
