import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"

export class PopupDropDownPane extends PopupPane
   constructor: (items, options)->
      super("components/popup/popup-drop-down-pane")
      @defaultTitle = ko.observable(null)
      @defaultLeftActionBtn = ko.observable(false)
      @defaultRightActionBtn = ko.observable(false)
      @items = ko.observableArray(items)



