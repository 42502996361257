import "./drop-down-heading-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./drop-down-heading-cell.pug";
import ko from "knockout";

export interface DropDownHeadingCellParams {
   title: string;
}

export class DropDownHeadingCell {
   readonly title: string;
   constructor(params: DropDownHeadingCellParams) {
      this.title = params.title;
   }

   static factory<T>(
      formatter: (data: T) => string,
   ): GridCellFactory<T, DropDownHeadingCellParams> {
      return (data: T): GridCell<DropDownHeadingCellParams> => {
         return {
            component: {
               name: "drop-down-heading-cell",
               params: {
                  title: formatter(data),
               },
            },
            height: 20,
            isUnfocusable: true,
         };
      };
   }
}

ko.components.register("drop-down-heading-cell", {
   viewModel: DropDownHeadingCell,
   template: template(),
   synchronous: true,
});
