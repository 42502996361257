import "./configure-columns.styl";
import template from "./configure-columns.pug";
import type { MaybeObservable } from "knockout";
import ko, { observable, unwrap } from "knockout";
import type { PopupParams } from "@/bindings/popup";
import { Popup } from "@/lib/components/popup/popup";
import type { CustomColumnHeadersPane } from "@/lib/components/popup/custom-column-headers-pane";
import type { ColumnHeader } from "@/models/column-header";

export interface ConfigureColumnsParams {
   paneProvider: MaybeObservable<() => CustomColumnHeadersPane>;
   onUpdate: MaybeObservable<(columnHeaders: ColumnHeader[]) => void>;
   onReset: MaybeObservable<() => void>;
}

export class ConfigureColumns {
   readonly popupWrapper: PopupParams;
   readonly isPopupVisible = observable(false);

   constructor({ paneProvider, onUpdate, onReset }: ConfigureColumnsParams) {
      this.popupWrapper = {
         popupBuilder: () => {
            this.isPopupVisible(true);
            const pane = unwrap(paneProvider)();
            return new Popup(
               "Choose Columns",
               Popup.FrameType.BELOW,
               Popup.ArrowLocation.TOP_RIGHT,
               [pane],
               ["list-view__toolbar-btn", "icon-column-headers--gray"],
               ["configure-columns__popup"],
               (err, status) => {
                  this.isPopupVisible(false);
                  if (status == Popup.ExitStatus.AFFIRMATIVE_ACTION) {
                     unwrap(onUpdate)(pane.currentColumnHeaders());
                  } else if (status == Popup.ExitStatus.NEGATING_ACTION) {
                     unwrap(onReset)();
                  }
               },
            );
         },
         options: { triggerClasses: ["icon-column-headers--gray"] },
      };
   }
}

ko.components.register("configure-columns", {
   viewModel: ConfigureColumns,
   template: template(),
   synchronous: true,
});
