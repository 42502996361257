import "./send-alert.styl";
import template from "./send-alert.pug";
import type { ObservableArray, PureComputed, Subscription } from "knockout";
import ko, { isObservable, observable, pureComputed, unwrap } from "knockout";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { CannedMessage } from "@/models/canned-message";
import type { ProcessDefaultAlertForProjectConfig } from "@/stores/alert-store";
import { Alert } from "@/models/alert";
import type { GetAssignmentsAssignment } from "@/stores/assignment-2-store.core";
import {
   notificationManagerInstance,
   Notification,
   Icons,
} from "@/lib/managers/notification-manager";
import { AlertStore } from "@/stores/alert-store.core";
import type { AlertContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { CannedMessageType } from "@laborchart-modules/common/dist/rethink/schemas/enums/canned-messages";
import { authManager } from "@/lib/managers/auth-manager";

enum State {
   SELECT_OPTION = 0,
   SET_SCHEDULE = 1,
}

const BUTTON_TRANSITION_TIME = 300; // milliseconds.

abstract class AlertOption {
   constructor(readonly title: string) {}
   abstract isValid(): boolean;
   abstract getMessage(): ProcessDefaultAlertForProjectConfig;
   abstract getPopupParams(): { buttonText: string; titleText: string; message: string };
}

class SendImmediatelyAlertOption extends AlertOption {
   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Send Immediately");
   }

   getMessage() {
      return {
         context: Alert.Context.OPEN,
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams() {
      return {
         buttonText: "Send",
         titleText: "Send Alerts",
         message: `${this.numberOfRecords()} Alerts(s) will be sent to assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }
   isValid(): boolean {
      return true;
   }
}

class SaveDraftAlertOption extends AlertOption {
   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Save Draft");
   }
   getMessage() {
      return {
         context: Alert.Context.DRAFTS,
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams() {
      return {
         buttonText: "Save",
         titleText: "Save Draft Alerts",
         message: `${this.numberOfRecords()} Alert(s) will be saved as drafts for assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }
   isValid() {
      return true;
   }
}

class ScheduledAlertOption extends AlertOption {
   readonly date = ko.observable<Date | undefined>(new Date());
   readonly time = ko.observable<number | undefined>(undefined);

   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Schedule");
   }

   getMessage() {
      return {
         context: Alert.Context.SCHEDULED,
         detached_day: getDetachedDay(this.date()!),
         time: this.time(),
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams(): { buttonText: string; titleText: string; message: string } {
      return {
         buttonText: "Schedule",
         titleText: "Schedule Alerts",
         message: `${this.numberOfRecords()} Alert(s) will be scheduled to be sent to assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }
   isValid(): boolean {
      const dateVal: Date | undefined = this.date();
      const timeVal: number | undefined = this.time();
      if (!dateVal) return false;
      if (isNaN(Number(timeVal))) return false;
      return true;
   }
}

export type SendAlertParams = {
   assignments: ObservableArray<GetAssignmentsAssignment>;
};

export class SendAlert {
   readonly isButtonVisible = observable(false);
   readonly buttonOpacity = observable(1);
   readonly buttonDisplayValue = observable<"block" | "none">("block");
   readonly isPopupVisible = observable(false);
   readonly state = observable<State>(State.SELECT_OPTION);
   readonly transition = observable(Transition.FORWARD);
   readonly isOptionsFocused = observable(false);
   readonly alertStrategy = observable<AlertOption | null>(null);

   readonly isScheduledSelected = ko.pureComputed(() => {
      return this.alertStrategy() instanceof ScheduledAlertOption;
   });

   readonly numberOfRecords = ko.pureComputed(() => {
      return this.params.assignments().length;
   });

   readonly alertStrategies: ObservableArray<AlertOption>;

   readonly buttonText = pureComputed(() => {
      return `Send Alert (${this.numberOfRecords()}) …`;
   });

   readonly disableSaveBtn = pureComputed(() => {
      const strategy = this.alertStrategy();
      if (!strategy) return true;
      return !strategy.isValid();
   });

   readonly resizeContentTrigger = observable(0);

   private readonly subscriptions: Subscription[] = [];

   constructor(private readonly params: SendAlertParams) {
      this.buttonOpacity(unwrap(this.params.assignments).length > 0 ? 1 : 0);
      this.buttonDisplayValue(this.buttonOpacity() === 1 ? "block" : "none");
      this.isButtonVisible.subscribe((isVisible) => {
         if (isVisible) {
            this.buttonDisplayValue("block");
            // Schedule opacity to update in a separate pass after display is updated.
            setTimeout(() => this.buttonOpacity(1), 0);
         } else {
            this.buttonOpacity(0);
            setTimeout(() => {
               if (!this.isButtonVisible()) this.buttonDisplayValue("none");
            }, BUTTON_TRANSITION_TIME);
         }
      });
      if (isObservable(this.params.assignments)) {
         this.subscriptions.push(this.params.assignments.subscribe(this.onRecordsChanged, this));
      }
      this.alertStrategies = ko.observableArray<AlertOption>([
         new SendImmediatelyAlertOption(this.numberOfRecords),
         new ScheduledAlertOption(this.numberOfRecords),
         new SaveDraftAlertOption(this.numberOfRecords),
      ]);
   }

   onButtonClick = (): void => {
      if (this.isPopupVisible()) {
         this.isPopupVisible(false);
         return;
      }
      this.transition(Transition.NONE);
      this.state(State.SELECT_OPTION);
      this.isPopupVisible(true);
   };

   onListItemClick = (alertStrategy: AlertOption): void => {
      this.alertStrategy(alertStrategy);
      if (this.isScheduledSelected()) {
         this.transition(Transition.FORWARD);
         this.state(State.SET_SCHEDULE);
      } else {
         this.displayModal();
      }
   };

   onClickOff = (): void => {
      this.isPopupVisible(false);
   };

   onEscape = (): void => {
      if (this.state() == State.SET_SCHEDULE) {
         this.transition(Transition.BACKWARD);
         this.state(State.SELECT_OPTION);
      } else if (this.isPopupVisible()) {
         this.isPopupVisible(false);
      }
   };

   onPopupTransitionStart = (): void => {
      this.resizeContentTrigger(Date.now());
   };

   onPopupTransitionEnd = (): void => {
      if (this.isPopupVisible()) {
         this.isOptionsFocused(true);
      }
   };

   onViewVisible = (index: number): void => {
      if (index == State.SELECT_OPTION) {
         this.isOptionsFocused(true);
      }
   };

   private onRecordsChanged(records: Array<{ id: string }>) {
      if (records.length == 0) {
         this.isPopupVisible(false);
         this.isButtonVisible(false);
      } else {
         this.isButtonVisible(true);
      }
   }

   displayModal(): void {
      const alertStrategy: AlertOption | null = this.alertStrategy();
      if (!alertStrategy) return;
      //Close popup & defer the rest of the workflow to the modal.
      this.isPopupVisible(false);

      const { buttonText, titleText, message } = alertStrategy.getPopupParams();
      const confirmationMessagePane = new ConfirmActionPaneViewModel(
         buttonText,
         message,
         titleText,
      );
      const confirmationModal = new Modal();
      confirmationModal.setPanes([confirmationMessagePane]);
      modalManager.showModal(
         confirmationModal,
         null,
         { class: "send-alert__confirm-action-modal" },
         async (_, exitStatus) => {
            if (exitStatus === "cancelled") return;

            const failed: GetAssignmentsAssignment[] = [];
            await Promise.all(
               this.params.assignments().map(async (assignment) => {
                  const message = alertStrategy.getMessage();
                  const groupId =
                     authManager.selectedGroupId() === "my-groups"
                        ? undefined
                        : authManager.selectedGroupId();

                  const requestBody = {
                     batch_id: assignment.id,
                     canned_type: message.cannedType as CannedMessageType,
                     group_id: groupId,
                     context: message.context as unknown as AlertContext,
                     detached_day: message.detached_day,
                     time: message.time,
                  };
                  const payloadRequest = {
                     body: requestBody,
                  };

                  try {
                     return AlertStore.processDefaultAlertForProject(payloadRequest);
                  } catch (error) {
                     console.error(
                        `Error executing AlertStore.processDefaultAlertForProject for assignment with ID ${assignment.id}:`,
                        error,
                     );
                     failed.push(assignment);
                  }
               }),
            );

            if (failed.length) {
               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: `Failed to create ${failed.length} alert(s).`,
                  }),
               );
            }
         },
      );
   }

   dispose(): void {
      this.subscriptions.forEach((subscription) => {
         subscription.dispose();
      });
   }
}

ko.components.register("send-alert", {
   viewModel: SendAlert,
   template: template(),
});
