import "./timezone-selector.styl"
import timezoneSelectorTemplateFn from "./timezone-selector.pug"
import ko from "knockout"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { TimezonePane } from "@/lib/components/popup/timezone-pane"

export class TimezoneSelector
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.selectedTimezone, Function)

      @selectedTimezone = params.selectedTimezone
      @showingPopup = ko.observable(false)

      @zoneDisplayName = ko.pureComputed =>
         return "Select Timezone" unless @selectedTimezone()?
         return @selectedTimezone()

      @frameLocation = params.frameLocation or Popup.FrameType.BELOW
      @arrowLocation = params.arrowLocation or Popup.ArrowLocation.TOP_RIGHT

      @mapPopupBuilder = =>
         return new Popup("Select Timezone", @frameLocation, @arrowLocation,
            [new TimezonePane(@selectedTimezone)],
            ['timezone-selector__map-btn', 'icon-world'], ['timezone-selector__popup'])

      @mapPopupWrapper = {
         popupBuilder: @mapPopupBuilder
         options: {triggerClasses: ['icon-world']}
      }


timezoneSelectorTemplate = timezoneSelectorTemplateFn()

ko.components.register("timezone-selector",
   viewModel: TimezoneSelector,
   template: timezoneSelectorTemplate,
)