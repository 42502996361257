import "./list-view-export-modal-pane-simple.styl";
import template from "./list-view-export-modal-pane-simple.pug";
import { Icons, notificationManagerInstance } from "@/lib/managers/notification-manager";
import { Notification } from "@/lib/managers/notification-manager";
import { ModalPane } from "@/lib/components/modals/modal-pane";
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";
import {
   createOrientationOptions,
   createPdfSizeOptions,
   PageOrientation,
} from "@/lib/reports/report-options";
import { FileType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { Observable } from "knockout";
import { observable } from "knockout";
import { modalManager } from "@/lib/managers/modal-manager";

export type ReportConfig = {
   fileType: FileType;
   pageSize?: {
      width: number;
      height: number;
   };
   orientation?: PageOrientation;
};

export class ListViewExportModalPaneSimple extends ModalPane {
   static DEFAULT_ACTION_TEXT = "Export";
   private onExport: (t: ReportConfig) => Promise<void>;
   private readonly fileTypes: Array<SegmentedControllerItem<FileType>> = [
      new SegmentedControllerItem<FileType>("PDF", FileType.PDF),
      new SegmentedControllerItem<FileType>("CSV", FileType.CSV),
   ];
   private readonly selectedFileType: Observable<SegmentedControllerItem<FileType>> = observable(
      this.fileTypes[0],
   );
   private readonly orientationOptions = createOrientationOptions();
   private readonly selectedOrientationOption = observable(this.orientationOptions[0]);
   private readonly pdfSizeOptions = createPdfSizeOptions();
   private readonly selectedPageSize = observable(this.pdfSizeOptions[0]);

   constructor({
      title,
      onExport,
   }: {
      title: string;
      onExport: (config: ReportConfig) => Promise<void>;
   }) {
      super(title, ListViewExportModalPaneSimple.DEFAULT_ACTION_TEXT, template());
      this.onExport = onExport;
      this.overflowable(true);
   }

   async actionBtnIntercept(): Promise<void> {
      const orientation = this.selectedOrientationOption().value();
      const pageSize = this.selectedPageSize().value();
      const fileType = this.selectedFileType().value();

      try {
         this.isActionEnabled(false);
         this.setActionText("Exporting...");
         await this.onExport(
            fileType === FileType.CSV
               ? { fileType }
               : {
                    fileType,
                    pageSize: {
                       width:
                          orientation === PageOrientation.PORTRAIT
                             ? pageSize.width
                             : pageSize.height,
                       height:
                          orientation === PageOrientation.PORTRAIT
                             ? pageSize.height
                             : pageSize.width,
                    },
                    orientation,
                 },
         );
      } catch (e) {
         notificationManagerInstance.show(
            new Notification({
               icon: Icons.WARNING,
               text: "An unexpected error occurred while downloading.",
            }),
         );
      } finally {
         modalManager.modalFinished();
      }
   }
}
