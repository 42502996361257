import "./batch-edit.styl";
import template from "./batch-edit.pug";
import type {
   MaybeObservable,
   MaybeObservableArray,
   PureComputed,
   Subscribable,
   Subscription,
} from "knockout";
import ko, { isObservable, observable, observableArray, pureComputed, unwrap } from "knockout";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import type {
   EditorComponentFactory,
   EditorComponentParams,
} from "@/lib/components/editors/common/editor-component";
import { SaveCancelledError } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "../common";
import type { RowBase } from "../grid/grid-store";
import { BatchEditConflictModalPane } from "./batch-edit-conflict-modal-pane";
import type { GridColumnGroup } from "../grid/grid-column-group";
import { createValidTransformedValueComputed } from "@/lib/utils/knockout";
import { textSearch } from "@/lib/utils/text-search";
import type { Conflict } from "../batch-actions/batch-actions";
import { BATCH_ACTION_MAX_RECORD_COUNT } from "../batch-actions/batch-actions";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";

export type BatchEditor<T, V = any> = {
   factory: EditorComponentFactory<T, V>;

   /**
    * Validator used to verify each record can be saved with the given value.
    * If a string is returned, the record will not be saved and the conflict
    * modal will be shown instead.
    */
   validator?: ((record: T, value: V) => string | null) | null;

   /**
    * Whether the editor component internally manages its loading and saving state.
    * When true, the save provider will not be intercepted and the editor component
    * is expected to manage the saving state.
    * Defaults to false.
    */
   hasInternalSaveManagement?: boolean;
};

export type BatchEditParams<T extends RowBase> = {
   /**
    * The column groups used in the conflict modal to identify the records whose
    * updates are invalid.
    */
   conflictModalColumnGroups:
      | MaybeObservable<Array<GridColumnGroup<T>>>
      | PureComputed<Array<GridColumnGroup<T>>>;

   /**
    * The list of all available editors for batch edits.
    */
   editors: MaybeObservableArray<BatchEditor<T>> | PureComputed<Array<BatchEditor<T>>>;

   /**
    * The records to be batch edited.
    */
   records: MaybeObservable<T[]> | PureComputed<T[]>;

   /**
    * Observable for when the batch edit should be disabled while records are being
    * loaded.
    */
   isWaitingForRecords?: Subscribable<boolean>;
};

const BUTTON_TRANSITION_TIME = 300; // milliseconds.

const enum State {
   SELECT_FIELD = 0,
   EDITING = 1,
   SAVING = 2,
   LOADING_RECORDS = 3,
   TOO_MANY_RECORDS = 4,
}

/** The maximum number of records that can be updated at once via batch update. */
const MAX_RECORD_COUNT = BATCH_ACTION_MAX_RECORD_COUNT;

export class BatchEdit<T extends RowBase> {
   readonly isWaitingForRecords: Subscribable<boolean>;
   readonly isButtonVisible = observable(false);
   readonly buttonOpacity = observable(1);
   readonly buttonDisplayValue = observable<"block" | "none">("block");
   readonly isPopupVisible = observable(false);
   readonly search = observable<string | null>(null);
   readonly state = observable<State>(State.SELECT_FIELD);
   readonly editorComponents = observableArray<ComponentArgs<EditorComponentParams<unknown>>>();
   readonly activeEditorComponent = observable<ComponentArgs<EditorComponentParams<unknown>>>(null);
   readonly transition = observable(Transition.FORWARD);
   readonly isPopupFocused = observable(false);
   readonly isEditorFocused = observable(false);
   readonly numberOfRecords = createValidTransformedValueComputed({
      value: this.params.records,
      transform: (value) => value.length,
   });
   readonly exceedsMaxBatchLimit = pureComputed(() => {
      return this.numberOfRecords() > MAX_RECORD_COUNT;
   });
   readonly buttonText = pureComputed(() => {
      return `Batch Edit (${this.numberOfRecords()}) …`;
   });
   readonly visibleEditorComponents = pureComputed(() => {
      const search = this.search();
      if (!search) return this.editorComponents();
      return textSearch({
         items: this.editorComponents(),
         textProvider: (item) => unwrap(item.params.title) || "",
         search: this.search(),
      });
   });
   readonly isSearchFocused = observable(false);
   readonly resizeContentTrigger = observable(0);
   readonly isSaving = observable(false);

   private readonly subscriptions: Subscription[] = [];
   private isShowingConflict = false;

   constructor(private readonly params: BatchEditParams<T>) {
      this.buttonOpacity(unwrap(this.params.records).length > 0 ? 1 : 0);
      this.buttonDisplayValue(this.buttonOpacity() === 1 ? "block" : "none");
      this.isButtonVisible.subscribe((isVisible) => {
         if (isVisible) {
            this.buttonDisplayValue("block");
            // Schedule opacity to update in a separate pass after display is updated.
            setTimeout(() => this.buttonOpacity(1), 0);
         } else {
            this.buttonOpacity(0);
            setTimeout(() => {
               if (!this.isButtonVisible()) this.buttonDisplayValue("none");
            }, BUTTON_TRANSITION_TIME);
         }
      });
      if (isObservable(this.params.records)) {
         this.subscriptions.push(
            this.params.records.subscribe(this.onRecordsOrEditorsChangedChanged, this),
         );
      }
      if (isObservable(this.params.editors)) {
         this.subscriptions.push(
            this.params.editors.subscribe(this.onRecordsOrEditorsChangedChanged, this),
         );
      }
      this.isWaitingForRecords = params.isWaitingForRecords
         ? params.isWaitingForRecords
         : observable(false);
      this.subscriptions.push(
         this.isWaitingForRecords.subscribe(this.onIsWaitingForRecordsChanged, this),
         this.isSaving.subscribe(this.onIsSaving, this),
      );
   }

   onButtonClick = (): void => {
      if (this.isPopupVisible()) {
         this.isPopupVisible(false);
         return;
      }
      this.transition(Transition.NONE);
      this.setInitialState();
      this.editorComponents(this.createEditorComponents());
      this.isPopupVisible(true);
   };

   onListItemClick = (editorComponent: ComponentArgs<EditorComponentParams<unknown>>): void => {
      this.transition(Transition.FORWARD);
      this.activeEditorComponent(editorComponent);
      this.state(State.EDITING);
   };

   onClickOff = (): void => {
      // Ignore click offs when the conflict modal is visible since it's not in the
      // batch edit component hierarchy.
      if (!this.isShowingConflict) {
         this.isPopupVisible(false);
      }
   };

   onEscape = (): void => {
      // Ignore the escape key when showing the conflict modal to avoid dismissing unexpectedly.
      if (this.isShowingConflict) return;
      if (this.state() == State.EDITING) {
         this.state(State.SELECT_FIELD);
      } else if (this.isPopupVisible()) {
         this.isPopupVisible(false);
      }
   };

   onPopupTransitionStart = (): void => {
      this.resizeContentTrigger(Date.now());
   };

   onPopupTransitionEnd = (): void => {
      if (this.isPopupVisible()) {
         this.focusListView();
      } else {
         this.state(State.SELECT_FIELD);
         this.activeEditorComponent(null);
      }
   };

   onViewVisible = (index: number): void => {
      if (index == State.SELECT_FIELD) {
         this.focusListView();
         this.activeEditorComponent(null);
      } else {
         this.isEditorFocused(true);
      }
   };

   onViewHidden = (index: number): void => {
      if (index == State.EDITING) {
         // Recreate the editor components after the component is dismissed to reset the values.
         this.editorComponents(this.createEditorComponents());
      }
   };

   private onRecordsOrEditorsChangedChanged() {
      if (unwrap(this.params.records).length == 0 || unwrap(this.params.editors).length == 0) {
         this.isPopupVisible(false);
         this.isButtonVisible(false);
         return;
      }
      this.isButtonVisible(true);
      if (this.numberOfRecords() > MAX_RECORD_COUNT) {
         this.transition(Transition.FADE);
         this.state(State.TOO_MANY_RECORDS);
      }
   }

   private onIsWaitingForRecordsChanged() {
      this.transition(Transition.FADE);
      this.setInitialState();
   }

   private onIsSaving() {
      if (this.isPopupVisible()) {
         this.transition(Transition.FADE);
         this.setInitialState();
      }
   }

   private focusListView() {
      if (this.state() == State.SELECT_FIELD) {
         this.isSearchFocused(true);
      } else {
         this.isPopupFocused(true);
      }
   }

   private setInitialState() {
      if (this.exceedsMaxBatchLimit()) {
         this.state(State.TOO_MANY_RECORDS);
      } else if (this.isSaving()) {
         this.state(State.SAVING);
      } else if (this.isWaitingForRecords()) {
         this.state(State.LOADING_RECORDS);
      } else {
         this.state(State.SELECT_FIELD);
      }
   }

   private async saveRecordsOrShowConflicts(
      editor: BatchEditor<T>,
      saveProvider: (value: unknown) => Promise<void>,
      value: unknown,
   ): Promise<void> {
      if (editor.hasInternalSaveManagement) {
         return saveProvider(value);
      }
      if (editor.validator) {
         const conflicts: Array<Conflict<T>> = [];
         for (const record of unwrap(this.params.records)) {
            const reason = editor.validator(record, value);
            if (reason) {
               conflicts.push({ record, reason });
            }
         }
         if (conflicts.length) {
            this.showConflictModal(editor, conflicts, value);
            throw new SaveCancelledError();
         }
      }
      this.isPopupVisible(false);
      this.trackSave(saveProvider(value));
   }

   private async showConflictModal(
      editor: BatchEditor<T>,
      conflicts: Array<Conflict<T>>,
      value: unknown,
   ) {
      if (editor == null) return;
      const records = unwrap(this.params.records);
      const validRecords = records.filter(
         (record) => !conflicts.some((c) => c.record.id == record.id),
      );
      this.isShowingConflict = true;
      try {
         const status = await BatchEditConflictModalPane.show<T>({
            records,
            columnGroups: unwrap(this.params.conflictModalColumnGroups),
            conflicts,
            saveProvider: async () => {
               this.isPopupVisible(false);
               this.trackSave(editor.factory(validRecords)!.params.saveProvider(value));
            },
         });
         if (status == "finished") {
            this.isPopupVisible(false);
         } else {
            this.isEditorFocused(true);
         }
      } finally {
         this.isShowingConflict = false;
      }
   }

   private createEditorComponents() {
      const records = unwrap(this.params.records);
      if (records.length == 0) return [];
      return unwrap(this.params.editors)
         .flatMap((batchEditor) => {
            const editor = batchEditor.factory(records);
            if (editor == null) return [];
            const saveProvider = editor.params.saveProvider;
            editor.params.saveProvider = (value) =>
               this.saveRecordsOrShowConflicts(batchEditor, saveProvider, value);
            editor.params.onClose = (context) => {
               if (!context.hasSaved) {
                  this.transition(Transition.BACKWARD);
                  this.state(State.SELECT_FIELD);
               }
            };
            return [editor];
         })
         .sort((a, b) => {
            const titleA = unwrap(a.params.title) || "";
            const titleB = unwrap(b.params.title) || "";
            return titleA.localeCompare(titleB);
         });
   }

   private async trackSave(promise: Promise<any>) {
      try {
         this.isSaving(true);
         await promise;
      } catch (error) {
         // NOTE: This should never be reached.
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "batch-edit_track-save";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });
      } finally {
         this.isSaving(false);
      }
   }
}

ko.components.register("batch-edit", {
   viewModel: BatchEdit,
   template: template(),
});
