import "./search-bar.styl";
import searchBarTemplateFn from "./search-bar.pug";
import type { Observable } from "knockout";
import { components, observable } from "knockout";
import { Popup } from "@/lib/components/popup/popup";
import { ListViewSearchInfoPane } from "@/lib/components/popup/list-view-search-info-pane";

export interface SearchBarParams {
   query?: Observable<string | null>;
   callback?: (searchQuery: Observable<string | null>) => void;
   placeholder?: string;
   searchableFields?: string[];
   showInfoPopup?: boolean;
}

export class SearchBar {
   private readonly searchQuery: Observable<string | null>;
   private readonly callback: (searchQuery: Observable<string | null>) => void;
   private readonly placeholder: string;
   private readonly searchableFields: string[];
   private readonly showInfoPopup: boolean;

   constructor(params: SearchBarParams) {
      this.callback = params.callback ?? (() => {});
      this.searchQuery = params.query ?? observable(null);
      this.placeholder = params.placeholder ?? "Search";
      this.searchableFields = params.searchableFields ?? [];
      this.showInfoPopup = params.showInfoPopup ?? false;
   }

   private readonly searchInformationPopupBuilder = () => {
      return new Popup(
         "",
         Popup.FrameType.BELOW,
         Popup.ArrowLocation.TOP_LEFT,
         [new ListViewSearchInfoPane(this.searchableFields)],
         ["search-bar__info-popup", "icon-information--gray"],
         ["search-bar__popup--info"],
         () => {
            return;
         },
      );
   };

   private readonly searchInformationPopupWrapper = observable({
      popupBuilder: this.searchInformationPopupBuilder,
      options: { triggerClasses: ["icon-information--gray"] },
   });

   private readonly fireCallback = () => {
      this.callback(this.searchQuery);
   };

   private readonly clearInput = () => {
      // istanbul ignore next
      this.searchQuery("");
      this.fireCallback();
   };
}

components.register("search-bar", {
   viewModel: SearchBar,
   template: searchBarTemplateFn(),
});
