import "./date-input.styl"
import dateInputTemplateFn from "./date-input.pug"
import { defaultStore } from "@/stores/default-store"
import { DefaultStore } from "@/stores/default-store"
import ko from 'knockout'

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { CalendarPane } from "@/lib/components/popup/calendar-pane"
 
export class DateInput
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.selectedDate, Function)
      assertOfType(params.hidePopup, optional([Function, Boolean]))

      @selectedDate = params.selectedDate
      @selectedDate.subscribe(@handleDateChange)
      @ignoreSelectedDateChanged = false

      @disableClear = if params.disableClear? then params.disableClear else false

      @dateComponents = ko.observable(@createDateComponents(@selectedDate()))
      @dateComponents.subscribe(@onDateComponentsChanged)
      @monthValue = ko.pureComputed({
         read: () => @dateComponents().month
         write: (month) => @dateComponents({
            ...@dateComponents(),
            month,
         })
      })
      @dateValue = ko.pureComputed({
         read: () => @dateComponents().date
         write: (date) => @dateComponents({
            ...@dateComponents(),
            date,
         })
      })
      @yearValue = ko.pureComputed({
         read: () => @dateComponents().year
         write: (year) => @dateComponents({
            ...@dateComponents(),
            year,
         })
      })

      @dateSeparator = defaultStore.selectedDateFormatSeparator
      @dateFormatStrategies = @buildDateFormatStrategies(@dateValue, @monthValue, @yearValue)
      @inputRenderables = ko.pureComputed =>
         orderedRenderables = @dateFormatStrategies.get(defaultStore.getDateFormat())
         return orderedRenderables()

      # Alert
      @showAlert = ko.observable(false)

      if params.hidePopup?
         if params.hidePopup instanceof Function
            @hidePopup = params.hidePopup
         else
            @hidePopup = ko.observable(params.hidePopup)
      else
         @hidePopup = ko.observable(false)

      @calendarFrameLocation = if params.calendarFrameLocation? then params.calendarFrameLocation else Popup.FrameType.BELOW
      @calendarArrowLocation = if params.calendarArrowLocation? then params.calendarArrowLocation else Popup.ArrowLocation.TOP_RIGHT

      @calendarPopupBuilder = =>
         return new Popup("Select Date", @calendarFrameLocation, @calendarArrowLocation,
            [new CalendarPane(@selectedDate)], ['date-input__btn', 'icon-calendar'], ['date-input__calendar-popup'])

      @calendarPopupWrapper = {
         popupBuilder: @calendarPopupBuilder
         options: {triggerClasses: ['icon-calendar']}
      }

   buildDateFormatStrategies: (dateObservable, monthObservable, yearObservable) ->
      dayRenderable = {
         placeholder: 'dd',
         value: dateObservable,
         css: 'date-input__day'
      }

      monthRenderable = {
         placeholder: 'mm',
         value: monthObservable,
         css: 'date-input__month'
      }

      yearRenderable = {
         placeholder: 'yyyy',
         value: yearObservable
         css: 'date-input__year'
      }

      strategies = new Map()

      strategies.set(DefaultStore.DateFormat.DD_MM_YYYY, -> [ dayRenderable, monthRenderable, yearRenderable])
      strategies.set(DefaultStore.DateFormat.MM_DD_YYYY, -> [ monthRenderable, dayRenderable, yearRenderable])
      strategies.set(DefaultStore.DateFormat.YYYY_MM_DD, ->  [ yearRenderable, monthRenderable, dayRenderable ])
      strategies.set(DefaultStore.DateFormat.YYYY_DD_MM, -> [ yearRenderable, dayRenderable, monthRenderable])

      # TODO: Change keys to reflect order rather than shape,
      # These are repeated for dashes (and in the future for other separators)
      # and could be simplified because we can infer the separator elsewhere
      strategies.set(DefaultStore.DateFormat.DD_MM_YYYY_DASHED, -> [ dayRenderable, monthRenderable, yearRenderable])
      strategies.set(DefaultStore.DateFormat.MM_DD_YYYY_DASHED, -> [ monthRenderable, dayRenderable, yearRenderable])
      strategies.set(DefaultStore.DateFormat.YYYY_MM_DD_DASHED, -> [ yearRenderable, monthRenderable, dayRenderable])

      return strategies

   handleDateChange: (newDate) =>
      return if @ignoreSelectedDateChanged
      @dateComponents(@createDateComponents(newDate))
      @showAlert(false)

   onDateComponentsChanged: (dateComponents) => 
      year = Number(dateComponents.year)
      isValidYear = !isNaN(year) && year >= 1000 && year <= 2100

      month = Number(dateComponents.month)
      isValidMonth = !isNaN(month) && month >= 1 && month <= 12

      date = Number(dateComponents.date)
      isValidDate = !isNaN(month) && date >= 1 && date <= 31

      hours = Number(dateComponents.hours)
      isValidHours = !isNaN(hours) && hours >= 0 && hours <= 23

      minutes = Number(dateComponents.minutes)
      isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes <= 59

      seconds = Number(dateComponents.seconds)
      isValidSeconds = !isNaN(seconds) && seconds >= 0 && seconds <= 59

      milliseconds = Number(dateComponents.milliseconds)
      isValidMilliseconds = !isNaN(milliseconds) && milliseconds >= 0 && milliseconds <= 999

      if !isValidYear or !isValidMonth or !isValidDate or !isValidHours or !isValidMinutes or !isValidSeconds or !isValidMilliseconds
         # Check if the date can be autofilled based on a single input.
         now = new Date()
         if isValidYear && dateComponents.date == "" && dateComponents.month == ""
            return @dateComponents({
               ...dateComponents,
               date: if year == now.getFullYear() then now.getDate().toString() else "1",
               month: if year == now.getFullYear() then (now.getMonth() + 1).toString() else "1",
            })
         if isValidMonth && dateComponents.date == "" && dateComponents.year == ""
            return @dateComponents({
               ...dateComponents,
               date: now.getDate().toString(),
               year: now.getFullYear().toString(),
            })
         if isValidDate && dateComponents.month == "" && dateComponents.year == ""
            return @dateComponents({
               ...dateComponents,
               month: (now.getMonth() + 1).toString(),
               year: now.getFullYear().toString(),
            })

         # The date is invalid. Show the alert.
         @ignoreSelectedDateChanged = true
         @selectedDate(null)
         @showAlert(true)
         @ignoreSelectedDateChanged = false
         return
      
      newDate = new Date(year, month - 1, date, hours, minutes, seconds, milliseconds)
      return if (newDate.getTime() == @selectedDate()?.getTime())

      @ignoreSelectedDateChanged = true
      @selectedDate(newDate)
      @showAlert(false)
      @ignoreSelectedDateChanged = false

      # Update components if the date shifted after being created from mismatch between
      # date and the number of days in the month.
      if newDate.getFullYear() != year || newDate.getMonth() != month - 1 || newDate.getDate() != date
         @dateComponents({
            date: newDate.getDate().toString(),
            month: (newDate.getMonth() + 1).toString(),
            year: newDate.getFullYear().toString()
         })

   clearDate: ->
      @dateComponents({
         date: "",
         month: "",
         year: ""
      })

   createDateComponents: (date) => 
      return {
         year: if date then date.getFullYear().toString() else "",
         month: if date then (date.getMonth() + 1).toString() else "",
         date: if date then date.getDate().toString() else "",
         hours: if date then date.getHours().toString() else "",
         minutes: if date then date.getMinutes().toString() else "",
         seconds: if date then date.getSeconds().toString() else "",
         milliseconds: if date then date.getMilliseconds().toString() else "",
      }


dateInputTemplate = dateInputTemplateFn()

ko.components.register("date-input",
   viewModel: DateInput,
   template: dateInputTemplate
)