import "./progress-bar-2.styl";
import type { MaybeObservable, Observable, Subscription } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./progress-bar-2.pug";
import { requestAnimationFrames } from "@/lib/utils/animation";

const DEFAULT_HEIGHT = 32;

export interface ProgressBar2Params {
   isVisible: Observable<boolean>;
   percentage?: Observable<number>;
   onFinished?: () => void;
   height?: MaybeObservable<number>;
}

export class ProgressBar2 {
   readonly isVisible: Observable<boolean>;
   readonly percentage: Observable<number>;
   readonly isActuallyVisible = observable(false);
   readonly isFinishing = observable(false);

   readonly style = ko.pureComputed(() => {
      return { height: `${unwrap(this.height)}px` };
   });

   private readonly onFinished: (() => void) | null;
   private readonly hasRealPercentage: boolean;
   private readonly subscriptions: Subscription[] = [];

   private height: MaybeObservable<number>;
   private hasFinishedAnimating = false;
   private hasStartedFinalAnimation = false;

   constructor(params: ProgressBar2Params) {
      this.isVisible = params.isVisible;
      this.isActuallyVisible(this.isVisible());
      this.percentage = params.percentage || observable(0);
      this.onFinished = params.onFinished || null;
      this.height = params.height || DEFAULT_HEIGHT;
      this.hasRealPercentage = params.percentage != null;
      this.subscriptions.push(this.isVisible.subscribe(this.onIsVisibleChanged));
      if (this.isVisible() && !this.hasRealPercentage) {
         this.simulateProgress();
      }
   }

   onIsVisibleChanged = (value: boolean): void => {
      if (value) {
         if (!this.hasRealPercentage) {
            this.percentage(0);
            this.simulateProgress();
         }
         this.isFinishing(false);
         this.hasFinishedAnimating = false;
         this.isActuallyVisible(true);
         return;
      }

      if (this.hasFinishedAnimating) {
         // The bar has already been animated to 100%. Hide immediately.
         this.hide();
      } else {
         // Wait for the bar to finish animating to 100%.
         this.isFinishing(true);
         this.hasStartedFinalAnimation = false;
         this.percentage(100);
         requestAnimationFrames(2, () => {
            // Check if the final animation actually started. If not, the
            // progress bar may be hidden such that the animation would never
            // start.
            if (!this.hasStartedFinalAnimation) {
               this.hide();
            }
         });
      }
   };

   onTransitionStart = (): void => {
      if (this.isFinishing()) this.hasStartedFinalAnimation = true;
   };

   onTransitionEnd = (): void => {
      if (this.isFinishing()) {
         // The bar has been animated to 100% and should now be hidden.
         this.hide();
      } else if (this.percentage() >= 100) {
         // The bar has been animated to 100% but not yet hidden.
         this.hasFinishedAnimating = true;
      }
   };

   dispose = (): void => {
      this.subscriptions.forEach((s) => s.dispose());
   };

   private hide() {
      if (!this.isActuallyVisible()) return;
      this.isActuallyVisible(false);
      if (this.onFinished) {
         this.onFinished();
      }
      this.isFinishing(false);
      this.hasFinishedAnimating = false;
      this.hasStartedFinalAnimation = false;
   }

   private simulateProgress = () => {
      const percentage = this.percentage();
      if (percentage >= 100 || !this.isVisible()) {
         return;
      }
      if (percentage < 35) {
         this.percentage(percentage + 5);
         return window.setTimeout(this.simulateProgress, 500);
      } else if (percentage < 50) {
         this.percentage(percentage + 5);
         return window.setTimeout(this.simulateProgress, 1000);
      } else if (percentage < 75) {
         this.percentage(percentage + 5);
         return window.setTimeout(this.simulateProgress, 500);
      } else if (percentage < 95) {
         this.percentage(percentage + 5);
         return window.setTimeout(this.simulateProgress, 1800);
      } else {
         // Over 16s load, just wait for completion.
         return;
      }
   };
}

ko.components.register("progress-bar-2", {
   viewModel: ProgressBar2,
   template: template(),
   synchronous: true,
});
