import "./checkbox.styl"
import checkboxTemplateFn from "./checkbox.pug"
import ko from "knockout"

export class Checkbox
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.clearTrigger, optional(Function))

      if params.value
         assertOfType(params.value, Function)
         @value = params.value
      else if params.nestedValue
         assertOfType(params.nestedValue, Function)
         assertOfType(params.childKey, [Function, String])
         @value = params.nestedValue()[ko.unwrap(params.childKey)]
      else
         @value = ko.observable()

      @data = params.data or {}

      if params.key?
         assertOfType(params.key, String)
         @key = params.key
      else
         @key = null

      if params.onChanged
         assertOfType(params.onChanged, Function)
         @onChanged = params.onChanged

      if params.isVisible
         assertOfType(params.isVisible, [Boolean, Function])
         if params.isVisible instanceof(Function)
            @value(params.isVisible(@data))
         else
            @value(params.isVisible)

      if params.clearTrigger?
         params.clearTrigger.subscribe =>
            @value(false)

      if params.checkTrigger?
         params.checkTrigger.subscribe =>
            @value(true)

      if params.deselectEnabled?
         if params.deselectEnabled instanceof Function
            @deselectEnabled = params.deselectEnabled
         else
            @deselectEnabled = ko.observable(params.deselectEnabled)
      else
         @deselectEnabled = ko.observable(false)

      if params.deselectCallback?
         if params.deselectCallback instanceof Function
            @deselectCallback = params.deselectCallback
         else
            @deselectCallback = null
      else
         @deselectCallback = null

      @disabled = params.disabled or ko.observable(false)
      @selected = ko.pureComputed =>
         if @key?
            return @value() == @key
         else
            return @value()

   deselect: ->
      return unless @deselectEnabled()
      @deselectCallback(@value()) if @deselectCallback?

   onClick: ->
      return if @disabled()
      return @deselect() if @deselectEnabled()
      if @key?
         @value(@key)
      else
         @value(!@value())
      @onChanged(@value(), @data) if @onChanged


checkboxTemplate = checkboxTemplateFn()

ko.components.register("checkbox",
   viewModel: Checkbox,
   template: checkboxTemplate,
)