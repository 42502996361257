import { ObservableData } from "@/lib/utils/observable-data"
import { ViewModel } from "@/lib/vm/viewmodel"

export class ModalTab extends ViewModel
   constructor: (title, template) ->
      assertArgs(arguments, String, String)
      super(template)
      @disableHeader = ko.observable(false)
      @tabTitle = ko.observable(title)
      @isActionEnabled = ko.observable(false)
      @isEditing = ko.observable(false)
      @modal = null
      @data = null
      @paneClasses = ko.observable()
      @displayingNotice = ko.observable(null)
      @overflowable = ko.observable(false)
      @showListIcon = ko.observable(false)
      @showListCount = ko.observable(false)

   initialize: (modal, data) ->
      @modal = modal
      @data = data

   ### Called when this pane has been transitioned to. Direction = (forward/back) ###
   transitionedTo: (direction) ->
      assertArgs(arguments, String)

   ### Called when this pane has been transitioned from. Direction = (forward/back). ###
   transitionedFrom: (direction) ->
      assertArgs(arguments, String)

   setTitle: (title) ->
      assertArgs(arguments, String)
      @tabTitle(title)

   getTabData: ->
      return null

