import type {
   Computed,
   MaybeObservable,
   Observable,
   PureComputed,
   Subscription,
   utils,
} from "knockout";
import { pureComputed, unwrap } from "knockout";

export interface CompactArrayChange<T> {
   index: number;
   status: "added" | "deleted" | "retained" | "updated";
   values: T[];
}

/** Creates a pureComputed that's only updated when a valid value is received. */
export function createValidValueComputed<T>({
   value,
   allowUpdateCheck = (value: T) => Boolean(value),
}: {
   value: MaybeObservable<T>;
   allowUpdateCheck?: (value: T) => boolean;
}): PureComputed<T> {
   let lastValidValue = unwrap(value);
   return pureComputed(() => {
      const newValue = unwrap(value);
      if (allowUpdateCheck(newValue)) {
         lastValidValue = newValue;
      }
      return lastValidValue;
   });
}

/**
 * Creates a pureComputed that's only updated when a valid value is received.
 * Allow the value to be transformed.
 *
 * NOTE: If the transformed value is an object, the computed will always
 * notify subscribers, even if the transformed value has not changed.
 */
export function createValidTransformedValueComputed<I, O>({
   value,
   transform,
   allowUpdateCheck = (value: O) => Boolean(value),
}: {
   value: MaybeObservable<I> | PureComputed<I>;
   transform: (value: I) => O;
   allowUpdateCheck?: (value: O) => boolean;
}): PureComputed<O> {
   let lastValidValue = transform(unwrap(value));
   return pureComputed(() => {
      const newValue = transform(unwrap(value));
      if (allowUpdateCheck(newValue)) {
         lastValidValue = newValue;
      }
      return lastValidValue;
   });
}

/**
 * Creates a subscription with a callback containing both the new value and the
 * previous value.
 */
export function subscribeWithPreviousValue<T>(
   observable: Observable<T> | Computed<T> | PureComputed<T>,
   callback: (value: T, previous: T) => void,
): Subscription {
   let previous = observable.peek();
   return observable.subscribe((value) => {
      const currentPrevious = previous;
      previous = value;
      callback(value, currentPrevious);
   });
}

/** Merges array changes to be as few updates as possible. */
export function compactArrayChanges<T>(
   changes: utils.ArrayChanges<T>,
): Array<CompactArrayChange<T>> {
   return changes.reduce(
      (acc, change) => {
         const last = acc.length ? acc[acc.length - 1] : null;
         if (last) {
            const lastIndex = last.index + (last.values.length - 1);
            const isPossibleUpdate =
               (change.status == "added" && last.status == "deleted") ||
               (change.status == "deleted" && last.status == "added");

            if (isPossibleUpdate && lastIndex == change.index) {
               // Combine a 'deleted' / 'added' on the same index as an 'updated'.
               const updatedValue =
                  change.status == "added" ? change.value : last.values[last.values.length - 1];
               if (last.values.length > 1) {
                  // Remove the last values from the compacted change and merge it with
                  // the 'added' value.
                  return acc.slice(0, acc.length - 1).concat([
                     {
                        index: last.index,
                        status: last.status,
                        values: last.values.slice(0, last.values.length - 1),
                     },
                     {
                        index: change.index,
                        status: "updated",
                        values: [updatedValue],
                     },
                  ]);
               }

               // Check if we can combine with a preceeding update.
               if (
                  acc.length > 1 &&
                  acc[acc.length - 2].status == "updated" &&
                  acc[acc.length - 2].index + acc[acc.length - 2].values.length == change.index
               ) {
                  const lastUpdate = acc[acc.length - 2];
                  return acc.slice(0, acc.length - 2).concat([
                     {
                        ...lastUpdate,
                        values: [...lastUpdate.values, updatedValue],
                     },
                  ]);
               }

               // Merge the added and deleted as a single update.
               return acc.slice(0, acc.length - 1).concat([
                  {
                     index: change.index,
                     status: "updated",
                     values: [updatedValue],
                  },
               ]);
            }
            if (last.status == change.status && last.index + last.values.length == change.index) {
               // Combine sequential changes.
               last.values.push(change.value);
               return acc;
            }
         }
         return acc.concat([
            {
               index: change.index,
               status: change.status,
               values: [change.value],
            },
         ]);
      },
      [] as Array<{
         index: number;
         status: "added" | "deleted" | "retained" | "updated";
         values: T[];
      }>,
   );
}
