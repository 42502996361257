var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-sync"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Csvg xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"\u003E\u003Cpath d=\"M12 18a6 6 0 01-6-6c0-1 .25-1.97.7-2.8L5.24 7.74A7.93 7.93 0 004 12a8 8 0 008 8v3l4-4-4-4m0-11V1L8 5l4 4V6a6 6 0 016 6c0 1-.25 1.97-.7 2.8l1.46 1.46A7.93 7.93 0 0020 12a8 8 0 00-8-8z\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fsvg\u003E";
};




pug_mixins["icon-search"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-search\" width=\"26px\" height=\"26px\" viewbox=\"0 0 26 26\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-search\"\u003E\u003Cpath id=\"icon-search__shape\" d=\"M18.5820469,16.3522013 L17.4076615,16.3522013 L16.9914237,15.950829 C18.4482561,14.2561464 19.3253288,12.056032 19.3253288,9.66266438 C19.3253288,4.32590051 14.9994282,0 9.66266438,0 C4.32590051,0 0,4.32590051 0,9.66266438 C0,14.9994282 4.32590051,19.3253288 9.66266438,19.3253288 C12.056032,19.3253288 14.2561464,18.4482561 15.950829,16.9914237 L16.3522013,17.4076615 L16.3522013,18.5820469 L23.78502,26 L26,23.78502 L18.5820469,16.3522013 Z M9.66266438,16.3522013 C5.96112064,16.3522013 2.9731275,13.3642081 2.9731275,9.66266438 C2.9731275,5.96112064 5.96112064,2.9731275 9.66266438,2.9731275 C13.3642081,2.9731275 16.3522013,5.96112064 16.3522013,9.66266438 C16.3522013,13.3642081 13.3642081,16.3522013 9.66266438,16.3522013 Z\" fill=\"#7C8084\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003Crect id=\"icon_search__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"26\" height=\"26\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Ctransitioning-content params=\"\n         activeIndex: state,\n         transition: params.transitionController.direction,\n         resizeTrigger: resizeContentTrigger,\n         onVisible: onViewVisible\" data-bind=\"visible: isPopupVisible, onEscape: onEscape\"\u003E\u003Cdiv class=\"batch-edit__options\"\u003E\u003Cfield-editor-title params=\"title: 'Batch Edit Options', backArrowCallback: backArrowCallback\"\u003E\u003C\u002Ffield-editor-title\u003E\u003Cfocus-lock\u003E\u003Cdiv class=\"batch-edit__options__search\"\u003E";
pug_mixins["icon-search"]();
pug_html = pug_html + "\u003Cinput class=\"batch-edit__options__search__input\" placeholder=\"Search...\" data-bind=\"\n                  textInput: search,\n                  hasFocus: isSearchFocused\"\u003E\u003C!-- ko if: search --\u003E\u003Cspan class=\"batch-edit__options__search__close\" data-bind=\"click: () =&gt; search(null)\"\u003E\u003Cdiv class=\"batch-edit__options__search__close__icon\"\u003E\u003Cdiv class=\"sprite icon-close-x\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C!-- \u002Fko --\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"batch-edit__options__list\"\u003E\u003C!-- ko foreach: visibleEditorComponents --\u003E\u003Cbutton class=\"batch-edit__options__list-item\" data-bind=\"\n                  text: params.title,\n                  click: $parent.onListItemClick\"\u003E\u003C\u002Fbutton\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: visibleEditorComponents().length == 0 --\u003E\u003Cdiv class=\"batch-edit__options__empty\"\u003ENo matching fields\u003C\u002Fdiv\u003E\u003C!-- \u002Fko --\u003E\u003C\u002Fdiv\u003E\u003C\u002Ffocus-lock\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"batch-edit__editor\" data-bind=\"if: activeEditorComponent\"\u003E\u003Cdiv data-bind=\"\n            css: activeEditorComponent().name,\n            containsFocus: isEditorFocused,\n            component: activeEditorComponent\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"batch-edit__message\" tabindex=\"0\" data-bind=\"hasFocus: isPopupFocused\"\u003E\u003Cdiv class=\"batch-edit__message__icon batch-edit__message__icon--waiting\"\u003E";
pug_mixins["icon-sync"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cspan\u003EUpdating records...\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftransitioning-content\u003E";;return pug_html;};
module.exports = template;