import "./batch-edit.styl";
import template from "./batch-edit.pug";
import type { MaybeObservableArray, PureComputed } from "knockout";
import ko, { observable, observableArray, pureComputed, unwrap } from "knockout";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import type {
   EditorComponentFactory,
   EditorComponentParams,
} from "@/lib/components/editors/common/editor-component";
import { SaveCancelledError } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "../../common";
import type { RowBase } from "../../grid/grid-store";
import { BatchEditConflictModalPane } from "./batch-edit-conflict-modal-pane";
import { createValidTransformedValueComputed } from "@/lib/utils/knockout";
import { textSearch } from "@/lib/utils/text-search";
import type { BaseActionParams, Conflict } from "../batch-actions";
import { BatchActionState, TRANSITION_TIMING } from "../batch-actions";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";

export type BatchEditor<T, V = any> = {
   factory: EditorComponentFactory<T, V>;

   /**
    * Validator used to verify each record can be saved with the given value.
    * If a string is returned, the record will not be saved and the conflict
    * modal will be shown instead.
    */
   validator?: ((record: T, value: V) => string | null) | null;

   /**
    * Whether the editor component internally manages its loading and saving state.
    * When true, the save provider will not be intercepted and the editor component
    * is expected to manage the saving state.
    * Defaults to false.
    */
   hasInternalSaveManagement?: boolean;
};

export type EditActionParams<T extends RowBase> = {
   /**
    * The list of all available editors for batch edits.
    */
   editors: MaybeObservableArray<BatchEditor<T>> | PureComputed<Array<BatchEditor<T>>>;
};
export type BatchActionEditParams<T extends RowBase> = BaseActionParams<T> & EditActionParams<T>;

const enum State {
   SELECT_FIELD = 0,
   EDITING = 1,
   SAVING = 2,
}

export class BatchEdit<T extends RowBase> {
   readonly isPopupVisible: PureComputed<boolean>;
   readonly search = observable<string | null>(null);
   readonly state = observable<State>(State.SELECT_FIELD);
   readonly editorComponents = observableArray<ComponentArgs<EditorComponentParams<unknown>>>();
   readonly activeEditorComponent = observable<ComponentArgs<EditorComponentParams<unknown>>>(null);
   readonly isPopupFocused = observable(false);
   readonly isEditorFocused = observable(false);
   readonly numberOfRecords = createValidTransformedValueComputed({
      value: this.params.records,
      transform: (value) => value.length,
   });
   readonly visibleEditorComponents = pureComputed(() => {
      const search = this.search();
      if (!search) return this.editorComponents();
      return textSearch({
         items: this.editorComponents(),
         textProvider: (item) => unwrap(item.params.title) || "",
         search: this.search(),
      });
   });
   readonly isSearchFocused = observable(false);
   readonly resizeContentTrigger = observable(0);
   // Makes sure that click-off method is only firing once view is fully active
   private clickOffEnabled = false;

   private isShowingConflict = false;
   private readonly backArrowCallback = () => {
      this.params.transitionController.backToActionList();
   };

   constructor(private readonly params: BatchActionEditParams<T>) {
      this.isPopupVisible = pureComputed(
         () => params.transitionController.state() === BatchActionState.EDIT,
      );
      this.isPopupVisible.subscribe(this.initializeView, this);
   }

   private initializeView(visible: boolean) {
      if (visible) {
         this.editorComponents(this.createEditorComponents());
         setTimeout(() => this.isSearchFocused(true), TRANSITION_TIMING);
      } else {
         this.activeEditorComponent(null);
         this.state(State.SELECT_FIELD);
      }
   }

   onListItemClick = (editorComponent: ComponentArgs<EditorComponentParams<unknown>>): void => {
      this.params.transitionController.direction(Transition.FORWARD);
      this.activeEditorComponent(editorComponent);
      this.state(State.EDITING);
   };

   onEscape = (): void => {
      if (this.isPopupVisible() === false || this.state() === State.SAVING) return;
      // Ignore the escape key when showing the conflict modal to avoid dismissing unexpectedly.
      if (this.isShowingConflict) return;
      if (this.state() == State.EDITING) {
         this.params.transitionController.direction(Transition.BACKWARD);
         this.state(State.SELECT_FIELD);
      } else {
         this.params.transitionController.backToActionList();
      }
   };

   onViewVisible = (index: number): void => {
      if (index == State.SELECT_FIELD) {
         this.focusListView();
         this.activeEditorComponent(null);
      } else {
         this.isEditorFocused(true);
      }
   };

   private focusListView() {
      if (this.state() == State.SELECT_FIELD) {
         this.isSearchFocused(true);
      }
   }

   private async saveRecordsOrShowConflicts(
      editor: BatchEditor<T>,
      saveProvider: (value: unknown) => Promise<void>,
      value: unknown,
   ): Promise<void> {
      if (editor.hasInternalSaveManagement) {
         return saveProvider(value);
      }
      if (editor.validator) {
         const conflicts: Array<Conflict<T>> = [];
         for (const record of unwrap(this.params.records)) {
            const reason = editor.validator(record, value);
            if (reason) {
               conflicts.push({ record, reason });
            }
         }
         if (conflicts.length) {
            this.showConflictModal(editor, conflicts, value);
            throw new SaveCancelledError();
         }
      }
      this.trackSave(saveProvider(value));
   }

   private async showConflictModal(
      editor: BatchEditor<T>,
      conflicts: Array<Conflict<T>>,
      value: unknown,
   ) {
      if (editor == null) return;
      const records = unwrap(this.params.records);
      const validRecords = records.filter(
         (record) => !conflicts.some((c) => c.record.id == record.id),
      );
      this.isShowingConflict = true;
      try {
         const status = await BatchEditConflictModalPane.show<T>({
            records,
            columnGroups: unwrap(this.params.conflictModalColumns),
            conflicts,
            saveProvider: async () => {
               this.trackSave(editor.factory(validRecords)!.params.saveProvider(value));
            },
         });
         if (status !== "finished") {
            this.isEditorFocused(true);
         }
      } finally {
         this.isShowingConflict = false;
      }
   }

   private createEditorComponents() {
      const records = unwrap(this.params.records);
      if (records.length == 0) return [];
      return unwrap(this.params.editors)
         .flatMap((batchEditor) => {
            const editor = batchEditor.factory(records);
            if (editor == null) return [];
            const saveProvider = editor.params.saveProvider;
            editor.params.saveProvider = (value) =>
               this.saveRecordsOrShowConflicts(batchEditor, saveProvider, value);
            editor.params.onClose = (context) => {
               if (!context.hasSaved) {
                  this.params.transitionController.direction(Transition.BACKWARD);
                  this.state(State.SELECT_FIELD);
               }
            };
            return [editor];
         })
         .sort((a, b) => {
            const titleA = unwrap(a.params.title) ?? "";
            const titleB = unwrap(b.params.title) ?? "";
            return titleA.localeCompare(titleB);
         });
   }

   private async trackSave(promise: Promise<any>) {
      try {
         this.state(State.SAVING);
         await this.params.transitionController.disableUntil(promise);
      } catch (error) {
         // NOTE: This should never be reached.
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "batch-edit_track-save";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });
      } finally {
         this.params.transitionController.direction(Transition.BACKWARD);
         this.state(State.SELECT_FIELD);
      }
   }
}

ko.components.register("action-batch-edit", {
   viewModel: BatchEdit,
   template: template(),
});
