import "./assignment-card.styl";
import template from "./assignment-card.pug";
import { DateUtils } from "@/lib/utils/date";
import { router } from "@/lib/router";
import type { MaybeObservable, Observable, ObservableArray } from "knockout";
import ko from "knockout";
import { formatTimeValue } from "@laborchart-modules/common/dist/datetime";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { defaultStore } from "@/stores/default-store";

/* Modals */
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { FyiInfoPaneViewModel } from "@/lib/components/modals/fyi-info-pane";

/* Popups */
import { Popup } from "@/lib/components/popup/popup";
import { AssignmentCardInfoPane } from "@/lib/components/popup/assignment-card-info-pane/assignment-card-info-pane";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";

export type AssignmentCardParams = {
   allowDetailsPerm: MaybeObservable<boolean>;
   data: {
      assignableGroupIds: ObservableArray<string>;
      dynamicSubtitle: Observable<string | null>;
      endDay: Observable<number>;
      endTime: Observable<number | null>;
      groupIds: ObservableArray<string>;
      instanceEndDay: Observable<number>;
      instanceStartDay: Observable<number>;
      isPending: Observable<boolean>;
      percentAllocated: Observable<number | null>;
      positionName: Observable<string | null>;
      resourcePhotoUrl: Observable<string | null>;
      resourceTitle: Observable<string>;
      resourceType: MaybeObservable<"person" | "crew" | "tool" | "tool-set">;
      startDay: Observable<number>;
      startTime: Observable<number | null>;
      status: Observable<SerializedStatus | null>;
   };
   exprClassCheck: (...params: unknown[]) => unknown;
   disableDragStyle?: boolean;
   settingsCallback: (...params: unknown[]) => unknown;
};

export class AssignmentCard {
   private readonly allowDetailsPerm: AssignmentCardParams["allowDetailsPerm"];
   private readonly data: AssignmentCardParams["data"];
   private readonly settingsCallback: AssignmentCardParams["settingsCallback"];
   private readonly exprClassCheck: AssignmentCardParams["exprClassCheck"];
   private readonly disableDragStyle: AssignmentCardParams["disableDragStyle"];

   private readonly outsideGroupAccess: boolean;
   private readonly resourcePhotoUrl: string | null;
   private readonly btnsVisible: Observable<boolean>;
   private readonly assignmentInfoPopupWrapper: Observable<unknown>;

   private readonly hasAssignment = ko.pureComputed(() => {
      return (
         (this.data.startTime() != null && this.data.endTime() != null) ||
         this.data.percentAllocated() != null
      );
   });

   private readonly startTime = ko.pureComputed(() => {
      const startTime = this.data.startTime();
      if (startTime == null) {
         return "";
      }
      return formatTimeValue(startTime);
   });

   private readonly endTime = ko.pureComputed(() => {
      const endTime = this.data.endTime();
      if (endTime == null) {
         return "";
      }
      return formatTimeValue(endTime);
   });

   private readonly resourceIcon = ko.pureComputed(() => {
      switch (ko.unwrap(this.data.resourceType)) {
         case "person":
            return "icon-person";
         case "tool":
            return "icon-tool";
         case "crew":
            return "icon-crew-resource";
         case "tool-set":
            return "icon-tool-set";
      }
   });

   private readonly isPending = ko.pureComputed(() => {
      return this.data.isPending();
   });

   private readonly truncateName = ko.pureComputed(() => this.data.resourceTitle().length >= 16);

   private readonly truncateSubtitle = ko.pureComputed(() => {
      const positionName = this.data.positionName();
      return positionName != null && positionName.length >= 30;
   });

   constructor(params: AssignmentCardParams) {
      /*------------------------------------
       Data Properties
    ------------------------------------*/
      this.allowDetailsPerm = params.allowDetailsPerm ?? ko.observable(true);
      this.data = params.data;
      this.settingsCallback = params.settingsCallback;

      if (!authManager.isAdmin()) {
         const cardsGroupIds =
            (authManager.usingTypedGroups()
               ? this.data.assignableGroupIds()
               : this.data.groupIds()) || [];
         const usersGroupIds = authManager.usingTypedGroups()
            ? authManager.authedUser()?.accessGroupIds() ?? []
            : authManager.authedUser()?.groupIds() ?? [];

         // If the resource is an Admin, they are available to all Groups.
         const foundMatch =
            cardsGroupIds.length === 0 ||
            usersGroupIds.some((userGroupId) => {
               return cardsGroupIds.includes(userGroupId);
            });

         // The variable, outsideGroupAccess, determines whether a user should be understood
         // as having access to the assignment-cards equal to someone outside of the owning group.
         this.outsideGroupAccess = !foundMatch;
      } else {
         this.outsideGroupAccess = false;
      }
      const resourcePhotoUrl = this.data.resourcePhotoUrl();
      this.resourcePhotoUrl =
         resourcePhotoUrl != null ? AssignmentCard.getResourcePhotoUrl(resourcePhotoUrl) : null;

      /*------------------------------------
       View Properties
    ------------------------------------*/
      this.btnsVisible = ko.observable(false);

      this.exprClassCheck = params.exprClassCheck;
      this.disableDragStyle = params.disableDragStyle || false;
      if (this.outsideGroupAccess) {
         this.disableDragStyle = true;
      }
      const assignmentInfoPopupBuilder = (data: { data: unknown }) => {
         let popupClasses;
         popupClasses = ["assignments-card__info-popup"];
         if (this.hasAssignment()) {
            popupClasses = [
               "assignments-card__info-popup",
               "assignments-card__info-popup--assignment",
            ];
         }
         return new Popup(
            "Assignment Info",
            null,
            null,
            [new AssignmentCardInfoPane(data.data, this.exprClassCheck, this.outsideGroupAccess)],
            [
               "assignment-card__status-text",
               "assignment-card__status",
               "icon-more-hor",
               "sprite",
               "assignment-card__status--empty",
            ],
            popupClasses,
         );
      };
      const assginmentInfoPopupData = {
         popupBuilder: assignmentInfoPopupBuilder,
         options: {
            triggerClasses: [
               "assignment-card__status-text",
               "assignment-card__status",
               "icon-more-hor",
               "sprite",
               "assignment-card__status--empty",
            ],
         },
         dynamicPositioning: true,
         ...(!this.hasAssignment()
            ? { positioningContainerClass: "resource-bench__resource-container" }
            : {}),
      };
      this.assignmentInfoPopupWrapper = ko.observable<unknown>(assginmentInfoPopupData);
   }

   private static getResourcePhotoUrl(resourcePhotoUrl: string): string {
      const fragments = resourcePhotoUrl.split("upload/");
      return `${fragments[0]}upload/g_face,c_thumb,w_30,h_30/${fragments[1]}`;
   }

   getSubTitle(): string {
      const dynamicSubtitle = this.data.dynamicSubtitle() ?? "";
      if (dynamicSubtitle.indexOf("<LC_USE_") === -1) {
         return dynamicSubtitle;
      }
      if (dynamicSubtitle === "<LC_USE_BATCH_DATES>") {
         const startDay =
            this.data.startDay() != null ? this.data.startDay() : this.data.instanceStartDay();
         const endDay =
            this.data.endDay() != null ? this.data.endDay() : this.data.instanceEndDay();
         return (
            DateUtils.formatDetachedDay(startDay, defaultStore.getDateFormat()) +
            " - " +
            DateUtils.formatDetachedDay(endDay, defaultStore.getDateFormat())
         );
      } else if (dynamicSubtitle === "<LC_USE_INSTANCE_DATES>") {
         return (
            DateUtils.formatDetachedDay(
               this.data.instanceStartDay(),
               defaultStore.getDateFormat(),
            ) +
            " - " +
            DateUtils.formatDetachedDay(this.data.instanceEndDay(), defaultStore.getDateFormat())
         );
      } else if (dynamicSubtitle === "<LC_USE_STATUS_NAME>") {
         const status = this.data.status();
         if (status != null) {
            return status.name;
         }
         return "";
      } else if (dynamicSubtitle === "<LC_USE_JOB_TITLE>") {
         const positionName = this.data.positionName();
         if (positionName != null) {
            return positionName;
         }
         return "";
      }
      return "";
   }

   private readonly settingsClicked = (data: unknown): void => {
      console.info("settingsClicked:", data);
      if (!this.allowDetailsPerm) {
         return;
      }
      if (this.settingsCallback != null) {
         this.settingsCallback(data);
         return;
      }
   };

   groupWarningClicked(): void {
      const message = "You do not have access to the group(s) this assignment belongs to.";
      const pane1 = new FyiInfoPaneViewModel("Access Blocked", message);
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-action-modal",
         },
         () => {},
      );
   }

   showBtns(): void {
      if (!this.allowDetailsPerm) {
         return;
      }
      return this.btnsVisible(true);
   }

   hideBtns(): void {
      if (!this.allowDetailsPerm) {
         return;
      }
      return this.btnsVisible(false);
   }

   navigateToResource(resource: { data: { resourceId: Observable<string> } }): void {
      if (!this.allowDetailsPerm) {
         return;
      }
      switch (ko.unwrap(this.data.resourceType)) {
         case "person":
            return router.navigate(
               null,
               `/groups/${authManager.selectedGroupId()}/people/${resource.data.resourceId()}`,
            );
      }
   }
}

ko.components.register("assignment-card", {
   viewModel: AssignmentCard,
   template: template(),
});
