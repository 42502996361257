import "./manpower-graph.styl"
import manpowerGraphTemplateFn from "./manpower-graph.pug"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"
import $ from "jquery"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { TotalsStore } from "@/stores/totals-store.core"
import { PositionStore } from "@/stores/position-store.core"

### Mediators ###
import { ScrollbarMediator } from "@/lib/mediators/scrollbar-mediator"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class ManpowerGraph
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.projectId, String)

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)

      # Labor Plans fields
      @laborPlanRequests = if params.laborPlanRequests then params.laborPlanRequests else null
      @isLaborPlan = if @laborPlanRequests then ko.observable(true) else ko.observable(false)

      @projectId = params.projectId
      @currentRequest = ko.observable()
      @graphExpanded = ko.observable(false)
      @loading = ko.observable(false)

      @horizontalScrollbarMediator = new ScrollbarMediator()
      
      @originalGraphData = ko.observable()
      @graphData = ko.observable(null)
      @totalsData = ko.observable()

      @originalGraphColumns = ko.observable([])
      @graphColumns = ko.observable([])
      @graphColors = ko.observable({})
      @graphColorIndex = ko.observableArray([])
      @graphOptions = ko.observable({})

      @blockPositionSubscription = false
      @selectedPositions = ko.observableArray([])
      @selectedPositions.subscribe () =>
         return if @blockPositionSubscription
         @redrawWithPositionFilters()

      @rawPositionOptions = ko.observable()
      @positionOptions = ko.pureComputed(() =>
         @blockPositionSubscription = true
         @selectedPositions([])
         items = (ko.unwrap(@rawPositionOptions) ? []).map (option) =>
            item = new MultiDropDownItem(option.name, option.id, true)
            @selectedPositions.push(item)
            return item
         # Adding the None option.
         noneItem = new MultiDropDownItem("None", "null", true)
         items.push(noneItem)
         @selectedPositions.push(noneItem)
         @blockPositionSubscription = false
         return items
      )

      @noPositionsEnabled = ko.pureComputed =>
         return false unless @breakdownJobTitles()
         return @selectedPositions().length == 0

      @hasDataToDisplay = ko.pureComputed =>
         return if @graphData()? then @graphData().length != 0 else false

      @entityOptions = [
         new SegmentedControllerItem("Assignments", "assignments")
         new SegmentedControllerItem("Requests", "requests")
         new SegmentedControllerItem("Both", "both")
      ]
      @selectedEntity = ko.observable(@entityOptions[2])
      @selectedEntity.subscribe (newVal) =>
         @reloadData() if newVal?

      @breakdownJobTitles = ko.observable(false)
      @breakdownJobTitles.subscribe (newVal) =>
         # Initial load for position options.
         if !@rawPositionOptions()?
            options = []
            stream = await PositionStore.findPositionsStream({}).stream
            for await row from stream
               options.push(row)
            @rawPositionOptions(options)
         @reloadData() if newVal?

      if @isLaborPlan()
         @breakdownJobTitles(true)

      @unitOptions = [
         new DropDownItem("People", "people")
         new DropDownItem("Hours", "hours")
         new DropDownItem("Man-Days", "man-days")
      ]
      if @canViewProjectFinancials
         @unitOptions.push(new DropDownItem("Cost", "cost"))

      @selectedUnit = ko.observable(@unitOptions[0])
      @selectedUnit.subscribe (newVal) =>
         @reloadData() if newVal?

      @timeFrameOptions = [
         new SegmentedControllerItem("Weeks", "weeks")
         new SegmentedControllerItem("Months", "months")
      ]
      @selectedTimeFrame = ko.observable(@timeFrameOptions[0])
      @selectedTimeFrame.subscribe (newVal) =>
         @reloadData() if newVal?

      @timelineOptions = [
         new SegmentedControllerItem("Compressed", "compressed")
         new SegmentedControllerItem("Expanded", "expanded")
      ]
      @selectedTimelineOption = ko.observable(@timelineOptions[0])
      @selectedTimelineOption.subscribe (newVal) =>
         @reloadData() if newVal?

      @graphIsCompressed = ko.pureComputed =>
         return @selectedTimelineOption().value() == "compressed"

      @loadData()

   expandGraph: ->
      @graphExpanded(true)
      # Triggering redraw to handle sizing. 
      graphData = @graphData()
      @graphData(null)
      @graphData(graphData)

   minimizeGraph: ->
      @graphExpanded(false)
      # Triggering redraw to handle sizing. 
      graphData = @graphData()
      @graphData(null)
      @graphData(graphData)

   checkColorIndexDisabled: (data) ->
      allowedPositionIds = @selectedPositions().map (item) -> return item.value()
      return allowedPositionIds.indexOf(data.id) == -1

   handleColorIndexMouseOver: (data) ->
      # Sorry for using jquery. 
      $(".manpower-graph__graph .#{data.id}").attr "class", (index, classNames) ->
         if classNames.indexOf("manpower-graph__highlight-stroke--yellow") == -1
            return classNames + " manpower-graph__highlight-stroke--yellow"
         else
            return classNames

   handleColorIndexMouseOut: (data) ->
      # Sorry for using jquery. 
      $(".manpower-graph__graph .#{data.id}").attr "class", (index, classNames) ->
         if classNames.indexOf("manpower-graph__highlight-stroke--yellow") != -1
            classNames = classNames.replace("manpower-graph__highlight-stroke--yellow", "")
            # Remove any extra white spaces to be nice.
            return classNames.replace(/\s\s+/g, ' ')
         else
            return classNames
   
   redrawWithPositionFilters: =>
      return unless @breakdownJobTitles()
      originalGraphData = @originalGraphData()

      allowedPositionIds = @selectedPositions().map (item) -> return item.value()

      filteredData = originalGraphData.filter (item) ->
         foundMatch = false
         for key of item
            continue unless key.indexOf("--") != -1
            id = key.split("--")[1]
            if allowedPositionIds.indexOf(id) != -1
               foundMatch = true
               break

         return foundMatch

      originalGraphColumns = @originalGraphColumns()
      filteredColumns = originalGraphColumns.filter (key) ->
         id = key.split("--")[1]
         return allowedPositionIds.indexOf(id) != -1

      totalsData = {
         assignments: {}
         requests: {}
         totals: {}
      }

      for item in filteredData
         detachedDay = DateUtils.getDetachedDay(item.date)
         for key, val of item
            continue if key == "date"
            id = key.split("--")[1]
            if allowedPositionIds.indexOf(id) != -1
               if key.indexOf("assignments") != -1
                  if totalsData.assignments[detachedDay]?
                     totalsData.assignments[detachedDay] += if val.entityIds? then val.entityIds.length else val.count
                  else
                     totalsData.assignments[detachedDay] = if val.entityIds? then val.entityIds.length else val.count

                  if totalsData.totals[detachedDay]?
                     totalsData.totals[detachedDay] += if val.entityIds? then val.entityIds.length else val.count
                  else
                     totalsData.totals[detachedDay] = if val.entityIds? then val.entityIds.length else val.count

               else if key.indexOf("requests") != -1
                  if totalsData.requests[detachedDay]?
                     totalsData.requests[detachedDay] += if val.entityIds? then val.entityIds.length else val.count
                  else
                     totalsData.requests[detachedDay] = if val.entityIds? then val.entityIds.length else val.count

                  if totalsData.totals[detachedDay]?
                     totalsData.totals[detachedDay] += if val.entityIds? then val.entityIds.length else val.count
                  else
                     totalsData.totals[detachedDay] = if val.entityIds? then val.entityIds.length else val.count

      @graphData(null)
      @graphColumns(filteredColumns)
      @totalsData(totalsData)
      @graphData(filteredData)

   reloadData: =>
      @graphData(null)
      @loadData()

   handleData: (data) ->
      countsByPeople = @selectedUnit().value() == "people"
      aggregateGraphData = {}
      rawTotalsData = {
         assignments: {}
         requests: {}
         totals: {}
      }
      totalsData = {
         assignments: {}
         requests: {}
         totals: {}
      }
      columns = []
      keyedColors = {}
      colorIndexSets = []
      aggregateByWeeks = @selectedTimeFrame().value() == "weeks"

      # Rollup Totals
      for key, val of data.rollupData.daily_ass_totals
         if aggregateByWeeks
            startDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
            chunkStartDate = new Date(weekStartMs)
            chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
         else
            monthChunk = String(key).slice(0, -2)
            chunkStartingDay = "#{monthChunk}01"
            chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

         if countsByPeople
            # Assignments
            if rawTotalsData.assignments[chunkStartingDay]? 
               for id in val.peopleIds
                  if rawTotalsData.assignments[chunkStartingDay].entityIds.indexOf(id) == -1
                     rawTotalsData.assignments[chunkStartingDay].entityIds.push(id)
            else
               rawTotalsData.assignments[chunkStartingDay] = {
                  entityIds: [...val.peopleIds]
               }
            # Aggregate Total
            if rawTotalsData.totals[chunkStartingDay]? 
               for id in val.peopleIds
                  if rawTotalsData.totals[chunkStartingDay].entityIds.indexOf(id) == -1
                     rawTotalsData.totals[chunkStartingDay].entityIds.push(id)
            else
               rawTotalsData.totals[chunkStartingDay] = {
                  entityIds: [...val.peopleIds]
               }
         else
            # Assignments
            if rawTotalsData.assignments[chunkStartingDay]? 
               rawTotalsData.assignments[chunkStartingDay].count += val
            else
               rawTotalsData.assignments[chunkStartingDay] = {
                  count: val
               }
            # Aggregate Totals
            if rawTotalsData.totals[chunkStartingDay]? 
               rawTotalsData.totals[chunkStartingDay].count += val
            else
               rawTotalsData.totals[chunkStartingDay] = {
                  count: val
               }
   
      for key, val of data.rollupData.daily_request_totals
         if aggregateByWeeks
            startDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
            chunkStartDate = new Date(weekStartMs)
            chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
         else
            monthChunk = String(key).slice(0, -2)
            chunkStartingDay = "#{monthChunk}01"
            chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

         if countsByPeople
            # Requests
            if rawTotalsData.requests[chunkStartingDay]? 
               for id in val.requestIds
                  if rawTotalsData.requests[chunkStartingDay].entityIds.indexOf(id) == -1
                     rawTotalsData.requests[chunkStartingDay].entityIds.push(id)
            else
               rawTotalsData.requests[chunkStartingDay] = {
                  entityIds: [...val.requestIds]
               }
            # Aggregate Totals
            if rawTotalsData.totals[chunkStartingDay]? 
               for id in val.requestIds
                  if rawTotalsData.totals[chunkStartingDay].entityIds.indexOf(id) == -1
                     rawTotalsData.totals[chunkStartingDay].entityIds.push(id)
            else
               rawTotalsData.totals[chunkStartingDay] = {
                  entityIds: [...val.requestIds]
               }
         else
            # Requests
            if rawTotalsData.requests[chunkStartingDay]? 
               rawTotalsData.requests[chunkStartingDay].count += val
            else
               rawTotalsData.requests[chunkStartingDay] = {
                  count: val
               }
            # Aggregate Totals
            if rawTotalsData.totals[chunkStartingDay]? 
               rawTotalsData.totals[chunkStartingDay].count += val
            else
               rawTotalsData.totals[chunkStartingDay] = {
                  count: val
               }

      # Cleaning up totals.
      for key, val of rawTotalsData.assignments
         if countsByPeople
            totalsData.assignments[key] = val.entityIds.length
         else
            totalsData.assignments[key] = val.count

      for key, val of rawTotalsData.requests
         if countsByPeople
            totalsData.requests[key] = val.entityIds.length
         else
            totalsData.requests[key] = val.count

      for key, val of rawTotalsData.totals
         if countsByPeople
            totalsData.totals[key] = val.entityIds.length
         else
            totalsData.totals[key] = val.count

      # Breakdown Values
      for set in data.breakdownData
         # set could be position or project
         setId = set.id or "null"
         setColor = set.color or "black"
         keyedColors[setId] = setColor
         colorIndexSets.push({name: set.name, color: setColor, id: setId})

         columns.push("assignments--#{setId}")
         columns.push("requests--#{setId}")

         if countsByPeople
            for key, val of set.daily_ass_totals
               if aggregateByWeeks
                  startDate = DateUtils.getAttachedDate(Number(key))
                  weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
                  chunkStartDate = new Date(weekStartMs)
                  chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
               else
                  monthChunk = String(key).slice(0, -2)
                  chunkStartingDay = "#{monthChunk}01"
                  chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

               if aggregateGraphData[chunkStartingDay]? 
                  if aggregateGraphData[chunkStartingDay]["assignments--#{setId}"]?
                     for id in val.peopleIds
                        if aggregateGraphData[chunkStartingDay]["assignments--#{setId}"].entityIds.indexOf(id) == -1
                           aggregateGraphData[chunkStartingDay]["assignments--#{setId}"].entityIds.push(id)
                  else
                     aggregateGraphData[chunkStartingDay]["assignments--#{setId}"] = {
                        color: setColor
                        entityIds: val.peopleIds
                     }
               else
                  aggregateGraphData[chunkStartingDay] = {
                     date: chunkStartDate
                     "assignments--#{setId}": {
                        color: setColor
                        entityIds: val.peopleIds
                     }
                  }

            for key, val of set.daily_request_totals
               if aggregateByWeeks
                  startDate = DateUtils.getAttachedDate(Number(key))
                  weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
                  chunkStartDate = new Date(weekStartMs)
                  chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
               else
                  monthChunk = String(key).slice(0, -2)
                  chunkStartingDay = "#{monthChunk}01"
                  chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

               if aggregateGraphData[chunkStartingDay]?
                  if aggregateGraphData[chunkStartingDay]["requests--#{setId}"]?
                     for id in val.requestIds
                        if aggregateGraphData[chunkStartingDay]["requests--#{setId}"].entityIds.indexOf(id) == -1
                           aggregateGraphData[chunkStartingDay]["requests--#{setId}"].entityIds.push(id)
                  else
                     aggregateGraphData[chunkStartingDay]["requests--#{setId}"] = {
                        color: setColor
                        entityIds: val.requestIds
                     }
               else
                  aggregateGraphData[chunkStartingDay] = {
                     date: chunkStartDate
                     "requests--#{setId}": {
                        color: setColor
                        entityIds: val.requestIds
                     }
                  }
         else
            for key, val of set.daily_ass_totals
               if aggregateByWeeks
                  startDate = DateUtils.getAttachedDate(Number(key))
                  weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
                  chunkStartDate = new Date(weekStartMs)
                  chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
               else
                  monthChunk = String(key).slice(0, -2)
                  chunkStartingDay = "#{monthChunk}01"
                  chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

               if aggregateGraphData[chunkStartingDay]? 
                  if aggregateGraphData[chunkStartingDay]["assignments--#{setId}"]?
                     aggregateGraphData[chunkStartingDay]["assignments--#{setId}"].count += val
                  else
                     aggregateGraphData[chunkStartingDay]["assignments--#{setId}"] = {
                        color: setColor
                        count: val
                     }
               else
                  aggregateGraphData[chunkStartingDay] = {
                     date: chunkStartDate
                     "assignments--#{setId}": {
                        color: setColor
                        count: val
                     }
                  }

            for key, val of set.daily_request_totals
               if aggregateByWeeks
                  startDate = DateUtils.getAttachedDate(Number(key))
                  weekStartMs = startDate.setDate(startDate.getDate() - startDate.getDay())
                  chunkStartDate = new Date(weekStartMs)
                  chunkStartingDay = DateUtils.getDetachedDay(chunkStartDate)
               else
                  monthChunk = String(key).slice(0, -2)
                  chunkStartingDay = "#{monthChunk}01"
                  chunkStartDate = DateUtils.getAttachedDate(Number(chunkStartingDay))

               if aggregateGraphData[chunkStartingDay]?
                  if aggregateGraphData[chunkStartingDay]["requests--#{setId}"]?
                     aggregateGraphData[chunkStartingDay]["requests--#{setId}"].count += val
                  else
                     aggregateGraphData[chunkStartingDay]["requests--#{setId}"] = {
                        color: setColor
                        count: val
                     }
               else
                  aggregateGraphData[chunkStartingDay] = {
                     date: chunkStartDate
                     "requests--#{setId}": {
                        color: setColor
                        count: val
                     }
                  }

      formattedGraphData = []
      # Need to add zero'd data in to make the graph represent correctly.
      nextKey = null
      for key, val of aggregateGraphData
         if nextKey? and key != nextKey
            # Loop through adding zero'd dates until we are on track.
            zeroData = {}
            for nestedKey, nestedVal of val
               continue if nestedKey == "date"
               if val.entityIds?
                  zeroData[nestedKey] = {color: nestedVal.color, entityIds: []}
               else
                  zeroData[nestedKey] = {color: nestedVal.color, count: 0}

            while nextKey < key
               zeroDatedData = Object.assign({}, zeroData)
               zeroDatedData['date'] = DateUtils.getAttachedDate(nextKey)
               formattedGraphData.push(zeroDatedData)
               if aggregateByWeeks
                  nextKey = DateUtils.incrementDetachedDay(Number(nextKey), 7)
               else
                  nextKey = DateUtils.incrementDetachedDayMonths(Number(nextKey), 1)


         # else
         if aggregateByWeeks
            nextKey = DateUtils.incrementDetachedDay(Number(key), 7)
         else
            nextKey = DateUtils.incrementDetachedDayMonths(Number(key), 1)
         formattedGraphData.push(val)
      
      sortedColumns = columns.sort()
      @graphColumns(sortedColumns)
      @originalGraphColumns(sortedColumns)
      @totalsData(totalsData)
      @graphColors(keyedColors)
      @originalGraphData(formattedGraphData)
      @graphOptions({
         dataPointsBy: if aggregateByWeeks then "weeks" else "months"
         viewTimeline: @selectedTimelineOption().value()
      })
      @graphColorIndex(colorIndexSets)
      @loading(false)
      if @breakdownJobTitles()
         @redrawWithPositionFilters()
      else
         @graphData(formattedGraphData)
   
   formatRequestData: (laborPlanRequests) ->
      ###------------------------------------
         Format Labor Plan Requests to match workforce object shape
      ------------------------------------###
      workDays = laborPlanRequests[0].workDays
      positionData = ko.unwrap(@rawPositionOptions)

      formattedPositions = {
         "null": {
            data: null
            daily_ass_totals: {}
            daily_request_totals: {},
            daily_time_off_totals: {}
         }
      }
      for position in positionData
         formattedPositions[position.id] = {
            data: position
            daily_ass_totals: {}
            daily_request_totals: {}
         }

      rollupRequestTotals = {}

      for request in laborPlanRequests
         workDays = request.workDays
         startDay = Number(request.detachedStartDay)
         endDay = Number(request.detachedEndDay)

         processingDay = startDay
         soonestEndDay = endDay
        
         while processingDay <= soonestEndDay
            processingDate = DateUtils.getAttachedDate(processingDay)
            weekDay = processingDate.getDay()
            unless workDays[weekDay]
               processingDay = DateUtils.incrementDetachedDay(processingDay)
               continue

            unless formattedPositions[String(request.position_id)]?
               # must be an archived position
               request.position_id = null
            
            if formattedPositions[String(request.position_id)].daily_request_totals[processingDay]?
               matchedDayData = formattedPositions[String(request.position_id)].daily_request_totals[processingDay]
               if matchedDayData.requestIds.indexOf(request.id) == -1
                  matchedDayData.count++
                  matchedDayData.requestIds.push(request.id)
            else
               formattedPositions[String(request.position_id)].daily_request_totals[processingDay] = {count: 1, requestIds: [request.id]}
            
            if rollupRequestTotals[processingDay]?
               matchedDayData = rollupRequestTotals[processingDay]
               if matchedDayData.requestIds.indexOf(request.id) == -1
                  matchedDayData.count++
                  matchedDayData.requestIds.push(request.id)
            else
               rollupRequestTotals[processingDay] = {count: 1, requestIds: [request.id]}

            processingDay = DateUtils.incrementDetachedDay(processingDay)

      formattedData = {
         rollup_data: {
            daily_request_totals: rollupRequestTotals
         }
      }
      
      positionList = []
      for key, val of formattedPositions
         continue unless Object.keys(val.daily_ass_totals).length > 0 or Object.keys(val.daily_request_totals).length > 0

         completePositionData = val.data or {}
         completePositionData['daily_ass_totals'] = val.daily_ass_totals
         completePositionData['daily_request_totals'] = val.daily_request_totals

         positionList.push(completePositionData)
         positionList.sort (a, b) ->
            aSeq = if a.sequence? then a.sequence else 10000
            bSeq = if b.sequence? then b.sequence else 10000
            return aSeq - bSeq
         
      formattedData['breakdown_data'] = positionList

      
      ###------------------------------------
         TODO: Merge Labor Plan Requests and existing projectData
      ------------------------------------###

      return formattedData
   
   getDataOptions: =>
      # Hard setting these options as they are hidden in the labor plans page
      totalsUnit = ""
      viewBy = ""

      if @isLaborPlan() or @breakdownJobTitles()
         viewBy = "job-title"
      else
         viewBy = "project"

      if @isLaborPlan()
         totalsUnit = "people"
      else if @selectedUnit()?
         totalsUnit = @selectedUnit().value()
      else
         totalsUnit = "hours"
      
      return {
         totalsUnit,
         viewBy,
      }

   loadData: ->
      @loading(true)
      if @currentRequest()
         @currentRequest(null)

      dataOptions = @getDataOptions()
      options = {
         totalsUnit: dataOptions.totalsUnit
         viewBy: dataOptions.viewBy
         getAssignments: @selectedEntity().value() == "both" or @selectedEntity().value() == "assignments" or @laborPlanRequests?
         getRequests: @selectedEntity().value() == "both" or @selectedEntity().value() == "requests" or @laborPlanRequests?
      }

      try
         request = await TotalsStore.getProjectTotals(@projectId, options).payload;
         formattedRequests = if @isLaborPlan() then @formatRequestData(@laborPlanRequests) else null
         formattedData = {
            totalPossible: request.data.total_possible
            assignablePeopleCount: request.data.assignable_people_count
            rollupData: if formattedRequests then formattedRequests.rollup_data else request.data.rollup_data
            breakdownData: if formattedRequests then formattedRequests.breakdown_data else request.data.breakdown_data
         }
         @handleData(formattedData)
      catch err
         console.log err
      
      @currentRequest(request)

manpowerGraphTemplate = manpowerGraphTemplateFn()

ko.components.register("manpower-graph",
   viewModel: ManpowerGraph,
   template: manpowerGraphTemplate
)
