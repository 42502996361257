import "./tag-accordion.styl"
import tagAccordionTemplateFn from "./tag-accordion.pug"
import { Dom } from "@/lib/utils/dom"

### Models ###
import { Tag } from "@/models/tag"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

export class TagAccordion
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.saveCallback, optional(Function))
      assertOfType(params.cancelCallback, optional(Function))
      assertOfType(params.deleteCallback, optional(Function))
      assertOfType(params.tags, optional([arrayOf(Tag), Function]))
      assertOfType(params.tag, optional([Tag, Function]))
      assertOfType(params.hideDates, optional(Boolean))

      @hideDates = if params.hideDates? then params.hideDates else false

      if params.tag?
         if params.tag instanceof Function
            assertOfType(params.tag(), Tag)
            @tag = params.tag
         else
            @tag = ko.observable(params.tag)
      else
         @tag = ko.observable(new Tag({}, true))

      @blankTag = ko.pureComputed =>
         return !@tag().name()?

      # Drop Down
      if params.tags?
         if params.tags instanceof Function
            assertOfType(params.tags(), arrayOf(Tag))
            @tags = params.tags
         else
            @tags = ko.observableArray(params.tags)
         @tagOptions = ko.pureComputed =>
            return @tags().map (tag) ->
               new DropDownItem(tag.name(), tag.id)
         @selectedTag = ko.observable()

      @files = ko.observableArray()
      @files(@tag().attachments()) if @tag().attachments().length != 0
      @files.subscribe (newVal) =>
         @tag().attachments(newVal)

      # Dates
      @effectiveDate = ko.observable()
      @effectiveDate(@tag().effectiveDate()) if @tag().effectiveDate()?
      @effectiveDate.subscribe (newVal) =>
         @tag().effectiveDate(newVal)
      @expirationDate = ko.observable()
      @expirationDate(@tag().exprDate()) if @tag().exprDate()?
      @expirationDate.subscribe (newVal) =>
         @tag().exprDate(newVal)

      @saveCallback = if params.saveCallback? then params.saveCallback else false
      @cancelCallback = if params.cancelCallback? then params.cancelCallback else false
      @deleteCallback = if params.deleteCallback? then params.deleteCallback else false

      @showSave = ko.pureComputed =>
         return @saveCallback and @tag().name()?

      @isExpanded = ko.observable(false)
      if params.openOnLoad == true then @isExpanded(true)

      # Alerts
      @showEffectiveDateAlert = ko.observable(false)
      @showExprDateAlert = ko.observable(false)
      @showAttachmentAlert = ko.observable(false)

   selectTag: (data) =>
      for tag in @tags()
         return @tag(tag) if data.value() == tag.id

   toggleExpansion: ->
      @isExpanded(!@isExpanded())

   fireSave: ->
      @showEffectiveDateAlert(false)
      @showExprDateAlert(false)
      @showAttachmentAlert(false)
      if @tag().requireExprDate() and !@hideDates
         return @showEffectiveDateAlert(true) unless @effectiveDate()?
         return @showExprDateAlert(true) unless @expirationDate()?
      for file in @files()
         return @showAttachmentAlert(true) unless file.createdAt()?

      @saveCallback(@tag()) if @saveCallback

   fireCancel: ->
      @isExpanded(false)
      @cancelCallback() if @cancelCallback

   fireDelete: ->
      @deleteCallback(@tag()) if @deleteCallback


tagAccordionTemplate = tagAccordionTemplateFn()

ko.components.register("tag-accordion",
   viewModel: TagAccordion,
   template: tagAccordionTemplate,
)