import "./section-heading.styl";
import template from "./section-heading.pug";
import { ViewModel } from "../../../../vm/viewmodel";
import type { MaybeComputed } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "../../../common";

export type SectionHeadingParams = {
   text: MaybeComputed<string>;
};

export class SectionHeading extends ViewModel {
   readonly text: SectionHeadingParams["text"];

   constructor({ text }: SectionHeadingParams) {
      super(template());
      this.text = text;
   }

   static factory(params: SectionHeadingParams): ComponentArgs<SectionHeadingParams> {
      return {
         name: "section-heading",
         params,
      };
   }
}

ko.components.register("section-heading", {
   viewModel: SectionHeading,
   template: template(),
   synchronous: true,
});
