import "./confirm-with-message-pane.styl"
import template from "./confirm-with-message-pane.pug"
import { defaultStore } from "@/stores/default-store"
import { DateUtils } from "@/lib/utils/date"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmWithMessagePane extends ModalPane
   constructor: (title) ->
      assertArgs(arguments, String)
      super("", "Apply", template())
      @title = ko.observable(title or "Confirm Action")
      @overflowable(true)
      @isActionEnabled = ko.pureComputed =>
         return (!@scheduleMessagesForLater() or
         (@scheduleMessagesForLater() and @scheduledMessageDate()? and @scheduledMessageTime()?))

      @saveMessagesAsDrafts = ko.observable(false)
      @scheduleMessagesForLater = ko.observable(false)
      @sendMessagesInstantly = ko.observable(false)

      @scheduledMessageDate = ko.observable(null)
      @scheduledMessageTime = ko.observable(null)
      @timeOptions = ko.observableArray(defaultStore.getTimeOptions(4.75))

      @saveMessagesAsDrafts.subscribe (newVal) =>
         if newVal
            @scheduleMessagesForLater(false)
            @sendMessagesInstantly(false)
            @scheduledMessageDate(null)
            @scheduledMessageTime(null)

      @scheduleMessagesForLater.subscribe (newVal) =>
         if newVal
            @saveMessagesAsDrafts(false)
            @sendMessagesInstantly(false)

      @sendMessagesInstantly.subscribe (newVal) =>
         if newVal
            @scheduleMessagesForLater(false)
            @saveMessagesAsDrafts(false)
            @scheduledMessageDate(null)
            @scheduledMessageTime(null)


   actionBtnIntercept: ->
      config = {}
      if @sendMessagesInstantly()
         config['context'] = 'open'
      else if @saveMessagesAsDrafts()
         config['context'] = 'drafts'
      else if @scheduleMessagesForLater() and @scheduledMessageDate()? and @scheduledMessageTime()?
         config['context'] = 'scheduled'
         config['scheduledDetachedDay'] = DateUtils.getDetachedDay(@scheduledMessageDate())
         config['scheduledTime'] = @scheduledMessageTime().value()
      @data.set('messageConfig', config)
      modalManager.modalFinished()
