import "./large-text-input.styl";
import template from "./large-text-input.pug";
import { ViewModel } from "../../../../vm/viewmodel";
import type { MaybeObservable } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "../../../common";
import type { CanRequestSize } from "../sized-modal";

export type LargeTextInputParams = {
   value: MaybeObservable<string>;
   description?: string;
   placeholder?: string;
   title?: string;
} & CanRequestSize;

export class LargeTextInput extends ViewModel {
   readonly description: string | null;
   readonly placeholder: string | null;
   readonly title: string | null;
   readonly value: MaybeObservable<string>;

   constructor({ placeholder, value, title, description, requestSize }: LargeTextInputParams) {
      super(template());
      this.description = description ?? null;
      this.placeholder = placeholder ?? null;
      this.title = title ?? null;
      this.value = value;
      requestSize({
         height: 500,
         width: 500,
      });
   }

   static factory(params: LargeTextInputParams): ComponentArgs<LargeTextInputParams> {
      return {
         name: "large-text-input",
         params,
      };
   }
}

ko.components.register("large-text-input", {
   viewModel: LargeTextInput,
   template: template(),
   synchronous: true,
});
