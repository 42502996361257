import type { MaybeObservable, Observable } from "knockout";
import ko, { isObservable, observable, pureComputed, unwrap } from "knockout";
import { Color } from "@/lib/utils/color";
import template from "./labeled-checkbox.pug";
import "./labeled-checkbox.styl";
import type { ComponentArgs } from "@/lib/components/common";

export type LabeledCheckboxParams = {
   value: MaybeObservable<boolean | null>;
   component?: ComponentArgs<unknown>;
   title?: MaybeObservable<string | null>;
   color?: MaybeObservable<string | null>;
   dotColor?: string | null;
   isFocusable?: MaybeObservable<boolean>;
   isDisabled?: MaybeObservable<boolean>;
   onChanged?: () => void;
};

export class LabeledCheckbox {
   readonly value: Observable<boolean | null>;
   readonly component: Exclude<LabeledCheckboxParams["component"], undefined> | null;
   readonly title: MaybeObservable<string | null>;
   readonly isFocusable: Observable<boolean>;
   readonly isDisabled: Observable<boolean>;
   readonly onChanged: (() => void) | null;
   readonly dotColor: string | null;
   readonly checkedColor = pureComputed(() => {
      // TODO: Handle custom colors for disabled state properly.
      return (
         unwrap(this.params.color || null) ||
         (this.isDisabled() ? Color.LC_ORANGE_DISABLED : Color.LC_ORANGE)
      );
   });
   readonly hasBlackCheck = pureComputed(() => {
      return !Color.colorIsDark(this.checkedColor());
   });
   readonly hasFocus = observable(false);
   readonly boxColor = pureComputed(() => {
      if (!unwrap(this.value)) return Color.LC_WHITE;
      return this.checkedColor();
   });
   readonly boxShadow = pureComputed(() => {
      if (this.isDisabled()) return "";
      if (this.hasFocus()) {
         const [r, b, g] = Color.getRgb(this.checkedColor());
         return `0 0 0 2px rgba(${r}, ${b}, ${g}, 0.24)`;
      } else {
         return "";
      }
   });

   constructor(private readonly params: LabeledCheckboxParams) {
      this.value = isObservable(params.value) ? params.value : observable<any>(params.value);
      this.component = params.component ?? null;
      this.title = params.title || null;
      this.dotColor = params.dotColor ?? null;
      this.isFocusable = isObservable(params.isFocusable)
         ? params.isFocusable
         : observable(params.isFocusable !== false);
      this.isDisabled = isObservable(params.isDisabled)
         ? params.isDisabled
         : observable<any>(params.isDisabled || false);
      this.onChanged = params.onChanged ?? null;
   }

   onClick = (): void => {
      if (this.isDisabled()) {
         return;
      }
      if (this.isFocusable() && isObservable(this.value)) {
         this.value(!unwrap(this.value()));
      }
      if (this.onChanged) {
         this.onChanged();
      }
   };

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      if (event.key == "Enter" || event.key == " ") {
         this.onClick();
         return false;
      }
      return true;
   };
}

ko.components.register("labeled-checkbox", {
   viewModel: LabeledCheckbox,
   template: template(),
   synchronous: true,
});
