import "./modal-header.styl";
import template from "./modal-header.pug";
import { ViewModel } from "../../../vm/viewmodel";
import type { MaybeObservable } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "../../common";
import type { CanRequestSize } from "./sized-modal";

export type ModalHeaderParams = {
   title: MaybeObservable<string>;
} & CanRequestSize;

export class ModalHeader extends ViewModel {
   readonly title: MaybeObservable<string>;

   readonly HEIGHT = 56;

   constructor({ title, requestSize }: ModalHeaderParams) {
      super(template());
      this.title = title;

      requestSize({
         width: null,
         height: this.HEIGHT,
      });
   }

   static factory(params: ModalHeaderParams): ComponentArgs<ModalHeaderParams> {
      return {
         name: "modal-header",
         params,
      };
   }
}

ko.components.register("modal-header", {
   viewModel: ModalHeader,
   template: template(),
   synchronous: true,
});
