import { Store } from "@/stores/store"

export class CloudinaryStore extends Store

   uploadPhoto: (fileBase64Url, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/cloudinary/upload"
         method: "POST"
         data: {file: fileBase64Url}
      }, (err, data) =>
         return callback(err) if err
         callback(null, data)


CloudinaryStore.Errors = {
   NOTHING_TO_UPDATE: "No data needed to be updated"
}

export cloudinaryStore = new CloudinaryStore()