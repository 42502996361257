import "./modal-footer.styl";
import template from "./modal-footer.pug";
import { ViewModel } from "../../../vm/viewmodel";
import type { PureComputed } from "knockout";
import ko from "knockout";
import type { ButtonDropDownParams } from "@/lib/components/button-drop-down/button-drop-down";
import type { ComponentArgs } from "../../common";
import type { CanRequestSize } from "./sized-modal";

export type ModalAction = {
   buttonComponent: ComponentArgs<ButtonDropDownParams>;
};

export type ModalFooterParams = {
   actions: PureComputed<ModalAction[]>;
} & CanRequestSize;

export class ModalFooter extends ViewModel {
   readonly actions: ModalFooterParams["actions"];

   readonly HEIGHT = 40;

   constructor({ actions, requestSize }: ModalFooterParams) {
      super(template());
      this.actions = actions;

      requestSize({
         width: null,
         height: 40,
      });
   }

   static factory(params: ModalFooterParams): ComponentArgs<ModalFooterParams> {
      return {
         name: "modal-footer",
         params,
      };
   }
}

ko.components.register("modal-footer", {
   viewModel: ModalFooter,
   template: template(),
   synchronous: true,
});
