import "./tabbed-pane.styl";
import template from "./tabbed-pane.pug";
import { ViewModel } from "../../../vm/viewmodel";
import type { Observable, PureComputed } from "knockout";
import ko, { observable, unwrap, pureComputed } from "knockout";
import type { ComponentArgs } from "../../common";
import type { OptionalSize, CanRequestSize } from "./sized-modal";

export type ModalTab = {
   tabName: string;
   component: ComponentArgs<CanRequestSize>;
   hasChanges: PureComputed<boolean>;
};

export type TabbedPaneParams = {
   tabs: ModalTab[];
   initialTabIndex: number;
} & CanRequestSize;

class TabbedPaneState {
   readonly TAB_PANE_WIDTH = 220;
   private readonly internalState: {
      activeTabIndex: Observable<number>;
      maxRequestedSize: Observable<OptionalSize>;
      tabs: Observable<ModalTab[]>;
   };

   readonly activeTabIndex = pureComputed(() => unwrap(this.internalState.activeTabIndex));
   readonly currentRequestedSize = pureComputed(() => {
      const maxRequestedSize = unwrap(this.internalState.maxRequestedSize);
      return {
         height: maxRequestedSize.height,
         width: (maxRequestedSize.width ?? 0) + (this.hasExactlyOneTab() ? 0 : this.TAB_PANE_WIDTH),
      };
   });
   readonly tabs = pureComputed(() => {
      const tabs = unwrap(this.internalState.tabs);
      return tabs.map((tab) => ({
         ...tab,
         component: {
            ...tab.component,
            params: {
               ...tab.component.params,
               requestSize: this.requestNewMaximumSize,
            },
         },
      }));
   });
   readonly hasExactlyOneTab = pureComputed(() => this.tabs().length === 1);

   readonly activeTab = pureComputed(() => unwrap(this.tabs)[unwrap(this.activeTabIndex)]);

   constructor({ tabs, initialTabIndex }: { tabs: ModalTab[]; initialTabIndex: number }) {
      this.internalState = {
         activeTabIndex: observable(initialTabIndex),
         maxRequestedSize: observable<OptionalSize>({
            height: null,
            width: null,
         }),
         tabs: observable(tabs),
      };
   }

   readonly requestNewMaximumSize = (size: OptionalSize) => {
      const currentMaxSize = unwrap(this.internalState.maxRequestedSize);
      const height =
         (currentMaxSize.height ?? 0) < (size.height ?? 0) ? size.height : currentMaxSize.height;
      const width =
         (currentMaxSize.width ?? 0) < (size.width ?? 0) ? size.width : currentMaxSize.width;
      this.internalState.maxRequestedSize({ height: height ?? 0, width: width ?? 0 });
   };

   readonly selectTab = (tabIndex: number): void => {
      this.internalState.activeTabIndex(tabIndex);
   };
}

export class TabbedPane extends ViewModel {
   private readonly state: TabbedPaneState;

   constructor({ tabs, initialTabIndex, requestSize }: TabbedPaneParams) {
      super(template());
      this.state = new TabbedPaneState({
         tabs: unwrap(tabs),
         initialTabIndex,
      });

      this.state.currentRequestedSize.subscribe((size) => {
         requestSize(size);
      });
   }

   static factory(params: TabbedPaneParams): ComponentArgs<TabbedPaneParams> {
      return {
         name: "tabbed-pane",
         params,
      };
   }
}

ko.components.register("tabbed-pane", {
   viewModel: TabbedPane,
   template: template(),
   synchronous: true,
});
