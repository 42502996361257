import "./batch-delete.styl";
import template from "./batch-delete.pug";
import { components, unwrap } from "knockout";
import type { RowBase } from "../../grid/grid-store";
import { BatchDeleteConflictModalPane } from "./batch-delete-conflict-modal-pane";
import type { Conflict, BaseActionParams } from "../batch-actions";
import { BatchActionState } from "../batch-actions";

export type BatchDeleteValidator<T> = (records: T[]) => {
   conflicts: Array<Conflict<T>>;
   valid: T[];
};

export type DeleteActionParams<T extends RowBase> = {
   /**
    * A note to be displayed at the top of the batch-delete modal.
    */
   modalNote?: string;

   /**
    * Validator for the records selected to be batch-deleted. Validates and returns the
    * records, grouped by whether or not a conflict was detected.
    */
   validator: BatchDeleteValidator<T>;

   /**
    * The action to be performed when user clicks Delete.
    */
   action: (stagedForDeletion: T[]) => Promise<void>;
};

export type BatchActionDeleteParams<T extends RowBase> = BaseActionParams<T> &
   DeleteActionParams<T>;

export class BatchDelete<T extends RowBase> {
   constructor(private readonly params: BatchActionDeleteParams<T>) {
      // Whenever batch-delete becomes active, open the modal
      params.transitionController.state.subscribe((state) => {
         if (state === BatchActionState.DELETE) {
            params.transitionController.closePopup();
            this.showModal();
         }
      }, this);
   }

   private async showModal() {
      const { records, conflictModalColumns, modalNote, action, validator } = this.params;

      const validationResults = validator(unwrap(records));

      await BatchDeleteConflictModalPane.show<T>({
         valid: validationResults.valid,
         conflicts: validationResults.conflicts,
         columnGroups: unwrap(conflictModalColumns),
         modalNote: modalNote,
         saveProvider: async () => {
            await this.params.transitionController.disableUntil(action(validationResults.valid));
         },
      });
   }
}

components.register("action-batch-delete", {
   viewModel: BatchDelete,
   template: template(),
});
