import type { MaybeSubscribable, PureComputed } from "knockout";
import { pureComputed } from "knockout";
import ko from "knockout";
import template from "./field-editor-title.pug";
import "./field-editor-title.styl";

export type FieldEditorTitleParams = {
   title: MaybeSubscribable<string>;
   validationErrorMessage?: PureComputed<string | null>;
   backArrowCallback?: () => void;
};

export class FieldEditorTitle {
   readonly title: PureComputed<string>;
   readonly validationErrorMessage: PureComputed<string | null>;
   readonly backArrowCallback: (() => void) | null;

   constructor(params: FieldEditorTitleParams) {
      this.title = pureComputed(() => ko.unwrap(params.title));
      this.validationErrorMessage = pureComputed(() => {
         return params.validationErrorMessage != null ? params.validationErrorMessage() : null;
      });
      this.backArrowCallback = params.backArrowCallback ?? null;
   }

   readonly validationDisplayValue = pureComputed<"initial" | "none">(() => {
      return this.validationErrorMessage == null ? "none" : "initial";
   });
}

ko.components.register("field-editor-title", {
   viewModel: FieldEditorTitle,
   template: template(),
   synchronous: true,
});
