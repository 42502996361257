import "./time-picker-2.styl";
import type { Subscribable } from "knockout";
import ko, { pureComputed } from "knockout";
import template from "./time-picker-2.pug";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { ComponentArgs } from "@/lib/components/common";
import type { SegmentedController2Params } from "@/lib/components/segmented-controller-2/segmented-controller-2";

enum Meridian {
   AM = "am",
   PM = "pm",
}

type TimeOption = {
   id: string;
   value: number;
};

export type TimePicker2Params = {
   value: Subscribable<number | null>;
};

export class TimePicker2 {
   readonly cellFactory = TextCell.factory<TimeOption>((item) => item.id);
   readonly value: Subscribable<number | null>;

   private readonly meridianSegmentedControllerParams: SegmentedController2Params<Meridian>;

   readonly selectedHour = pureComputed<TimeOption | null>({
      read: () => {
         const value = this.value();
         if (value == null) return null;
         let hour = Math.floor(value > 12 ? value - 12 : value);
         if (hour === 0) {
            hour = 12;
         }
         return this.hourItems.find((item) => item.value == hour) ?? null;
      },
      write: (item) => {
         if (!item) return;
         let hour;
         if (this.selectedMeridian() == Meridian.PM && item.value < 12) {
            hour = item.value + 12;
         } else if (
            this.selectedMeridian() == Meridian.AM &&
            (item.value == 12 || item.value == 0)
         ) {
            hour = 0;
         } else {
            hour = item.value;
         }
         this.value(hour + (this.selectedMin()?.value ?? 0));
      },
   });

   readonly selectedMin = pureComputed<TimeOption | null>({
      read: () => {
         const value = this.value();
         if (value == null) return null;
         const min = value % 1;
         return this.minuteItems.find((item) => item.value == min) ?? null;
      },
      write: (item) => {
         if (!item) return;
         const min = item.value;
         let hour = this.selectedHour()?.value ?? 0;
         if (this.selectedMeridian() == Meridian.PM && hour < 12) {
            hour = hour + 12;
         } else if (this.selectedMeridian() == Meridian.AM && (hour == 12 || hour == 0)) {
            hour = 0;
         }
         this.value(min + hour);
      },
   });

   readonly selectedMeridian = pureComputed<Meridian>({
      read: () => {
         const value = this.value();
         return value >= 12 ? Meridian.PM : Meridian.AM;
      },
      write: (meridian) => {
         if (!meridian) return;
         const min = this.selectedMin()?.value ?? 0;
         let hour = this.selectedHour()?.value ?? 0;
         hour = meridian == Meridian.PM ? hour + 12 : hour;
         if (hour == 12 && meridian == Meridian.AM) {
            hour = 0;
         } else if (hour == 24 && meridian == Meridian.PM) {
            hour = 12;
         }
         this.value(hour + min);
      },
   });

   readonly hourItems: TimeOption[] = [
      { id: "1", value: 1 },
      { id: "2", value: 2 },
      { id: "3", value: 3 },
      { id: "4", value: 4 },
      { id: "5", value: 5 },
      { id: "6", value: 6 },
      { id: "7", value: 7 },
      { id: "8", value: 8 },
      { id: "9", value: 9 },
      { id: "10", value: 10 },
      { id: "11", value: 11 },
      { id: "12", value: 12 },
   ];
   readonly minuteItems: TimeOption[] = [
      { id: "00", value: 0 },
      { id: "15", value: 0.25 },
      { id: "30", value: 0.5 },
      { id: "45", value: 0.75 },
   ];

   readonly hourPane = new ArrayDropDownPane({
      items: this.hourItems,
   });
   readonly minutePane = new ArrayDropDownPane({
      items: this.minuteItems,
   });

   readonly isMeridianAM = pureComputed(() => this.selectedMeridian() === Meridian.AM);
   readonly isMeridianPM = pureComputed(() => this.selectedMeridian() === Meridian.PM);

   constructor({ value }: TimePicker2Params) {
      this.value = value;
      this.meridianSegmentedControllerParams = {
         onChange: (meridian) => this.selectedMeridian(meridian),
         options: [
            { name: "AM", value: Meridian.AM, isSelected: this.isMeridianAM },
            { name: "PM", value: Meridian.PM, isSelected: this.isMeridianPM },
         ],
         requestSize: () => {},
      };
   }

   static factory(params: TimePicker2Params): ComponentArgs<TimePicker2Params> {
      return {
         name: "time-picker-2",
         params,
      };
   }
}

ko.components.register("time-picker-2", {
   viewModel: TimePicker2,
   template: template(),
   synchronous: true,
});
