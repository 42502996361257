import { EventHandler } from "@/lib/utils/event-handler";
import type { Bounds, Point } from "@/lib/utils/geometry";
import { contains, findContainmentDelta } from "@/lib/utils/geometry";
import { createValidTransformedValueComputed } from "@/lib/utils/knockout";
import type { Subscription } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import type { ComponentArgs } from "../common";
import type { EditorComponentParams } from "../editors/common/editor-component";
import { GridActionResult, GridActionSource, GridCursorState } from "./grid-column";
import { GridContextMenuAction } from "@/lib/components/grid/grid-context-menu/grid-context-menu";
import type { GridLayout, RenderedCell } from "./grid-layout";
import type { RowBase } from "./grid-store";
import type { GridAction } from "@/lib/components/grid/virtual-grid/virtual-grid";
import { FocusMode, GridActionType } from "@/lib/components/grid/virtual-grid/virtual-grid";

export type GridEditorParams<TRow extends RowBase> = {
   layout: GridLayout<TRow>;
   focusMode: FocusMode;
   onGridAction: (action: GridAction<TRow>) => void;
};

export const enum SelectionState {
   NONE = "none",
   FOCUSED = "focused",
   CONTEXT_MENU = "context_menu",
   EDITOR = "editor",
}

export type CellSelection =
   | {
        state: SelectionState.NONE;
     }
   | {
        state: SelectionState.FOCUSED | SelectionState.CONTEXT_MENU | SelectionState.EDITOR;
        rowIndex: number;
        cellIndex: number;
        clickLocation?: Point | null;
     };

/** Class that manages the grid selection and editing experience. */
export class GridEditor<TRow extends RowBase> {
   /** Current cell selection. Do NOT update directly. */
   readonly cellSelection = observable<CellSelection>({ state: SelectionState.NONE });

   /** Bounds of the current cell selection or null. */
   readonly cellSelectionBounds = pureComputed<Bounds | null>(() => {
      const cell = this.getSelectedCell();
      if (!cell) return null;
      const bounds = this.layout.getInnerCellBounds(cell);
      return {
         ...bounds,
         left: bounds.left - cell.column.columnPadding / 2,
         width: bounds.width + cell.column.columnPadding,
      };
   });

   /**
    * Whether the currently selected cell is editable. Memorize the value when the cell is null
    * to avoid changing during the exit animation.
    */
   readonly isCellActionable = createValidTransformedValueComputed({
      value: this.cellSelection,
      transform: (selection) => {
         const cell =
            selection.state != SelectionState.NONE
               ? this.layout.getCell(selection.rowIndex, selection.cellIndex)
               : null;
         return cell ? this.getCellCursorState(cell) == GridCursorState.ACTIONABLE : null;
      },
      allowUpdateCheck: (value) => value !== null,
   });
   readonly isCellEditable = pureComputed(
      (() => {
         let lastValue = false;
         return () => {
            const cell = this.getSelectedCell();
            if (!cell) return lastValue;
            lastValue = Boolean(cell.column.column.editorFactory);
            return lastValue;
         };
      })(),
   );

   /** Bounds the context menu is anchored to or null. */
   readonly contextMenuAnchorBounds = observable<Bounds | null>();

   /** Bounds the editor is anchored to or null. */
   readonly editorAnchorBounds = observable<Bounds | null>();

   /** Currently visible editor. */
   readonly editorComponent = observable<ComponentArgs<EditorComponentParams<unknown>> | null>(
      null,
   );

   /** List of actions available to the current context menu. */
   readonly contextMenuActions = observableArray<GridContextMenuAction>([]);

   /** Tracks the timestamp of the last time the cell selection changed. Used to power click handling. */
   readonly lastCellSelectionChange = observable(Date.now());

   private readonly layout: GridLayout<TRow>;
   private readonly focusMode: FocusMode;
   private readonly onGridAction: (action: GridAction<TRow>) => void;
   private readonly subscriptions: Subscription[] = [];

   private hasScheduledScrollToSelection = false;

   constructor({ layout, focusMode, onGridAction }: GridEditorParams<TRow>) {
      this.layout = layout;
      this.focusMode = focusMode;
      this.onGridAction = onGridAction;
      this.subscriptions.push(this.cellSelection.subscribe(this.onCellSelectionChanged, this));

      // Subscribe to keydown events to move the selection.
      window.addEventListener("keydown", this.onWindowKeyDown);
   }

   /** Focuses the given cell if possible. */
   focusCell({
      rowIndex,
      cellIndex,
      clickLocation = null,
      immediatelyScroll = false,
   }: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
      immediatelyScroll?: boolean;
   }): void {
      let cell = this.layout.getCell(rowIndex, cellIndex);
      if (!cell) return;
      if (cell.isUnfocusable) {
         // Intelligently move to the next cell farthest from the current cell.
         const selection = this.cellSelection();
         const direction =
            selection.state != SelectionState.NONE
               ? rowIndex > selection.rowIndex
                  ? "down"
                  : "up"
               : "down";
         cell = this.findNextFocusable({ rowIndex, cellIndex, direction });
         if (!cell) return;
      }

      this.cellSelection({
         state: SelectionState.FOCUSED,
         rowIndex,
         cellIndex,
         clickLocation: this.offsetClickLocationByScrollDelta({
            rowIndex,
            cellIndex,
            clickLocation,
         }),
      });

      if (this.focusMode == FocusMode.CELL) {
         cell.hasFocus(true);
      }
      if (immediatelyScroll) {
         this.layout.scrollCellIntoView(cell);
      } else {
         this.scheduleScrollToSelection();
      }
   }

   /** Shows the context menu for the given cell if possible. */
   showContextMenu(selection: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
   }): void {
      const cell = this.layout.getCell(selection.rowIndex, selection.cellIndex);
      if (!cell) return;

      const actions = this.getContextMenuActions(cell);
      if (!actions.length) return;

      // Update the click location to account for the viewport being scrolled.
      selection.clickLocation = this.offsetClickLocationByScrollDelta(selection);

      // Update the position of the context menu.
      this.contextMenuActions(this.getContextMenuActions(cell));
      this.contextMenuAnchorBounds(this.calculateSelectionBounds(selection));
      this.cellSelection({ ...selection, state: SelectionState.CONTEXT_MENU });
      this.scheduleScrollToSelection();
   }

   /** Triggers the action for the selection if it exists.  */
   triggerAction({
      rowIndex,
      cellIndex,
      clickLocation,
      source,
   }: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
      source: GridActionSource;
   }): GridActionResult {
      const cell = this.layout.getCell(rowIndex, cellIndex);
      if (!cell) return GridActionResult.REMAIN_FOCUSED;

      const actionResult = this.getActionResult({ cell, source });
      if (actionResult == GridActionResult.SHOW_EDITOR) {
         this.showEditor({ rowIndex, cellIndex, clickLocation });
      }
      return actionResult;
   }

   /** Shows the editor for the given cell if possible. */
   showEditor(selection: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
   }): void {
      const cell = this.layout.getCell(selection.rowIndex, selection.cellIndex);
      if (!cell || !cell.column.column.editorFactory) return;

      // Update the click location to account for the viewport being scrolled.
      selection.clickLocation = this.offsetClickLocationByScrollDelta(selection);

      const editor = cell.column.column.editorFactory({
         row: cell.row.value,
         cursorState: this.getCellCursorState(cell),
      });
      if (editor == null) return;
      editor.params.onClose = () => {
         this.focusCell(selection);
      };
      this.editorComponent(editor);
      this.editorAnchorBounds(this.calculateSelectionBounds(selection));
      this.cellSelection({ ...selection, state: SelectionState.EDITOR });
      this.scheduleScrollToSelection();
   }

   /** Removes the current selection entirely. */
   clearSelection(): void {
      this.cellSelection({ state: SelectionState.NONE });
   }

   moveCellSelection({
      rowIndex,
      cellIndex,
   }: {
      rowIndex?: number | null;
      cellIndex?: number | null;
   }): void {
      const cellSelection = this.cellSelection();
      if (cellSelection && cellSelection.state != SelectionState.NONE) {
         this.cellSelection({
            ...cellSelection,
            rowIndex: rowIndex != null ? rowIndex : cellSelection.rowIndex,
            cellIndex: cellIndex != null ? cellIndex : cellSelection.cellIndex,
         });
      }
   }

   dispose(): void {
      window.removeEventListener("keydown", this.onWindowKeyDown);
   }

   private onWindowKeyDown = (event: KeyboardEvent) => {
      const selection = this.cellSelection();
      if (selection.state == SelectionState.NONE) return true;

      const state = selection.state;
      const rowIndex = selection.rowIndex;
      const cellIndex = selection.cellIndex;
      const cell = this.layout.getCell(rowIndex, cellIndex);
      const isHandled = new EventHandler([
         {
            visit: () =>
               [SelectionState.EDITOR, SelectionState.CONTEXT_MENU].includes(state) &&
               event.key == "Escape",
            accept: () => this.focusCell({ rowIndex, cellIndex }),
         },
         {
            visit: () => state == SelectionState.FOCUSED && event.key == "Escape",
            accept: () => {
               // Clear the grid selection and explicitly blur the element to move focus off the grid.
               this.clearSelection();
               // NOTE: Following behavior may become problematic in the future if the activeElement somehow
               // does not stay fixed on the previously focused cell.
               if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
            },
         },
         {
            visit: () =>
               state == SelectionState.FOCUSED &&
               event.key == "Enter" &&
               this.focusMode == FocusMode.CELL,
            accept: () => {
               this.triggerAction({ rowIndex, cellIndex, source: GridActionSource.ENTER_KEY }),
                  this.onGridAction({ type: GridActionType.CELL_ENTER, cell: cell! });
            },
         },
         {
            visit: () =>
               [SelectionState.FOCUSED, SelectionState.CONTEXT_MENU].includes(state) &&
               event.key == " ",
            accept: () => {
               // Intentionally do nothing to prevent the page down behavior.
            },
         },
         {
            visit: () =>
               ["ArrowUp", "ArrowRight", "ArrowDown", "ArrowLeft"].includes(event.key) &&
               !["INPUT", "TEXTAREA"].includes(document.activeElement?.tagName || ""),
            accept: () => {
               // For all states besides FOCUSED, handle the event to prevent the default
               // scroll behavior but don't actually move the cell selection.
               if (state != SelectionState.FOCUSED) return;
               let cell: RenderedCell<TRow> | null = null;
               switch (event.key) {
                  case "ArrowUp":
                     cell = this.findNextFocusable({ rowIndex, cellIndex, direction: "up" });
                     if (!cell) this.layout.scrollToEdge("top");
                     break;
                  case "ArrowRight":
                     cell = this.findNextFocusable({ rowIndex, cellIndex, direction: "right" });
                     break;
                  case "ArrowDown":
                     cell = this.findNextFocusable({ rowIndex, cellIndex, direction: "down" });
                     if (!cell) this.layout.scrollToEdge("bottom");
                     break;
                  case "ArrowLeft":
                     cell = this.findNextFocusable({ rowIndex, cellIndex, direction: "left" });
                     break;
               }
               if (cell) {
                  this.focusCell({
                     rowIndex: cell.row.index,
                     cellIndex: cell.index(),
                     immediatelyScroll: true,
                  });
               }
            },
         },
      ]).handle(event);
      return !isHandled;
   };

   private onCellSelectionChanged() {
      this.lastCellSelectionChange(Date.now());
   }

   private calculateSelectionBounds(selection: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
   }) {
      return selection.clickLocation
         ? {
              top: selection.clickLocation.top - 10,
              left: selection.clickLocation.left - 10,
              width: 20,
              height: 20,
           }
         : this.getCellSelectionBounds(selection);
   }

   private getCellSelectionBounds(selection: { rowIndex: number; cellIndex: number }) {
      return this.layout.getInnerCellBounds(
         this.layout.getCell(selection.rowIndex, selection.cellIndex)!,
      );
   }

   private offsetClickLocationByScrollDelta(selection: {
      rowIndex: number;
      cellIndex: number;
      clickLocation?: Point | null;
   }) {
      if (!selection.clickLocation) return null;
      const cell = this.layout.getCell(selection.rowIndex, selection.cellIndex);
      if (!cell) return null;
      const bounds = this.layout.getOuterCellBounds(cell);
      const viewportBounds = this.layout.rowsViewportBounds();
      if (contains(viewportBounds, bounds)) {
         return selection.clickLocation;
      }
      const delta = findContainmentDelta(viewportBounds, bounds);
      return {
         top: selection.clickLocation.top + delta.top,
         left: selection.clickLocation.left + delta.left,
      };
   }

   private scheduleScrollToSelection() {
      if (this.hasScheduledScrollToSelection) return;
      this.hasScheduledScrollToSelection = true;
      requestAnimationFrame(() => {
         const selection = this.cellSelection();
         if (selection.state != SelectionState.NONE) {
            const cell = this.layout.getCell(selection.rowIndex, selection.cellIndex);
            if (cell) {
               this.layout.scrollCellIntoView(cell);
            }
         }
         this.hasScheduledScrollToSelection = false;
      });
   }

   private getContextMenuActions(cell: RenderedCell<TRow>) {
      const actions = [];
      if (this.getCellCursorState(cell) == GridCursorState.ACTIONABLE) {
         actions.push(GridContextMenuAction.EDIT);
      }
      if (cell.column.column.urlProvider) {
         actions.push(GridContextMenuAction.OPEN_IN_NEW_TAB);
      }
      if (cell.column.column.copyProvider) {
         actions.push(GridContextMenuAction.COPY);
      }
      return actions;
   }

   private getSelectedCell() {
      const selection = this.cellSelection();
      return selection.state != SelectionState.NONE
         ? this.layout.getCell(selection.rowIndex, selection.cellIndex)
         : null;
   }

   private getCellCursorState(cell: RenderedCell<TRow>) {
      if (cell.column.column.cursorStateProvider) {
         return cell.column.column.cursorStateProvider(cell.row.value);
      }
      return cell.column.column.editorFactory
         ? GridCursorState.ACTIONABLE
         : GridCursorState.NON_ACTIONABLE;
   }

   private getActionResult({
      cell,
      source,
   }: {
      cell: RenderedCell<TRow>;
      source: GridActionSource;
   }) {
      const hasEditor = Boolean(cell.column.column.editorFactory);
      const cursorState = this.getCellCursorState(cell);

      // When a cell has an action provider, use it's result directly.
      if (cell.column.column.actionProvider) {
         const result = cell.column.column.actionProvider({
            row: cell.row.value,
            source,
            cursorState,
         });
         return !hasEditor && result == GridActionResult.SHOW_EDITOR
            ? GridActionResult.REMAIN_FOCUSED
            : GridActionResult.SHOW_EDITOR;
      }

      // When the cell does not have an editor, default to `REMAIN_FOCUSED`.
      if (!hasEditor) {
         return GridActionResult.REMAIN_FOCUSED;
      }

      // When a cell does not have an action provider, then we ignore single click behavior
      // specifically to prevent showing the cell editor.
      if ([GridActionSource.UNFOCUSED_CLICK].includes(source)) {
         return GridActionResult.REMAIN_FOCUSED;
      }

      // When a cell does not have an action provider, use the cursor and editor state to
      // determine what should happen.
      return cursorState == GridCursorState.NON_ACTIONABLE
         ? GridActionResult.REMAIN_FOCUSED
         : GridActionResult.SHOW_EDITOR;
   }

   private findNextFocusable({
      rowIndex,
      cellIndex,
      direction,
   }: {
      rowIndex: number;
      cellIndex: number;
      direction: "up" | "down" | "left" | "right";
   }) {
      let currentRow =
         direction == "up" ? rowIndex - 1 : direction == "down" ? rowIndex + 1 : rowIndex;
      let currentCell =
         direction == "left" ? cellIndex - 1 : direction == "right" ? cellIndex + 1 : cellIndex;
      while (true) {
         const cell = this.layout.getCell(currentRow, currentCell);
         if (!cell) {
            return null;
         }
         if (!cell.isUnfocusable) {
            return cell;
         }
         currentRow =
            direction == "up" ? currentRow - 1 : direction == "down" ? currentRow + 1 : currentRow;
         currentCell =
            direction == "left"
               ? currentCell - 1
               : direction == "right"
               ? currentCell + 1
               : currentCell;
      }
   }
}
