import "./weekday-selector.styl"
import weekdaySelectorTemplateFn from "./weekday-selector.pug"
import ko from "knockout"

export class WeekdaySelector
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.weekdayIndicies, Function)

      @weekdayIndicies = params.weekdayIndicies

      @sunday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(0) != -1
      @monday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(1) != -1
      @tuesday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(2) != -1
      @wednesday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(3) != -1
      @thursday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(4) != -1
      @friday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(5) != -1
      @saturday = ko.pureComputed =>
         return @weekdayIndicies().indexOf(6) != -1

   toggleDay: (dayIndex) ->
      return @weekdayIndicies.remove(dayIndex) if @weekdayIndicies().indexOf(dayIndex) != -1
      @weekdayIndicies.push(dayIndex)
   
weekdaySelectorTemplate = weekdaySelectorTemplateFn()

ko.components.register("weekday-selector",
   viewModel: WeekdaySelector,
   template: weekdaySelectorTemplate,
)
