import "./currency-input.styl"
import currencyInputTemplateFn from "./currency-input.pug"
import ko from "knockout"

export class CurrencyInput
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.value, Function)
      assertOfType(params.value(), optional(Number))
      assertOfType(params.perTitle, optional(String))
      assertOfType(params.blockTab, optional([Boolean, Function]))
      assertOfType(params.idToTabFocus, optional(String))

      @value = params.value
      @value.subscribe (newVal) =>
         return @value(0) if isNaN(Number(newVal))
         return newVal

      @perTitle = if params.perTitle? then ko.observable(params.perTitle) else null
      if params.blockTab instanceof Function
         @blockTab = params.blockTab
      else
         @blockTab = ko.observable(params.blockTab or false)

      @idToTabFocus = ko.observable(params.idToTabFocus or false)


currencyInputTemplate = currencyInputTemplateFn()

ko.components.register("currency-input",
   viewModel: CurrencyInput,
   template: currencyInputTemplate,
)