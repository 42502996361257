import "./date-pager.styl"
import datePagerTemplateFn from "./date-pager.pug"
import { defaultStore } from "@/stores/default-store"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"

{ YearFormat, getShortNumericDate } = DateUtils

export class DatePager
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.selectedDate(), Date)

      @selectedDate = params.selectedDate

      @displayString = ko.pureComputed =>
         format = ko.unwrap(defaultStore.getDateFormat())
         return getShortNumericDate(@selectedDate(), format, {
            yearFormat: YearFormat.FULL
         })

      if params.leftCallback
         assertOfType(params.leftCallback, Function)
         @leftCallback = params.leftCallback
      else
         @leftCallback = null

      if params.rightCallback
         assertOfType(params.rightCallback, Function)
         @rightCallback = params.rightCallback
      else
         @rightCallback = null

   pageLeft: ->
      @selectedDate(new Date(@selectedDate().setDate(@selectedDate().getDate() - 1)))
      @leftCallback(@selectedDate()) if @leftCallback?

   pageRight: ->
      @selectedDate(new Date(@selectedDate().setDate(@selectedDate().getDate() + 1)))
      @rightCallback(@selectedDate()) if @rightCallback?


datePagerTemplate = datePagerTemplateFn()

ko.components.register("date-pager",
   viewModel: DatePager,
   template: datePagerTemplate
)
