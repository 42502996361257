import "./button-confirm.styl";
import template from "./button-confirm.pug";
import type { MaybeObservable, MaybeComputed } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import { ButtonSize } from "@/lib/utils/buttons";
import type { ComponentArgs } from "@/lib/components/common/component-args";

// TODO: Add support for large buttons.
export type ButtonConfirmSize = Extract<ButtonSize, ButtonSize.NORMAL | ButtonSize.SMALL>;

export interface ButtonConfirmParams {
   text: MaybeObservable<string>;
   confirm: MaybeObservable<string> | MaybeComputed<string>;
   save: MaybeObservable<string>;
   onConfirming: () => void;
   onCancel: () => void;
   onClick: () => Promise<unknown> | void;
   size?: MaybeObservable<ButtonConfirmSize>;
}

const CONFIRM_DELAY = 300;
const CONFIRM_TIMEOUT = 5000;

enum State {
   ENABLED = 0,
   CONFIRM = 1,
   SAVE = 2,
}

export class ButtonConfirm {
   readonly state = observable(State.ENABLED);

   readonly classes = pureComputed(() => {
      // TODO: Add in color styling as well.
      const size = unwrap(this.params.size || ButtonSize.NORMAL);
      return `button-confirm__content--${size}`;
   });

   private timerId: unknown | null;
   private confirmShownTimestamp = 0;

   constructor(readonly params: ButtonConfirmParams) {}

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      if (event.key == "Enter" || event.key == " ") {
         this.onClick();
         return false;
      }
      return true;
   };

   onClick = async (): Promise<void> => {
      if (this.state() == State.SAVE) return;
      if (this.state() == State.CONFIRM) {
         // Add in a delay to avoid double clicks confirming without
         // the user intending it to.
         if (Date.now() - this.confirmShownTimestamp < CONFIRM_DELAY) {
            return;
         }
         try {
            this.state(State.SAVE);
            await this.params.onClick();
         } finally {
            this.state(State.ENABLED);
            clearTimeout(this.timerId as any);
            this.timerId = null;
         }
      } else if (this.state() == State.ENABLED) {
         this.state(State.CONFIRM);
         this.confirmShownTimestamp = Date.now();
         this.timerId = setTimeout(() => {
            this.state(State.ENABLED);
            this.timerId = null;
            if (this.params.onCancel) this.params.onCancel();
         }, CONFIRM_TIMEOUT);
         if (this.params.onConfirming) this.params.onConfirming();
      }
   };

   static factory(params: ButtonConfirmParams): ComponentArgs<ButtonConfirmParams> {
      return {
         name: "button-confirm",
         params,
      };
   }
}

ko.components.register("button-confirm", {
   viewModel: ButtonConfirm,
   template: template(),
   synchronous: true,
});
