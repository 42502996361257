import "./scrollbar.styl"
import scrollbarTemplateFn from "./scrollbar.pug"
import { Guid as GUID } from "@/lib/utils/guid"
import ko from "knockout"
import $ from "jquery"

export class Scrollbar
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.classToScroll, String)
      assertOfType(params.direction, optional(String))
      assertOfType(params.contentClass, optional(String))
      assertOfType(params.topBuffer, optional(Number))
      assertOfType(params.bottomBuffer, optional(Number))
      assertOfType(params.hide, optional(Function))
      assertOfType(params.hasBackground, optional(Boolean))

      @classToScroll = params.classToScroll
      @direction = params.direction or Scrollbar.Direction.Vertical
      @isVertical = ko.pureComputed =>
         return @direction == Scrollbar.Direction.Vertical
      @contentClass = params.contentClass or null
      @railId = ko.observable(GUID())
      @handleId = ko.observable(GUID())
      @$elementToScroll = $(".#{@classToScroll}")
      @$contentElement = if @contentClass? then $(".#{@contentClass}") else null
      @topBuffer = if params.topBuffer? then params.topBuffer else 10
      @bottomBuffer = if params.bottomBuffer? then params.bottomBuffer else 10
      @isDragging = ko.observable(false)
      @hide = if params.hide? then params.hide else ko.observable(false)
      @noScrollingNeeded = ko.observable(false)
      @hasBackground = ko.observable(if params.hasBackground? then params.hasBackground else false)

      @checkSize()

      $(window).on("resize", @checkSize)
      @$elementToScroll.scroll(@updateHandlePosition)

      if params.mediator
         params.mediator.initialize(@)

      if ko.isObservable(params.trigger)
         @triggerSubscription = params.trigger.subscribe () =>
            requestAnimationFrame(@checkSize)

   updateHandlePosition: =>
      return if @isDragging() or @hide() or @noScrollingNeeded()
      $handle = $("##{@handleId()}")
      return unless $handle?[0]?
      if @direction == Scrollbar.Direction.Vertical
         scrollTop = @$elementToScroll.scrollTop()
         scrollHeight = @$elementToScroll[0].scrollHeight
         scrollbarHeight = $("##{@railId()}").outerHeight() - $handle.outerHeight()
         scrollPercentage = (scrollTop / (scrollHeight - @$elementToScroll.outerHeight()))
         handlePosition = Math.round((scrollbarHeight * scrollPercentage) + @topBuffer)
         if handlePosition < @topBuffer
            handlePosition = @topBuffer
         else if handlePosition > scrollbarHeight
            handlePosition = (scrollbarHeight + @topBuffer)
         $handle[0].style['top'] = "#{handlePosition}px"
      else if @direction == Scrollbar.Direction.Horizontal
         scrollLeft = @$elementToScroll.scrollLeft()
         scrollWidth = @$elementToScroll[0].scrollWidth
         scrollbarWidth = $("##{@railId()}").outerWidth() - $handle.outerWidth()
         scrollPercentage = (scrollLeft / (scrollWidth - @$elementToScroll.outerWidth()))
         handlePosition = Math.round((scrollbarWidth * scrollPercentage) + @topBuffer)
         if (handlePosition < @topBuffer)
            handlePosition = @topBuffer
         else if handlePosition > scrollbarWidth
            handlePosition = (scrollbarWidth + @topBuffer)
         $handle[0].style['left'] = "#{handlePosition}px"

   updateScrollPosition: (scrollPercent) =>
      if @direction == Scrollbar.Direction.Vertical
         elementHeight = @$elementToScroll.outerHeight()
         elementScrollHeight = @$elementToScroll[0].scrollHeight
         hiddenHeight = elementScrollHeight - elementHeight
         @$elementToScroll.scrollTop(hiddenHeight * (scrollPercent / 100))
      else if @direction == Scrollbar.Direction.Horizontal
         elementWidth = @$elementToScroll.outerWidth()
         elementScrollWidth = @$elementToScroll[0].scrollWidth
         hiddenWidth = elementScrollWidth - elementWidth
         @$elementToScroll.scrollLeft(hiddenWidth * (scrollPercent / 100))

   checkSize: =>
      if @$contentElement?
         if @direction == Scrollbar.Direction.Vertical
            parentHeight = @$contentElement.parent().outerHeight()
            scrollHeight = @$contentElement[0].scrollHeight
            val = scrollHeight <= parentHeight
            @noScrollingNeeded(val)
            @updateHandlePosition() unless val
         else if @direction == Scrollbar.Direction.Horizontal
            parentWidth = @$contentElement.parent().outerWidth()
            scrollWidth = @$contentElement[0].scrollWidth
            val = scrollWidth <= parentWidth
            @noScrollingNeeded(val)
            @updateHandlePosition() unless val
      else
         if @direction == Scrollbar.Direction.Vertical
            parentHeight = @$elementToScroll.outerHeight()
            scrollHeight = @$elementToScroll[0].scrollHeight
            val = scrollHeight <= parentHeight
            @noScrollingNeeded(val)
            @updateHandlePosition() unless val
         else if @direction == Scrollbar.Direction.Horizontal
            parentWidth = @$elementToScroll.outerWidth()
            scrollWidth = @$elementToScroll[0].scrollWidth
            val = scrollWidth <= parentWidth
            @noScrollingNeeded(val)
            @updateHandlePosition() unless val

   checkContentSize: =>
      setTimeout(@checkSize, 0)

   dispose: ->
      $(window).off("resize", @onWindowResize)
      if @triggerSubscription
         @triggerSubscription.dispose()


Scrollbar.Direction = {
   Horizontal: "horizontal"
   Vertical: "vertical"
}

scrollbarTemplate = scrollbarTemplateFn()

ko.components.register("scrollbar",
   viewModel: Scrollbar,
   template: scrollbarTemplate,
)