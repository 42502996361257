var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-information"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-information\" width=\"14px\" height=\"14px\" viewbox=\"0 0 14 14\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-information\"\u003E\u003Crect class=\"icon__bg\" id=\"icon-information__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"14\" height=\"14\"\u003E\u003C\u002Frect\u003E\u003Cpath class=\"icon__shape\" id=\"icon-information__shape\" d=\"M6.3,10.5 L7.7,10.5 L7.7,6.3 L6.3,6.3 L6.3,10.5 Z M7,0 C3.136,0 0,3.136 0,7 C0,10.864 3.136,14 7,14 C10.864,14 14,10.864 14,7 C14,3.136 10.864,0 7,0 Z M7,13.3 C3.527125,13.3 0.7,10.472875 0.7,7 C0.7,3.527125 3.527125,0.7 7,0.7 C10.472875,0.7 13.3,3.527125 13.3,7 C13.3,10.472875 10.472875,13.3 7,13.3 Z M6.3,4.9 L7.7,4.9 L7.7,3.5 L6.3,3.5 L6.3,4.9 Z\" fill=\"#000000\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"person-type-heading-cell__container\" data-bind=\"style: { width: width + 'px' }\"\u003E\u003Cdiv class=\"person-type-heading-cell__icon\" data-bind=\"popup: personTypePopup,             clickBubble: false\"\u003E";
pug_mixins["icon-information"].call({
block: function(){
pug_html = pug_html + " ";
}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;