import "./timezone-pane.styl"
import template from "./timezone-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"

export class TimezonePane extends PopupPane
   constructor: (selectedTimezone) ->
      assertArgs(arguments, Function)
      assertOfType(selectedTimezone, optional(Object))
      super(template())
      @title = ko.observable()
      @leftActionBtn = ko.observable({
         text: "Cancel"
         callback: @cancelPopup
      })
      @rightActionBtn = ko.observable({
         text: "Select"
         callback: @saveTimezone
      })

      # Popup Properties
      @zoneName = ko.observable()
      @selectedTimezone = selectedTimezone
      @zoneName(@selectedTimezone()) if @selectedTimezone()?

   handleSelection: (name, offset, abbreviation) =>
      @zoneName(name)

   cancelPopup: ->
      @dismissHandler()

   saveTimezone: =>
      @selectedTimezone(@zoneName())
      @dismissHandler()
