import { authManager } from "@/lib/managers/auth-manager"
import { Store } from "@/stores/store"
import { requestContext } from "@/stores/common/request-context";
import $ from "jquery"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### Modals ###
import { Attachment } from "@/models/attachment"

export class AttachmentStore extends Store
   constructor: ->
      super()
      @vault = {}

   createAttachment: (data, callback) ->
      assertArgs(arguments, Object, Function)
      assertOfType(data.name, String)
      assertOfType(data.mimetype, nullable(String))
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/attachments"
         method: "POST"
         data: data
      }, (err, data) =>
         return callback(err) if err
         callback(null, new Attachment(data))

   deleteAttachment: (attachmentId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/attachments/#{attachmentId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   uploadFile: (fileId, file, callback) ->
      assertArgs(arguments, String, Object, Function)
      formData = new FormData()
      formData.append("upload", file)
      headers = if LaunchDarklyBrowser.getFlagValue('use-jwt-auth')
         { 'Authorization': "Bearer #{localStorage.getItem('wfpAccessToken')}" }
      else
         {}
      $.ajax
         url: requestContext.baseUrl + "/api/companies/#{authManager.companyId()}/upload/#{fileId}"
         method: "POST"
         dataType: "json"
         headers: headers
         crossDomain: true
         xhrFields: {
            withCredentials: true
         }
         cache: false
         data: formData
         contentType: false
         processData: false
         success: (data) ->
            callback(null, data)
         error: (error) ->
            if error.status == 401
               # Attempt to start a new session and restart the request.
               authManager.attemptToRenewSession ->
                  @makeRequest({
                     url: "/api/companies/#{authManager.companyId()}/upload/#{fileId}"
                     method: "POST"
                     data: formData
                  }, callback)
            else
               callback(error, null)


AttachmentStore.ValutKey = {}

export attachmentStore = new  AttachmentStore()
