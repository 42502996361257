import "./segmented-controller-2.styl";
import template from "./segmented-controller-2.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { MaybeComputed, PureComputed } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "@/lib/components/modals/editors/common/editor-element-template";

export type SegmentedController2Option<TValue> = {
   name: MaybeComputed<string>;
   value: TValue;
   isSelected: PureComputed<boolean>;
};

export type SegmentedController2Params<TValue> = {
   onChange: (change: TValue) => void;
   options: MaybeComputed<Array<SegmentedController2Option<TValue>>>;
} & EditorElementParams<TValue>;

export class SegmentedController2<TValue> extends ViewModel {
   readonly onChange: SegmentedController2Params<TValue>["onChange"];
   readonly options: SegmentedController2Params<TValue>["options"];

   readonly selectOption = (value: TValue): void => {
      this.onChange(value);
   };

   constructor({ onChange, options }: SegmentedController2Params<TValue>) {
      super(template());
      this.onChange = onChange;
      this.options = options;
   }

   static factory<TValue>(
      params: SegmentedController2Params<TValue>,
   ): ComponentArgs<SegmentedController2Params<TValue>> {
      return {
         name: "segmented-controller-2",
         params,
      };
   }
}

ko.components.register("segmented-controller-2", {
   viewModel: SegmentedController2,
   template: template(),
   synchronous: true,
});
