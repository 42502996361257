import "./batch-delete-conflict-modal-pane.styl";
import template from "./batch-delete-conflict-modal-pane.pug";
import { ModalPane } from "../../modals/modal-pane";
import type { RowBase } from "../../grid/grid-store";
import type { GridColumnGroup } from "../../grid/grid-column-group";
import { transformColumnGroups } from "../../grid/grid-column-group";
import { ArrayGridStore } from "../../grid/array-grid-store";
import type { Observable, PureComputed } from "knockout";
import { pureComputed } from "knockout";
import { observable } from "knockout";
import { ParagraphCell } from "../../grid/cells/paragraph-cell";
import { Modal } from "../../modals/modal";
import type { ModalExitStatus } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager";
import type { Conflict, ConflictRow } from "../batch-actions";
import type {
   SegmentedController2Option,
   SegmentedController2Params,
} from "../../segmented-controller-2/segmented-controller-2";

enum BatchDeleteModalSegment {
   CONFLICTS = "conflicts",
   VALID = "valid",
}

export type BatchDeleteConflictModalPaneParams<TRow extends RowBase> = {
   valid: TRow[];
   conflicts: Array<Conflict<TRow>>;
   columnGroups: Array<GridColumnGroup<TRow>>;
   modalNote?: string;
   saveProvider: () => Promise<void>;
};

export class BatchDeleteConflictModalPane<TRow extends RowBase> extends ModalPane {
   readonly selectedSegment: Observable<BatchDeleteModalSegment>;
   readonly segmentedControllerParams: SegmentedController2Params<BatchDeleteModalSegment>;
   readonly conflictsColumnGroups: Array<GridColumnGroup<ConflictRow<TRow>>>;
   readonly conflictsGridStore: ArrayGridStore<ConflictRow<TRow>>;
   readonly conflictCount: number;
   readonly validCount: number;

   readonly validColumnGroups: Array<GridColumnGroup<TRow>>;
   readonly validGridStore: ArrayGridStore<TRow>;
   readonly modalNote?: string;
   readonly deleteCountMessage: PureComputed<string>;
   private readonly saveProvider: () => Promise<void>;

   constructor({
      valid,
      conflicts,
      columnGroups,
      modalNote,
      saveProvider,
   }: BatchDeleteConflictModalPaneParams<TRow>) {
      super("Batch Delete", null, template());
      this.saveProvider = saveProvider;
      this.validColumnGroups = columnGroups;
      this.validGridStore = new ArrayGridStore(valid);
      this.modalNote = modalNote;

      this.conflictsColumnGroups = this.transformColumnGroups(columnGroups);
      this.conflictsGridStore = new ArrayGridStore(
         conflicts.map((conflict) => ({
            ...conflict,
            id: conflict.record.id,
         })),
      );

      this.conflictCount = this.conflictsGridStore.rows().length;
      this.validCount = this.validGridStore.rows().length;

      const conflictSegment: SegmentedController2Option<BatchDeleteModalSegment> = {
         name: `Conflicts (${this.conflictCount})`,
         value: BatchDeleteModalSegment.CONFLICTS,
         isSelected: pureComputed(
            () => this.selectedSegment() === BatchDeleteModalSegment.CONFLICTS,
         ),
      };
      const validSegment: SegmentedController2Option<BatchDeleteModalSegment> = {
         name: `Valid (${this.validCount})`,
         value: BatchDeleteModalSegment.VALID,
         isSelected: pureComputed(() => this.selectedSegment() === BatchDeleteModalSegment.VALID),
      };

      this.segmentedControllerParams = {
         onChange: (segment) => this.selectedSegment(segment),
         options: [conflictSegment, validSegment],
         requestSize: () => {},
      };

      this.selectedSegment = observable(
         this.conflictCount === 0 ? validSegment.value : conflictSegment.value,
      );

      this.deleteCountMessage = pureComputed(() => {
         if (this.validCount === 0) {
            return "Batch delete can not be applied to any of the selected rows.";
         } else if (this.conflictCount > 0) {
            return `You are about to delete ${this.validCount} of ${
               this.conflictCount + this.validCount
            } selected records.`;
         } else if (this.validCount === 1) {
            return "You are about to delete the selected record.";
         } else {
            return `You are about to delete the ${this.validCount} selected records.`;
         }
      });

      if (this.validCount > 0) {
         this.setReadyToSave();
      }
   }

   async actionBtnIntercept(done: () => void): Promise<void> {
      this.saveProvider();
      done();
   }

   private transformColumnGroups(columnGroups: Array<GridColumnGroup<TRow>>) {
      return transformColumnGroups({
         columnGroups,
         transform: (row: ConflictRow<TRow>) => row.record,
      }).concat([
         {
            columns: [
               {
                  key: "reason",
                  header: "Conflict",
                  width: 100,
                  cellFactory: ParagraphCell.factory((row) => ({
                     text: row.reason,
                     className: "batch-delete-conflict-modal__conflict-cell",
                  })),
                  autoResizable: true,
               },
            ],
         },
      ]);
   }

   private setReadyToSave() {
      this.setActionText("Delete");
      this.isActionEnabled(true);
   }

   static show<TRow extends RowBase>(
      params: BatchDeleteConflictModalPaneParams<TRow>,
   ): Promise<ModalExitStatus> {
      return new Promise((resolve) => {
         const pane = new BatchDeleteConflictModalPane<TRow>(params);
         const modal = new Modal();
         modal.setPanes([pane]);
         modalManager.showModal(
            modal,
            null,
            { class: "batch-delete-conflict-modal-pane" },
            (modal, status) => {
               resolve(status);
            },
         );
      });
   }
}
