import "./list-view-search-info-pane.styl";
import template from "./list-view-search-info-pane.pug";
import { PopupPane } from "@/lib/components/popup/popup-pane";
import type { PureComputed } from "knockout";
import { pureComputed } from "knockout";

export class ListViewSearchInfoPane extends PopupPane {
   readonly searchInformationText: PureComputed<string>;
   constructor(searchFields: string[]) {
      super(template());

      this.searchInformationText = pureComputed(() => {
         return [searchFields.slice(0, -1).join(", "), searchFields.slice(-1)[0]].join(
            searchFields.length < 2 ? "" : " and ",
         );
      });
   }
}
