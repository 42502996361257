import "./date-selector.styl"
import dateSelectorTemplateFn from "./date-selector.pug"
import { Popup } from "@/lib/components/popup/popup"
import { CalendarPane } from "@/lib/components/popup/calendar-pane"
import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"
import ko from "knockout"

export class DateSelector
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.actionText, optional([Function, String]))
      assertOfType(params.selectedDate, Function)

      @selectedDate = params.selectedDate
      if params.actionText instanceof Function
         @actionText = params.actionText
      else if params.actionText?
         @actionText = ko.observable(params.actionText)
      else
         @actionText = ko.observable()

      if params.clearable
         if params.clearable instanceof Function
            assertOfType(params.clearable(), Boolean)
            @clearable = params.clearable
         else
            @clearable = ko.observable(params.clearable)
      else
         @clearable = null

      @displayShortDate = params.displayShortDate or false

      @selectedDateString = ko.pureComputed =>
         return "Select Date" unless @selectedDate()?
         date = ko.unwrap(@selectedDate)
         if @displayShortDate
            return DateUtils.formatDate(date, defaultStore.getDateFormat(), {
               yearFormat: DateUtils.YearFormat.SHORT
               monthFormat: DateUtils.MonthFormat.ONE_DIGIT
               dayFormat: DateUtils.DayFormat.ONE_DIGIT
               weekdayFormat: DateUtils.WeekDayFormat.NONE
            })
         else
            return DateUtils.formatDate(date, defaultStore.getDateFormat(), {
               yearFormat: DateUtils.YearFormat.FULL
               monthFormat: DateUtils.MonthFormat.ABBREV
               dayFormat: DateUtils.DayFormat.ONE_DIGIT
               weekdayFormat: DateUtils.WeekDayFormat.ABBREV
            })

      @calendarFrameLocation = if params.calendarFrameLocation? then params.calendarFrameLocation else Popup.FrameType.BELOW
      @calendarArrowLocation = if params.calendarArrowLocation? then params.calendarArrowLocation else Popup.ArrowLocation.TOP_RIGHT

      @disabled = params.disabled or ko.observable(false)

      @calendarPopupBuilder = =>
         return if @disabled()
         return new Popup("Select Date", @calendarFrameLocation, @calendarArrowLocation,
            [new CalendarPane(@selectedDate)], ['date-selector__btn', 'icon-calendar'])

      @calendarPopupWrapper = {
         popupBuilder: @calendarPopupBuilder
         options: {triggerClasses: ['icon-calendar']}
      }

   clearSelection: =>
      return unless @clearable()
      @selectedDate(null)

dateSelectorTemplate = dateSelectorTemplateFn()

ko.components.register("date-selector",
   viewModel: DateSelector,
   template: dateSelectorTemplate,
)
