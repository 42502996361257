import "./person-type-heading-cell.styl";
import template from "./person-type-heading-cell.pug";
import type { MaybeObservable } from "knockout";
import ko, { observable, unwrap } from "knockout";
import { PersonTypeInfoPane } from "../../popup/person-type-info-pane";
import { Popup } from "../../popup/popup";

export interface PersonTypeHadingCellParams {
   width: MaybeObservable<number>;
}

export class PersonTypeHeading {
   readonly width: number;
   readonly personTypePopup = observable({
      popupBuilder: () =>
         new Popup(
            "",
            Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_LEFT,
            [new PersonTypeInfoPane()],
            [],
            ["person-type-heading-cell__popup"],
         ),
      options: {},
   });

   constructor(params: PersonTypeHadingCellParams) {
      this.width = unwrap(params.width);
   }
}

ko.components.register("person-type-heading-cell", {
   viewModel: PersonTypeHeading,
   template: template(),
   synchronous: true,
});
