import "./tag-instances-editor-drop-down.styl";
import type { Observable, ObservableArray, PureComputed, Subscription } from "knockout";
import ko, { observable, observableArray, pureComputed } from "knockout";
import template from "./tag-instances-editor-drop-down.pug";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { DropDownPane, LoadItemsParams } from "@/lib/components/drop-downs/drop-down-pane";
import { CompositeCellFactoryBuilder } from "@/lib/components/grid/cells/composite-cell-factory";
import { ArrayGridStore } from "@/lib/components/grid/array-grid-store";
import { ActionResult } from "@/lib/components/drop-downs/drop-down-2";
import { DropDownHeadingCell } from "@/lib/components/grid/cells/drop-down-heading-cell";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import { textSearch } from "@/lib/utils/text-search";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";

export type TagInstancesEditorDropDownParams = {
   allTags: ObservableArray<SerializedTag>;
   tagIdsInUse: Observable<Set<string>> | PureComputed<Set<string>>;

   onTagSelected: (tag: SerializedTag) => void;
};

enum CellType {
   HEADER = "header",
   CATEGORY = "category",
   TAG = "tag",
}

type Cell =
   | {
        id: string;
        type: CellType.HEADER | CellType.CATEGORY;
        data: string;
     }
   | {
        id: string;
        type: CellType.TAG;
        data: SerializedTag;
     };

export class TagInstancesEditorDropDown {
   readonly startingPane: AllCellsDropDownPane;
   readonly panes = observableArray<DropDownPane<Cell>>();

   readonly cellFactory = new CompositeCellFactoryBuilder<Cell>()
      .add({
         visit: (cell) => cell.type == CellType.HEADER,
         accept: () => DropDownHeadingCell.factory((cell) => cell.data as string),
      })
      .add({
         visit: (cell) => cell.type == CellType.CATEGORY,
         accept: () => TextCell.factory((cell) => cell.data as string),
      })
      .add({
         visit: (cell) => cell.type == CellType.TAG,
         accept: () => TextCell.factory((cell) => (cell.data as SerializedTag).name),
      })
      .build();

   private readonly availableTags = pureComputed(() => {
      return this.params.allTags();
   });

   constructor(private readonly params: TagInstancesEditorDropDownParams) {
      this.startingPane = new AllCellsDropDownPane(this.availableTags);
      this.panes([this.startingPane as any]);
   }

   actionInterceptor = (cell: Cell): ActionResult => {
      if (cell.type == CellType.TAG) {
         this.params.onTagSelected(cell.data);
         return ActionResult.CLOSE;
      }
      if (cell.type == CellType.CATEGORY) {
         const category = cell.data;
         const availableTags = this.availableTags().filter((tag) => {
            return tag.categories.includes(category);
         });

         // Create a new pane containing only the tags within the selected category.
         this.panes.push(
            new ArrayDropDownPane<Cell>({
               items: [{ type: CellType.HEADER, id: "header", data: category } as Cell].concat(
                  availableTags.map(
                     (tag) =>
                        ({
                           type: CellType.TAG,
                           id: tag.id,
                           data: tag,
                        } as Cell),
                  ),
               ),
               searchTextProvider: (cell) => {
                  return cell.type == CellType.TAG
                     ? `${cell.data.name} ${cell.data.abbreviation}`
                     : "";
               },
            }),
         );
      }
      return ActionResult.IGNORE;
   };
}

class AllCellsDropDownPane implements DropDownPane<Cell> {
   private readonly cells = observableArray<Cell>();

   readonly gridStore = observable<any>(new ArrayGridStore(this.cells));
   readonly isSearchable = observable(true);

   private readonly subscriptions: Subscription[] = [];
   private search: string | null = null;

   constructor(private readonly availableTags: PureComputed<SerializedTag[]>) {
      this.onAvailableTagsChanged();
      this.subscriptions.push(this.availableTags.subscribe(this.onAvailableTagsChanged, this));
   }

   loadItems(params: LoadItemsParams): void {
      this.search = params.search;

      const allCategories = this.availableTags().reduce((acc, tag) => {
         tag.categories.forEach((category) => acc.add(category));
         return acc;
      }, new Set<string>());

      const categories = textSearch({
         items: Array.from(allCategories),
         textProvider: (category) => category,
         search: this.search,
      }).sort((a, b) => a.localeCompare(b));

      const tags = textSearch({
         items: Array.from(this.availableTags()),
         textProvider: (tag) => `${tag.name} ${tag.abbreviation}`,
         search: this.search,
      });

      const cells: Cell[] = [];

      // Create category cells preceded by a header.
      if (categories.length) {
         cells.push({
            type: CellType.HEADER,
            id: "header:categories",
            data: this.search ? "Filtered Categories" : "Categories",
         });
         for (const category of categories) {
            cells.push({
               type: CellType.CATEGORY,
               id: `category:${category}`,
               data: category,
            });
         }
      }

      // Create tag cells preceded by a header.
      if (tags.length) {
         cells.push({
            type: CellType.HEADER,
            id: "header:all-tags",
            data: this.search ? "Filtered Tags" : "All Tags",
         });
         cells.push(
            ...tags.map(
               (tag) =>
                  ({
                     type: CellType.TAG,
                     id: tag.id,
                     data: tag,
                  } as Cell),
            ),
         );
      }
      this.cells(cells);
   }

   dispose() {
      this.subscriptions.forEach((s) => s.dispose());
   }

   private onAvailableTagsChanged() {
      this.loadItems({ search: this.search, startingAt: null });
   }
}

ko.components.register("tag-instances-editor-drop-down", {
   viewModel: TagInstancesEditorDropDown,
   template: template(),
   synchronous: true,
});
