import "./assignment-time-pane.styl"
import template from "./assignment-time-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { defaultStore } from "@/stores/default-store"
import { Format as FormatUtils } from "@/lib/utils/format"

export class AssignmentTimePane extends PopupPane
   constructor: (assignments) ->
      assertArgs(arguments, arrayOf(Object))
      super(template())
      # Popup Properties
      @assignments = FormatUtils.keyableSort(assignments, 'createdAt')

   formatTime: (time) ->
      return defaultStore.formatTime(ko.unwrap(time))
