import template from "./link-to-procore-heading-cell.pug";
import { components } from "knockout";
import { createRoot } from "react-dom/client";
import { Link as LinkIcon } from "@procore/core-icons";
import { createElement } from "react";

export class LinkToProcoreHeading {
   constructor() {
      // Wait for next tick of the event loop to ensure the DOM element is present.
      new Promise((res) => setTimeout(res, 0)).then(() => {
         const container = document.getElementById("link-to-procore-heading-cell");
         const root = createRoot(container!);
         root.render(createElement(LinkIcon, { size: "sm" }));
      });
   }
}

components.register("link-to-procore-heading-cell", {
   viewModel: LinkToProcoreHeading,
   template: template(),
   synchronous: true,
});
