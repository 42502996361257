import "./timezone-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./timezone-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export interface TimezoneEditorParams extends EditorComponentParams<string | null> {
   isRequired?: MaybeSubscribable<boolean>;
}

export class TimezoneEditor extends AbstractFieldEditor<string | null> {
   readonly validation = pureComputed(() => {
      if (unwrap(this.params.isRequired)) {
         if (this.value() === null) return `${this.title} is required.`;
      }
      return null;
   });

   constructor(private readonly params: TimezoneEditorParams) {
      super(params, observable(unwrap(params.value)));
   }

   validate(value: string | null): ValidatedValue<string | null> {
      if (unwrap(this.params.isRequired) && value == null) {
         return { valid: false, error: `${this.title} is required.` };
      }
      return { valid: true, value };
   }

   static factory<T>(
      provider: (records: T[]) => TimezoneEditorParams,
   ): (records: T[]) => ComponentArgs<TimezoneEditorParams> {
      return (records) => ({
         name: "timezone-editor",
         params: provider(records),
      });
   }
}

ko.components.register("timezone-editor", {
   viewModel: TimezoneEditor,
   template: template(),
   synchronous: true,
});
