import "./grid-cell-selection.styl";
import template from "./grid-cell-selection.pug";
import type { Observable, Subscribable, Subscription } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import type { Bounds } from "@/lib/utils/geometry";

export interface GridCellSelectionParams {
   isVisible: Observable<boolean>;
   isActionable: Observable<boolean>;
   bounds: Observable<Bounds | null>;
}

export class GridCellSelection {
   readonly isVisible: Observable<boolean>;
   readonly isActionable: Observable<boolean>;
   readonly bounds: Observable<Bounds | null>;
   readonly lastVisibleBounds: Observable<Bounds | null>;
   readonly isActuallyVisible = pureComputed(() => {
      return this.isVisible() && this.bounds() != null;
   });
   readonly style = pureComputed(() => {
      const bounds = this.lastVisibleBounds();
      return bounds
         ? {
              top: `${bounds.top}px`,
              left: `${bounds.left}px`,
              width: `${bounds.width}px`,
              height: `${bounds.height}px`,
           }
         : {};
   });
   readonly hasAnimationDisabled = pureComputed(() => {
      return this.boundsChangeWatcher.isActive();
   });

   private readonly boundsSubscription: Subscription;
   private readonly boundsChangeWatcher: RapidChangeWatcher;

   constructor({ isVisible, isActionable, bounds }: GridCellSelectionParams) {
      this.isVisible = isVisible;
      this.isActionable = isActionable;
      this.bounds = bounds;
      this.lastVisibleBounds = observable(unwrap(bounds));
      this.boundsSubscription = this.bounds.subscribe((value) => {
         // Cache the last visible bounds so the popup stays in place while it
         // animates out.
         if (value) {
            this.lastVisibleBounds(value);
         }
      });
      this.boundsChangeWatcher = new RapidChangeWatcher({
         subscribable: this.bounds,
         count: 3,
         millisecondRange: 275,
      });
   }

   onTransitionEnd = (isVisible: boolean): void => {
      if (!isVisible) {
         this.lastVisibleBounds(null);
      }
   };

   dispose = (): void => {
      this.boundsSubscription.dispose();
   };
}

class RapidChangeWatcher {
   /** Set to true when the repeated changes are happening. */
   readonly isActive = observable(false);

   private readonly count: number;
   private readonly millisecondRange: number;
   private readonly subscription: Subscription;
   private timestamps: number[] = [];
   private timerId: any | null = null;

   constructor({
      subscribable,
      count,
      millisecondRange,
   }: {
      subscribable: Subscribable;
      count: number;
      millisecondRange: number;
   }) {
      this.count = count;
      this.millisecondRange = millisecondRange;
      this.subscription = subscribable.subscribe(this.onValueChanged, this);
   }

   private onValueChanged() {
      if (this.timerId) clearTimeout(this.timerId);
      this.timestamps.push(Date.now());
      if (this.timestamps.length > this.count) {
         this.timestamps.shift();
      }
      if (this.timestamps.length < this.count) {
         this.isActive(false);
         return;
      }
      const delta = this.timestamps[this.count - 1] - this.timestamps[0];
      if (delta <= this.millisecondRange) {
         this.isActive(true);
         this.timerId = setTimeout(() => {
            this.timerId = null;
            this.isActive(false);
         }, this.millisecondRange - delta);
      } else {
         this.isActive(false);
      }
   }

   dispose() {
      if (this.timerId) clearTimeout(this.timerId);
      this.subscription.dispose();
   }
}

ko.components.register("grid-cell-selection", {
   viewModel: GridCellSelection,
   template: template(),
   synchronous: true,
});
