var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-arrow-left"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-arrow-left\" width=\"8px\" height=\"14px\" viewbox=\"0 0 8 14\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-arrow-left\"\u003E\u003Crect id=\"icon-arrow-up__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"8\" height=\"14\"\u003E\u003C\u002Frect\u003E\u003Cpolygon class=\"icon__shape\" id=\"icon-arrow-up__shape\" fill=\"#9B9B9B\" fill-rule=\"nonzero\" transform=\"translate(4.000000, 7.000000) scale(-1, 1) rotate(90.000000) translate(-4.000000, -7.000000) \" points=\"-1.355 11 4 6.05533063 9.355 11 11 9.47773279 4 3 -3 9.47773279\"\u003E\u003C\u002Fpolygon\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-arrow-right"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-arrow-right\" width=\"8px\" height=\"14px\" viewbox=\"0 0 8 14\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-arrow-right\"\u003E\u003Crect id=\"icon-arrow-up__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"8\" height=\"14\"\u003E\u003C\u002Frect\u003E\u003Cpolygon class=\"icon__shape\" id=\"icon-arrow-up__shape\" fill=\"#9B9B9B\" fill-rule=\"nonzero\" transform=\"translate(4.000000, 7.000000) rotate(90.000000) translate(-4.000000, -7.000000) \" points=\"-1.355 11 4 6.05533063 9.355 11 11 9.47773279 4 3 -3 9.47773279\"\u003E\u003C\u002Fpolygon\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"paging-control\"\u003E\u003Cdiv class=\"paging-control__text-container\"\u003E\u003C!-- ko if: totalCount() \u003E 0 --\u003E\u003Cspan data-bind=\"text: rangeMin\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"paging-control__text--static\"\u003E-\u003C\u002Fspan\u003E\u003Cspan data-bind=\"text: rangeMax\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"paging-control__text--static\"\u003Eof\u003C\u002Fspan\u003E\u003C!-- \u002Fko --\u003E\u003Cspan data-bind=\"text: totalCount\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"paging-control__btn-container\"\u003E\u003Ca class=\"paging-control__btn\" data-bind=\"click: decrementPage, css:             {'paging-control__btn--disabled': pageIndex() == 0}\"\u003E";
pug_mixins["icon-arrow-left"]();
pug_html = pug_html + "\u003C\u002Fa\u003E\u003Cdiv class=\"paging-control__btn-divider\" data-bind=\"css:             {'paging-control__btn-divider--disabled': totalCount() &lt;= 0}\"\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"paging-control__btn\" data-bind=\"click: incrementPage, css:             {'paging-control__btn--disabled': incrementDisabled}\"\u003E";
pug_mixins["icon-arrow-right"]();
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;