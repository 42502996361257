import "./modal-2.styl";
import template from "./modal-2.pug";
import { ViewModel } from "../../../vm/viewmodel";
import type { MaybeObservableArray } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "../../common";

export type Modal2Params = {
   components: MaybeObservableArray<ComponentArgs<unknown>>;
   onClose?: () => Promise<void> | void;
};

export class Modal2 extends ViewModel {
   readonly components: MaybeObservableArray<ComponentArgs<unknown>>;
   readonly onClose: () => Promise<void> | void;

   constructor({ components, onClose = () => {} }: Modal2Params) {
      super(template());
      this.components = components;
      this.onClose = onClose;
   }
}

ko.components.register("modal-2", {
   viewModel: Modal2,
   template: template(),
   synchronous: true,
});
