import "./date-input-2.styl";
import template from "./date-input-2.pug";
import type { PureComputed, Observable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "../common/component-args";
import { DateFormat } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";
import { TextField, TextFieldType } from "@/lib/components/text-field/text-field";
import { defaultStore } from "@/stores/default-store";
import type { Bounds } from "@/lib/utils/geometry";
import { WorkDay } from "@/lib/components/modals/editors/work-day-editor-element/work-day-editor-element";

export type DateInput2Params = {
   date: PureComputed<Date | null>;
   onDateChange: (date: Date | null) => void;
   anchorBounds?: Observable<Bounds>;
   isDisabled?: boolean;
   isClearable?: boolean;
   isInvalid?: PureComputed<boolean>;
   viewportBounds?: Observable<Bounds>;
};

const enum TimeType {
   DAY = "day",
   MONTH = "month",
   YEAR = "year",
}

type DateInputComponents = [
   { component: ComponentArgs<TextFieldParams>; type: TimeType },
   { component: ComponentArgs<TextFieldParams>; type: TimeType },
   { component: ComponentArgs<TextFieldParams>; type: TimeType },
];

class DateInput2State {
   private readonly internalState: {
      dayString: Observable<string>;
      monthString: Observable<string>;
      yearString: Observable<string>;
      isPopupVisible: Observable<boolean>;

      /**
       * Problem:
       * - dateString fields need to be updated when date changes, including clearing/nullifying.
       * - dateString fields push updates to date, including nullifying when one field is invalid.
       * - Thus, changing one field to an invalid state (like clearing it) will cause all fields
       *    to be cleared, which is undesired.
       * Solution:
       * - Use this flag to prevent null-updates to dateString fields when they are themselves
       *    the source of the update.
       */
      isChangeFromInternalTextFields: boolean;
   };

   private readonly isValidYear = (year: number) => {
      return !(isNaN(year) || year < 1900 || year > 2100);
   };
   /** month is in range 1-12. */
   private readonly isValidMonth = (month: number) => {
      return !(isNaN(month) || month < 1 || month > 12);
   };
   /**
    * @param day is in range 1-31.
    * @param month is in range 0-11.
    * @param year is any valid year for javascript Date values.
    */
   private readonly isValidDay = ({
      day,
      month,
      year,
   }: {
      day: number;
      month: number;
      year: number;
   }) => {
      return !(isNaN(day) || day < 1 || day > new Date(year, month + 1, 0).getDate());
   };

   readonly yearString = pureComputed({
      read: () => unwrap(this.internalState.yearString),
      write: (year: string) => {
         this.internalState.isChangeFromInternalTextFields = true;
         this.internalState.yearString(year);
      },
   });
   readonly monthString = pureComputed({
      read: () => unwrap(this.internalState.monthString),
      write: (month: string) => {
         this.internalState.isChangeFromInternalTextFields = true;
         this.internalState.monthString(month);
      },
   });
   readonly dayString = pureComputed({
      read: () => unwrap(this.internalState.dayString),
      write: (day: string) => {
         this.internalState.isChangeFromInternalTextFields = true;
         this.internalState.dayString(day);
      },
   });
   readonly computedDate = pureComputed<Date | null>(() => {
      const rawYear = Number(unwrap(this.yearString));
      const year = this.isValidYear(rawYear) ? rawYear : null;
      if (year === null) return null;

      const rawMonth = Number(unwrap(this.monthString));
      const month = this.isValidMonth(rawMonth) ? rawMonth - 1 : null;
      if (month === null) return null;

      const rawDay = Number(unwrap(this.dayString));
      const day = this.isValidDay({ day: rawDay, month, year }) ? rawDay : null;
      if (day === null) return null;

      return new Date(year, month, day);
   });

   readonly weekDayName = pureComputed(() => {
      const date = unwrap(this.computedDate);
      if (date == null) return null;
      const names: Record<WorkDay, string> = {
         [WorkDay.SUNDAY]: "Sunday",
         [WorkDay.MONDAY]: "Monday",
         [WorkDay.TUESDAY]: "Tuesday",
         [WorkDay.WEDNESDAY]: "Wednesday",
         [WorkDay.THURSDAY]: "Thursday",
         [WorkDay.FRIDAY]: "Friday",
         [WorkDay.SATURDAY]: "Saturday",
      };
      return names[date.getDay() as WorkDay];
   });

   readonly isPopupVisible = pureComputed(() => unwrap(this.internalState.isPopupVisible));

   constructor(date: PureComputed<Date | null>) {
      const unwrappedDate = unwrap(date);

      if (unwrappedDate != null) {
         this.internalState = {
            yearString: observable(unwrappedDate.getFullYear().toString()),
            monthString: observable((unwrappedDate.getMonth() + 1).toString()),
            dayString: observable(unwrappedDate.getDate().toString()),
            isPopupVisible: observable(false),
            isChangeFromInternalTextFields: false,
         };
      } else {
         this.internalState = {
            yearString: observable(""),
            monthString: observable(""),
            dayString: observable(""),
            isPopupVisible: observable(false),
            isChangeFromInternalTextFields: false,
         };
      }

      date.subscribe((date: Date | null) => {
         if (date == null && this.internalState.isChangeFromInternalTextFields) {
            this.internalState.isChangeFromInternalTextFields = false;
            return;
         }
         this.internalState.isChangeFromInternalTextFields = false;

         if (date === null) {
            this.internalState.yearString("");
            this.internalState.monthString("");
            this.internalState.dayString("");
         } else {
            this.internalState.yearString(date.getFullYear().toString());
            this.internalState.monthString((date.getMonth() + 1).toString());
            this.internalState.dayString(date.getDate().toString());
         }
      });
   }

   togglePopup = () => this.internalState.isPopupVisible(!unwrap(this.isPopupVisible));
   setPopupState = (isPopupVisible: boolean) => this.internalState.isPopupVisible(isPopupVisible);
}

export class DateInput2 {
   private readonly state: DateInput2State;

   readonly anchorBounds: Exclude<DateInput2Params["anchorBounds"], undefined>;
   readonly date: DateInput2Params["date"];
   readonly isInvalid: DateInput2Params["isInvalid"];
   readonly isDisabled: DateInput2Params["isDisabled"];
   readonly isClearable: DateInput2Params["isClearable"];
   readonly onDateChange: DateInput2Params["onDateChange"];
   readonly viewportBounds: Exclude<DateInput2Params["viewportBounds"], undefined>;

   readonly orderedDateInputs: DateInputComponents;
   readonly separator: "/" | "-";
   readonly isPopupVisible = pureComputed(() => unwrap(this.state.isPopupVisible));

   private readonly yearInputComponent: ComponentArgs<TextFieldParams>;
   private readonly monthInputComponent: ComponentArgs<TextFieldParams>;
   private readonly dayInputComponent: ComponentArgs<TextFieldParams>;

   constructor({
      anchorBounds,
      date,
      onDateChange,
      isDisabled = false,
      isClearable = false,
      isInvalid,
      viewportBounds,
   }: DateInput2Params) {
      this.anchorBounds =
         anchorBounds ||
         observable({
            height: 0,
            width: 0,
            top: 0,
            left: 0,
         });
      this.date = date;
      this.isDisabled = isDisabled;
      this.isClearable = isClearable;
      this.isInvalid = isInvalid;
      this.onDateChange = onDateChange;
      this.viewportBounds =
         viewportBounds ||
         observable({
            height: 0,
            width: 0,
            top: 0,
            left: 0,
         });

      this.state = new DateInput2State(date);

      this.state.computedDate.subscribe((date) => {
         onDateChange(date);
      });

      this.yearInputComponent = TextField.factory({
         isClearable: pureComputed(() => false),
         isDisabled,
         placeholder: "YYYY",
         type: TextFieldType.NUMBER,
         value: this.state.yearString as PureComputed<string | null>,
      });
      this.monthInputComponent = TextField.factory({
         isClearable: pureComputed(() => false),
         isDisabled,
         placeholder: "MM",
         type: TextFieldType.NUMBER,
         value: this.state.monthString as PureComputed<string | null>,
      });
      this.dayInputComponent = TextField.factory({
         isClearable: pureComputed(() => false),
         isDisabled,
         placeholder: "DD",
         type: TextFieldType.NUMBER,
         value: this.state.dayString as PureComputed<string | null>,
      });
      this.orderedDateInputs = this.getOrderedDateInputComponents();
      this.separator = unwrap(defaultStore.selectedDateFormatSeparator);
   }

   private clearDate() {
      this.onDateChange(null);
      this.yearInputComponent.params.value(null);
      this.monthInputComponent.params.value(null);
      this.dayInputComponent.params.value(null);
   }

   private getOrderedDateInputComponents(): DateInputComponents {
      const dateFormat = defaultStore.getDateFormat() as unknown as DateFormat;
      if ([DateFormat.DD_MM_YYYY, DateFormat.DD_MM_YYYY_DASHED].includes(dateFormat))
         return [
            { component: this.dayInputComponent, type: TimeType.DAY },
            { component: this.monthInputComponent, type: TimeType.MONTH },
            { component: this.yearInputComponent, type: TimeType.YEAR },
         ];

      if ([DateFormat.MM_DD_YYYY, DateFormat.MM_DD_YYYY_DASHED].includes(dateFormat))
         return [
            { component: this.monthInputComponent, type: TimeType.MONTH },
            { component: this.dayInputComponent, type: TimeType.DAY },
            { component: this.yearInputComponent, type: TimeType.YEAR },
         ];

      if ([DateFormat.YYYY_MM_DD, DateFormat.YYYY_MM_DD_DASHED].includes(dateFormat))
         return [
            { component: this.yearInputComponent, type: TimeType.YEAR },
            { component: this.monthInputComponent, type: TimeType.MONTH },
            { component: this.dayInputComponent, type: TimeType.DAY },
         ];

      if ([DateFormat.YYYY_DD_MM].includes(dateFormat))
         return [
            { component: this.yearInputComponent, type: TimeType.YEAR },
            { component: this.dayInputComponent, type: TimeType.DAY },
            { component: this.monthInputComponent, type: TimeType.MONTH },
         ];

      // Default to DD_MM_YYYY.
      return [
         { component: this.dayInputComponent, type: TimeType.DAY },
         { component: this.monthInputComponent, type: TimeType.MONTH },
         { component: this.yearInputComponent, type: TimeType.YEAR },
      ];
   }

   static factory(params: DateInput2Params): ComponentArgs<DateInput2Params> {
      return {
         name: "date-input-2",
         params,
      };
   }
}

ko.components.register("date-input-2", {
   viewModel: DateInput2,
   template: template(),
   synchronous: true,
});
