import "./logo-uploader.styl"
import logoUploaderTemplateFn from "./logo-uploader.pug"
import { cloudinaryStore } from "@/stores/cloudinary-store"
import { Color } from "@/lib/utils/color"
import { Notification, Icons, notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import ko from "knockout"

export class LogoUploader

   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.logo, Function)
      assertOfType(params.callback, optional(Function))
      assertOfType(params.disableEdit, optional([Boolean, Function]))

      @color = params.color
      @hasDarkText = ko.pureComputed () =>
         return !Color.colorIsDark(@color())
      @logo = params.logo
      @validationMessage = params.validationMessage
      @callback = if params.callback? then params.callback else () ->
      @showLoading = ko.observable(false)
      @disableEdit = if ko.isObservable(params.disableEdit) then params.disableEdit else ko.unwrap(params.disableEdit || false)

      @disposablePhotoBlock = false

      @newFile = ko.observable()
      @newFile.subscribe (newVal) =>
         return unless newVal?

         @showLoading(true)
         @disposablePhotoBlock = true
         cloudinaryStore.uploadPhoto newVal, (err, data) =>
            if err
               return notificationManager.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "Logo failed to upload. Please try again.",
                  })
               )
            urlChunks = data.secure_url.split("upload/")
            cleanedUrl = "#{urlChunks[0]}upload/fl_ignore_aspect_ratio/#{urlChunks[1]}"
            @logo(null)
            @logo(cleanedUrl)
            @showLoading(false)

ko.components.register("logo-uploader",
   viewModel: LogoUploader,
   template: logoUploaderTemplateFn(),
)
