import "./qrcode-display.styl"
import qrCodeDisplayTemplateFn from "./qrcode-display.pug"
import ko from "knockout"
import QRCode from 'qrcode'
import { requestContext } from "@/stores/common/request-context"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

export class QRCodeDisplay
   constructor: (params) ->
      assertArgs(arguments, Object)
      companyQrId = params.companyQrId
      entityQrId = params.entityQrId
      # Not an observable.
      entityPathAbbr = params.entityPathAbbr
      entityTitle = params.entityTitle
      entitySubtitle = params.entitySubtitle

      @sizeOptions = [
         new DropDownItem("300px", 300)
         new DropDownItem("600px", 600)
         new DropDownItem("1200px", 1200)
      ]
      @selectedSize = ko.observable(@sizeOptions[0])

      @includeInfo = ko.observable(true)

      @infoMessage = params.infoMessage

      # The actual link in the QR Code.
      @destinationUrl = if !!requestContext.baseUrl
            # Replace origin if a requestContext is set
            ko.unwrap(params.url).replace(window.location.origin, requestContext.baseUrl)
         else
            ko.unwrap(params.url)
      @triggerDownload = ko.observable(false)
      @downloadUrl = ko.pureComputed =>
         baseUrl = requestContext.baseUrl + "/download/qrcode?"
         baseUrl += "cqi=#{companyQrId()}&eqi=#{entityQrId()}&epa=#{entityPathAbbr}&"
         if ko.unwrap(entityTitle)
            baseUrl += "title=#{ko.unwrap(entityTitle)}&"
         if ko.unwrap(entitySubtitle)
            baseUrl += "subtitle=#{ko.unwrap(entitySubtitle)}&"

         baseUrl += "width=#{@selectedSize().value()}&info=#{@includeInfo()}"

         return baseUrl

      setTimeout =>
         element = document.getElementById("qrcode-display-box")
         options = {}
         QRCode.toCanvas element, @destinationUrl, options, (err) ->
            console.log "Error: ", err if err
            console.log "done"

   openProfilePreview: ->
      window.open(@destinationUrl,'QR Profile Preview','resizable,height=650,width=400')

   download: ->
      @triggerDownload(true)

   handleDownloadComplete: =>
      @triggerDownload(false)

qrCodeDisplayTemplate = qrCodeDisplayTemplateFn()

ko.components.register("qrcode-display",
   viewModel: QRCodeDisplay,
   template: qrCodeDisplayTemplate,
)
