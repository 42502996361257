import "./note-accordion.styl"
import noteAccordionTemplateFn from "./note-accordion.pug"
import { Dom } from "@/lib/utils/dom"

### Models ###
import { Note } from "@/models/note"

export class NoteAccordion
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.note, optional(Note))
      assertOfType(params.saveCallback, optional(Function))
      assertOfType(params.cancelCallback, optional(Function))
      assertOfType(params.deleteCallback, optional(Function))

      if params.note?
         @creatingNewNote = ko.observable(false)
         @note = ko.observable(params.note)
      else
         @creatingNewNote = ko.observable(true)
         @note = ko.observable(new Note({}, true))

      @files = ko.observableArray()
      @files(@note().attachments()) if @note().attachments().length != 0
      @files.subscribe (newVal) =>
         @note().attachments(newVal)

      @saveCallback = if params.saveCallback? then params.saveCallback else false
      @cancelCallback = if params.cancelCallback? then params.cancelCallback else false
      @deleteCallback = if params.deleteCallback? then params.deleteCallback else false

      @isExpanded = ko.observable(false)
      if params.openOnLoad == true then @isExpanded(true)

      # Alerts
      @showTitleAlert = ko.observable(false)
      @showAttachmentAlert = ko.observable(false)

   toggleExpansion: ->
      @isExpanded(!@isExpanded())

   fireSave: =>
      @showTitleAlert(false)
      @showAttachmentAlert(false)
      return @showTitleAlert(true) unless @note().name()?
      for file in @files()
         return @showAttachmentAlert(true) unless file.createdAt()?
      @saveCallback(@note()) if @saveCallback

   fireCancel: ->
      @isExpanded(false)
      @cancelCallback() if @cancelCallback

   fireDelete: ->
      @deleteCallback(@note()) if @deleteCallback


noteAccordionTemplate = noteAccordionTemplateFn()

ko.components.register("note-accordion",
   viewModel: NoteAccordion,
   template: noteAccordionTemplate,
)