import "./progress-bar.styl"
import progressBarTemplateFn from "./progress-bar.pug"
import { Guid as GUID } from "@/lib/utils/guid"
import ko from "knockout"
import $ from "jquery"

export class ProgressBar
   constructor: (params) ->
      assertArgs(arguments, Object)

      @showBar = ko.observable(false)
      @loadingId = GUID()
      @blockUpdates = ko.observable(false)
      @useActualProgress = false
      if params.progressPercentage?
         @useActualProgress = true
         @barWidth = params.progressPercentage 
      else
         @barWidth = ko.observable(0)
      @barWidthValue = ko.pureComputed =>
         return "#{@barWidth()}%"

      params.mediator.initialize(@)

   showLoader: =>
      @blockUpdates(false)
      unless @useActualProgress
         @barWidth(0)
      @showBar(true)

   hideLoader: =>
      @showBar(false)
      return if @useActualProgress
      @barWidth(0)

   startProgress: =>
      return if @useActualProgress
      @updateProgress()

   appendData: (next, hideAfterCallback = false) =>
      # TODO: This isn't always firing.
      @blockUpdates(true)
      @$el_ = $(document.getElementById("#{@loadingId}"))
      @$el_.one "transitionend", =>
         next() if hideAfterCallback
         @hideLoader()
         next() unless hideAfterCallback
      unless @useActualProgress
         @barWidth(100)

   updateProgress: =>
      return if @blockUpdates() or @barWidth() >= 100
      if @barWidth() < 35
         @barWidth(@barWidth() + 5)
         setTimeout(@updateProgress, 500)
      else if @barWidth() < 50
         @barWidth(@barWidth() + 5)
         setTimeout(@updateProgress, 1000)
      else if @barWidth() < 75
         @barWidth(@barWidth() + 5)
         setTimeout(@updateProgress, 500)
      else if @barWidth() < 95
         @barWidth(@barWidth() + 5)
         setTimeout(@updateProgress, 1800)
      # Over 16s load, just wait for completion.


progressBarTemplate = progressBarTemplateFn()

ko.components.register("progress-bar",
   viewModel: ProgressBar,
   template: progressBarTemplate
)
