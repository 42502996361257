import "./send-alert.styl";
import template from "./send-alert.pug";
import type { ObservableArray, PureComputed, Subscription } from "knockout";
import ko, { observable, pureComputed } from "knockout";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { CannedMessage } from "@/models/canned-message";
import type { ProcessDefaultAlertForProjectConfig } from "@/stores/alert-store";
import { Alert } from "@/models/alert";
import type { GetAssignmentsAssignment } from "@/stores/assignment-2-store.core";
import {
   notificationManagerInstance,
   Notification,
   Icons,
} from "@/lib/managers/notification-manager";
import type { BatchActionsTransition } from "../batch-actions";
import { BatchActionState, TRANSITION_TIMING } from "../batch-actions";
import { AlertStore } from "@/stores/alert-store.core";
import type { AlertContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { CannedMessageType } from "@laborchart-modules/common/dist/rethink/schemas/enums/canned-messages";
import { authManager } from "@/lib/managers/auth-manager";

enum State {
   SELECT_OPTION = 0,
   SET_SCHEDULE = 1,
}

abstract class AlertOption {
   constructor(readonly title: string) {}
   abstract isValid(): boolean;
   abstract getMessage(): ProcessDefaultAlertForProjectConfig;
   abstract getPopupParams(): { buttonText: string; titleText: string; message: string };
}

class SendImmediatelyAlertOption extends AlertOption {
   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Send Immediately");
   }

   getMessage() {
      return {
         context: Alert.Context.OPEN,
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams() {
      return {
         buttonText: "Send",
         titleText: "Send Alerts",
         message: `${this.numberOfRecords()} Alerts(s) will be sent to assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }

   isValid(): boolean {
      return true;
   }
}

class SaveDraftAlertOption extends AlertOption {
   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Save Draft");
   }

   getMessage() {
      return {
         context: Alert.Context.DRAFTS,
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams() {
      return {
         buttonText: "Save",
         titleText: "Save Draft Alerts",
         message: `${this.numberOfRecords()} Alert(s) will be saved as drafts for assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }
   isValid() {
      return true;
   }
}

class ScheduledAlertOption extends AlertOption {
   readonly date = ko.observable<Date | undefined>(new Date());
   readonly time = ko.observable<number | undefined>(undefined);

   constructor(readonly numberOfRecords: PureComputed<number>) {
      super("Schedule");
   }

   getMessage() {
      return {
         context: Alert.Context.SCHEDULED,
         detached_day: getDetachedDay(this.date()!),
         time: this.time(),
         cannedType: CannedMessage.Type.ASSIGNMENT_NEW,
      };
   }

   getPopupParams(): { buttonText: string; titleText: string; message: string } {
      return {
         buttonText: "Schedule",
         titleText: "Schedule Alerts",
         message: `${this.numberOfRecords()} Alert(s) will be scheduled to be sent to assigned resources and project default recipients using Project's alert templates when available or company wide default.`,
      };
   }

   isValid(): boolean {
      return this.date() !== undefined && isNaN(Number(this.time())) === false;
   }
}

export type SendAlertParams = {
   /**
    * Keeps track of active Actions, transition direction, and provides method to
    * return to options list.
    */
   transitionController: BatchActionsTransition;

   assignments: ObservableArray<GetAssignmentsAssignment>;
};

export class SendAlert {
   readonly isPopupVisible: PureComputed<boolean>;
   readonly state = observable<State>(State.SELECT_OPTION);
   readonly isOptionsFocused = observable(false);
   readonly alertStrategy = observable<AlertOption | null>(null);

   readonly isScheduledSelected = ko.pureComputed(() => {
      return this.alertStrategy() instanceof ScheduledAlertOption;
   });

   readonly numberOfRecords = ko.pureComputed(() => {
      return this.params.assignments().length;
   });

   readonly alertStrategies: ObservableArray<AlertOption>;

   readonly disableSaveBtn = pureComputed(() => {
      const strategy = this.alertStrategy();
      if (!strategy) return true;
      return !strategy.isValid();
   });
   private readonly backArrowCallback = () => this.params.transitionController.backToActionList();

   private readonly subscriptions: Subscription[] = [];

   constructor(private readonly params: SendAlertParams) {
      this.isPopupVisible = pureComputed(
         () => params.transitionController.state() === BatchActionState.SEND_ALERTS,
      );

      this.isPopupVisible.subscribe((visible) => {
         if (!visible) {
            setTimeout(() => {
               this.alertStrategy(null);
               this.state(State.SELECT_OPTION);
            }, TRANSITION_TIMING);
         }
      }, this);

      this.alertStrategies = ko.observableArray<AlertOption>([
         new SendImmediatelyAlertOption(this.numberOfRecords),
         new ScheduledAlertOption(this.numberOfRecords),
         new SaveDraftAlertOption(this.numberOfRecords),
      ]);
   }

   onListItemClick = (alertStrategy: AlertOption): void => {
      this.alertStrategy(alertStrategy);
      if (this.isScheduledSelected()) {
         this.params.transitionController.direction(Transition.FORWARD);
         this.state(State.SET_SCHEDULE);
      } else {
         this.displayModal();
      }
   };

   onEscape = (): void => {
      if (this.isPopupVisible() === false) return;
      if (this.state() == State.SET_SCHEDULE) {
         this.params.transitionController.direction(Transition.BACKWARD);
         this.state(State.SELECT_OPTION);
      } else if (this.isPopupVisible()) {
         this.params.transitionController.backToActionList();
      }
   };

   displayModal(): void {
      const alertStrategy: AlertOption | null = this.alertStrategy();
      if (!alertStrategy) return;
      //Close popup & defer the rest of the workflow to the modal.
      this.params.transitionController.closePopup();

      const { buttonText, titleText, message } = alertStrategy.getPopupParams();
      const confirmationMessagePane = new ConfirmActionPaneViewModel(
         buttonText,
         message,
         titleText,
      );
      const confirmationModal = new Modal();
      confirmationModal.setPanes([confirmationMessagePane]);
      modalManager.showModal(
         confirmationModal,
         null,
         { class: "send-alert__confirm-action-modal" },
         async (_, exitStatus) => {
            if (exitStatus === "cancelled") return;

            const failed: GetAssignmentsAssignment[] = [];
            await Promise.all(
               this.params.assignments().map(async (assignment) => {
                  const message = alertStrategy.getMessage();
                  const groupId =
                     authManager.selectedGroupId() === "my-groups"
                        ? undefined
                        : authManager.selectedGroupId();

                  const requestBody = {
                     batch_id: assignment.id,
                     canned_type: message.cannedType as CannedMessageType,
                     group_id: groupId,
                     context: message.context as unknown as AlertContext,
                     detached_day: message.detached_day,
                     time: message.time,
                  };
                  const payloadRequest = {
                     body: requestBody,
                  };

                  try {
                     return AlertStore.processDefaultAlertForProject(payloadRequest);
                  } catch (error) {
                     console.error(
                        `Error executing AlertStore.processDefaultAlertForProject for assignment with ID ${assignment.id}:`,
                        error,
                     );
                     failed.push(assignment);
                  }
               }),
            );
            if (failed.length) {
               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: `Failed to create ${failed.length} alert(s).`,
                  }),
               );
            }
         },
      );
   }

   dispose(): void {
      this.subscriptions.forEach((subscription) => {
         subscription.dispose();
      });
   }
}

ko.components.register("action-send-alert", {
   viewModel: SendAlert,
   template: template(),
});
