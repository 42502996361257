import { findOffsetToOffsetAncestor, findViewportElement, orderByAncestry } from "./elements";
import type { Point } from "./geometry";

export interface DragUpdate {
   /** Element the position is relative to. */
   element: HTMLElement;

   /** Position of the mouse within the element. */
   position: Point;

   /** Delta of the mouse position relative where the drag began. */
   delta: Point;
}

/** Creates a drag handler for either a mouse or touch event. */
export function createDragHandler(params: {
   event: MouseEvent | TouchEvent;
   onChange?: (update: DragUpdate) => void;
   onDone?: (update: DragUpdate) => void;
   onCancel?: () => void;
}): MouseDragHandler | TouchDragHandler {
   if (params.event instanceof MouseEvent) {
      return new MouseDragHandler({
         ...params,
         event: params.event,
      });
   } else {
      return new TouchDragHandler({
         ...params,
         event: params.event,
      });
   }
}

interface AbstractDragHandlerParams {
   element: HTMLElement;
   onChange?: (update: DragUpdate) => void;
   onDone?: (update: DragUpdate) => void;
   onCancel?: () => void;
}

abstract class AbstractDragHandler {
   protected readonly topViewport: HTMLElement;
   protected readonly leftViewport: HTMLElement;
   protected readonly ancestor: HTMLElement;
   protected scrollTop: number;
   protected scrollLeft: number;
   protected initial: Point = { top: 0, left: 0 };
   protected position: Point = { top: 0, left: 0 };

   constructor(private readonly params: AbstractDragHandlerParams) {
      this.topViewport = findViewportElement(params.element, "top");
      this.leftViewport = findViewportElement(params.element, "left");
      this.topViewport.addEventListener("scroll", this.onTopViewportScroll);
      this.leftViewport.addEventListener("scroll", this.onLeftViewportScroll);
      this.ancestor = orderByAncestry(this.topViewport, this.leftViewport)[0];
      this.scrollTop = this.topViewport.scrollTop;
      this.scrollLeft = this.leftViewport.scrollLeft;
   }

   dispose() {
      this.topViewport.removeEventListener("scroll", this.onTopViewportScroll);
      this.leftViewport.removeEventListener("scroll", this.onLeftViewportScroll);
   }

   protected change(position: Point): void {
      this.position = position;
      if (this.params.onChange) {
         this.params.onChange(this.createUpdate());
      }
   }

   protected done(position: Point): void {
      this.position = position;
      if (this.params.onDone) {
         this.params.onDone(this.createUpdate());
      }
      this.dispose();
   }

   protected cancel(): void {
      if (this.params.onCancel) {
         this.params.onCancel();
      }
      this.dispose();
   }

   private onTopViewportScroll = () => {
      const oldScrollTop = this.scrollTop;
      this.scrollTop = this.topViewport.scrollTop;
      this.change({
         ...this.position,
         top: this.position.top + (this.scrollTop - oldScrollTop),
      });
   };

   private onLeftViewportScroll = () => {
      const oldScrollLeft = this.scrollLeft;
      this.scrollLeft = this.leftViewport.scrollLeft;
      this.change({
         ...this.position,
         left: this.position.left + (this.scrollLeft - oldScrollLeft),
      });
   };

   private createUpdate(): DragUpdate {
      return {
         element: this.ancestor,
         position: this.position,
         delta: {
            top: this.position.top - this.initial.top,
            left: this.position.left - this.initial.left,
         },
      };
   }
}

export interface MouseDragHandlerParams {
   event: MouseEvent;
   onChange?: (update: DragUpdate) => void;
   onDone?: (update: DragUpdate) => void;
   onCancel?: () => void;
}

export class MouseDragHandler extends AbstractDragHandler {
   constructor(params: MouseDragHandlerParams) {
      super({
         element: params.event.currentTarget as HTMLElement,
         onChange: params.onChange,
         onDone: params.onDone,
         onCancel: params.onCancel,
      });
      this.ancestor.addEventListener("mousemove", this.onMouseMove);
      this.ancestor.addEventListener("mouseup", this.onMouseUp);
      this.initial = this.position = this.findPosition(params.event);
   }

   dispose(): void {
      super.dispose();
      this.ancestor.removeEventListener("mousemove", this.onMouseMove);
      this.ancestor.removeEventListener("mouseup", this.onMouseUp);
   }

   private onMouseMove = (event: MouseEvent) => {
      this.change(this.findPosition(event));
   };

   private onMouseUp = (event: MouseEvent) => {
      this.done(this.findPosition(event));
   };

   private findPosition(event: MouseEvent) {
      const offset = findOffsetToOffsetAncestor(event.target as HTMLElement, document.body);
      offset.top += event.pageY - offset.top + this.topViewport.scrollTop;
      offset.left += event.pageX - offset.left + this.leftViewport.scrollLeft;
      return offset;
   }
}

export interface TouchDragHandlerParams {
   event: TouchEvent;
   onChange?: (update: DragUpdate) => void;
   onDone?: (update: DragUpdate) => void;
   onCancel?: () => void;
}

export class TouchDragHandler extends AbstractDragHandler {
   constructor(params: TouchDragHandlerParams) {
      super({
         element: params.event.currentTarget as HTMLElement,
         onChange: params.onChange,
         onDone: params.onDone,
         onCancel: params.onCancel,
      });
      this.ancestor.addEventListener("touchmove", this.onTouchMove);
      this.ancestor.addEventListener("touchend", this.onTouchEnd);
      this.ancestor.addEventListener("touchcancel", this.onTouchCancel);
      this.initial = this.position = this.findPosition(params.event);
   }

   dispose(): void {
      super.dispose();
      this.ancestor.removeEventListener("touchmove", this.onTouchMove);
      this.ancestor.removeEventListener("touchend", this.onTouchEnd);
      this.ancestor.removeEventListener("touchcancel", this.onTouchCancel);
   }

   private onTouchMove = (event: TouchEvent) => {
      this.change(this.findPosition(event));
   };

   private onTouchEnd = () => {
      this.done(this.position);
   };

   private onTouchCancel = () => {
      this.cancel();
   };

   private findPosition(event: TouchEvent) {
      const offset = findOffsetToOffsetAncestor(event.target as HTMLElement, document.body);
      offset.top += event.touches[0].pageY - offset.top + this.topViewport.scrollTop;
      offset.left += event.touches[0].pageX - offset.left + this.leftViewport.scrollLeft;
      return offset;
   }
}
