import "./time-off-bar-pane.styl"
import template from "./time-off-bar-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { defaultStore } from "@/stores/default-store"

export class TimeOffBarPane extends PopupPane
   constructor: (startTime, endTime, reason) ->
      assertArgs(arguments, Number, Number, String)
      super(template())
      # Popup Properties
      @title = ko.observable()
      @startTime = defaultStore.formatTime(startTime)
      @endTime = defaultStore.formatTime(endTime)
      @reason = reason
