import "./file-download-pane.styl";
import template from "./file-download-pane.pug";
import { DateUtils } from "@/lib/utils/date";
import type { Observable } from "knockout";
import { observable, pureComputed } from "knockout";
import { requestContext } from "@/stores/common/request-context";

/* Modals */
import { ModalPane } from "@/lib/components/modals/modal-pane";
import { modalManager } from "@/lib/managers/modal-manager";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";

/* Models */
import type { Attachment } from "@/models/attachment";

/* Notifications */
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export class FileDownloadPaneViewModel extends ModalPane {
   readonly attachment: Observable<Attachment>;
   readonly allowDownload: Observable<boolean>;

   readonly url = pureComputed(() => {
      return LaunchDarklyClient.getFlagValue("use-jwt-auth")
         ? `/api/v3/attachments/${this.attachment().id}/signed-url`
         : requestContext.baseUrl +
              `/api/download/companies/${authManager.companyId()}/attachments/${
                 this.attachment().id
              }`;
   });
   readonly createdDateString = pureComputed(() => {
      const date = new Date(this.attachment().createdAt());
      return `${DateUtils.getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;
   });
   readonly mimetypeSpriteClass = pureComputed(() => {
      switch (this.attachment().mimetype()) {
         // Word
         case "application/vnd.ms-word.document.macroenabled.12":
            return "file-icon-doc";
         case "application/vnd.ms-word.template.macroenabled.12":
            return "file-icon-doc";
         case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "file-icon-doc";
         case "application/msword":
            return "file-icon-doc";
         case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
            return "file-icon-doc";
         // Images
         case "image/jpeg":
            return "file-icon-jpg";
         case "video/jpeg":
            return "file-icon-jpg";
         case "application/pdf":
            return "file-icon-pdf";
         case "image/png":
            return "file-icon-png";
         case "image/tiff":
            return "file-icon-tiff";
         // Excel
         case "application/vnd.ms-excel":
            return "file-icon-xls";
         case "application/vnd.ms-excel.sheet.binary.macroenabled.12":
            return "file-icon-xls";
         case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "file-icon-xls";
         // Power Point
         case "application/vnd.ms-powerpoint.template.macroenabled.12":
            return "file-icon-ppt";
         case "application/vnd.ms-powerpoint":
            return "file-icon-ppt";
         case "application/vnd.ms-powerpoint.addin.macroenabled.12":
            return "file-icon-ppt";
         case "application/vnd.ms-powerpoint.slide.macroenabled.12":
            return "file-icon-ppt";
         case "application/vnd.ms-powerpoint.presentation.macroenabled.12":
            return "file-icon-ppt";
         case "application/vnd.ms-powerpoint.slideshow.macroenabled.1":
            return "file-icon-ppt";
         case "application/vnd.ms-project":
            return "file-icon-ppt";
         case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return "file-icon-ppt";
         case "application/vnd.openxmlformats-officedocument.presentationml.slide":
            return "file-icon-ppt";
         case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
            return "file-icon-ppt";
         case "application/vnd.openxmlformats-officedocument.presentationml.template":
            return "file-icon-ppt";
         // Text
         case "text/plain":
            return "file-icon-txt";
         // Email
         case "application/vnd.ms-outlook":
            return "file-icon-msg";
         case "application/vnd.ms-outlook-pst":
            return "file-icon-pst";
         case "application/rtf":
            return "file-icon-rtf";
         default:
            // Other
            return "file-icon-default";
      }
   });

   constructor(attachment: Attachment) {
      super("File Details", "", template());
      this.attachment = observable(attachment);
      this.allowDownload = observable(false);
   }

   startDownload = (): void => {
      return this.allowDownload(true);
   };

   handleFileDownload = (err: unknown): void => {
      if (err) {
         notificationManagerInstance.show(
            new Notification({
               icon: Icons.WARNING,
               text: "An unexpected error occurred during download.",
               duration: 5000,
            }),
         );
      }
      this.allowDownload(false);
      return modalManager.modalFinished();
   };
}
