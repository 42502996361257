import template from "./create-project-pane.pug"
import ko from "knockout"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { GroupStore } from "@/stores/group-store.core"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### UI Assets ###
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane"
import { TextCell } from "@/lib/components/grid/cells/text-cell"

export class CreateProjectPane extends ModalPane
   constructor: () ->
      super("Create a Project", "Save", template())

      @name = ko.observable()
      @streetAddress1 = ko.observable()
      @streetAddress2 = ko.observable()
      @city = ko.observable()
      @state = ko.observable()
      @zipcode = ko.observable()
      @country = ko.observable()
      @groupOptions = ko.observableArray()
      @startDate = ko.observable()
      @endDate = ko.observable()
      @dailyStartTime = ko.observable(7)
      @dailyEndTime = ko.observable(15.5)
      @jobNumber = ko.observable()
      @selectedTimezone = ko.observable()
      @colorOptions = ko.observableArray()
      @selectedColor = ko.observable("#34FFA9")
      @selectedStatus = ko.observable('active')
      @sendDailyRecap = ko.observable(true)
      @bidRate = ko.observable()

      @colorSelectorPopupBuilder = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@selectedColor)],
            ['cp-select-color-btn', 'cp-select-color-btn__color'],
            ['create-project__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['cp-select-color-btn__color']}
      }

      @selectedGroups = ko.observable(new Set())
      
      @groupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=> @groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedGroups()
            write: (groups) => @selectedGroups(groups)
         })
         textProvider: (group) => group.name
      })
      @groupDropdownParams = {
         actionInterceptor: @groupDropdownPane.actionInterceptor,
         cellFactory: @groupDropdownPane.cellFactory,
         panes: [@groupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedGroups()
            write: (groups) => @selectedGroups(groups)
         })
         placeholder: "Select Groups"
         isClearable: true
         selectedItemCellFactory: TextCell.factory((group) => group.id),
      };

      @loadData()

   maybeShowValidationError: (next) =>
      return @displayingNotice(CreateProjectPane.Notice.NAME) unless validateInput(@name())
      return @displayingNotice(CreateProjectPane.Notice.GROUP) unless @selectedGroups().size != 0
      for group in @selectedGroups()
         return @displayingNotice(CreateProjectPane.Notice.GROUP) unless group?.value()? and validateInput(group.value())
      if @selectedStatus() == "active"
         return @displayingNotice(CreateProjectPane.Notice.START_DATE) unless @startDate()?

      @displayingNotice(null)
      next()

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      @maybeShowValidationError =>
         @data.set("name", @name()) if @name()?
         @data.set("job_number", @jobNumber()) if @jobNumber()?
         @data.set("address_1", @streetAddress1()) if @streetAddress1()?
         @data.set("address_2", @streetAddress2()) if @streetAddress2()?
         @data.set("city_town", @city()) if @city()?
         @data.set("state_province", @state()) if @state()?
         @data.set("zipcode", @zipcode()) if @zipcode()?
         @data.set("country", @country()) if @country()?
         if @selectedGroups().length != 0
            groupIds = []
            for group from @selectedGroups()
               groupIds.push(group)
            @data.set("group_ids", groupIds)
         @data.set("start_date", @startDate().getTime()) if @startDate()?
         @data.set("est_end_date", @endDate().getTime()) if @endDate()?
         @data.set("daily_start_time", @dailyStartTime()) if @dailyStartTime()?
         @data.set("daily_end_time", @dailyEndTime()) if @dailyEndTime()?
         @data.set("color", @selectedColor()) if @selectedColor()?
         @data.set("timezone", @selectedTimezone()) if @selectedTimezone()?
         @data.set("status", @selectedStatus())
         @data.set("send_daily_recap", @sendDailyRecap())
         @data.set("bid_rate", @bidRate()) if @bidRate()?

         modalManager.modalFinished()

   loadData: ->
      stream = await GroupStore.findGroupsStream().stream
      for await row from stream
         @groupOptions.push(row)

      defaultStore.getResourceColorStrings (err, colors) =>
         @colorOptions(colors)


CreateProjectPane.Notice = {
   NAME: {
      text: 'Your project needs a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   GROUP: {
      text: 'At least one group must be selected.'
      info: null
      color: 'red'
      dissmissable: true
   }
   START_DATE: {
      text: 'Active projects need a start date.'
      info: null
      color: 'red'
      dissmissable: true
   }
}
