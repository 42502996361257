import "./phone-input.styl"
import PhoneInputTemplateFn from "./phone-input.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { TwilioStoreCore } from "@/stores/twilio-store.core"

### Popup ###
import { Popup } from "@/lib/components/popup/popup"
import { UniqueInputErrorPane } from "@/lib/components/popup/unique-input-error-pane"

export class PhoneInput
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.value, Function)
      assertOfType(params.value(), optional(String))
      assertOfType(params.isValid, Function)
      assertOfType(params.ignoreConflictIds, optional(Array))

      @value = params.value
      # Used by parent VM
      @isValid = params.isValid if params.isValid?

      @ignoreConflictIds = params.ignoreConflictIds

      @formattedValue = ko.observable()

      @flashGreen = ko.observable(false)
      @flashRed = ko.observable(false)

      @showingError = ko.observable(false)

      @hasValue = ko.pureComputed =>
         return ValidationUtils.validateInput(@value())

      @processing = ko.observable(false)

      @canSave = params.canSave
      @canSave(true)

      # TODO: This only becuase an issue if this input is rendered before data is available
      # to populate the value.
      @value.subscribe (newVal) =>
         if newVal != @formattedValue()
            @canSave(false)

      @errorTitle = ko.observable("Error")
      @errorMessage = ko.observable(null)
      @conflictPeople = ko.observableArray([])

      @focused = ko.observable()
      @focused.subscribe (newVal) =>
         if !newVal and ValidationUtils.validateInput(@value())
            @processing(true)
            @showingError(false)
            try
               result = await TwilioStoreCore.validateNumber(@value()).payload
               data = result.data
               if @ignoreConflictIds? and data.conflicts?
                  data.conflicts = data.conflicts.filter (item) =>
                     return @ignoreConflictIds.indexOf(item.id) == -1

               if data.conflicts.length > 0
                  @isValid(false)
                  @errorTitle("Conflict Error")
                  @errorMessage(null)
                  @conflictPeople(data.conflicts)
                  @showingError(true)
                  @flashRed(true)
                  setTimeout =>
                     @flashRed(false)
                  , 200

                  if data.formatted_number?
                     @formattedValue(data.formatted_number)
                     @value(data.formatted_number)
                  # TODO: What to do if it wasn't returned.
               else
                  if data.formatted_number?
                     @flashGreen(true)
                     setTimeout =>
                        @flashGreen(false)
                     , 200
                     @formattedValue(data.formatted_number)
                     @value(data.formatted_number)
                     @isValid(true)
                     @errorMessage(null)
                     @conflictPeople([])
                  else
                     @formattedValue(@value())
                     @showingError(true)
                     @errorTitle("Error")
                     @errorMessage(PhoneInput.Error.INVALID_FORMAT)
                     @conflictPeople(null)
                     @isValid(false)
                     @flashRed(true)
                     setTimeout =>
                        @flashRed(false)
                     , 200
               @canSave(true)
               @processing(false)
            catch err
               return console.error("Error in constructor PhoneInput: ", err)
         else
            @canSave(true)

      @errorPopupBuilder = =>
         return new Popup "", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new UniqueInputErrorPane(@errorTitle, @errorMessage, @conflictPeople)],
            ['phone-input__invalid-info'], ['phone-input__error-popup'],
            () => return

      @errorPopupWrapper = ko.observable({
         popupBuilder: @errorPopupBuilder
         options: {triggerClasses: ['phone-input__invalid-info']}
      })

   clearAll: ->
      @errorMessage(null)
      @conflictPeople([])
      @isValid(true)
      @showingError(false)
      @formattedValue(null)
      @value(null)


PhoneInput.Error = {
   INVALID_FORMAT: "Invalid Formatting. Make sure to include the full number."
}

PhoneInputTemplate = PhoneInputTemplateFn()

ko.components.register("phone-input",
   viewModel: PhoneInput,
   template: PhoneInputTemplate,
)