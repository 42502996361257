import "./tooltip.styl";
import template from "./tooltip.pug";
import type { MaybeObservable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import {
   AnchoredPopupColor,
   AnchoredPopupShadow,
} from "@/lib/components/anchord-popup/anchored-popup";

export interface TooltipParams {
   /** Text to show in the tooltip. */
   text: MaybeObservable<string | null>;

   /** Anchored popup color. */
   color?: MaybeObservable<AnchoredPopupColor>;

   /** Anchored popup shadow. */
   shadow?: MaybeObservable<AnchoredPopupShadow>;

   /** Content nodes to use instead of the child nodes of the component. */
   contentNodes?: MaybeObservable<HTMLElement[]>;

   /** Context to use when binding the content nodes. */
   data?: MaybeObservable<unknown> | null;
}

/**
 * Tooltip shown automatically when the content is hovered over.
 *
 * @example
 * <tooltip params="text: 'Tooltip text'">
 *    <div>Hoverable content</div>
 * </tooltip>
 */
export class Tooltip {
   readonly text: MaybeObservable<string | null>;
   readonly color: MaybeObservable<AnchoredPopupColor>;
   readonly shadow: MaybeObservable<AnchoredPopupShadow>;
   readonly contentNodes?: MaybeObservable<HTMLElement[]>;
   readonly data: MaybeObservable<unknown> | null;

   readonly isHovered = observable(false);
   readonly isPopupVisible = pureComputed(() => {
      return this.isHovered() && unwrap(this.text);
   });
   readonly anchorNodes: HTMLElement[];

   constructor({
      color = AnchoredPopupColor.LIGHT_GRAY,
      shadow = AnchoredPopupShadow.LIGHT,
      text = null,
      contentNodes,
      data = null,
   }: TooltipParams) {
      this.color = color;
      this.shadow = shadow;
      this.text = text;
      this.contentNodes = contentNodes;
      this.data = data;
      this.anchorNodes = [Tooltip.createNode()];
   }

   private static createNode() {
      const element = document.createElement("span");
      element.classList.add("tooltip__content");
      element.setAttribute("data-bind", "text: $data.text");
      return element;
   }
}

ko.components.register("tooltip", {
   viewModel: Tooltip,
   template: template(),
   synchronous: true,
});
