import "./time-picker.styl"
import timePickerTemplateFn from "./time-picker.pug"
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import ko from "knockout"

export class TimePicker
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.value, Function)

      @disposableHourBlock = false
      @hourOptions = ko.observableArray([
         new DropDownItem("1", 1)
         new DropDownItem("2", 2)
         new DropDownItem("3", 3)
         new DropDownItem("4", 4)
         new DropDownItem("5", 5)
         new DropDownItem("6", 6)
         new DropDownItem("7", 7)
         new DropDownItem("8", 8)
         new DropDownItem("9", 9)
         new DropDownItem("10", 10)
         new DropDownItem("11", 11)
         new DropDownItem("12", 12)
      ])
      @selectedHour = ko.observable()
      @selectedHour.subscribe =>
         return @disposableHourBlock = false if @disposableHourBlock
         unless @selectedMinute()?
            @disposableMinuteBlock = true
            @selectedMinute(@minuteOptions()[0])
         @setvalue()

      @disposableMinuteBlock = false
      @minuteOptions = ko.observableArray([
         new DropDownItem("00", 0)
         new DropDownItem("15", 0.25)
         new DropDownItem("30", 0.5)
         new DropDownItem("45", 0.75)
      ])
      @selectedMinute = ko.observable()
      @selectedMinute.subscribe =>
         return @disposableMinuteBlock = false if @disposableMinuteBlock
         @setvalue()

      @disposableMeridiemBlock = false
      @meridiemOptions = ko.observableArray([
         new SegmentedControllerItem("AM", "am")
         new SegmentedControllerItem("PM", "pm")
      ])
      @selectedMeridiem = ko.observable()
      @selectedMeridiem.subscribe =>
         return @disposableMeridiemBlock = false if @disposableMeridiemBlock
         @setvalue()

      @dispoableValueBlock = false
      @value = params.value
      @value.subscribe (newVal) =>
         return @dispoableValueBlock = false if @dispoableValueBlock
         @breakdownValue(newVal)

      if @value()?
         @breakdownValue(@value())
      else if params.defaultVal?
         @breakdownValue(params.defaultVal)
      else
         @disposableMeridiemBlock = true
         @selectedMeridiem(@meridiemOptions()[0])

      @disabled = params.disabled or ko.observable(false)

   breakdownValue: (val) ->
      @disposableHourBlock = true
      @disposableMinuteBlock = true
      @disposableMeridiemBlock = true
      
      minute = val % 1
      for option in @minuteOptions()
         if option.value() == minute
            @selectedMinute(option)
            break

      hour = Math.floor(val)
      if hour >= 12
         if hour == 12
            @selectedHour(@hourOptions()[11])
         else
            @selectedHour(@hourOptions()[hour - 13])
         @selectedMeridiem(@meridiemOptions()[1])
      else
         if hour == 0
            @selectedHour(@hourOptions()[11])
         else
            @selectedHour(@hourOptions()[hour - 1])
         @selectedMeridiem(@meridiemOptions()[0])

   setvalue: =>
      return unless @selectedHour()? and @selectedMinute()?
      if @selectedMeridiem().value() == "am"
         if @selectedHour().value() == 12
            hour = 0
         else
            hour = @selectedHour().value()
      else
         if @selectedHour().value() == 12
            hour = 12
         else
            hour = @selectedHour().value() + 12

      @dispoableValueBlock = true
      @value(hour + @selectedMinute().value())


timePickerTemplate = timePickerTemplateFn()

ko.components.register("time-picker",
   viewModel: TimePicker,
   template: timePickerTemplate
)
