import "./toggle-switch.styl"
import switchTemplateFn from "./toggle-switch.pug"

### Framework Includes ###
import ko from "knockout"

export class ToggleSwitch
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.onChanged, optional(Function))
      if params.value
         assertOfType(params.value, Function)
         assertOfType(params.value(), optional(Boolean))
         @value = params.value
      else
         @value = ko.observable()

      if params.disabled
         assertOfType(params.disabled, Function)
         @disabled = params.disabled
      else
         @disabled = ko.observable(false)

      @onChanged = if params.onChanged? then params.onChanged else null

   toggleSwitch: ->
      unless @disabled()
         @value(!@value())
         @onChanged(@value()) if @onChanged?


switchTemplate = switchTemplateFn()

ko.components.register("toggle-switch",
   viewModel: ToggleSwitch,
   template: switchTemplate
)
