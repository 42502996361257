import { EventHandler } from "@/lib/utils/event-handler";
import type { components, MaybeObservable, MaybeSubscribable, Subscribable } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import template from "./field-editor.pug";
import "./field-editor.styl";

export interface DeleteText {
   text: string;
   pendingText: string;
}

export interface FieldEditorParams {
   title: Subscribable<string> | string;
   onSave: () => void;
   onCancel: () => void;
   canSave?: MaybeSubscribable<boolean> | null;
   saveText?: MaybeObservable<string>;
   cancelText?: MaybeObservable<string>;
   hasSaveButton?: MaybeSubscribable<boolean>;
   onDelete?: MaybeObservable<() => Promise<void>>;
   deleteText?: MaybeObservable<DeleteText>;
}

const CONFIRMING_DELETE_CLASS = "field-editor--confirming-delete";

export class FieldEditor {
   readonly title: MaybeSubscribable<string>;
   readonly saveText: MaybeObservable<string>;
   readonly cancelText: MaybeObservable<string>;
   readonly canSave: MaybeSubscribable<boolean>;
   readonly hasSaveButton: MaybeSubscribable<boolean>;
   readonly onDelete?: MaybeObservable<() => Promise<void>>;
   readonly deleteText: MaybeObservable<DeleteText>;

   readonly isSaveDisabled = pureComputed(() => {
      return !unwrap(this.canSave);
   });

   readonly hasDeleteButton = pureComputed(() => {
      return Boolean(this.onDelete && unwrap(this.onDelete));
   });

   constructor(
      private readonly params: FieldEditorParams,
      private readonly element: Element,
      readonly contentNodes: Node[],
   ) {
      this.title = params.title;
      this.canSave = params.canSave ?? true;
      this.saveText = params.saveText ?? "Save";
      this.cancelText = params.cancelText ?? "Cancel";
      this.hasSaveButton = params.hasSaveButton ?? true;
      this.onDelete = params.onDelete;
      this.deleteText = params.deleteText ?? { text: "Clear", pendingText: "Clearing..." };
   }

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const handled = new EventHandler([
         {
            visit: () =>
               event.key == "Enter" && (event.metaKey || event.ctrlKey) && unwrap(this.canSave),
            accept: () => this.onSave(),
         },
         {
            visit: () => event.key == "Escape",
            accept: () => this.params.onCancel(),
         },
      ]).handle(event);
      return !handled;
   };

   onSave = (): void => {
      this.element.classList.remove(CONFIRMING_DELETE_CLASS);
      if (this.canSave) {
         this.params.onSave();
      }
   };

   onCancel = (): void => {
      this.params.onCancel();
   };

   onDeleteConfirming = (): void => {
      this.element.classList.add(CONFIRMING_DELETE_CLASS);
   };

   onDeleteCancel = (): void => {
      this.element.classList.remove(CONFIRMING_DELETE_CLASS);
   };

   onDeleteInternal = (): Promise<void> => {
      this.element.classList.remove(CONFIRMING_DELETE_CLASS);
      return unwrap(this.onDelete!)();
   };
}

ko.components.register("field-editor", {
   viewModel: {
      createViewModel: (params: components.ViewModelParams, componentInfo) => {
         return new FieldEditor(
            params as FieldEditorParams,
            componentInfo.element as Element,
            componentInfo.templateNodes || [],
         );
      },
   } as components.ViewModelFactory,
   template: template(),
   synchronous: true,
});
