import "./unique-input-error-pane.styl"
import template from "./unique-input-error-pane.pug"
import { ObservableData } from "@/lib/utils/observable-data"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"


export class UniqueInputErrorPane extends PopupPane
   constructor: (title, errorMessage, conflictPeople) ->
      assertArgs(arguments, Function, nullable(Function), nullable(Function))
      super(template())

      @title = title
      @errorMessage = errorMessage
      @conflictPeople = conflictPeople

   navigateToPerson: (person) ->
      # In the event this is being fired from a modal.
      modalManager.modalCancelled()
      # TODO: Evalute if this is a security issue since we can't garuntee the user has
      # group overlap with the person.
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{person.id}")